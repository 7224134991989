var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"table",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.filters.search,"footer-props":_vm.footerProps},on:{"current-items":_vm.updateFiltered},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('label.search'),"single-line":"","hide-details":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('v-spacer'),_vm._t("default")],2)]},proxy:true},{key:"item.scheduledDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.scheduledDate,_vm.$locale, _vm.timeZone))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('generatedReports.'+item.status))+" ")]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [(_vm.actions.indexOf('download') >= 0)?_c('v-btn',{attrs:{"text":"","icon":"","color":"primary","disabled":item.status === 'ERROR',"title":_vm.$t('generatedReports.download')},on:{"click":function($event){return _vm.throwAction('downloadItem', item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e(),(_vm.actions.indexOf('delete') >= 0)?_c('v-btn',{attrs:{"text":"","icon":"","color":"primary","title":_vm.$t('generatedReports.delete')},on:{"click":function($event){return _vm.throwAction('deleteItem', item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"header.selections",fn:function(){return [(_vm.selection == 'multiple')?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.selectUnselectAll()}}},[(_vm.areAllSelected())?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked-outline ")]):(_vm.isAnySelected())?_c('v-icon',[_vm._v(" mdi-minus-box-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1):_vm._e()]},proxy:true},{key:"item.selections",fn:function(ref){
var item = ref.item;
return [(_vm.selection != 'none')?_c('span',[(_vm.isSelected(item))?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.deselect(item)}}},[(_vm.selection === 'multiple')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked-outline ")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-radiobox-marked ")])],1):_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.select(item)}}},[(_vm.selection === 'multiple')?_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-circle-outline ")])],1)],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }