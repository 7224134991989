<template>
  <v-data-table
    ref="table"
    multi-sort
    :headers="headers"
    :items="items"
    :search="filters.search"
    :footer-props="footerProps"
    dense
    @current-items="updateFiltered"
  >
    <!-- TOP BAR -->
    <template #[`top`]>
      <v-toolbar
        flat
        color="white"
      >
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          :label="$t('label.search')"
          single-line
          hide-details
        />
        <v-spacer />
        <slot />
      </v-toolbar>
    </template>
    <!-- Device type -->
    <template #[`item.status.type`]="{ item }">
      {{ item.status.type ? $t('deviceType.' + item.status.type) : '-' }}
    </template>
    <!-- System Type: header -->
    <template #[`item.skyIt.status.systemType`]="{ item }">
      {{ item.skyIt && item.skyIt.status && item.skyIt.status.systemType ? $t('headendSystemType.' + item.skyIt.status.systemType) : '-' }}
    </template>
    <!-- Headend status:header -->
    <template #[`header.headendStatus`]>
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          {{ $t('status') }}
          <v-btn
            icon
            :color="filters.error.length ? 'primary' : ''"
            v-on="on"
          >
            <v-icon small>
              mdi-filter-menu
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="subtitle-1">
            {{ $t('headends.statusFilter') }}
          </v-card-title>
          <v-card-text>
            <v-checkbox
              v-for="item in noAlarms"
              :key="item.value"
              v-model="filters.noAlarms"
              :label="item.text"
              :value="item.value"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            />
            <v-divider />
            <v-checkbox
              v-for="[alarm] in activeAlarms"
              :key="alarm"
              v-model="filters.error"
              :label="$t('alarmList.'+alarm)"
              :value="alarm"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="
                !filters.error.length && !filters.noAlarms.length
              "
              text
              @click="
                filters.error = []
                filters.noAlarms = []
              "
            >
              {{ $t('clearFilters') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
    <!-- Headend status:cell -->
    <template #[`item.headendStatus`]="{ item }">
      <v-icon
        v-if="item.status && item.status.alarms.length"
        color="error"
        :title="headendStatusErrorTitle(item.status.alarms)"
      >
        mdi-alert-outline
      </v-icon>
      <v-icon
        v-else
        color="success"
        :title="$t('statusOk')"
      >
        mdi-check
      </v-icon>
    </template>
    <!-- DRM  status: header -->
    <template #[`header.status.drm`]>
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          {{ $t('drm') }}
          <v-btn
            icon
            :color="filters.drm.length ? 'primary' : ''"
            v-on="on"
          >
            <v-icon small>
              mdi-filter-menu
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="subtitle-1">
            {{ $t('filterDRMs') }}
          </v-card-title>
          <v-card-text>
            <v-checkbox
              v-for="item in noDrm"
              :key="item.value"
              v-model="filters.noDrm"
              :label="item.text"
              :value="item.value"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            />
            <v-divider />
            <v-checkbox
              v-for="item in drmTypes"
              :key="item.value"
              v-model="filters.drm"
              :label="item.text"
              :value="item.value"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="!filters.drm.length || !filters.noDrm.length"
              text
              @click="
                filters.drm = []
                filters.noDrm = []
              "
            >
              {{ $t('clearFilters') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
    <!-- DRM  status: cell -->
    <template #[`item.status.drm`]="{ item }">
      <v-icon
        v-if="item.status.drm.length"
        color="success"
        :title="$t(item.status.drm)"
      >
        mdi-check
      </v-icon>
      <v-icon
        v-else
        color="error"
      >
        mdi-close
      </v-icon>
    </template>
    <!-- SYSTEM TYPE : cell -->
    <template #[`header.skyIt.status.systemType`]>
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          {{ $t('systemType') }}
          <v-btn
            icon
            :color="filters.sstype.length ? 'primary' : ''"
            v-on="on"
          >
            <v-icon small>
              mdi-filter-menu
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="subtitle-1">
            {{ $t('filtersSType') }}
          </v-card-title>
          <v-card-text>
            <v-checkbox
              v-for="item in systemTypes"
              :key="item.value"
              v-model="filters.sstype"
              :label="item.text"
              :value="item.value"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="!filters.sstype.length"
              text
              @click="filters.sstype = []"
            >
              {{ $t('clearFilters') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
    <!-- State: header -->
    <template #[`header.tabcorp.status.state`]>
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          {{ $t('headends.state') }}
          <v-btn
            icon
            :color="filters.states.length ? 'primary' : ''"
            v-on="on"
          >
            <v-icon small>
              mdi-filter-menu
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="subtitle-1">
            {{ $t('filterState') }}
          </v-card-title>
          <v-card-text>
            <v-checkbox
              v-for="item in noStates"
              :key="item.value"
              v-model="filters.noStates"
              :label="item.text"
              :value="item.value"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            />
            <v-divider />
            <v-checkbox
              v-for="state in getTabcorpStateList()"
              :key="state"
              v-model="filters.states"
              :label="state"
              :value="state"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            />
          </v-card-text>
          <v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :disabled="!filters.states.length && !filters.noStates.length"
                text
                @click="
                  filters.states = []
                  filters.noStates = []
                "
              >
                {{ $t('clearFilters') }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
    <!-- Groups: header -->
    <template #[`header.config.groups`]="{ header }">
      {{ header.text }}
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            :color="filters.groups.length ? 'primary' : ''"
            v-on="on"
          >
            <v-icon small>
              mdi-filter-menu
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="subtitle-1">
            {{ $t('filterGroups') }}
          </v-card-title>
          <v-card-text>
            <v-treeview
              v-model="filters.noGroups"
              item-key="id"
              :items="itemNoGroups"
              hoverable
              color="primary"
              selected-color="primary"
              transition
              selectable
              expand-icon="mdi-menu-down-outline"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              selection-type="independent"
            >
              <template #label="{ item }">
                {{ item.name }}
              </template>
            </v-treeview>
            <v-divider />
            <v-checkbox
              v-for="group in groups"
              :key="group.id"
              v-model="filters.groups"
              :label="group.name"
              :value="group.id"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="
                !filters.groups.length && !filters.noGroups.length
              "
              text
              @click="
                filters.groups = []
                filters.noGroups = []
              "
            >
              {{ $t('clearFilters') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
    <!-- Groups: cell -->
    <template
      v-if="groups.length"
      #[`item.config.groups`]="{ item }"
    >
      <v-chip
        v-for="(group, index) in item.config.groups"
        :key="index"
        class="ma-1"
        small
        label
      >
        {{ getGroupById(group).name }}
      </v-chip>
    </template>
    <!-- Connection: header -->
    <template #[`header.status.connection`]="{ header }">
      {{ header.text }}
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            :color="filters.connection.length ? 'primary' : ''"
            v-on="on"
          >
            <v-icon small>
              mdi-filter-menu
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="subheading font-weight-bold">
            {{ $t('filterConnection') }}
          </v-card-title>
          <v-card-text>
            <v-checkbox
              v-model="filters.connection"
              value="online"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            >
              <template #label>
                <v-icon
                  class="pr-2"
                  color="success"
                  small
                >
                  mdi-server-network
                </v-icon>
                {{ $t('online') | capitalize }}
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="filters.connection"
              value="connecting"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            >
              <template #label>
                <v-icon
                  class="pr-2"
                  color="warning"
                  small
                >
                  mdi-server-network
                </v-icon>
                {{ $t('connecting') | capitalize }}
              </template>
            </v-checkbox>
            <v-checkbox
              v-model="filters.connection"
              color="primary"
              value="offline"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            >
              <template #label>
                <v-icon
                  class="pr-2"
                  small
                >
                  mdi-server-network-off
                </v-icon>
                {{ $t('offline') | capitalize }}
              </template>
            </v-checkbox>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="!filters.connection.length"
              text
              @click="filters.connection = []"
            >
              {{ $t('clearFilters') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
    <!-- Connection: cell -->
    <template #[`item.status.connection`]="{ item }">
      <v-icon
        v-if="!isOffline(item)"
        :color="isOnline(item) ? 'success' : 'warning'"
        :title="isOnline(item) ? $t('online') : $t('connecting')"
      >
        mdi-server-network
      </v-icon>
      <v-icon
        v-else
        :title="$t('offline')"
      >
        mdi-server-network-off
      </v-icon>
    </template>
    <!-- Settings: cell -->
    <template #[`item.settings`]="{ item }">
      <!-- VIEW BUTTON -->
      <v-btn
        v-if="showAction('view')"
        text
        icon
        color="primary"
        :title="$t('headendAction.show')"
        @click="throwAction('viewItem', item)"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <!-- EDIT BUTTON -->
      <v-btn
        v-if="showAction('edit')"
        text
        icon
        color="primary"
        :title="$t('headendAction.edit')"
        @click="throwAction('editItem', item)"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <!-- ACCESS BUTTON -->
      <v-btn
        v-if="showAction('access')"
        text
        icon
        color="primary"
        :disabled="isOffline(item)"
        :title="$t('headendAction.access')"
        @click="throwAction('accessItem', item)"
      >
        <v-icon>mdi-login-variant</v-icon>
      </v-btn>
      <!-- DOWNLOAD ACTION -->
      <v-btn
        v-if="showAction('download')"
        text
        icon
        color="primary"
        :disabled="isOffline(item)"
        :title="$t('headendAction.downloadDetailedReport')"
        @click="throwAction('download', item)"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <!-- LYNK BUTTON -->
      <v-btn
        v-if="showAction('lynk')"
        text
        icon
        color="primary"
        :disabled="isOffline(item) || !isLynk(item)"
        :title="$t('headendAction.accessLynk')"
        @click="throwAction('accessLynk', item)"
      >
        <v-icon>mdi-lock-open</v-icon>
      </v-btn>
      <!-- DISCONNECT BUTTON -->
      <v-btn
        v-if="showAction('disconnect')"
        text
        icon
        color="primary"
        :title="$t('headendAction.disconnect')"
        @click="throwAction('disconnectItem', item)"
      >
        <v-icon>mdi-server-remove</v-icon>
      </v-btn>
      <!-- DELETE BUTTON -->
      <v-btn
        v-if="showAction('delete')"
        text
        icon
        color="primary"
        :title="$t('headendAction.delete')"
        @click="throwAction('deleteItem', item)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <!-- DELETE OFFLINE BUTTON -->
      <v-btn
        v-if="showAction('deleteOffline')"
        text
        icon
        color="primary"
        :disabled="isOnline(item)"
        :title="$t('headendAction.delete')"
        @click="throwAction('deleteItem', item)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <!-- SELECTIONS HEADER -->
    <template #[`header.selections`]>
      <v-btn
        v-if="selection == 'multiple'"
        text
        icon
        @click="selectUnselectAll()"
      >
        <v-icon
          v-if="areAllSelected()"
          color="primary"
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon v-else-if="isAnySelected()">
          mdi-minus-box-outline
        </v-icon>
        <v-icon v-else>
          mdi-checkbox-blank-outline
        </v-icon>
      </v-btn>
    </template>
    <!-- Selections: cell -->
    <template #[`item.selections`]="{ item }">
      <span v-if="selection != 'none'">
        <v-btn
          v-if="isSelected(item)"
          text
          icon
          @click="deselect(item)"
        >
          <v-icon
            v-if="selection === 'multiple'"
            color="primary"
          >
            mdi-checkbox-marked-outline
          </v-icon>
          <v-icon
            v-else
            color="primary"
          >
            mdi-radiobox-marked
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          text
          icon
          @click="select(item)"
        >
          <v-icon v-if="selection === 'multiple'">
            mdi-checkbox-blank-outline
          </v-icon>
          <v-icon v-else>
            mdi-checkbox-blank-circle-outline
          </v-icon>
        </v-btn>
      </span>
    </template>
  </v-data-table>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import referenceData from '@/mixins/referenceData.js'

const initHeadendsTableFilter = {
  search: '',
  groups: [],
  noGroups: [],
  error: [],
  noAlarms: [],
  connection: [],
  drm: [],
  noDrm: [],
  sstype: [],
  states: [],
  noStates: []
}

export default Vue.extend({
  name: 'HeadendsTable',
  mixins: [referenceData],
  props: {
    items: {
      type: Array,
      default: () => []
    }, // as PropOptions<Headend[]>, // The list of headends
    selection: {
      type: String,
      default: 'none' // single or multiple
    }, // as PropOptions<string>,
    actions: {
      type: Array,
      default: () => [] // Actions types: view, edit, access, download, lynk, delete
    }, // as PropOptions<string[]>,
    columns: {
      type: Array,
      default: () => [] // Columns to show:
    }, // as PropOptions<string[]>,
    alertsFilter: {
      type: Array,
      default: () => []
    }// as PropOptions<string[]>
  },
  data () {
    return {
      selectedItems: {},
      filteredItems: [],
      filters: initHeadendsTableFilter,
      itemNoGroups: [
        {
          id: 1,
          name: this.$t('filterNoGroups')
        }
      ],
      noDrm: [{ text: this.$t('headends.noDRM') }],
      noAlarms: [{ text: this.$t('headends.noAlarms') }],
      noStates: [{ text: this.$t('headends.noStates') }],
      possibleHeaders: {
        remoteSiteDeviceName: {
          text: this.$t('headends.siteName'),
          align: 'left',
          sortable: true,
          value: 'status.remoteSiteDeviceName'
        },
        contractId: {
          text: this.$t('headends.contractId'),
          align: 'left',
          sortable: true,
          value: 'skyIt.status.contractId'
        },
        id: {
          text: this.$t('headends.id'),
          align: 'left',
          sortable: true,
          value: 'id'
        },
        firmwareVersion: {
          text: this.$t('headends.firmwareVersion'),
          align: 'left',
          sortable: true,
          value: 'status.firmwareVersion'
        },
        province: {
          text: this.$t('headends.province'),
          align: 'left',
          sortable: true,
          value: 'skyIt.status.province'
        },
        state: {
          text: this.$t('headends.state'),
          align: 'left',
          sortable: true,
          value: 'tabcorp.status.state',
          filter: (value) => {
            if (value !== undefined && value !== null) {
              for (const i of this.filters.states) {
                if (value.includes(i)) return true
              }
            }
            if (this.filters.noStates.length > 0) {
              if (value === undefined || value === null || value.length === 0) {
                return true
              }
            } else {
              if (!this.filters.states.length) return true
            }
            return false
          }
        },
        type: {
          text: this.$t('headends.headendType'),
          align: 'left',
          sortable: true,
          value: 'status.type'
        },
        systemType: {
          text: this.$t('headends.systemType'),
          align: 'left',
          sortable: false,
          value: 'skyIt.status.systemType',
          filter: (value, search, item) => {
            if (!this.filters.sstype.length) {
              return true
            }
            for (const i of this.filters.sstype) {
              if (item.skyIt && 'systemType' in item.skyIt.status) {
                if (item.skyIt.status.systemType.includes(i)) {
                  return true
                }
              }
            }
            return false
          }
        },
        headendStatus: {
          text: this.$t('status'),
          align: 'left',
          sortable: false,
          value: 'headendStatus',
          filter: (value, search, item) => {
            for (const i of this.filters.error) {
              if (item.status.alarms.includes(i)) {
                return true
              }
            }
            if (this.filters.noAlarms.length > 0) {
              if (item.status.alarms.length === 0) {
                return true
              }
            } else {
              if (!this.filters.error.length) return true
            }
            return false
          }
        },
        drm: {
          text: this.$t('drm'),
          align: 'left',
          sortable: false,
          value: 'status.drm',
          filter: (value, search, item) => {
            for (const i of this.filters.drm) {
              if (item.status.drm.includes(i)) {
                return true
              }
            }
            if (this.filters.noDrm.length > 0) {
              if (item.status.drm.length === 0) {
                return true
              }
            } else {
              if (!this.filters.drm.length) return true
            }
            return false
          }
        },
        venueId: {
          text: this.$t('headends.venueId'),
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'tabcorp.status.uniqueVenueID'
        },
        alias: {
          text: this.$t('headends.alias'),
          align: 'left',
          sortable: true,
          value: 'config.alias'
        },
        groups: {
          text: this.$t('groups.groups'),
          align: 'left',
          sortable: false,
          value: 'config.groups',
          filter: (value) => {
            for (const i of this.filters.groups) {
              if (value.includes(i)) return true
            }
            if (this.filters.noGroups.length > 0) {
              if (value.length === 0) {
                return true
              }
            } else {
              if (!this.filters.groups.length) return true
            }
            return false
          }
        },
        connection: {
          text: this.$t('connection'),
          align: 'center',
          sortable: false,
          value: 'status.connection',
          filter: (value, search, item) => {
            if (!this.filters.connection.length) return true
            for (const i of this.filters.connection) {
              if (item.status.connection === i) {
                return true
              }
            }
            return false
          }
        },
        settings: {
          text: '',
          align: 'end',
          sortable: false,
          value: 'settings'
        },
        selections: {
          text: '',
          align: 'end',
          sortable: false,
          value: 'selections'
        }
      },
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('headendsPerPageText')
      }
    }
  },
  computed: {
    ...mapGetters({ getGroupById: 'getGroupById' }),
    ...mapGetters({ activeAlarms: 'activeHeadendAlarmMap' }),
    ...mapState({
      groups: state => {
        return state.groupsNew.all
      }
    }),
    headers () {
      const headers = []
      this.columns.forEach(item => {
        headers.push(this.possibleHeaders[item])
      })
      if (this.actions.length > 0) {
        headers.push(this.possibleHeaders.settings)
      }
      if (this.selection === 'multiple' || this.selection === 'single') {
        headers.push(this.possibleHeaders.selections)
      }
      return headers
    }
  },
  watch: {
    items: function (newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.selectedItems = {}
        return
      }
      for (let i = 0; i < newVal.length; i++) {
        if (newVal[i].id !== oldVal[i].id) {
          this.selectedItems = {}
          return
        }
      }
    },
    alertsFilter: function (newErrorFilter, oldErrorFilter) {
      if (Array.isArray(newErrorFilter)) {
        this.filters.error = newErrorFilter
      }
    }
  },
  methods: {
    isFlow: function (item) {
      return item.status.type === 'flowskyit' || item.status.type === 'flow'
    },
    isLynk: function (item) {
      return item.status.drm.includes('lynk')
    },
    isOffline: function (item) {
      return item.status.connection === 'offline'
    },
    isOnline: function (item) {
      return item.status.connection === 'online'
    },
    showAction: function (action) {
      return this.actions.includes(action)
    },
    throwAction: function (action, item) {
      this.$emit(action, item)
    },
    headendStatusErrorTitle: function (errorArray) {
      const translatedErrors = errorArray.map((text) => this.$t('alarmList.' + text))
      return this.$t('headendErrorTitle', { error: translatedErrors.join(', ') })
    },
    isSelected (item) {
      return this.selectedItems[item.id]
    },
    deselect: function (item) {
      this.$delete(this.selectedItems, item.id)
      this.$emit('input', Object.values(this.selectedItems))
    },
    select: function (item) {
      if (this.selectedItems !== {} && this.selection === 'single') {
        this.selectedItems = {}
      }
      this.$set(this.selectedItems, item.id, item)
      this.$emit('input', Object.values(this.selectedItems))
    },
    selectUnselectAll: function () {
      if (Object.values(this.selectedItems).length > 0) {
        this.selectedItems = {}
      } else {
        this.selectedItems = {}
        this.filteredItems.forEach((item, i) => {
          this.$set(this.selectedItems, item.id, item)
        })
      }
      this.$emit('input', Object.values(this.selectedItems))
    },
    areAllSelected: function () {
      return Object.values(this.selectedItems).length === this.items.length
    },
    isAnySelected: function () {
      return Object.values(this.selectedItems).length > 0
    },
    updateFiltered: function (items) {
      if (this.$refs.table) {
        try {
          this.filteredItems = this.$refs.table.$children[0].filteredItems
        } catch (e) {
          this.filteredItems = items
        }
      } else {
        this.filteredItems = items
      }
    },
    getTabcorpStateList: function () {
      return 'NSW,QLD,NT,VIC,WA,SA,ACT,TAS'.split(',')
    }
  }
})
</script>
