<template>
  <div class="d-flex">
    <ModuleDialog
      v-model="camDialog.show"
      :title="camDialog.title"
      type="CAM"
      :module="module"
      :cam="cam"
    />
    <a
      :class="getClassName"
      :title="getCAMTitle"
      @click="showCAMDialog"
    />
  </div>
</template>

<script>
// common
import ModuleDialog from '../../../common/ModuleDialog.vue'

import { ReportStatusModuleCamStatus } from '../../../../types/Report'

export default {
  name: 'SlotCAM',
  components: {
    ModuleDialog
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    cam: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      camDialog: {
        show: false
      }
    }
  },
  computed: {
    isCAMReady: function () {
      return this.cam !== undefined && this.cam !== null && this.cam.status === ReportStatusModuleCamStatus.READY
    },
    hasAlarms: function () {
      return this.cam.alarms !== undefined && this.cam.alarms.length > 0
    },
    getClassName: function () {
      let className = 'module__cam'
      if (this.hasAlarms) {
        className = className.concat(' error')
      } else if (this.isCAMReady) {
        className = className.concat(' enabled')
      }
      return className
    },
    getCAMTitle: function () {
      return 'CAM ' + this.cam.id
    }
  },
  methods: {
    showCAMDialog: function () {
      this.camDialog.show = true
      this.camDialog.title = this.$t('moduleDialog.cam.title', { moduleInfo: this.getModuleDialogTitle() })
    },
    getModuleDialogTitle: function () {
      return this.$t('moduleDialog.title', { moduleModel: this.module.name, slot: this.module.slot })
    }
  }
}
</script>

<style scoped>
.module__cam {
  border: 0.0625rem solid #666;
  background-color: #666;
  display: block;
  width: 0.5rem;
  height: 9.25rem;
  cursor: pointer;
}
.module__cam.enabled {
  background-color: #00FF00;
}
.module__cam.error {
  background-color: #AD0000;
}
</style>
