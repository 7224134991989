<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-text-field
          v-model="passwordAdmin"
          prepend-icon="mdi-lock"
          :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
          :label="$t('passwordAdmin')"
          :type="showPassword1 ? 'text' : 'password'"
          :rules="[
            rules.requiredPassword,
            rules.passwordAdminMatch
          ]"
          @click:append="showPassword1 = !showPassword1"
          @click="validateForm"
          @keyup="validateForm"
          @change="setPasswords"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="passwordAdmin2"
          prepend-icon="mdi-lock"
          :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
          :label="$t('passwordAdminepeat')"
          :type="showPassword2 ? 'text' : 'password'"
          :rules="[
            rules.requiredPassword,
            rules.passwordAdminMatch
          ]"
          @click:append="showPassword2 = !showPassword2"
          @click="validateForm"
          @keyup="validateForm"
          @change="setPasswords"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="passwordInstaller"
          prepend-icon="mdi-lock"
          :append-icon="showPassword3 ? 'mdi-eye' : 'mdi-eye-off'"
          :label="$t('passwordInstaller')"
          :type="showPassword3 ? 'text' : 'password'"
          :rules="[
            rules.requiredPassword,
            rules.passwordInstallerMatch
          ]"
          @click:append="showPassword3 = !showPassword3"
          @click="validateForm"
          @keyup="validateForm"
          @change="setPasswords"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="passwordInstaller2"
          prepend-icon="mdi-lock"
          :append-icon="showPassword4 ? 'mdi-eye' : 'mdi-eye-off'"
          :label="$t('passwordInstallerRepeat')"
          :type="showPassword4 ? 'text' : 'password'"
          :rules="[
            rules.requiredPassword,
            rules.passwordInstallerMatch
          ]"
          @click:append="showPassword4 = !showPassword4"
          @click="validateForm"
          @keyup="validateForm"
          @change="setPasswords"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'ChangePasswordOrder',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          type: 'changePassword'
        }
      }
    }
  },
  data () {
    return {
      action: {
        type: 'changePassword'
      },
      rules: {
        required: value => !!value || this.$t('validation.requiredField'),
        requiredPassword: () =>
          !!this.passwordAdmin ||
          !!this.passwordInstaller ||
          this.$t('validation.requiredField'),
        passwordAdminMatch: () =>
          this.passwordAdmin === this.passwordAdmin2 ||
          this.$t('validation.passwordMismatch'),
        passwordInstallerMatch: () =>
          this.passwordInstaller === this.passwordInstaller2 ||
          this.$t('validation.passwordMismatch')
      },
      passwordAdmin: '',
      passwordAdmin2: '',
      passwordInstaller: '',
      passwordInstaller2: '',
      showPassword1: false,
      showPassword2: false,
      showPassword3: false,
      showPassword4: false
    }
  },
  watch: {
    action: {
      handler: function (newData) {
        this.$emit('input', newData)
      },
      deep: true
    }
  },
  mounted: function () {
    this.action = {
      type: 'changePassword'
    }
    if (this.value.info !== undefined && this.value.type === 'changePassword') {
      if (this.value.info.admin !== undefined && this.value.info.admin.length > 0) {
        this.passwordAdmin = this.value.info.admin
        this.passwordAdmin2 = this.value.info.admin
      }
      if (this.value.info.installer !== undefined && this.value.info.installer.length > 0) {
        this.passwordInstaller = this.value.info.installer
        this.passwordInstaller2 = this.value.info.installer
      }
    }
  },
  methods: {
    validateForm: function () {
      this.$emit('validateForm')
    },
    setPasswords: function () {
      this.action = {
        type: 'changePassword',
        info: {
          admin: this.passwordAdmin,
          installer: this.passwordInstaller
        }
      }
    }
  }
}
</script>
