<template>
  <v-container fluid>
    <ConfirmDialog
      v-model="deleteConfirmDialog.show"
      :item-id="deleteConfirmDialog.itemId"
      :title="$t('orderSchedule.deleteItemTitle')"
      :message="deleteConfirmDialog.message"
      @confirmed="deleteItem"
    />
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-btn
            icon
            color="primary"
            :title="$t('back')"
            @click="backToOrders"
          >
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ order.name }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            class="ma-2"
            color="primary"
            :title="$t('orderSchedule.delete')"
            @click="confirmDeleteItem"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                cols="3"
                md="3"
              >
                <v-text-field
                  v-model="parsed.creationDate"
                  :label="$t('orderSchedule.createdAt')"
                  readonly
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-textarea
                  v-model="order.description"
                  :label="$t('orderSchedule.description')"
                  outlined
                  readonly
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('orderSchedule.executionMode') }}
          </v-card-title>
          <v-card-text>
            <OrderScheduleExecutionMode
              :next-order-date="parsed.nextOrderDate"
              :last-order-date="parsed.lastOrderDate"
              :execution="order.execution"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('orderSchedule.target') }}
          </v-card-title>
          <v-card-text>
            <OrderScheduleTarget
              :target="order.target"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            {{ $t('orderSchedule.executed') }}
            <v-spacer />
            <v-btn
              class="ma-2"
              color="primary"
              :disabled="isReloadExecutedDisabled"
              :title="$t('orderSchedule.reload')"
              @click="reloadExecutedOrders"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <OrdersExecutedTable
              :items="executedOrders"
              :actions="['view']"
              :columns="[
                'scheduledDate',
                'name',
                'description',
                'action',
                'okay',
                'error',
                'status',
              ]"
              @viewItem="viewOrderExecution"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import router from '@/router'

import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { CHANNEL_MAP_TEMPLATES_GET_ALL } from '@/store/actions/channelMapTemplates'
import { ORDERS_SCHEDULES_GET_ALL, ORDERS_SCHEDULES_DELETE } from '@/store/actions/ordersSchedules'
import { FIRMWARES_GET_ALL } from '@/store/actions/firmwares'
import { HEADENDS_GET_ALL } from '@/store/actions/headends'
import { HEADEND_TYPES_GET_ALL } from '@/store/actions/headendTypes'
import { GROUPS_GET_ALL } from '@/store/actions/groups'

import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import OrderScheduleTarget from '@/views/orderSchedule/components/OrderScheduleTarget.vue'
import OrderScheduleExecutionMode from '@/views/orderSchedule/components/OrderScheduleExecutionMode.vue'
import OrdersExecutedTable from '@/views/ordersExecuted/components/OrdersExecutedTable.vue'

import OrderExecutionsService from '@/services/orders/OrderExecutionsService'

export default {
  name: 'OrderSchedule',
  components: {
    ConfirmDialog,
    OrderScheduleExecutionMode,
    OrderScheduleTarget,
    OrdersExecutedTable
  },
  data () {
    return {
      deleteConfirmDialog: { show: false },
      tab: null,
      dialog: false,
      tabIndex: 0,
      order: { execution: { mode: '' }, target: { groups: [], devices: [] } },
      parsed: { creationDate: '-', nextOrderDate: '-', lastOrderDate: '-' },
      executedOrders: [],
      isReloadExecutedDisabled: false
    }
  },
  computed: {
    ...mapGetters('customization', ['timeZone']),
    ...mapGetters(['getOrderScheduleById'])
  },
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    const promises = [
      this.$store.dispatch(ORDERS_SCHEDULES_GET_ALL),
      this.$store.dispatch(HEADEND_TYPES_GET_ALL),
      this.$store.dispatch(FIRMWARES_GET_ALL),
      this.$store.dispatch(HEADENDS_GET_ALL),
      this.$store.dispatch(GROUPS_GET_ALL)
    ]
    if (this.$store.getters['customization/isTabcorp']) {
      promises.push(this.$store.dispatch(CHANNEL_MAP_TEMPLATES_GET_ALL))
    }
    Promise.all(promises)
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('orderSchedule.loaded'))
        this.order = JSON.parse(JSON.stringify(this.getOrderScheduleById(this.$route.params.orderScheduleId)))
        this.parsed = {
          creationDate: this.$options.filters.formatDate(this.order.creationDate, this.$locale, this.timeZone),
          nextOrderDate: this.$options.filters.formatDate(this.order.nextOrderDate, this.$locale, this.timeZone),
          lastOrderDate: this.$options.filters.formatDate(this.order.lastOrderDate, this.$locale, this.timeZone)
        }
        this.loadOrderExecutions(this.order.id)
      })
      .catch(error => {
        this.$store.dispatch(LOADING_DECREASE)
        if (error.response.status !== 401) {
          this.$store.dispatch(SNACKBAR_ERROR, this.$t('orderSchedule.errorLoadingOrder'))
        }
      })
    const order = this.getOrderScheduleById(this.$route.params.orderScheduleId)
    if (order) {
      this.order = JSON.parse(JSON.stringify(order))
      this.parsed = {
        creationDate: this.$options.filters.formatDate(this.order.creationDate, this.$locale, this.timeZone),
        nextOrderDate: this.$options.filters.formatDate(this.order.nextOrderDate, this.$locale, this.timeZone),
        lastOrderDate: this.$options.filters.formatDate(this.order.lastOrderDate, this.$locale, this.timeZone)
      }
    }
  },
  methods: {
    backToOrders: function () {
      this.$router.push('/ordersSchedules')
    },
    confirmDeleteItem () {
      this.deleteConfirmDialog.message = this.$t(
        'orderSchedule.deleteItemMessage',
        this.order
      )
      this.deleteConfirmDialog.itemId = this.order.id
      this.deleteConfirmDialog.show = true
    },
    deleteItem: function (itemId) {
      this.$store
        .dispatch(ORDERS_SCHEDULES_DELETE, itemId)
        .then(() => {
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('orderSchedule.orderRemoved'))
          router.push('/ordersSchedules')
        })
        .catch(() => {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('orderSchedule.errorRemovingOrder')
          )
        })
    },
    reloadExecutedOrders () {
      this.isReloadExecutedDisabled = true
      this.loadOrderExecutions(this.order.id)
    },
    loadOrderExecutions (orderId) {
      OrderExecutionsService.getExecutedOrdersFromSchedule(orderId)
        .then(response => {
          this.isReloadExecutedDisabled = false
          this.executedOrders = response.data
        }).catch(() => {
          this.isReloadExecutedDisabled = false
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('orderSchedule.errorLoadingExecutions')
          )
        })
    },
    viewOrderExecution (orderExecution) {
      // TODO
    }
  }
}
</script>
