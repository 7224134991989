import {
  REPORTS_TEMPLATES_GET_ALL,
  REPORTS_TEMPLATES_CREATE,
  REPORTS_TEMPLATES_DELETE,
  REPORTS_TEMPLATES_CLEAR
} from '@/store/actions/reportsTemplates'

import { AUTH_LOGOUT } from '@/store/actions/authentication'

import reportsTemplatesApi from '@/services/api/reports/ReportsTemplates'

export default {
  state: {
    all: []
  },
  mutations: {
    [REPORTS_TEMPLATES_GET_ALL]: (state, data) => {
      if (data !== undefined && data !== null && data) {
        state.all = data
      }
    },
    [REPORTS_TEMPLATES_CREATE]: (state, reportTemplate) => {
      state.all.push(reportTemplate)
    },
    [REPORTS_TEMPLATES_DELETE]: (state, id) => {
      var index = state.all.findIndex(
        reportTemplate => reportTemplate.id === id
      )
      state.all.splice(index, 1)
    },
    [REPORTS_TEMPLATES_CLEAR]: state => {
      state.all.length = 0
    }
  },
  actions: {
    [REPORTS_TEMPLATES_GET_ALL]: ({ commit, state, dispatch }) => {
      return new Promise((resolve, reject) => {
        if (state.all.length > 0) {
          resolve(state.all)
          return
        }
        reportsTemplatesApi
          .getAll()
          .then(response => {
            // console.log('[store.reportsTemplates] Report templates lodaded')
            commit(REPORTS_TEMPLATES_GET_ALL, response)
            resolve(response)
          })
          .catch(error => {
            // console.error('[store.reportsTemplates] Error loading reportTemplates')
            if (error.response !== undefined && error.response.status === 401) {
              dispatch(AUTH_LOGOUT)
            }
            reject(error)
          })
      })
    },
    [REPORTS_TEMPLATES_CREATE]: ({ commit }, reportTemplate) => {
      return new Promise((resolve, reject) => {
        reportsTemplatesApi
          .create(reportTemplate)
          .then(() => {
            // console.log('[store.reportsTemplates] Report template ' + reportTemplate.id + ' created')
            reportTemplate.creationDate = new Date()
            commit(REPORTS_TEMPLATES_CREATE, reportTemplate)
            resolve()
          })
          .catch(error => {
            // console.error('[store.reportsTemplates] Error creating the report template with the id: ' + reportTemplate.id)
            reject(error)
          })
      })
    },
    [REPORTS_TEMPLATES_DELETE]: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        reportsTemplatesApi
          .delete(id)
          .then(() => {
            // console.log('[store.reportsTemplates] Report template ' + reportTemplate.id + ' removed')
            commit(REPORTS_TEMPLATES_DELETE, id)
            resolve()
          })
          .catch(error => {
            // console.error('[store.reportsTemplates] Error deleting the report template with the id: ' + reportTemplate.id)
            reject(error)
          })
      })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      commit(REPORTS_TEMPLATES_CLEAR)
    }
  },
  getters: {
    getReportTemplateById: state => reportEmplateId => {
      return state.all.find(reportTemplate => {
        return reportTemplate.id === reportEmplateId
      })
    }
  }
}
