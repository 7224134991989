import AuthorizationApi from '@/services/api/AuthorizationApi'

export default {
  login (username, password) {
    const payload = {
      username: username,
      password: password
    }
    return AuthorizationApi().post('/session', payload)
  }
}
