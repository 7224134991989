const referenceData = {
  data () {
    return {
      drmTypes: [
        {
          value: 'vsecure',
          text: this.$t('vsecure')
        },
        {
          value: 'lynk',
          text: this.$t('lynk')
        },
        {
          value: 'proidiom',
          text: this.$t('proidiom')
        },
        {
          value: 'simulcrypt',
          text: this.$t('simulcrypt')
        }
      ],
      systemTypes: [
        {
          value: 'unk',
          text: this.$t('headendSystemType.unk')
        },
        {
          value: 'cam',
          text: this.$t('headendSystemType.cam')
        },
        {
          value: 'box',
          text: this.$t('headendSystemType.box')
        },
        {
          value: 'etv',
          text: this.$t('headendSystemType.etv')
        },
        {
          value: 'sin',
          text: this.$t('headendSystemType.sin')
        },
        {
          value: 'mix',
          text: this.$t('headendSystemType.mix')
        },
        {
          value: 'clr',
          text: this.$t('headendSystemType.clr')
        }
      ],
      connectionTypes: [
        {
          value: 'online',
          icon: 'mdi-server-network',
          iconColor: 'success',
          text: this.$t('online')
        },
        {
          value: 'connecting',
          icon: 'mdi-server-network',
          iconColor: 'warning',
          text: this.$t('connecting')
        },
        {
          value: 'offline',
          icon: 'mdi-server-network-off',
          iconColor: '',
          text: this.$t('offline')
        }
      ],
      alarmTypes: [
        {
          value: 'unknown',
          text: this.$t('alarmList.unknown')
        },
        {
          value: 'cams',
          text: this.$t('alarmList.cams')
        },
        {
          value: 'inputs',
          text: this.$t('alarmList.inputs')
        },
        {
          value: 'outputs',
          text: this.$t('alarmList.outputs')
        },
        {
          value: 'system',
          text: this.$t('alarmList.system')
        },
        {
          value: 'hardware',
          text: this.$t('alarmList.hardware')
        },
        {
          value: 'modules',
          text: this.$t('alarmList.modules')
        },
        {
          value: 'services',
          text: this.$t('alarmList.services')
        },
        {
          value: 'ikunet',
          text: this.$t('alarmList.ikunet')
        },
        {
          value: 'temperature',
          text: this.$t('alarmList.temperature')
        },
        {
          value: 'switch',
          text: this.$t('alarmList.switch')
        },
        {
          value: 'hdmis',
          text: this.$t('alarmList.hdmis')
        }
      ],
      tabcorpAlarmTypes: [
        {
          value: 'modules',
          text: this.$t('alarmList.modules')
        },
        {
          value: 'cams',
          text: this.$t('alarmList.cams')
        },
        {
          value: 'inputs',
          text: this.$t('alarmList.inputs')
        },
        {
          value: 'outputs',
          text: this.$t('alarmList.outputs')
        },
        {
          value: 'firmware',
          text: this.$t('alarmList.firmware')
        },
        {
          value: 'services',
          text: this.$t('alarmList.services')
        }
      ]
    }
  }
}

export default referenceData
