<template>
  <a
    class="module__plug enabled"
    title="Plug"
  />
</template>

<script>
export default {
  name: 'SlotPlug'
}
</script>

<style scoped>
.module__plug {
  background-image: url('../../../../assets/images/plug.svg');
  width: 2.563rem;
  height: 3.5rem;
  margin-bottom: 0.25rem;
  cursor: auto;
}
.module__plug.enabled {
  background-image: url('../../../../assets/images/plug-enabled.svg');
  cursor: pointer;
}
</style>
