<template>
  <a
    class="module__input-sat"
    title="Input SAT"
  />
</template>

<script>
export default {
  name: 'SlotInputSAT'
}
</script>

<style scoped>
.module__input-sat {
  background-image: url('../../../../assets/images/input-sat.svg');
  width: 1.344rem;
  height: 1.344rem;
  background-size: 1.344rem 1.344rem;
  margin-bottom: 1.056rem;
  cursor: auto;
}
</style>
