<template>
  <v-card tile>
    <v-toolbar
      flat
      dark
      color="primary"
    >
      <v-btn
        icon
        dark
        @click="throwAction('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ $t('reportPreVisualization.title') }} {{ title ? '- ' + title : '' }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        class="ma-1"
        @click="downloadReport"
      >
        <v-icon left>
          mdi-download
        </v-icon>
        {{ $t('reportPreVisualization.downloadReport') }}
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ $t('reportPreVisualization.reportResume') }}
            </v-card-title>
            <v-card-text>
              <ReportResumeTable
                :report-date="reportDate"
                :report-headends="reportHeadends"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ $t('reportPreVisualization.reportData') }}
            </v-card-title>
            <v-card-text>
              <HeadendsTable
                :items="reportHeadends"
                :columns="tableHeadersColumns()"
                selection="none"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <span class="note mt-3">* {{ $t('reportOnDemand.reportFullDataNote') }}</span>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        @click="throwAction('close')"
      >
        {{ $t('button.close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { HEADENDS_GET_ALL } from '@/store/actions/headends'
import HeadendsTable from '@/views/headends/components/HeadendsTable.vue'
import ReportResumeTable from '@/views/reportOnDemand/components/ReportResumeTable.vue'

export default {
  name: 'ReportPreVisualization',
  components: {
    HeadendsTable,
    ReportResumeTable
  },
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => {}
    },
    load: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      reportHeadends: [],
      reportDate: new Date()
    }
  },
  computed: {
    ...mapGetters({
      getGroupById: 'getGroupById'
    }),
    ...mapGetters('customization', ['timeZone']),
    ...mapState({
      headends: state => {
        return state.headendsNew.all
      }
    })
  },
  watch: {
    load: function (newVal) {
      if (newVal) {
        this.loadReport()
      }
    }
  },
  mounted () {
    if (this.load) {
      this.loadReport()
    }
  },
  methods: {
    loadReport: function () {
      this.$store.dispatch(LOADING_INCREASE)
      Promise.all([this.$store.dispatch(HEADENDS_GET_ALL)])
        .then(() => {
          this.processData()
          this.$store.dispatch(LOADING_DECREASE)
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.response.status !== 401) {
            this.$store.dispatch(
              SNACKBAR_ERROR,
              this.$t('reportOnDemand.errorCreatingTheReport')
            )
          }
        })
    },
    throwAction: function (action, item) {
      this.$emit(action, item)
    },
    tableHeadersColumns: function () {
      if (this.$isSky) {
        // SKY headers in order
        return [
          'remoteSiteDeviceName',
          'province',
          'contractId',
          'id',
          'firmwareVersion',
          'systemType',
          'headendStatus',
          'drm',
          'groups'
        ]
      } else if (this.$isTabcorp) {
        // Tabcorp headers in order
        return ['headendStatus', 'venueId', 'id', 'alias', 'state', 'firmwareVersion']
      } else {
        // Default headers in order
        return ['headendStatus', 'id', 'alias', 'firmwareVersion']
      }
    },
    headersColumns: function () {
      if (this.$isSky) {
        // SKY headers in order
        return [
          'id',
          'status',
          'type',
          'contractId',
          'mainContractId',
          'name',
          'location',
          'description',
          'comment',
          'firmwareVersion',
          'timeZone',
          'maxTemp',
          'drm',
          'systemType',
          'province',
          'installer',
          'alerts',
          'lastCommunicationAt',
          'firstCommunicationAt',
          'lastDisconnectionAt',
          'lastConfigurationChangeAt',
          'groups'
        ]
      } else if (this.$isTabcorp) {
        // Tabcorp headers in order
        return [
          'id',
          'status',
          'type',
          'venueId',
          'name',
          'location',
          'alias',
          'comment',
          'firmwareVersion',
          'timeZone',
          'maxTemp',
          'systemType',
          'state',
          'installer',
          'installationDate',
          'alerts',
          'lastCommunicationAt',
          'firstCommunicationAt',
          'lastDisconnectionAt',
          'lastConfigurationChangeAt',
          'groups',
          'modelSerial'
        ]
      } else {
        // Default headers in order
        return [
          'id',
          'status',
          'alias',
          'type',
          'name',
          'location',
          'description',
          'firmwareVersion',
          'timeZone',
          'maxTemp',
          'drm',
          'lastCommunicationAt',
          'firstCommunicationAt',
          'lastDisconnectionAt',
          'lastConfigurationChangeAt',
          'alerts',
          'groups'
        ]
      }
    },
    processData: function () {
      this.reportHeadends.length = 0
      this.reportHeadends = this._filterByAlarms(
        this.headends,
        this.filters.alarmTypes
      )
      this.reportHeadends = this._filterByConnections(
        this.reportHeadends,
        this.filters.connectionTypes
      )
      this.reportHeadends = this._filterBySkySystems(
        this.reportHeadends,
        this.filters.systemTypes
      )
      this.reportHeadends = this._filterByDRMs(
        this.reportHeadends,
        this.filters.drmTypes
      )
      this.reportHeadends = this._filterByHeadendTypes(
        this.reportHeadends,
        this.filters.headendTypes
      )
      this.reportHeadends = this._filterByGroups(
        this.reportHeadends,
        this.filters.groups
      )
      this.reportDate = new Date()
    },
    _filterByAlarms: function (headends, alarms) {
      if (alarms.length === 0) {
        return headends
      }
      return headends.filter(headend => {
        if (headend.status.alarms.length === 0 && alarms.includes('WITHOUT')) {
          return true
        }
        const found = headend.status.alarms.some(alarm =>
          alarms.includes(alarm)
        )
        return found
      })
    },
    _filterByConnections: function (headends, connections) {
      if (connections.length === 0) {
        return headends
      }
      return headends.filter(headend => {
        return connections.includes(headend.status.connection)
      })
    },
    _filterBySkySystems: function (headends, systems) {
      if (systems.length === 0) {
        return headends
      }
      return headends.filter(headend => {
        return systems.includes(headend.skyIt.status.systemType)
      })
    },
    _filterByDRMs: function (headends, drms) {
      if (drms.length === 0) {
        return headends
      }
      return headends.filter(headend => {
        if (headend.status.drm.length === 0 && drms.includes('WITHOUT')) {
          return true
        }
        const found = headend.status.drm.some(drm => drms.includes(drm))
        return found
      })
    },
    _filterByHeadendTypes: function (headends, headendTypes) {
      if (headendTypes.length === 0) {
        return headends
      }
      return headends.filter(headend => {
        return headendTypes.includes(headend.status.type)
      })
    },
    _filterByGroups: function (headends, groups) {
      if (groups.length === 0) {
        return headends
      }
      return headends.filter(headend => {
        if (headend.config.groups.length === 0 && groups.includes('WITHOUT')) {
          return true
        }
        const found = headend.config.groups.some(group =>
          groups.includes(group)
        )
        return found
      })
    },
    downloadReport: function () {
      let csv = ''
      csv += this.headersColumns()
        .map(column => this.$t('reportOnDemand.' + column))
        .join(',')
      csv += '\n'

      this.reportHeadends.forEach(headend => {
        const row = []
        this.headersColumns().forEach(column => {
          switch (column) {
            // COMMON
            case 'id':
              row.push(headend.id)
              break
            case 'alias':
              var alias = ''
              if (headend.config.alias !== undefined && headend.config.alias !== null) {
                alias = headend.config.alias
                alias = JSON.stringify(alias)
              }
              row.push(alias)
              break
            case 'comment':
              var comment = ''
              if (headend.config.comment !== undefined && headend.config.comment !== null) {
                comment = JSON.stringify(comment)
                comment = comment.replaceAll('\\n', '\n')
              }
              row.push(comment)
              break
            case 'remoteSiteDeviceName':
            case 'name':
              var name = ''
              if (
                headend.status.remoteSiteDeviceName !== undefined &&
                headend.status.remoteSiteDeviceName !== null
              ) {
                name = headend.status.remoteSiteDeviceName
                  .replaceAll(',', '')
                  .replace(/(?:\r\n|\r|\n)/g, ' ')
                name = JSON.stringify(name)
              }
              row.push(name)
              break
            case 'remoteSiteLocation':
            case 'location':
              var location = ''
              if (
                headend.status.remoteSiteLocation !== undefined &&
                headend.status.remoteSiteLocation !== null
              ) {
                location = headend.status.remoteSiteLocation
                  .replaceAll(',', '')
                  .replace(/(?:\r\n|\r|\n)/g, ' ')
                location = JSON.stringify(location)
              }
              row.push(location)
              break
            case 'remoteSiteDescription':
            case 'description':
              var description = ''
              var forceRemoteSiteDescription
              forceRemoteSiteDescription = false
              if (column === 'remoteSiteDescription') {
                forceRemoteSiteDescription = true
              }
              if (!forceRemoteSiteDescription &&
                headend.config.description !== undefined &&
                headend.config.description !== null
              ) {
                description = headend.config.description
                  .replaceAll(',', '')
                  .replace(/(?:\r\n|\r|\n)/g, ' ')
                description = JSON.stringify(description)
              } else if (
                headend.status.remoteSiteDescription !== undefined &&
                headend.status.remoteSiteDescription !== null
              ) {
                description = headend.status.remoteSiteDescription
                  .replaceAll(',', '')
                  .replace(/(?:\r\n|\r|\n)/g, ' ')
                description = JSON.stringify(description)
              }
              row.push(description)
              break
            case 'firmwareVersion':
              var firmwareVersion = headend.status.firmwareVersion
              firmwareVersion = JSON.stringify(firmwareVersion)
              row.push(firmwareVersion)
              break
            case 'timeZone':
              var timeZone = this.timeZone
              timeZone = JSON.stringify(timeZone)
              row.push(timeZone)
              break
            case 'maxTemp':
              var maxTemp = ''
              if (headend.status.maxTemp !== undefined && headend.status.maxTemp !== null) {
                maxTemp = headend.status.maxTemp
                maxTemp = JSON.stringify(maxTemp)
              }
              row.push(maxTemp)
              break
            case 'drm':
              var drm = ''
              if (headend.status.drm !== undefined && headend.status.drm !== null) {
                drm = headend.status.drm.map(drm => this.$t(drm)).join(' ')
                drm = JSON.stringify(drm)
              }
              row.push(drm)
              break
            case 'firstConnection':
            case 'firstCommunicationAt':
              var firstConnection = ''
              if (headend.status.firstConnection !== null) {
                firstConnection = headend.status.firstConnection
              }
              firstConnection = JSON.stringify(firstConnection)
              row.push(firstConnection)
              break
            case 'lastConnection':
            case 'lastCommunicationAt':
              var lastConnection = ''
              if (headend.status.lastConnection !== null) {
                lastConnection = headend.status.lastConnection
              }
              lastConnection = JSON.stringify(lastConnection)
              row.push(lastConnection)
              break
            case 'lastDisconnection':
            case 'lastDisconnectionAt':
              var lastDisconnection = ''
              if (headend.status.lastDisconnection !== null) {
                lastDisconnection = headend.status.lastDisconnection
              }
              lastDisconnection = JSON.stringify(lastDisconnection)
              row.push(lastDisconnection)
              break
            case 'lastConfigurationChange':
            case 'lastConfigurationChangeAt':
              var lastConfigurationChange = ''
              if (headend.status.lastConfigurationChange !== null) {
                lastConfigurationChange = headend.status.lastConfigurationChange
              }
              lastConfigurationChange = JSON.stringify(lastConfigurationChange)
              row.push(lastConfigurationChange)
              break
            case 'alarms':
            case 'alerts':
              var alarms = ''
              if (headend.status.alarms !== undefined && headend.status.alarms !== null && headend.status.alarms.length > 0) {
                alarms = headend.status.alarms.join('|')
                alarms = JSON.stringify(alarms)
              }
              row.push(alarms)
              break
            case 'status':
            case 'connection':
              row.push(this.$t(headend.status.connection))
              break
            case 'headendStatus':
              var headendStatus = ''
              if (headend.status.alarms.length === 0) {
                headendStatus = 'OK'
              } else {
                headendStatus = headend.status.alarms.join(' ')
                headendStatus = JSON.stringify(headendStatus)
              }
              row.push(headendStatus)
              break
            case 'groups':
              var groups = ''
              if (headend.config.groups !== undefined && headend.config.groups !== null) {
                groups = headend.config.groups
                  .map(group => this.getGroupById(group).name)
                  .join('|')
                groups = JSON.stringify(groups)
              }
              row.push(groups)
              break
            case 'type':
              var type = headend.status.type
              type = JSON.stringify(type)
              row.push(type)
              break
            case 'systemType':
              var systemType = ''
              if (this.$isSky && headend.skyIt !== undefined && headend.skyIt !== null && headend.skyIt.status !== null && headend.skyIt.status.systemType !== null) {
                systemType = this.$t('headendSystemType.' + headend.skyIt.status.systemType)
                systemType = JSON.stringify(systemType)
              }
              if (this.$isTabcorp && headend.tabcorp !== undefined && headend.tabcorp !== null && headend.tabcorp.status !== null && headend.tabcorp.status.systemType !== null) {
                systemType = this.$t('headendSystemType.' + headend.tabcorp.status.systemType)
                systemType = JSON.stringify(systemType)
              }
              if (systemType === '') {
                systemType = this.$t('headendSystemType.unknown')
                systemType = JSON.stringify(systemType)
              }
              row.push(systemType)
              break
            case 'installer':
              var installer = ''
              if (this.$isSky && headend.skyIt !== undefined && headend.skyIt !== null && headend.skyIt.status !== undefined && headend.skyIt.status !== null &&
                headend.skyIt.status.installerCode !== undefined && headend.skyIt.status.installerCode !== null) {
                installer = headend.skyIt.status.installerCode
                installer = JSON.stringify(installer)
              }
              if (this.$isTabcorp && headend.tabcorp !== undefined && headend.tabcorp !== null && headend.tabcorp.status !== undefined && headend.tabcorp.status !== null &&
                headend.tabcorp.status.installerId !== undefined && headend.tabcorp.status.installerId !== null) {
                installer = headend.tabcorp.status.installerId
                installer = JSON.stringify(installer)
              }
              row.push(installer)
              break
            // SKY
            case 'contractId':
              var contractId = ''
              if (headend.skyIt !== undefined && headend.skyIt !== null && headend.skyIt.status !== null) {
                contractId = headend.skyIt.status.contractId
                contractId = JSON.stringify(contractId)
              }
              row.push(contractId)
              break
            case 'mainContractId':
              var mainContractId = ''
              if (headend.skyIt !== undefined && headend.skyIt !== null && headend.skyIt.status !== null) {
                mainContractId = headend.skyIt.status.mainContractId
                mainContractId = JSON.stringify(mainContractId)
              }
              row.push(mainContractId)
              break
            case 'province':
              var province = ''
              if (headend.skyIt !== undefined && headend.skyIt !== null && headend.skyIt.status !== null) {
                province = headend.skyIt.status.province
                province = JSON.stringify(province)
              }
              row.push(province)
              break
            // TABCORP
            case 'venueId':
              var venueId = ''
              if (headend.tabcorp !== undefined && headend.tabcorp !== null && headend.tabcorp.status !== null) {
                venueId = headend.tabcorp.status.uniqueVenueID
                venueId = JSON.stringify(venueId)
              }
              row.push(venueId)
              break
            case 'state':
              var state = ''
              if (this.$isTabcorp && headend.tabcorp !== undefined && headend.tabcorp !== null && headend.tabcorp.status !== undefined && headend.tabcorp.status !== null &&
                headend.tabcorp.status.state !== undefined && headend.tabcorp.status.state !== null) {
                state = headend.tabcorp.status.state
                state = JSON.stringify(state)
              }
              row.push(state)
              break
            case 'installationDate':
              row.push('')
              break
            case 'modelSerial':
              row.push('')
              break
          }
        })
        csv += row.join(',')
        csv += '\n'
      })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      let reportTitle = ''
      if (this.title !== '') {
        reportTitle = this.title
      } else {
        reportTitle = this.$t('reportOnDemand.reportFileName')
      }
      anchor.download =
        reportTitle + '-' + this.reportDate.toISOString() + '.csv'
      anchor.click()
    }
  }
}
</script>
<style>
.note {
  display: block;
  font-size: small;
}
</style>
