<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        offset-sm="3"
      >
        <v-card :loading="loading">
          <v-form @submit.prevent="requestLogin">
            <v-card-text>
              <v-container>
                <v-text-field
                  v-model="login.username"
                  prepend-icon="mdi-account"
                  name="Username"
                  :label="$t('login.username')"
                  :error="errors.username.status"
                  :error-messages="errors.username.message"
                  autofocus
                  required
                />
                <v-text-field
                  v-model="login.password"
                  prepend-icon="mdi-lock"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  name="Password"
                  :label="$t('login.password')"
                  :type="showPassword ? 'text' : 'password'"
                  :error="errors.password.status"
                  :error-messages="errors.password.message"
                  required
                  @click:append="showPassword = !showPassword"
                />
              </v-container>
              <v-row>
                <v-col>
                  <p class="text-md-right">
                    <router-link
                      :to="{ path:'/forgotPassword' }"
                    >
                      {{ $t('login.forgotPassword') }}
                    </router-link>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                type="submit"
                color="primary"
                :title="$t('login.login')"
              >
                {{ $t('login.login') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import router from '@/router'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { AUTH_REQUEST, AUTH_LOAD } from '@/store/actions/authentication'

export default {
  name: 'Login',
  components: {},
  data () {
    return {
      showPassword: false,
      returnUrl: '',
      login: {
        username: null,
        password: null
      },
      loading: false,
      errors: {
        username: { status: false, message: '' },
        password: { status: false, message: '' }
      }
    }
  },
  created () {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.$route.query.returnUrl || '/headends'
    this.$store.dispatch(LOADING_INCREASE)
    this.$nextTick(() => {
      this.$store
        .dispatch(AUTH_LOAD)
        .then(response => {
          this.$store.dispatch(LOADING_DECREASE)
          router.push(this.returnUrl)
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          // console.log(err)
        })
    })
  },
  methods: {
    requestLogin: function () {
      this.errors = {
        username: { status: false, message: '' },
        password: { status: false, message: '' }
      }
      this.loading = true
      if (this.$_guardUserAndPasswordAreFilled(this.login, this.errors)) {
        this.loading = false
        return
      }
      this.$store
        .dispatch(AUTH_REQUEST, {
          username: this.login.username,
          password: this.login.password
        })
        .then(response => {
          this.$router.push(this.returnUrl)
        })
        .catch(error => {
          this.loading = false
          this.$_apiErrorMessageManager(error)
        })
    },
    $_guardUserAndPasswordAreFilled: function (login) {
      let hasError = false
      if (login.username === null || login.username === '') {
        this.errors.username.status = true
        hasError = true
      } else {
        this.errors.username.status = false
      }
      if (login.password === null || login.password === '') {
        this.errors.password.status = true
        hasError = true
      } else {
        this.errors.password.status = false
      }
      return hasError
    },
    $_apiErrorMessageManager: function (error) {
      if (error.status > 500 || error.data === '') {
        this.errors.password.message = this.$t('login.loginGeneralError')
      }
      if (error.status === 404 || error.data === '') {
        this.errors.password.message = this.$t('login.loginCallNotFoundError')
      }
      if (error.data.error === 'username_not_exist') {
        this.errors.username.message = this.$t('login.usernameNotExist')
      }
      if (error.data.error === 'password_not_valid') {
        this.errors.password.message = this.$t('login.passwordNotValid')
      }
      // console.log(error)
    }
  }
}
</script>
