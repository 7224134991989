<template>
  <div>
    <div>
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.streamingInterface.labels.mode') }}</span>
      <span class="data__value">{{ getRJ45Mode() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.streamingInterface.labels.mac') }}</span>
      <span class="data__value">{{ getRJ45MAC() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.streamingInterface.labels.link') }}</span>
      <span class="data__value">{{ getRJ45Link() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes && isModeUnicast && isRJ45IPAddressDefined">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.streamingInterface.labels.ipAddress') }}</span>
      <span class="data__value">{{ getRJ45IPAddress() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes && isModeUnicast && isRJ45NetmaskDefined">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.streamingInterface.labels.netmask') }}</span>
      <span class="data__value">{{ getRJ45Netmask() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes && isModeUnicast && isRJ45GatewayDefined">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.streamingInterface.labels.gateway') }}</span>
      <span class="data__value">{{ getRJ45Gateway() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes && isModeUnicast && isRJ45DNSDefined">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.streamingInterface.labels.dns') }}</span>
      <span class="data__value">{{ getRJ45DNS() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes && !isModeUnicast && isRJ45IPAddressDefined">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.streamingInterface.labels.multicastIPAddress') }}</span>
      <span class="data__value">{{ getRJ45IPAddress() }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../../store/namespaces'

import {
  ReportStatusModuleNetworkControlPresent,
  ReportStatusModuleNetworkControlLink
} from '../../../../types/Report'

export default {
  name: 'StreamingData',
  props: {
    module: {
      type: Object,
      required: true
    },
    rj45: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getConfigurationModuleNetworkRJ45']),
    isNetworkRJ45PresentYes: function () {
      return (this.rj45 !== undefined && this.rj45 !== null && this.rj45.streaming !== undefined && this.rj45.streaming !== null && this.rj45.streaming.present === ReportStatusModuleNetworkControlPresent.yes)
    },
    isModeUnicast: function () {
      return this.rj45.streaming.enabled
    },
    isRJ45IPAddressDefined: function () {
      return (this.rj45.streaming?.ip !== undefined && this.rj45.streaming?.ip !== null) ?? false
    },
    isRJ45NetmaskDefined: function () {
      return (this.rj45.streaming?.mask !== undefined && this.rj45.streaming?.mask !== null) ?? false
    },
    isRJ45GatewayDefined: function () {
      return (this.rj45.streaming?.gateway !== undefined && this.rj45.streaming?.gateway !== null) ?? false
    },
    isRJ45DNSDefined: function () {
      return (this.rj45.streaming?.dns !== undefined && this.rj45.streaming?.dns !== null) ?? false
    }
  },
  methods: {
    getRJ45Mode: function () {
      const configurationModuleNetworkRJ45Data = this.getConfigurationModuleNetworkRJ45(this.module.slot)
      if (configurationModuleNetworkRJ45Data.streaming.enabled) {
        return configurationModuleNetworkRJ45Data.streaming.dhcp
          ? this.$t('moduleDialog.rj45.sections.streamingInterface.modes.dhcpUnicast')
          : this.$t('moduleDialog.rj45.sections.streamingInterface.modes.fixedIPUnicast')
      } else {
        return this.$t('moduleDialog.rj45.sections.streamingInterface.modes.multicast')
      }
    },
    getRJ45MAC: function () {
      return this.rj45.streaming.mac
    },
    getRJ45Link: function () {
      let link
      switch (this.rj45.streaming.link) {
        case ReportStatusModuleNetworkControlLink.up:
          link = this.$t('common.networkInterface.link.up')
          break
        case ReportStatusModuleNetworkControlLink.down:
          link = this.$t('common.networkInterface.link.down')
          break
      }
      return link
    },
    getRJ45IPAddress: function () {
      return this.rj45.streaming.ip
    },
    getRJ45Netmask: function () {
      return this.rj45.streaming.mask
    },
    getRJ45Gateway: function () {
      return this.rj45.streaming.gateway
    },
    getRJ45DNS: function () {
      return this.rj45.streaming.dns?.join(', ')
    }
  }
}</script>
<style scoped>
.data__label {
  display: inline-block;
  width: 15em;
  margin-left: 2em;
  vertical-align: top;
}
.data__value {
  display: inline-block;
  width: 55%;
}
</style>
