<template>
  <div class="module">
    <div class="empty-img" />
    <div class="module__body">
      <div class="module__top" />
      <div class="module__middle">
        <div class="module__name" />
      </div>
      <div class="module__bottom" />
      <div class="module__number">
        <span>{{ index }}</span>
      </div>
    </div>
    <div class="empty-img" />
  </div>
</template>

<script>

export default {
  name: 'ModuleEmpty',
  props: {
    index: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>
.module__body {
  background-color: #7F7F7F;
}
.empty-img {
  width: 3.25rem;
  height: 1.188rem;
}
.module__number {
  border-top: 1px solid #777;
  cursor: default;
}
.modules .module .module__number span {
  color: #FFF;
}
</style>
