var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items-per-page":-1,"hide-default-footer":"","items":_vm.globalOutputList,"headers":_vm.headersForOutputServices},scopedSlots:_vm._u([{key:"item.serviceId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseInt(item.serviceId) === 0 ? _vm.$t('emptyService') : item.serviceId)+" ")]}},{key:"item.input",fn:function(ref){
var item = ref.item;
return [(!_vm.checkServiceInput(item))?_c('v-icon',{attrs:{"color":"red","title":_vm.$t('channelMapEdit.serviceNotAtInput')}},[_vm._v(" mdi-alert ")]):_vm._e(),_vm._v(" "+_vm._s(parseInt(item.inputServiceId) === 0 ? '-' : item.input)+" ")]}},{key:"item.lcn",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":[ _vm.rules.required, _vm.rules.lcn ],"type":"number"},model:{value:(item.lcn),callback:function ($$v) {_vm.$set(item, "lcn", $$v)},expression:"item.lcn"}})]}},{key:"item.outputName",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":[ _vm.rules.required ]},model:{value:(item.outputName),callback:function ($$v) {_vm.$set(item, "outputName", $$v)},expression:"item.outputName"}})]}},{key:"item.module",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.module === undefined ? '-' : item.module + 1)+" ")]}},{key:"item.outputServiceId",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":[ _vm.rules.required, _vm.rules.outputServiceId ],"type":"number"},model:{value:(item.outputServiceId),callback:function ($$v) {_vm.$set(item, "outputServiceId", $$v)},expression:"item.outputServiceId"}})]}},{key:"item.language",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.languages,"rules":[ _vm.rules.required ]},model:{value:(item.language),callback:function ($$v) {_vm.$set(item, "language", $$v)},expression:"item.language"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":"","small":"","title":_vm.$t('channelMapEdit.deleteService')},on:{"click":function($event){return _vm.removeServiceFromOutput(item, item.module)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }