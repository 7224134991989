<template>
  <v-data-table
    ref="table"
    multi-sort
    :headers="headers"
    :items="items"
    :search="filters.search"
    :footer-props="footerProps"
    dense
    @current-items="updateFiltered"
  >
    <!-- TOP BAR -->
    <template #[`top`]>
      <v-toolbar
        flat
        color="white"
      >
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          :label="$t('section.serviceStatus.search')"
          single-line
          hide-details
        />
        <v-spacer />
        <slot />
      </v-toolbar>
    </template>
    <!-- Headend status:cell -->
    <template #[`item.alarmStatusData`]="{ item }">
      <div v-if="item.alarmStatusData">
        <v-icon
          v-if="item.alarmStatusData.alarm"
          color="warning"
          :title="headendStatusErrorTitle(item.alarmStatusData)"
        >
          mdi-alert
        </v-icon>
        <v-icon
          v-else
          color="success"
          :title="$t('section.serviceStatus.status.statusOk')"
        >
          mdi-check-circle
        </v-icon>
      </div>
    </template>
  </v-data-table>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { NS_GROUPS } from '../../store/namespaces'

const initServiceStatusTableFilter = {
  search: '',
  connection: []
}

export default Vue.extend({
  name: 'ServiceStatusTable',
  props: {
    items: {
      type: Array,
      default: () => []
    }, // as PropOptions<Headend[]>, // The list of headends
    columns: {
      type: Array,
      default: () => []
    } // as PropOptions<string[]>
  },
  data () {
    return {
      selectedItems: {},
      filteredItems: [],
      filters: initServiceStatusTableFilter,
      possibleHeaders: {
        status: {
          text: this.$t('section.serviceStatus.table.headers.status'),
          align: 'center',
          sortable: false,
          value: 'alarmStatusData'
        },
        lcn: {
          text: this.$t('section.serviceStatus.table.headers.lcn'),
          align: 'left',
          sortable: true,
          value: 'lcn'
        },
        inputName: {
          text: this.$t('section.serviceStatus.table.headers.inputName'),
          align: 'left',
          sortable: true,
          value: 'inputName'
        },
        outputName: {
          text: this.$t('section.serviceStatus.table.headers.outputName'),
          align: 'left',
          sortable: true,
          value: 'outputName'
        },
        inputServiceId: {
          text: this.$t('section.serviceStatus.table.headers.inputServiceId'),
          align: 'left',
          sortable: true,
          value: 'inputServiceId'
        },
        outputServiceId: {
          text: this.$t('section.serviceStatus.table.headers.outputServiceId'),
          align: 'left',
          sortable: true,
          value: 'outputServiceId'
        },
        decrypt: {
          text: this.$t('section.serviceStatus.table.headers.decrypt'),
          align: 'left',
          sortable: true,
          value: 'decrypt'
        },
        moduleData: {
          text: this.$t('section.serviceStatus.table.headers.moduleData'),
          align: 'left',
          sortable: true,
          value: 'moduleData'
        },
        inputData: {
          text: this.$t('section.serviceStatus.table.headers.inputData'),
          align: 'left',
          sortable: true,
          value: 'inputData'
        },
        outputData: {
          text: this.$t('section.serviceStatus.table.headers.outputData'),
          align: 'left',
          sortable: true,
          value: 'outputData'
        }
      },
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [50, 100, 150, -1],
        itemsPerPageText: this.$t('section.serviceStatus.table.itemsPerPage')
      }
    }
  },
  computed: {
    ...mapGetters({ getGroupById: NS_GROUPS + '/getGroupById' }),
    headers () {
      const headers = []
      this.columns.forEach(item => {
        headers.push(this.possibleHeaders[item])
      })
      return headers
    }
  },
  methods: {
    headendStatusErrorTitle: function (alarmStatusData) {
      const translatedErrors = []
      if (alarmStatusData.descramblingAlarm) {
        translatedErrors.push(this.$t('section.serviceStatus.status.descramblingAlarm'))
      }
      if (alarmStatusData.selectionAlarm) {
        translatedErrors.push(this.$t('section.serviceStatus.status.selectionAlarm'))
      }
      return this.$t('section.serviceStatus.status.statusErrors', { error: translatedErrors.join(', ') })
    },
    updateFiltered: function (items) {
      if (this.$refs.table) {
        try {
          this.filteredItems = this.$refs.table.$children[0].filteredItems
        } catch (e) {
          this.filteredItems = items
        }
      } else {
        this.filteredItems = items
      }
    }
  }
})

</script>
