<template>
  <span>
    <ConfirmDialog
      v-model="dialogControl.showDeleteConfirmDialog"
      :item-id="accessedItem"
      :title="$t('generatedReports.deleteTitle')"
      :message="dialogControl.deleteConfirmDialogMessage"
      @confirmed="deleteItem"
    />
    <v-container fluid>
      <v-card>
        <v-card-title>
          {{ $t('generatedReports.viewTitle') }}
        </v-card-title>
        <v-card-text>
          <ReportsGeneratedTable
            :items="items"
            selection="none"
            :actions="['download', 'delete']"
            @deleteItem="confirmDeleteItem"
            @downloadItem="downloadItem"
          />
        </v-card-text>
      </v-card>
    </v-container>
  </span>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'

import ReportsGeneratedTable from '@/views/reportsGenerated/components/ReportsGeneratedTable'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import {
  REPORTS_GENERATED_GET_ALL,
  REPORTS_GENERATED_DOWNLOAD,
  REPORTS_GENERATED_DELETE
} from '@/store/actions/reportsGenerated'

export default {
  name: 'ReportsGenerated',
  components: {
    ReportsGeneratedTable,
    ConfirmDialog
  },
  data () {
    return {
      dialogControl: {
        showDeleteConfirmDialog: false,
        deleteConfirmDialogMessage: null
      },
      accessedItem: null
    }
  },
  computed: {
    ...mapGetters('customization', ['timeZone']),
    ...mapState({ items: state => state.reportsGenerated.all })
  },
  watch: {},
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    this.$store
      .dispatch(REPORTS_GENERATED_GET_ALL)
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_SUCCESS,
          this.$t('generatedReports.loadedReports')
        )
      })
      .catch(error => {
        this.$store.dispatch(LOADING_DECREASE)
        if (error && error.response && error.response.status !== 401) {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('generatedReports.errorLoadingReports')
          )
        }
      })
  },
  methods: {
    downloadItem: function (item) {
      this.$store
        .dispatch(REPORTS_GENERATED_DOWNLOAD, item.id)
        .then(csv => {
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('generatedReports.reportDownloaded')
          )
          const anchor = document.createElement('a')
          anchor.href =
            'data:text/csv;charset=utf-8,' + encodeURIComponent(csv.join('\n'))
          anchor.target = '_blank'
          const formatedDate = this.$options.filters.formatDate(item.scheduledDate, this.$locale, this.timeZone)
          anchor.download =
            item.scheduleName +
            '-' +
            formatedDate +
            '.csv'
          anchor.click()
        })
        .catch(() => {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('generatedReports.errorDownloadingTheReport')
          )
        })
    },
    confirmDeleteItem: function (item) {
      this.accessedItem = item.id
      this.dialogControl.showDeleteConfirmDialog = true
      this.dialogControl.deleteConfirmDialogMessage = this.$t(
        'generatedReports.deleteMessage',
        item
      )
    },
    deleteItem: function (itemId) {
      this.$store
        .dispatch(REPORTS_GENERATED_DELETE, itemId)
        .then(() => {
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('generatedReports.reportsDeleted')
          )
        })
        .catch(() => {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('generatedReports.errorDeletingReport')
          )
        })
    }
  }
}
</script>
