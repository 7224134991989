<template>
  <v-card>
    <v-card-title class="subheading font-weight-bold">
      {{ title }}
      <v-spacer />
      <v-btn
        icon
        :color="filter.length ? 'primary' : ''"
        @click="expand = !expand"
      >
        <v-icon>
          {{ expand ? 'mdi-filter-minus' : 'mdi-filter-plus' }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-expand-transition>
      <v-card-text v-show="expand">
        <v-checkbox
          v-for="item in items"
          :key="item.value"
          v-model="filter"
          :value="item.value"
          color="primary"
          on-icon="mdi-checkbox-marked-outline"
          off-icon="mdi-checkbox-blank-outline"
          dense
          hide-details
        >
          <template #label>
            <div class="checkbox__label">
              <v-icon
                v-if="item.icon"
                class="pr-2"
                :color="item.iconColor"
                small
              >
                {{ item.icon }}
              </v-icon>
              {{ item.text | capitalize }}
            </div>
          </template>
        </v-checkbox>
      </v-card-text>
    </v-expand-transition>
    <v-expand-transition>
      <v-card-actions v-show="expand">
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="!filter.length"
          text
          @click="filter = []"
        >
          {{ $t('clearFilters') }}
        </v-btn>
      </v-card-actions>
    </v-expand-transition>
  </v-card>
</template>
<script>
export default {
  name: 'ItemsFilter',
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      expand: false,
      filter: []
    }
  },
  watch: {
    value: function (newValue) {
      this.filter = newValue
    },
    filter: function (newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
<style scoped>
.checkbox__label {
  color: rgba(0, 0, 0, 0.87);
}
</style>
