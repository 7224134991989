<template>
  <v-data-table
    :headers="headers"
    :items="historyOrders"
    :footer-props="footerProps"
  >
    <template #[`item.status.createdTime`]="{item}">
      {{ item.status.createdTime | formatDate($locale, $timezone) }}
    </template>
    <template #[`item.status.finishTime`]="{item}">
      {{ item.status.finishTime | formatDate($locale, $timezone) }}
    </template>
    <template #[`item.status.status`]="{item}">
      <span
        :class="statusColor[item.status.status]"
        class="text-uppercase"
        :title="statusTitle[item.status.status]"
      >
        {{ statusName[item.status.status] }}
      </span>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'DisplayActiveOrdersForId',
  props: {
    headendId: {
      type: String,
      default: null
    },
    historyOrders: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      statusName: {
        PENDING: this.$t('orderHistoryStatus.pending'),
        ABORTED: this.$t('orderHistoryStatus.aborted'),
        RUNNING: this.$t('orderHistoryStatus.running'),
        OK: this.$t('orderHistoryStatus.ok'),
        ERROR: this.$t('orderHistoryStatus.error')
      },
      statusColor: {
        PENDING: 'grey--text',
        ABORTED: 'error--text',
        RUNNING: 'yellow--text text--darken-3',
        OK: 'success--text',
        ERROR: 'error--text'
      },
      statusTitle: {
        PENDING: this.$t('orderHistoryStatusTitle.pending'),
        ABORTED: this.$t('orderHistoryStatusTitle.aborted'),
        RUNNING: this.$t('orderHistoryStatusTitle.running'),
        OK: this.$t('orderHistoryStatusTitle.ok'),
        ERROR: this.$t('orderHistoryStatusTitle.error')
      },
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('orders.perPageText')
      }
    }
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('alias'),
          align: 'left',
          value: 'status.alias'
        },
        {
          text: this.$t('dateCreated'),
          align: 'left',
          value: 'status.createdTime'
        },
        {
          text: this.$t('dateFinished'),
          align: 'left',
          value: 'status.finishTime'
        },
        {
          text: this.$t('status'),
          align: 'left',
          value: 'status.status'
        }
      ]
    }
  }
}
</script>
