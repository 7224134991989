<template>
  <v-card
    elevation="2"
  >
    <v-card-title
      class="title"
    >
      {{ $t('channelMapEdit.input') }}
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-text-field
        v-model="searchText"
        prepend-icon="mdi-magnify"
        :label="$t('channelMapEdit.search')"
      />
      <v-expansion-panels
        class="ma-1"
        multiple
        accordion
      >
        <v-expansion-panel
          v-for="(mux, index) in scannedChannelMaps"
          :key="index"
        >
          <v-expansion-panel-header class="subtitle-2">
            <v-row no-gutters>
              <v-col
                cols="6"
              >
                ONID: {{ mux.onid }}
              </v-col>
              <v-col
                cols="6"
              >
                TSID: {{ mux.tsid }}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense>
              <v-list-item
                v-for="(service, serviceIndex) in mux.services"
                :key="serviceIndex"
                dense
              >
                <v-list-item-content>
                  {{ service.name }}
                </v-list-item-content>
                <v-btn
                  color="primary"
                  icon
                  small
                  :title="$t('channelMapEdit.addService')"
                  @click="inputServiceSelected(service, mux.modules)"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  name: 'ChannelMapInputOptions',
  props: {
    channelMaps: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      searchText: null
    }
  },
  computed: {
    scannedChannelMaps: function () {
      const dataParsed = []
      this.channelMaps.forEach((moduleInputServices, moduleKey) => {
        Object.entries(moduleInputServices).forEach(([inputKey, inputData]) => {
          const repeatedMux = dataParsed.find(mux => {
            return mux.tsid === inputData.tsid &&
              mux.onid === inputData.onid
          })
          if (repeatedMux) {
            repeatedMux.modules.push({
              slot: moduleKey,
              input: inputKey
            })
          } else {
            const muxItem = {
              tsid: inputData.tsid,
              onid: inputData.onid,
              modules: [
                {
                  slot: moduleKey,
                  input: inputKey
                }
              ],
              services: inputData.inputServices.filter(service => {
                return !this.searchText || service.name.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0
              }).map(service => { return { ...service } })
            }
            if (muxItem.services.length > 0) {
              dataParsed.push(muxItem)
            }
          }
        })
        dataParsed.sort((a, b) => {
          if (a.onid === b.onid) {
            return a.tsid > b.tsid
          }
          return a.onid > b.onid
        })
      })
      return dataParsed
    }
  },
  methods: {
    inputServiceSelected: function (service, module) {
      this.$emit('selected', service, module)
    }
  }
}
</script>
