import {
  TIME_ZONES_GET,
  TIME_ZONES_CLEAR
} from '@/store/actions/timeZones'
import {
  AUTH_LOGOUT
} from '@/store/actions/authentication'

import timeZonesApi from '@/services/api/authorization/TimeZones'

export default {
  state: {
    all: []
  },
  mutations: {
    [TIME_ZONES_GET]: (state, data) => {
      state.all = data
    },
    [TIME_ZONES_CLEAR]: state => {
      state.all.length = 0
    }
  },
  actions: {
    [TIME_ZONES_GET]: ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        if (state.all.length > 0) {
          resolve(state.all)
          return
        }
        timeZonesApi.get().then((response) => {
          // console.log('[store.timeZones] Time zones lodaded')
          const info = response.data
          commit(TIME_ZONES_GET, info)
          resolve(info)
        })
          .catch((error) => {
            // console.error('[store.timeZones] Error loading time zones')
            reject(error)
          })
      })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      commit(TIME_ZONES_CLEAR)
    }
  },
  getters: {
  }
}
