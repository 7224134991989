<template>
  <div class="ml-8 mr-8">
    <v-expansion-panels
      accordion
      focusable
      class="mt-6 mb-6"
    >
      <v-expansion-panel
        v-for="(item, index) in getCamsData"
        :key="index"
      >
        <v-expansion-panel-header :color="getExpansionPanelHeaderColor(item)">
          <span v-if="!isCAMDisabled(item)">
            <span class="mr-4">{{ item.id }}</span>&nbsp;
            <span class="data__header__name">{{ getCamModel(item) }}</span>
            <span class="data__header__value">{{ getCamStatus(item) }}</span>
          </span>
          <span v-else>
            <span class="mr-4">{{ item.id }}</span>&nbsp;
            <span class="disabled">{{ getCamStatus(item) }}</span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-4">
          <AlarmMessages
            v-if="hasAlarms(item)"
            :alarms="getAlarms(item)"
          />
          <div>
            <span class="data__label">{{ $t('moduleDialog.cam.labels.dataSync') }}</span>
            <span class="data__value">{{ getCamDataSync(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.cam.labels.services') }}</span>
            <span class="data__value">{{ getCamServices(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.cam.labels.streams') }}</span>
            <span class="data__value">{{ getCamStreams(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.cam.labels.dataCapacity') }}</span>
            <span class="data__value">{{ getFormattedCamDataCapacity(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.cam.labels.dataUsage') }}</span>
            <span class="data__value">
              <a
                @click="changeCamDataUsageMode()"
              >
                <span class="full__bar">
                  <span
                    class="percentage__bar bar__color"
                    :style="getCamDataUsageStyleWidth(item)"
                  >
                    &nbsp;
                  </span>
                  <span
                    class="bar__limit__value"
                    :style="getCamDataUsageMinStyleLeft(item)"
                  />
                  <span
                    class="bar__limit__value"
                    :style="getCamDataUsageMaxStyleLeft(item)"
                  />
                  <span
                    v-if="showCamDataUsagePercentage"
                    class="bar__value"
                  >
                    {{ getFormattedCamDataUsagePercentage(item) }}
                  </span>
                  <span
                    v-if="!showCamDataUsagePercentage"
                    class="bar__value"
                  >
                    {{ getFormattedCamDataUsageMbps(item) }}
                  </span>
                </span>
              </a>
            </span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.cam.labels.qualityOfService') }}</span>
            <span class="data__value">{{ getCamQualityOfService(item) }}</span>
          </div>
          <div v-if="hasCamServicesAssigned(item)">
            <span class="data__label">{{ $t('moduleDialog.cam.labels.serviceList') }}</span>
            <span class="data__value">
              <ul class="data__list">
                <li
                  v-for="(service, serviceIndex) in getCamServiceList(item)"
                  :key="serviceIndex"
                >
                  {{ getServiceName(service) }}
                </li>
              </ul>
            </span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../store/namespaces'

import {
  ReportStatusModuleCamStatus,
  ReportStatusModelCamSyncValue
} from '../../../types/Report'

import AlarmMessages from './AlarmMessages.vue'

export default {
  name: 'CAMData',
  components: {
    AlarmMessages
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    cam: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      showCamDataUsagePercentage: true
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getStatusModuleCams']),
    getCamsData: function () {
      const cams = JSON.parse(JSON.stringify(this.getStatusModuleCams(this.module.slot)))
      return cams.sort((a, b) => {
        return (a.id > b.id)
          ? 1
          : (a.id < b.id)
            ? -1
            : 0
      })
    }
  },
  methods: {
    isCAMDisabled: function (item) {
      return item === undefined || item === null || item.status === ReportStatusModuleCamStatus.EXTRACTED || item.status === ReportStatusModuleCamStatus.INSERTED || item.status === ReportStatusModuleCamStatus.INVALID
    },
    hasAlarms: function (item) {
      return item.alarms !== undefined && item.alarms.length > 0
    },
    getAlarms: function (item) {
      return item.alarms
    },
    getExpansionPanelHeaderColor: function (item) {
      if (!this.isCAMDisabled(item)) {
        if (this.hasAlarms(item)) {
          return '#D31F1F'
        }
      }
      return ''
    },
    getCamModel: function (item) {
      return item.model
    },
    getCamStatus: function (item) {
      let status
      switch (item.status) {
        case ReportStatusModuleCamStatus.EXTRACTED:
          status = this.$t('moduleDialog.cam.status.extracted')
          break
        case ReportStatusModuleCamStatus.INSERTED:
          status = this.$t('moduleDialog.cam.status.inserted')
          break
        case ReportStatusModuleCamStatus.STARTED:
          status = this.$t('moduleDialog.cam.status.started')
          break
        case ReportStatusModuleCamStatus.READY:
          status = this.$t('moduleDialog.cam.status.ready')
          break
        case ReportStatusModuleCamStatus.INVALID:
          status = this.$t('moduleDialog.cam.status.invalid')
          break
        default:
          status = this.$t('common.unknown')
          break
      }
      return status
    },
    getCamDataSync: function (item) {
      let sync
      switch (item.sync) {
        case ReportStatusModelCamSyncValue.UNLOCKED:
          sync = this.$t('common.unlocked')
          break
        case ReportStatusModelCamSyncValue.LOCKED:
          sync = this.$t('common.locked')
          break
      }
      return sync
    },
    getCamServices: function (item) {
      return item.services.current + '/' + item.services.max
    },
    getCamStreams: function (item) {
      return item.streams.current + '/' + item.streams.max
    },
    getCamDataCapacity: function (item) {
      return item.bitrate
    },
    getFormattedCamDataCapacity: function (item) {
      return this.getCamDataCapacity(item) + ' Mbps'
    },
    getCamDataUsage: function (item) {
      return 100 - item.free.current
    },
    getFormattedCamDataUsagePercentage: function (item) {
      return this.getCamDataUsage(item) + '%'
    },
    getFormattedCamDataUsageMbps: function (item) {
      return Math.round((this.getCamDataUsage(item) * this.getCamDataCapacity(item))) + ' Mbps'
    },
    getCamDataUsageStyleWidth: function (item) {
      return 'width: ' + this.getCamDataUsage(item) + '%;'
    },
    getCamDataUsageMinStyleLeft: function (item) {
      const min = 100 - item.free.min
      return 'left: ' + min + '%;'
    },
    getCamDataUsageMaxStyleLeft: function (item) {
      const max = 100 - item.free.max
      return 'left: ' + max + '%;'
    },
    changeCamDataUsageMode: function () {
      this.showCamDataUsagePercentage = !this.showCamDataUsagePercentage
    },
    getCamQualityOfService: function (item) {
      if (item.services.current > 0) {
        // Formula: nominalSeconds * 100 / (nominalSeconds + noCamSeconds + noLinkSeconds)
        const denominator = item.nominalSeconds + item.noCamSeconds + item.noLinkSeconds
        if (denominator > 0) {
          return Math.round(item.nominalSeconds * 100 / denominator) + '%'
        }
      }
      return '0%'
    },
    getCamServiceList: function (item) {
      let services = []
      const servicesRF = this.module.services.rf.filter((service) => {
        return service.decrypt === item.id
      })
      services = services.concat(servicesRF)
      const servicesIP = this.module.services.ip.filter((service) => {
        return service.decrypt === item.id
      })
      services = services.concat(servicesIP)
      return services.sort((a, b) => {
        return (a.id > b.id)
          ? 1
          : (a.id < b.id)
            ? -1
            : 0
      })
    },
    hasCamServicesAssigned: function (item) {
      return this.getCamServiceList(item).length > 0
    },
    getServiceName: function (service) {
      return service.outputName
    }
  }
}
</script>
<style scoped>
.data__header__name {
  display: inline-block;
  width: 15rem;
}
.data__label {
  display: inline-block;
  width: 15em;
  margin-left: 2em;
  vertical-align: top;
}
.data__value {
  display: inline-block;
  width: 55%;
}
.full__bar {
  position: relative;
  display: inline-block;
  background-color: #9E9E9E;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
}
.percentage__bar {
  position: absolute;
  display: inline-block;
}
.bar__color {
  background-color: #628C36;
}
.bar__value {
  position: relative;
  margin-left: 0.5em;
  color: black;
}
.bar__limit__value {
  position: absolute;
  height: 100%;
  border: solid 1px red;
  background-color: red;
}
.data__list {
  list-style: none;
  margin-left: -25px;
}
</style>
