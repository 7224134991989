<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- TEMPLATES-->
        <ReportTemplatesTable
          v-model="selectedTemplates"
          :items="templates"
          selection="single"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card-actions>
          <v-spacer />
          <slot name="actions" />
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import ReportTemplatesTable from '@/views/reportTemplates/components/ReportTemplatesTable.vue'

export default {
  name: 'ReportsScheduledTemplates',
  components: {
    ReportTemplatesTable
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      commandInformationForm: false, // Checks if the form is valid
      selectedTemplates: []
    }
  },
  computed: {
    ...mapState({
      templates: state => {
        return state.reportsTemplates.all
      }
    })
  },
  watch: {
    selectedTemplates: function () {
      this.$emit('input', this.selectedTemplates[0])
    },
    value: function () {
      this.selectedTemplates.push(this.value)
    }
  },
  mounted () {},
  methods: {}
}
</script>
