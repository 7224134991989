<template>
  <v-container fluid>
    <v-col cols="8">
      <v-card>
        <v-card-title>
          {{ $t('section.backups.title') }}
          <v-spacer />
          {{ numberOfUserCreatedBackups >= maxNumberOfUserCreatedBackups ? $t('section.backups.userMaximumBackups') : '' }}
          <v-btn
            v-if="hasRights('flt:da')"
            :disabled="(!isOnline() || numberOfUserCreatedBackups >= maxNumberOfUserCreatedBackups)"
            text
            icon
            :title="$t('section.backups.createBackup')"
            color="primary"
            @click="createBackup"
          >
            <v-icon>mdi-cloud-upload</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="backups"
            :footer-props="footerProps"
          >
            <!-- CREATED AT -->
            <template #[`item.createdAt`]="{ item }">
              {{ item.createdAt | formatDate($locale, timeZone) }}
            </template>
            <!-- AUTOMATIC -->
            <template #[`item.isAutomatic`]="{ item }">
              {{ item.isAutomatic | formatBoolean($t('common.yes'), $t('common.no')) }}
            </template>
            <template #[`item.restore`]="{ item }">
              <v-btn
                icon
                color="primary"
                :title="$t('section.backups.downloadBackup')"
                @click="downloadBackup(item)"
              >
                <v-icon>
                  mdi-download
                </v-icon>
              </v-btn>
              <v-btn
                icon
                color="primary"
                :title="$t('section.backups.applyBackup')"
                :disabled="!isOnline()"
                @click="applyBackup(item)"
              >
                <v-icon>
                  mdi-backup-restore
                </v-icon>
              </v-btn>
              <v-btn
                icon
                color="primary"
                :title="$t('section.backups.deleteBackup')"
                @click="deleteBackup(item)"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { NS_AUTHENTICATION, NS_CUSTOMIZATION, NS_BACKUPS, NS_HEADEND, NS_SNACKBAR } from '../store/namespaces'
import { SNACKBAR_ERROR } from '../store/actions/snackbar'

export default {
  name: 'Backups',
  props: {
    publicDomain: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      maxNumberOfUserCreatedBackups: 3,
      confirmDialog: {
        show: false
      },
      infoHeaders: [
        {
          text: this.$t('section.backups.table.headers.name'),
          align: 'left',
          sortable: true,
          value: 'url'
        },
        {
          text: this.$t('section.backups.table.headers.date'),
          align: 'right',
          sortable: true,
          value: 'createdAt'
        },
        {
          text: this.$t('section.backups.table.headers.automatic'),
          align: 'right',
          sortable: true,
          value: 'isAutomatic'
        }
      ],
      restoreHeader: {
        text: '',
        align: 'end',
        sortable: false,
        value: 'restore'
      },
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('section.backups.table.itemsPerPage')
      }
    }
  },
  computed: {
    ...mapGetters(NS_AUTHENTICATION, ['hasRights']),
    ...mapGetters(NS_BACKUPS, ['getBackups']),
    ...mapGetters(NS_CUSTOMIZATION, ['timeZone']),
    ...mapGetters(NS_HEADEND, ['getHeadend']),
    headers: function () {
      if (this.hasRights('flt:da')) {
        return this.infoHeaders.concat([this.restoreHeader])
      }
      return this.infoHeaders
    },
    headend: function () {
      return this.getHeadend
    },
    backups: function () {
      return this.getBackups
    },
    numberOfUserCreatedBackups: function () {
      return this.backups.filter(backup => !backup.isAutomatic).length
    }
  },
  methods: {
    getBackupUrl: function (backup) {
      const protocol = 'https://'
      const path = '/uploads/backups/' + this.headend.id + '/'
      return protocol + this.publicDomain + path + backup.url
    },
    isOnline: function () {
      return this.headend.status.connection === 'online'
    },
    applyBackup: function (backup) {
      this.$emit('applyBackup', backup)
    },
    createBackup: function () {
      this.$emit('createBackup')
    },
    deleteBackup: function (backup) {
      this.$emit('deleteBackup', backup)
    },
    downloadBackup: function (backup) {
      const url = this.getBackupUrl(backup)
      fetch(url)
        .then(res => res.blob()) // Gets the response and returns it as a blob
        .then(blob => {
          const link = window.document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = backup.url
          link.click()
        }).catch(() => {
          const errorMessage = this.$t('error.downloadingBackup', { backup: backup.url })
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_ERROR, errorMessage)
        })
    }
  }
}
</script>

<style scoped></style>
