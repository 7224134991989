<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validForm"
      value="false"
    >
      <v-row>
        <v-col cols="12">
          <v-toolbar>
            <v-btn
              icon
              color="primary"
              :title="$t('back')"
              @click="backToReportTemplates"
            >
              <v-icon>mdi-arrow-left-circle</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ $t('reportTemplates.newViewTitle') }}
            </v-toolbar-title>

            <v-spacer />
            <v-btn
              color="primary darken-1"
              class="ma-1"
              @click="saveTemplate"
            >
              <v-icon left>
                mdi-content-save
              </v-icon>
              {{ $t('reportTemplates.save') }}
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <!-- NAME -->
              <v-text-field
                v-model="report.name"
                :rules="[rules.required]"
                :label="$t('reportTemplates.name')"
                @click="validateForm"
              />
              <!-- DESCRIPTION -->
              <span class="title">{{ $t('reportTemplates.description') }}</span>
              <v-textarea
                ref="comment"
                v-model="report.description"
                no-resize
                class="mb4"
                maxlength="256"
              />
              <!-- FILTER -->
              <ReportsFilter v-model="report.headendsFilter" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { uuid } from 'vue-uuid'
import ReportsFilter from '@/views/reportOnDemand/components/ReportsFilter'

import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'

import { HEADEND_TYPES_GET_ALL } from '@/store/actions/headendTypes'
import { GROUPS_GET_ALL } from '@/store/actions/groups'

import { REPORTS_TEMPLATES_CREATE } from '@/store/actions/reportsTemplates'

export default {
  name: 'ReportTemplateCreateEdit',
  components: {
    ReportsFilter
  },
  data () {
    return {
      validForm: false,
      report: {
        filter: {
          alarmTypes: [],
          connectionTypes: [],
          systemTypes: [],
          drmTypes: [],
          headendTypes: [],
          groups: []
        }
      },
      rules: {
        required: value => !!value || this.$t('validation.requiredField')
      }
    }
  },
  computed: {},
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    Promise.all([
      this.$store.dispatch(HEADEND_TYPES_GET_ALL),
      this.$store.dispatch(GROUPS_GET_ALL)
    ])
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_SUCCESS,
          this.$t('reportTemplates.loaded')
        )
        if (this.viewMode === 'edit') {
          this.loadOrder()
        }
      })
      .catch(error => {
        this.$store.dispatch(LOADING_DECREASE)
        if (error.response.status !== 401) {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('reportTemplates.errorLoadingRequiredData')
          )
        }
      })
    this.mode = 'CREATE'
    this.prepareData()
  },
  methods: {
    validateForm: function () {
      this.$refs.form.validate()
    },
    resetFilters: function () {
      this.report.headendsFilter = {
        alarmTypes: [],
        connectionTypes: [],
        systemTypes: [],
        drmTypes: [],
        headendTypes: [],
        groups: []
      }
    },
    backToReportTemplates: function () {
      this.$router.push('/reportTemplates')
    },
    prepareData: function () {
      if (this.mode === 'CREATE') {
        this.report = {
          id: uuid.v4(),
          name: '',
          description: '',
          type: 'CURRENT_STATE',
          headendsFilter: {
            alarmTypes: [],
            connectionTypes: [],
            systemTypes: [],
            drmTypes: [],
            headendTypes: [],
            groups: []
          }
        }
      }
    },
    saveTemplate: function () {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(REPORTS_TEMPLATES_CREATE, this.report)
        .then(value => {
          // console.log('[views.reportTemplates] Template with id: ' + itemId + ' deleted')
          this.$store.dispatch(LOADING_DECREASE)
          this.$t('reportTemplates.reportTemplateCreated')
          this.$router.push('/reportTemplates')
        })
        .catch(() => {
          // console.error('[views.reportTemplates] Template with id: ' + itemId + ' could not be deleted')
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('reportTemplates.errorDeletingTheTemplate')
          )
        })
    }
  }
}
</script>
