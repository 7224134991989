import { render, staticRenderFns } from "./SlotPlug.vue?vue&type=template&id=f4d4cfd4&scoped=true&"
import script from "./SlotPlug.vue?vue&type=script&lang=js&"
export * from "./SlotPlug.vue?vue&type=script&lang=js&"
import style0 from "./SlotPlug.vue?vue&type=style&index=0&id=f4d4cfd4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4d4cfd4",
  null
  
)

export default component.exports