<template>
  <v-container fluid>
    <v-card :flat="true">
      <v-card-text>
        <ServiceStatusTable
          :items="servicesList"
          :columns="servicesColumns()"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { NS_REPORT } from '../store/namespaces'
import { ReportConfigurationModuleInputsType, ReportConfigurationModuleOutputsType } from '../types/Report'
import ServiceStatusTable from './serviceStatus/ServiceStatusTable.vue'

export default {
  name: 'ServiceStatus',
  components: {
    ServiceStatusTable
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getServicesListSortedByLCN']),
    servicesList: function () {
      return this.getServicesListSortedByLCN.map((service) => {
        const formattedService = {
          id: service.id,
          inputName: service.inputName,
          outputName: service.outputName,
          forcedName: service.forcedName,
          inputServiceId: service.inputServiceId,
          outputServiceId: service.outputServiceId,
          lcn: service.lcn,
          input: service.input,
          output: service.output,
          type: service.type,
          decrypt: service.decrypt,
          language: service.language,
          moduleSlot: service.slot,
          moduleModel: service.model,
          moduleData: this.getServiceModuleData(service.moduleSlot, service.moduleModel),
          inputData: this.getServiceInputData(service.input, service.inputServiceId, service.inputData),
          outputData: this.getServiceOutputData(service.output, service.outputData),
          alarmStatusData: service.alarmStatusData
        }
        return formattedService
      })
    }
  },
  methods: {
    servicesHeadersColumns: function () {
      return [
        'status',
        'lcn',
        'inputName',
        'outputName',
        'inputServiceId',
        'outputServiceId',
        'decrypt',
        'moduleData',
        'inputData',
        'outputData'
      ]
    },
    servicesColumns: function () {
      return this.servicesHeadersColumns()
    },
    getServiceModuleData: function (moduleSlot, moduleModel) {
      return this.$t('section.serviceStatus.moduleData', { slot: moduleSlot, model: moduleModel })
    },
    getServiceInputData: function (input, inputServiceId, data) {
      if (data?.inputEnabled) {
        if (input !== 0 && inputServiceId !== 0) {
          if (data.showInputPrefix) {
            return input + ' - ' + data.inputData
          } else {
            if (data.inputPrefixType === ReportConfigurationModuleInputsType.ip) {
              return this.$t('section.serviceStatus.iptv') + ' - ' + data.inputData
            }
          }
        } else {
          return this.$t('section.serviceStatus.emptyService')
        }
      } else {
        return input + ' - ' + this.$t('section.serviceStatus.notEnabled')
      }
    },
    getServiceOutputData: function (output, data) {
      if (data?.outputEnabled) {
        if (data.showOutputPrefix) {
          return output + ' - ' + data.outputData
        } else {
          if (data.outputPrefixType === ReportConfigurationModuleOutputsType.ip) {
            return data.outputData
          }
        }
      } else {
        return output + ' - ' + this.$t('section.serviceStatus.notEnabled')
      }
    }
  }
}
</script>

<style scoped></style>
