<template>
  <v-alert
    v-if="headend.status.alarms.length"
    type="error"
    border="bottom"
    prominent
  >
    <div class="title">
      {{ $t('errorAlert') }}
    </div>
    <ul>
      <li
        v-for="(alarm, index) in headend.status.alarms"
        :key="index"
      >
        {{ alarm }}
      </li>
    </ul>
  </v-alert>
</template>

<script>
export default {
  name: 'HeadendAlert',
  props: {
    headend: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  }
}
</script>
