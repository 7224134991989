var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"table",attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.items,"search":_vm.filters.search,"footer-props":_vm.footerProps},on:{"current-items":_vm.updateFiltered},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('label.search'),"single-line":"","hide-details":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('v-spacer'),_vm._t("default")],2)]},proxy:true},{key:"header.roleTranslation",fn:function(){return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._v(" "+_vm._s(_vm.$t('users.role'))+" "),_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.filters.roles.length ? 'primary' : ''}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-filter-menu ")])],1)]}}])},[_c('v-card',[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('users.filtersRol'))+" ")]),_c('v-card-text',_vm._l((_vm.roles),function(item){return _c('v-checkbox',{key:item.value,attrs:{"label":item.name,"value":item.id,"color":"primary","on-icon":"mdi-checkbox-marked-outline","off-icon":"mdi-checkbox-blank-outline","dense":"","hide-details":""},model:{value:(_vm.filters.roles),callback:function ($$v) {_vm.$set(_vm.filters, "roles", $$v)},expression:"filters.roles"}})}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.filters.roles.length,"text":""},on:{"click":function($event){_vm.filters.roles = []}}},[_vm._v(" "+_vm._s(_vm.$t('clearFilters'))+" ")])],1)],1)],1)]},proxy:true},{key:"item.rol",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.rol)+" ")]}},{key:"item.information",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.information && item.information.length > 20) ? item.information.substring(0,20) : item.information)+" "+_vm._s((item.information && item.information.length > 20) ? '...' : '')+" ")]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [(_vm.actions.indexOf('edit') >= 0)?_c('v-btn',{attrs:{"text":"","icon":"","color":"primary","title":_vm.$t('users.edit'),"disabled":_vm.isSameUser(item)},on:{"click":function($event){return _vm.throwAction('editItem', item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.actions.indexOf('delete') >= 0)?_c('v-btn',{attrs:{"text":"","icon":"","color":"primary","disabled":_vm.isSameUser(item),"title":_vm.$t('reportsScheduledTable.delete')},on:{"click":function($event){return _vm.throwAction('deleteItem', item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"header.selections",fn:function(){return [(_vm.selection == 'multiple')?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.selectUnselectAll()}}},[(_vm.areAllSelected())?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked-outline ")]):(_vm.isAnySelected())?_c('v-icon',[_vm._v(" mdi-minus-box-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1):_vm._e()]},proxy:true},{key:"item.selections",fn:function(ref){
var item = ref.item;
return [(_vm.selection != 'none')?_c('span',[(_vm.isSelected(item))?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.deselect(item)}}},[(_vm.selection === 'multiple')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked-outline ")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-radiobox-marked ")])],1):_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.select(item)}}},[(_vm.selection === 'multiple')?_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-circle-outline ")])],1)],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }