<template>
  <div>
    <v-expansion-panels
      accordion
      focusable
      class="mt-6 mb-6"
    >
      <v-expansion-panel
        v-for="(item, index) in getServicesList()"
        :key="index"
      >
        <v-expansion-panel-header :color="getExpansionPanelHeaderColor(item)">
          <span>
            <span class="mr-4">{{ (index + 1) }}</span>&nbsp;
            <span class="data__header__name">{{ getOutputIPAndPort(item) }}</span>&nbsp;
            <span>{{ getServiceName(item) }}</span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-4">
          <AlarmMessages
            v-if="hasAlarms(item)"
            :alarms="getAlarms(item)"
          />
          <div>
            <span class="data__label">{{ $t('moduleDialog.rj45.sections.iptvSPTSOutputs.labels.protocol') }}</span>
            <span class="data__value">{{ getOutputProtocol(item) }}</span>
          </div>
          <div v-if="isOutputQualityOfServiceDefined(item)">
            <span class="data__label">{{ $t('moduleDialog.rj45.sections.iptvSPTSOutputs.labels.qualityOfService') }}</span>
            <span class="data__value">{{ getOutputQualityOfService(item) }}</span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../../store/namespaces'

import {
  ReportConfigurationModuleIPOutputsItemProtocolValue,
  ReportConfigurationModuleIPOutputsItemProtocolLabel
} from '../../../../types/Report'

import AlarmMessages from '../AlarmMessages.vue'

export default {
  name: 'IPTVSPTSOutputsData',
  components: {
    AlarmMessages
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    rj45: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getStatusModuleBySlot', 'getModuleIPTVSPTSOutputsServicesList'])
  },
  created () {
    this.getServicesList()
  },
  methods: {
    getStatusModuleOutputSPTSItem: function (moduleOutput) {
      return this.getStatusModuleBySlot(this.module.slot).outputsSPTS.find((outputSPTS) => {
        return outputSPTS.id === moduleOutput.id
      })
    },
    getServicesList: function () {
      return this.getModuleIPTVSPTSOutputsServicesList(this.module)
    },
    hasAlarms: function (item) {
      return item.alarms !== undefined && item.alarms.length > 0
    },
    getAlarms: function (item) {
      return item.alarms
    },
    getExpansionPanelHeaderColor: function (item) {
      if (this.hasAlarms(item)) {
        return '#D31F1F'
      }
      return ''
    },
    getOutputIPAndPort: function (item) {
      return item.outputIpAddress + ':' + item.outputIpPort
    },
    getServiceName: function (item) {
      return item.serviceName
    },
    getOutputProtocol: function (item) {
      let protocol
      switch (item.outputProtocol) {
        case ReportConfigurationModuleIPOutputsItemProtocolValue.UDP:
          protocol = ReportConfigurationModuleIPOutputsItemProtocolLabel.UDP
          break
        case ReportConfigurationModuleIPOutputsItemProtocolValue.UDP_RTP:
          protocol = ReportConfigurationModuleIPOutputsItemProtocolLabel.UDP_RTP
          break
      }
      return protocol
    },
    isOutputQualityOfServiceDefined: function (item) {
      const moduleOutput = this.module.outputs?.ip?.spts?.find((output) => {
        return output.id === item.outputId
      })
      if (moduleOutput?.enabled) {
        const outputStatus = this.getStatusModuleOutputSPTSItem(moduleOutput)
        return outputStatus.nominalSeconds !== undefined && outputStatus.nominalSeconds !== null &&
          outputStatus.impairedSeconds !== undefined && outputStatus.impairedSeconds !== null &&
          outputStatus.noLinkSeconds !== undefined && outputStatus.noLinkSeconds !== null
      }
      return false
    },
    getOutputQualityOfService: function (item) {
      const moduleOutput = this.module.outputs?.ip?.spts?.find((output) => {
        return output.id === item.outputId
      })
      const outputStatus = this.getStatusModuleOutputSPTSItem(moduleOutput)
      // Formula:  nominalSeconds * 100 / (nominalSeconds + impairedSeconds + noLinkSeconds)
      const denominator = outputStatus.nominalSeconds + outputStatus.impairedSeconds + outputStatus.noLinkSeconds
      if (denominator > 0) {
        return Math.round(outputStatus.nominalSeconds * 100 / denominator) + '%'
      }
      return ''
    }
  }
}
</script>
<style scoped>
.data__header__name {
  display: inline-block;
  width: 14.5rem;
}
.data__label {
  display: inline-block;
  width: 15em;
  margin-left: 2.5em;
  vertical-align: top;
}
.data__value {
  display: inline-block;
  width: 49%;
}
</style>
