<template>
  <v-dialog
    :value="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
    @input="$emit('input', $event)"
  >
    <v-card tile>
      <v-toolbar
        flat
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t('orderSchedule.orderScheduleDialogTitle') }} {{ title ? '- ' + title : '' }}
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="3"
                    md="3"
                  >
                    <v-text-field
                      v-model="parsed.createdAt"
                      :label="$t('orderSchedule.createdAt')"
                      readonly
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-textarea
                      v-model="order.description"
                      :label="$t('orderSchedule.description')"
                      outlined
                      readonly
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                {{ $t('orderSchedule.executionMode') }}
              </v-card-title>
              <v-card-text>
                <OrderScheduleExecutionMode
                  :next-order-date="parsed.nextOrderAt"
                  :last-order-date="parsed.lastOrderAt"
                  :execution="order.execution"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                {{ $t('orderSchedule.target') }}
              </v-card-title>
              <v-card-text>
                <OrderScheduleTarget
                  :target="order.target"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          :title="$t('button.close')"
          @click="close"
        >
          {{ $t('button.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import OrderScheduleTarget from '@/views/orderSchedule/components/OrderScheduleTarget.vue'
import OrderScheduleExecutionMode from '@/views/orderSchedule/components/OrderScheduleExecutionMode.vue'
export default {
  name: 'OrdersScheduleDialog',
  components: {
    OrderScheduleTarget,
    OrderScheduleExecutionMode
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    scheduleId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      order: {},
      parsed: { createdAt: '-', nextOrderAt: '-', lastOrderAt: '-' }
    }
  },
  computed: {
    ...mapGetters('customization', ['timeZone']),
    ...mapGetters(['getOrderScheduleById'])
  },
  watch: {
    scheduleId: function (val) {
      if (val) {
        this.order = this.getOrderScheduleById(val)
        this.parsed = {
          createdAt: this.$options.filters.formatDate(this.order.creationDate, this.$locale, this.timeZone),
          nextOrderAt: this.$options.filters.formatDate(this.order.nextOrderDate, this.$locale, this.timeZone),
          lastOrderAt: this.$options.filters.formatDate(this.order.lastOrderDate, this.$locale, this.timeZone)
        }
      }
    }
  },
  methods: {
    close: function () {
      this.$emit('input', false)
    }
  }
}
</script>
