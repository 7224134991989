<template>
  <v-container fluid>
    <v-select
      v-model="selectedChannelMap"
      :label="$t('orderCommand.channelMap')"
      :items="channelMaps"
      item-text="name"
      item-value="id"
      :rules="[rules.required]"
      @change="channelMapSelected"
    />
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ChannelMapOrder',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          type: 'channelMap',
          info: {
            id: ''
          }
        }
      }
    }
  },
  data () {
    return {
      action: {
        type: 'channelMap'
      },
      rules: {
        required: value => !!value || this.$t('validation.requiredField')
      },
      selectedChannelMap: undefined
    }
  },
  computed: {
    ...mapGetters(['getChannelMapTemplate']),
    channelMaps () {
      return this.$store.state.channelMapTemplates.all
    }
  },
  watch: {
    action: {
      handler: function (newData) {
        this.$emit('input', newData)
      },
      deep: true
    }
  },
  mounted: function () {
    this.action = {
      type: 'channelMap',
      info: {
        id: ''
      }
    }
    if (this.value.info !== undefined && this.value.type === 'channelMap') {
      if (this.value.info.id !== undefined && this.value.info.id !== null) {
        this.action.info.id = this.value.info.id
        const channelMap = this.getChannelMapTemplate(this.value.info.id)
        this.selectedChannelMap = channelMap
      }
    }
  },
  methods: {
    channelMapSelected: function (channelMapId) {
      const channelMap = this.getChannelMapTemplate(channelMapId)
      this.action = {
        type: 'channelMap',
        info: { id: channelMap.id }
      }
    }
  }
}
</script>
