<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-btn
            icon
            color="primary"
            :title="$t('back')"
            @click="backToChannelMaps"
          >
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-toolbar-title v-if="viewMode === 'edit'">
            {{ $t('channelMapEdit.Edit') }}
          </v-toolbar-title>
          <v-toolbar-title v-if="viewMode === 'import'">
            {{ $t('channelMapEdit.Import') }}
          </v-toolbar-title>
          <v-toolbar-title v-if="viewMode === 'new'">
            {{ $t('channelMapEdit.New') }}
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-stepper
          v-model="stepperControl"
          vertical
        >
          <v-stepper-step
            step="1"
            editable
            :complete="stepperControl > 1"
            edit-icon="mdi-check"
          >
            {{ $t('channelMapEdit.step1Title') }}
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-row>
              <v-col>
                {{ $t('channelMapEdit.onlineHeadends') }}
                <HeadendsTable
                  v-model="selectedHeadend"
                  :items="onlineHeadends"
                  :columns="headendsColumns()"
                  selection="single"
                >
                  <template>
                    <v-btn
                      :disabled="!selectedHeadend.length"
                      color="primary"
                      class="ma-2"
                      :title="$t('channelMapEdit.scanDevice')"
                      @click="scanInput"
                    >
                      <v-icon left>
                        mdi-application-import
                      </v-icon>
                      {{ $t('channelMapEdit.scan') }}
                    </v-btn>
                  </template>
                </HeadendsTable>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step
            step="2"
            edit-icon="mdi-check"
            :editable="stepperControl > 2 || globalOutputList.length > 0 "
            :complete="stepperControl > 2"
          >
            {{ $t('channelMapEdit.step2Title') }}
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card
              v-if="Object.keys(deviceChannelMap).length || $route.params.channelMapId || ($route.name === 'importChannelMap')"
              flat
            >
              <v-row>
                <v-spacer />
                <v-btn
                  class="ma-3"
                  color="primary"
                  :title="$t('channelMapEdit.continue')"
                  @click="continueChannelMappingConfig3"
                >
                  {{ $t("channelMapEdit.continue") }}
                </v-btn>
              </v-row>
              <v-row class="ma-1">
                <v-col
                  v-if="deviceChannelMap.length"
                  cols="12"
                  lg="3"
                >
                  <ChannelMapInputOptions
                    :channel-maps="deviceChannelMap"
                    @selected="addServiceToOutput"
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="9"
                >
                  <ChannelMapOutputOptions
                    v-model="channelMapTemplate.info.modules"
                    :channel-maps="deviceChannelMap"
                  />
                </v-col>
              </v-row>
            </v-card>
            <!-- LOADING FALSE -->
            <v-row
              v-else
              flat
            >
              <v-col>
                <v-row
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step
            step="3"
            :editable="stepperControl > 2 || globalOutputList.length > 0 "
            :complete="stepperControl > 2"
          >
            {{ $t('channelMapEdit.step3Title') }}
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-form
              ref="form"
              v-model="validForm"
              lazy-validation
            >
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="channelMapTemplate.name"
                    :label="$t('channelMapEdit.name')"
                    maxlength="64"
                    :rules="[ rules.required, rules.name ]"
                  />
                  <span class="title">{{ $t('channelMapEdit.description') }}</span>
                  <v-textarea
                    ref="comment"
                    v-model="channelMapTemplate.description"
                    no-resize
                    class="mb4"
                    maxlength="256"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ChannelMapTable
                    v-model="channelMapTemplate.info.modules"
                    :channel-maps="deviceChannelMap"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row justify="end">
                    <v-btn
                      :disabled="!validForm"
                      class="ma-3"
                      color="primary"
                      :title="$t('channelMapEdit.downloadJson')"
                      @click="downloadJson()"
                    >
                      {{ $t('channelMapEdit.download') }}
                    </v-btn>
                    <v-btn
                      :disabled="!validForm"
                      class="ma-3"
                      color="primary"
                      :title="$t('channelMapEdit.save')"
                      @click="saveChannelMap"
                    >
                      {{ $t('channelMapEdit.save') }}
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { uuid } from 'vue-uuid'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { HEADENDS_INIT, HEADENDS_PAUSE } from '@/store/actions/headends'
import { GROUPS_GET_ALL } from '@/store/actions/groups'
import { CHANNEL_MAP_TEMPLATES_GET_ALL, CHANNEL_MAP_TEMPLATES_CREATE, CHANNEL_MAP_TEMPLATES_UPDATE } from '@/store/actions/channelMapTemplates'
import HeadendsTable from '@/views/headends/components/HeadendsTable.vue'
import ChannelMapOutputOptions from '@/views/channelMapCreateEdit/components/ChannelMapOutputOptions.vue'
import ChannelMapInputOptions from '@/views/channelMapCreateEdit/components/ChannelMapInputOptions.vue'
import ChannelMapTable from '@/views/channelMapCreateEdit/components/ChannelMapTable.vue'

import DeviceService from '@/services/fleets/DevicesService'
import downloadJsonMixins from '@/mixins/downloadJson.js'

export default {
  name: 'EditChannelMap',
  components: {
    HeadendsTable,
    ChannelMapInputOptions,
    ChannelMapOutputOptions,
    ChannelMapTable
  },
  mixins: [downloadJsonMixins],
  data () {
    return {
      viewMode: '',
      selectedHeadend: [], // The selected headend in the first step, so it can scan it when SCAN is pressed
      deviceChannelMap: [],
      stepperControl: null, // Control of the stepper
      originalChannelMapName: '',
      lcnInit: 601,
      validForm: true, // Checks if the form is valid
      rules: { // Specific rules so that the form can de validated
        required: value => !!value || this.$t('validation.requiredField'),
        name: value => this.originalChannelMapName === value || !this.channelMapNames.includes(value) || this.$t('validation.duplicatedName')
      },
      channelMapTemplate: {
        id: '',
        name: '',
        description: '',
        info: { modules: [{ services: [] }] }
      }
    }
  },
  computed: {
    ...mapGetters(['getChannelMapTemplate']),
    channelMaps () {
      return this.$store.state.channelMapTemplates.all
    },
    channelMapNames () {
      return this.$store.state.channelMapTemplates.all.map(channelMap => channelMap.name)
    },
    onlineHeadends: function () {
      return this.$store.state.headendsNew.all.filter(headend => headend.status.connection === 'online')
    },
    globalOutputList: function () { // All the outputs with added module info joint in one array
      let outputList = []
      this.channelMapTemplate.info.modules.forEach(module => {
        outputList = outputList.concat(...module.services)
      })
      return outputList
    }
  },
  mounted () {
    if (this.$route.path.includes('edit')) {
      this.viewMode = 'edit'
    } else if (this.$route.path.includes('import')) {
      this.viewMode = 'import'
    } else {
      this.viewMode = 'new'
    }
    this.loadData()
  },
  destroyed () {
    this.$store.dispatch(HEADENDS_PAUSE)
  },
  methods: {
    loadData: function () {
      this.$store.dispatch(LOADING_INCREASE)
      Promise.all([this.$store.dispatch(GROUPS_GET_ALL),
        this.$store.dispatch(HEADENDS_INIT),
        this.$store.dispatch(CHANNEL_MAP_TEMPLATES_GET_ALL)])
        .then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('channelMapEdit.dataLoaded'))
          if (this.viewMode === 'edit') {
            this.stepperControl = 2
            this.channelMapTemplate = JSON.parse(JSON.stringify(this.getChannelMapTemplate(this.$route.params.channelMapId)))
            this.originalChannelMapName = this.channelMapTemplate.name
          } else if (this.viewMode === 'import') {
            const info = JSON.parse(localStorage.getItem('channelImportedMapInfo'))
            const name = localStorage.getItem('channelImportedMapName')
            if (!info || !name) {
              this.backToChannelMaps()
            }
            this.channelMapTemplate = {
              id: uuid.v4(),
              name: name,
              description: this.$t('channelMapEdit.channelMapImportedFile', { name: name }),
              info: info
            }
            this.stepperControl = 3
          } else {
            this.channelMapTemplate = {
              id: uuid.v4(),
              name: '',
              description: '',
              info: { modules: [{ services: [] }] }
            }
          }
        }).catch((error) => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.response.status !== 401) {
            this.$store.dispatch(SNACKBAR_ERROR, this.$t('channelMapEdit.errorLoadingData'))
          }
        })
    },
    forceValidate: function () {
      this.$refs.form.validate()
    },
    addServiceToOutput: function (service, modules) {
      let lcn
      for (let i = this.lcnInit; i < 999; i++) {
        if (this.globalOutputList.findIndex(service => { return parseInt(service.lcn, 10) === i }) < 0) {
          lcn = i
          break
        }
      }
      let sid = parseInt(service.serviceId)
      for (let i = parseInt(service.serviceId); i < 65535; i++) {
        if (this.globalOutputList.findIndex(service => { return parseInt(service.outputServiceId, 10) === i }) < 0) {
          sid = i
          break
        }
      }
      // Module
      const outputService = {
        input: parseInt(modules[0].input),
        inputServiceId: service.serviceId,
        inputName: service.name,
        output: parseInt(modules[0].input),
        outputServiceId: sid,
        outputName: service.name,
        lcn: lcn,
        decrypt: 0,
        language: 'eng',
        module: modules[0].slot
      }
      this.channelMapTemplate.info.modules[0].services.push(outputService)
    },
    scanInput: function () {
      this.stepperControl = 2
      DeviceService.deviceChannelMap(this.selectedHeadend[0].id)
        .then((response) => {
          this.deviceChannelMap = response.data.channelMaps
        }).catch(response => {
          this.$store.dispatch(SNACKBAR_ERROR, this.$t('channelMapEdit.errorReadingInputServices'))
          this.stepperControl = 1
        })
    },
    continueChannelMappingConfig3: function () {
      this.stepperControl = 3
      this.forceValidate()
    },
    saveChannelMap: function () {
      const toSaveChannelMap = {
        id: this.channelMapTemplate.id,
        name: this.channelMapTemplate.name,
        description: this.channelMapTemplate.description,
        info: this.prepareData(this.channelMapTemplate.info)
      }
      this.$store.dispatch(LOADING_INCREASE)
      if (this.viewMode === 'new' || this.viewMode === 'import') {
        this.$store.dispatch(CHANNEL_MAP_TEMPLATES_CREATE, toSaveChannelMap).then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('channelMapEdit.channelMapCreated'))
          this.backToChannelMaps()
        }).catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_ERROR, this.$t('channelMapEdit.errorCreatingChannelMap'))
        })
      } else { // this.viewMode === edit
        this.$store.dispatch(CHANNEL_MAP_TEMPLATES_UPDATE, toSaveChannelMap).then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('channelMapEdit.channelMapUpdated'))
          this.backToChannelMaps()
        }).catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_ERROR, this.$t('channelMapEdit.errorUpdatingChannelMap'))
        })
      }
    },
    downloadJson: function () {
      if (!this.$refs.form.validate()) return
      const preparedData = this.prepareData(this.channelMapTemplate.info)
      this.downloadJsonFile(JSON.stringify(preparedData, (key, value) => {
        if (!isNaN(value)) {
          return +value
        }
        return value
      }, 4), this.channelMapTemplate.name)
    },
    prepareData: function (channelMap) {
      const data = JSON.parse(JSON.stringify(channelMap, (key, value) => {
        if (!isNaN(value)) {
          return +value
        }
        return value
      }))
      data.modules.map(module => {
        module.services.map(service => {
          delete service.inputName
          delete service.module
        })
      })
      return data
    },
    headendsColumns: function () {
      if (this.$isSky) {
        // SKY headers in order
        return ['remoteSiteDeviceName', 'contractId', 'id', 'firmwareVersion', 'systemType', 'groups']
      } else if (this.$isTabcorp) {
        // Tabcorp headers in order
        return ['headendStatus', 'venueId', 'alias', 'id', 'firmwareVersion', 'groups']
      } else {
        // Default headers in order
        return ['headendStatus', 'id', 'alias', 'firmwareVersion', 'groups']
      }
    },
    backToChannelMaps: function () {
      this.$router.push('/channelMaps')
    }
  }
}
</script>
