<template>
  <v-dialog
    :value="value"
    max-width="350px"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="newTag"
          maxlength="80"
          :label="$t('deployChannelMap.value')"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          :title="$t('button.cancel')"
          @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="newTag === ''"
          :title="$t('deployChannelMap.select')"
          @click="confirmed"
        >
          {{ $t('deployChannelMap.select') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ChannelMapDeployTagDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      newTag: null
    }
  },
  watch: {
    tag: function (newValue, oldValue) {
      this.newTag = newValue
    }
  },
  methods: {
    close: function () {
      this.$emit('input', false)
    },
    confirmed: function () {
      this.$emit('confirmed', this.newTag)
      this.close()
    }
  }
}
</script>
