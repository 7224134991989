<template>
  <div>
    <v-expansion-panels
      accordion
      focusable
      class="mt-6 mb-6"
    >
      <v-expansion-panel
        v-for="(item, index) in module.inputs.ip.inputs"
        :key="index"
        :disabled="!item.enabled"
      >
        <v-expansion-panel-header :color="getExpansionPanelHeaderColor(item)">
          <span v-if="item.enabled">
            <span class="mr-4">{{ (index + 1) }}</span>&nbsp;
            <span class="data__header__name">{{ getInputIPAndPort(item) }}</span>&nbsp;
            <span class="data__header__locked">{{ getInputLockedStatus(item) }}</span>
          </span>
          <span v-else>
            <span class="mr-4">{{ (index + 1) }}</span>&nbsp;
            <span class="disabled">{{ $t('common.disabled') }}</span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-if="item.enabled"
          class="mt-4"
        >
          <AlarmMessages
            v-if="hasAlarms(item)"
            :alarms="getAlarms(item)"
          />
          <div>
            <span class="data__label">{{ $t('moduleDialog.rj45.sections.iptvInputs.labels.protocol') }}</span>
            <span class="data__value">{{ getInputProtocol(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.rj45.sections.iptvInputs.labels.qualityOfService') }}</span>
            <span class="data__value">{{ getInputQualityOfService(item) }}</span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../../store/namespaces'

import {
  ReportConfigurationModuleIPInputsItemProtocolValue,
  ReportConfigurationModuleIPInputsItemProtocolLabel
} from '../../../../types/Report'

import AlarmMessages from '../AlarmMessages.vue'

export default {
  name: 'IPTVInputsData',
  components: {
    AlarmMessages
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    rj45: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getStatusModuleBySlot'])
  },
  methods: {
    getInputIPAndPort: function (moduleInput) {
      return moduleInput.ipAddress + ':' + moduleInput.ipPort
    },
    getStatusModuleInputIPItem: function (moduleInput) {
      return this.getStatusModuleBySlot(this.module.slot).inputsIP.find((inputIP) => {
        return inputIP.id === moduleInput.id
      })
    },
    hasAlarms: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputIPItem(moduleInput)
      return inputStatus.alarms !== undefined && inputStatus.alarms.length > 0
    },
    getAlarms: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputIPItem(moduleInput)
      return inputStatus.alarms
    },
    getExpansionPanelHeaderColor: function (moduleInput) {
      if (moduleInput.enabled) {
        if (this.hasAlarms(moduleInput)) {
          return '#D31F1F'
        }
      }
      return ''
    },
    getInputLockedStatus: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputIPItem(moduleInput)
      if (inputStatus !== undefined && inputStatus !== null) {
        return inputStatus.locked
          ? this.$t('common.locked')
          : this.$t('common.unlocked')
      } else {
        return ''
      }
    },
    getInputProtocol: function (moduleInput) {
      let protocol
      switch (moduleInput.protocol) {
        case ReportConfigurationModuleIPInputsItemProtocolValue.UDP:
          protocol = ReportConfigurationModuleIPInputsItemProtocolLabel.UDP
          break
        case ReportConfigurationModuleIPInputsItemProtocolValue.UDP_RTP:
          protocol = ReportConfigurationModuleIPInputsItemProtocolLabel.UDP_RTP
          break
      }
      return protocol
    },
    getInputQualityOfService: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputIPItem(moduleInput)
      // Formula:  nominalSeconds * 100 / (nominalSeconds + impairedSeconds + noLinkSeconds)
      const denominator = inputStatus.nominalSeconds + inputStatus.impairedSeconds + inputStatus.noLinkSeconds
      if (denominator > 0) {
        return Math.round(inputStatus.nominalSeconds * 100 / denominator) + '%'
      }
      return ''
    }
  }
}
</script>
<style scoped>
.data__header__name {
  display: inline-block;
  width: 14.5rem;
}
.data__label {
  display: inline-block;
  width: 15em;
  margin-left: 3em;
}
.data__value {
  display: inline-block;
  width: 50%;
}
</style>
