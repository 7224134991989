<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-btn
            icon
            color="primary"
            :title="$t('back')"
            @click="backToReportsScheduled"
          >
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-toolbar-title v-if="viewMode === 'edit'">
            {{ $t('reportScheduledCreate.viewEditTitle') }}
          </v-toolbar-title>
          <v-toolbar-title v-if="viewMode === 'new'">
            {{ $t('reportScheduledCreate.viewNewTitle') }}
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-stepper
          v-model="step"
          non-linear
        >
          <v-stepper-header>
            <!-- HEAD STEP 1 - INSERT REPORT INFORMATION -->
            <v-stepper-step
              :complete="step > 1"
              :rules="[() => isInfortmationStepValid || maxStepBeing < 2]"
              editable
              step="1"
            >
              {{ $t('reportScheduledCreate.fillTheInformation') }}
            </v-stepper-step>
            <!-- HEAD STEP 2 - SELECT TEMPLATE -->
            <v-stepper-step
              :complete="step > 2"
              :rules="[() => receiversFullfilled || maxStepBeing < 3]"
              editable
              step="2"
            >
              {{ $t('reportScheduledCreate.selectTemplates') }}
            </v-stepper-step>
            <!-- HEAD STEP 3 - SELECT WHEN -->
            <v-stepper-step
              editable
              step="3"
            >
              {{ $t('reportScheduledCreate.selectRecurrency') }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <ReportsScheduledInformation
                v-model="schedule.information"
                @isValid="checkOfInformationStepValidation"
              >
                <template #actions>
                  <v-btn
                    color="primary"
                    @click="nextStep(2)"
                  >
                    {{ $t('reportScheduledCreate.continue') }}
                  </v-btn>
                </template>
              </ReportsScheduledInformation>
            </v-stepper-content>
            <!-- STEP 2 - SELECT TEMPLATES -->
            <v-stepper-content step="2">
              <ReportsScheduledTemplates v-model="schedule.template">
                <template #actions>
                  <v-btn
                    color="primary"
                    @click="nextStep(1)"
                  >
                    {{ $t('reportScheduledCreate.back') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="nextStep(3)"
                  >
                    {{ $t('reportScheduledCreate.continue') }}
                  </v-btn>
                </template>
              </ReportsScheduledTemplates>
            </v-stepper-content>
            <!-- STEP 3 - SELECT WHEN -->
            <v-stepper-content step="3">
              <ReportsScheduledRecurrency v-model="schedule.recurrency">
                <template #actions>
                  <span
                    class="error--text"
                    style="padding-right: 10px;"
                  >{{
                    error
                  }}</span>
                  <v-btn
                    color="primary"
                    @click="nextStep(2)"
                  >
                    {{ $t('reportScheduledCreate.back') }}
                  </v-btn>
                  <v-btn
                    :disabled="!receiversFullfilled || !isInfortmationStepValid"
                    color="primary"
                    @click="saveOrUpdateSchedule()"
                  >
                    <v-icon left>
                      mdi-content-save
                    </v-icon>
                    {{ $t('reportScheduledCreate.save') }}
                  </v-btn>
                </template>
              </ReportsScheduledRecurrency>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import router from '@/router'
import { uuid } from 'vue-uuid'

import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'

import { REPORTS_TEMPLATES_GET_ALL } from '@/store/actions/reportsTemplates'
import { REPORTS_SCHEDULES_CREATE } from '@/store/actions/reportsSchedules'

import ReportsScheduledInformation from '@/views/reportsScheduledCreate/components/ReportsScheduledInformation'
import ReportsScheduledTemplates from '@/views/reportsScheduledCreate/components/ReportsScheduledTemplates'
import ReportsScheduledRecurrency from '@/views/reportsScheduledCreate/components/ReportsScheduledRecurrency'

export default {
  name: 'ReportsScheduledCreate',
  components: {
    ReportsScheduledInformation,
    ReportsScheduledTemplates,
    ReportsScheduledRecurrency
  },
  data () {
    return {
      viewMode: 'new',
      step: 1,
      maxStepBeing: 1,
      isInfortmationStepValid: false,
      schedule: {
        information: { name: '', description: '' },
        template: {},
        recurrency: {}
      },
      error: ''
    }
  },
  computed: {
    ...mapState({ reportsTemplates: state => state.reportsTemplates.all }),
    receiversFullfilled: function () {
      return this.schedule.template.id !== undefined
    }
  },
  watch: {},
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    Promise.all([this.$store.dispatch(REPORTS_TEMPLATES_GET_ALL)])
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_SUCCESS,
          this.$t('reportScheduledCreate.loaded')
        )
      })
      .catch(error => {
        this.$store.dispatch(LOADING_DECREASE)
        if (error.response.status !== 401) {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('reportScheduledCreate.errorLoadingRequiredData')
          )
        }
      })
    if (this.$route.path.includes('edit')) {
      this.viewMode = 'edit'
    } else {
      this.viewMode = 'new'
      this.schedule.id = uuid.v4()
    }
  },
  methods: {
    backToReportsScheduled: function () {
      router.push('/reportsScheduled')
    },
    nextStep: function (nextStep) {
      if (this.maxStepBeing < nextStep) {
        this.maxStepBeing = nextStep
      }
      this.step = nextStep
    },
    checkOfInformationStepValidation: function (isValid) {
      this.isInfortmationStepValid = isValid
    },
    saveOrUpdateSchedule: function () {
      if (this.viewMode === 'new') {
        this.saveSchedule()
      }
    },
    saveSchedule: function () {
      this.$store.dispatch(LOADING_INCREASE)
      const scheduleToSave = {
        id: this.schedule.id,
        name: this.schedule.information.name,
        description: this.schedule.information.description,
        templateId: this.schedule.template.id,
        templateName: this.schedule.template.name,
        mode: this.schedule.recurrency.mode,
        startDate: this.schedule.recurrency.executionInfo.startDate,
        endDate: this.schedule.recurrency.executionInfo.endDate,
        numberOfTimes: this.schedule.recurrency.executionInfo.numberOfTimes,
        frequency: this.schedule.recurrency.executionInfo.frequency
      }
      this.$store
        .dispatch(REPORTS_SCHEDULES_CREATE, scheduleToSave)
        .then(response => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('reportScheduledCreate.scheduleCreated')
          )
          router.push('/reportsScheduled')
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.status === 500 || error.data === '') {
            this.$store.dispatch(
              SNACKBAR_ERROR,
              this.$t('reportScheduledCreate.errorCreatingSchedule')
            )
          } else if (error.status === 400) {
            this.error = this.$t(
              'reportScheduledCreate.scheduleMustBeInTheFuture'
            )
          }
        })
    }
  }
}
</script>
