import {
  LOADING_INCREASE,
  LOADING_DECREASE,
  LOADING_RESET
} from '@/store/actions/loading'

import {
  AUTH_LOGOUT
} from '@/store/actions/authentication'

export default {
  state: {
    loadingItems: 0
  },
  mutations: {
    [LOADING_INCREASE]: (state) => {
      state.loadingItems++
    },
    [LOADING_DECREASE]: (state) => {
      if (state.loadingItems > 0) {
        state.loadingItems--
      }
    },
    [LOADING_RESET]: (state) => {
      state.loadingItems = 0
    }
  },
  actions: {
    [LOADING_INCREASE]: ({ commit }) => {
      commit('LOADING_INCREASE')
    },
    [LOADING_DECREASE]: ({ commit }) => {
      commit('LOADING_DECREASE')
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      commit('LOADING_RESET')
    }
  },
  getters: {
    areItemsLoading: (state) => state.loadingItems > 0
  }
}
