<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="6"
        md="4"
      >
        <v-text-field
          v-model="executionInfo.translatedMode"
          :label="$t('orderSchedule.mode')"
          readonly
        />
      </v-col>
      <v-col
        v-if="execution.mode !== 'ONCE'"
        cols="6"
        md="4"
      >
        <v-text-field
          v-model="lastOrderAt"
          :label="$t('orderSchedule.lastOrder')"
          readonly
        />
      </v-col>
      <v-col
        cols="6"
        md="4"
      >
        <v-text-field
          v-model="nextOrderAt"
          :label="$t('orderSchedule.nextOrder')"
          readonly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-col
          v-if="executionInfo.frequency"
          cols="6"
          md="4"
        >
          <EditInterval
            v-model="executionInfo.frequency"
            :disabled="true"
          />
        </v-col>
        <v-col
          v-if="executionInfo.numberOfTimes"
          cols="6"
          md="4"
        >
          <v-text-field
            v-model="executionInfo.numberOfTimes"
            :label="$t('orderSchedule.numberOfTimes')"
            readonly
          />
        </v-col>
        <v-col
          v-if="endAt"
          cols="6"
          md="4"
        >
          <v-text-field
            v-model="endAt"
            :label="$t('orderSchedule.endDate')"
            readonly
          />
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import EditInterval from '@/components/common/EditInterval.vue'
export default {
  name: 'OrderScheduleExecutionMode',
  components: {
    EditInterval
  },
  props: {
    nextOrderDate: {
      type: String,
      default: ''
    },
    lastOrderDate: {
      type: String,
      default: ''
    },
    execution: {
      type: Object,
      default: () => {
        return {
          mode: 'ONCE',
          startDate: new Date()
        }
      }
    }
  },
  data () {
    return {
      lastOrderAt: '',
      nextOrderAt: '',
      endAt: undefined,
      executionInfo: {
        mode: 'ONCE',
        startDate: new Date()
      }
    }
  },
  computed: {
    ...mapGetters('customization', ['timeZone'])
  },
  watch: {
    nextOrderDate: function (newVal) {
      this.nextOrderAt = newVal
    },
    lastOrderDate: function (newVal) {
      this.lastOrderAt = newVal
    },
    execution: function (newVal) {
      this.executionInfo = newVal
      this.executionInfo.translatedMode = this.$t('repeatModeOption.' + this.executionInfo.mode)
      if (newVal.endDate) {
        this.endAt = this.$options.filters.formatDate(newVal.endDate, this.$locale, this.timeZone)
      }
    }
  },
  mounted: function () {
    this.lastOrderAt = this.lastOrderDate
    this.nextOrderAt = this.nextOrderDate
    this.executionInfo = this.execution
    this.executionInfo.translatedMode = this.$t('repeatModeOption.' + this.executionInfo.mode)
    if (this.executionInfo.endDate) {
      this.endAt = this.$options.filters.formatDate(this.executionInfo.endDate, this.$locale, this.timeZone)
    }
  }
}
</script>
