<template>
  <v-data-table
    ref="table"
    multi-sort
    :headers="headers"
    :items="items"
    :search="filters.search"
    :footer-props="footerProps"
    @current-items="updateFiltered"
  >
    <!-- TOP BAR -->
    <template #top>
      <v-toolbar
        flat
        color="white"
      >
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          :label="$t('label.search')"
          single-line
          hide-details
        />
        <v-spacer />
        <slot />
      </v-toolbar>
    </template>
    <!-- DESCRIPTION -->
    <template #[`item.description`]="{ item }">
      <div :title="item.description">
        {{ (item.description && item.description.length > 20) ? item.description.substring(0,20)+'...' : item.description }}
      </div>
    </template>
    <!-- TYPE -->
    <template #[`item.action.type`]="{ item }">
      {{ $t('orderType.'+item.action.type) }}
    </template>
    <!-- CREATION DATE -->
    <template #[`item.creationDate`]="{item}">
      {{ item.creationDate | formatDate($locale, timeZone) }}
    </template>
    <!-- LAST ORDER DATE -->
    <template #[`item.lastOrderDate`]="{item}">
      {{ item.lastOrderDate | formatDate($locale, timeZone) }}
    </template>
    <!-- NEXT ORDER DATE -->
    <template #[`item.nextOrderDate`]="{item}">
      {{ item.nextOrderDate | formatDate($locale, timeZone) }}
    </template>
    <!-- SETTINGS -->
    <template #[`item.settings`]="{ item }">
      <!-- VIEW -->
      <v-btn
        v-if="actions.indexOf('view') >= 0"
        text
        icon
        color="primary"
        :title="$t('orderTemplates.view')"
        @click="throwAction('viewItem', item)"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <!-- ACTION -->
      <v-btn
        v-if="actions.indexOf('action') >= 0"
        text
        icon
        color="primary"
        :title="$t('orderTemplates.action')"
        @click="throwAction('actionItem', item)"
      >
        <v-icon>mdi-calendar-start</v-icon>
      </v-btn>
      <!-- EDIT -->
      <v-btn
        v-if="actions.indexOf('edit') >= 0"
        text
        icon
        color="primary"
        :title="$t('orderTemplates.edit')"
        @click="throwAction('editItem', item)"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <!-- DELETE BUTTON -->
      <v-btn
        v-if="actions.indexOf('delete') >= 0"
        text
        icon
        color="primary"
        :title="$t('orderTemplates.delete')"
        @click="throwAction('deleteItem', item)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <!-- SELECTIONS HEADER -->
    <template #[`header.selections`]>
      <v-btn
        v-if="selection == 'multiple'"
        text
        icon
        @click="selectUnselectAll()"
      >
        <v-icon
          v-if="areAllSelected()"
          color="primary"
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon v-else-if="isAnySelected()">
          mdi-minus-box-outline
        </v-icon>
        <v-icon v-else>
          mdi-checkbox-blank-outline
        </v-icon>
      </v-btn>
    </template>
    <!-- Selections: cell -->
    <template #[`item.selections`]="{ item }">
      <span v-if="selection != 'none'">
        <v-btn
          v-if="isSelected(item)"
          text
          icon
          @click="deselect(item)"
        >
          <v-icon
            v-if="selection === 'multiple'"
            color="primary"
          >
            mdi-checkbox-marked-outline
          </v-icon>
          <v-icon
            v-else
            color="primary"
          >
            mdi-radiobox-marked
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          text
          icon
          @click="select(item)"
        >
          <v-icon v-if="selection === 'multiple'">
            mdi-checkbox-blank-outline
          </v-icon>
          <v-icon v-else>
            mdi-checkbox-blank-circle-outline
          </v-icon>
        </v-btn>
      </span>
    </template>
  </v-data-table>
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'OrderTemplatesTable',
  props: {
    items: {
      type: Array,
      default: () => []
    }, // The list of headends
    selection: {
      type: String,
      default: 'none' // single or multiple
    },
    actions: {
      type: Array,
      default: () => [] // Actions types: view, action, edit, delete
    },
    columns: {
      type: Array,
      default: () => [] // Columns: name, description, type
    }
  },
  data () {
    return {
      selectedItems: {},
      filteredItems: [],
      filters: { search: null },
      possibleHeaders: {
        name: {
          text: this.$t('orderTemplates.name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        description: {
          text: this.$t('orderTemplates.description'),
          align: 'start',
          sortable: true,
          value: 'description'
        },
        type: {
          text: this.$t('orderTemplates.type'),
          align: 'start',
          sortable: true,
          value: 'action.type'
        },
        devices: {
          text: this.$t('orderTemplates.devices'),
          align: 'center',
          sortable: true,
          value: 'target.devices.length'
        },
        groups: {
          text: this.$t('orderTemplates.groups'),
          align: 'center',
          sortable: true,
          value: 'target.groups.length'
        },
        creationDate: {
          text: this.$t('orderTemplates.createdAt'),
          align: 'start',
          sortable: true,
          value: 'creationDate'
        },
        lastOrderDate: {
          text: this.$t('orderTemplates.lastOrderAt'),
          align: 'start',
          sortable: true,
          value: 'lastOrderDate'
        },
        nextOrderDate: {
          text: this.$t('orderTemplates.nextOrderAt'),
          align: 'start',
          sortable: true,
          value: 'nextOrderDate'
        }
      },
      settingsHeader: {
        text: '',
        align: 'end',
        sortable: false,
        value: 'settings'
      },
      selectionsHeader: {
        text: '',
        align: 'end',
        sortable: false,
        value: 'selections'
      },
      footerProps: {
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('orderTemplates.perPageText')
      }
    }
  },
  computed: {
    ...mapGetters('customization', ['timeZone']),
    ...mapState({ myAccount: state => state.authentication.account }),
    headers: function () {
      const headers = []
      this.columns.forEach(item => {
        headers.push(this.possibleHeaders[item])
      })
      if (this.actions.length > 0) {
        headers.push(this.settingsHeader)
      }
      if (this.selection === 'multiple' || this.selection === 'single') {
        headers.push(this.selectionsHeader)
      }
      return headers
    }
  },
  watch: {
    items: function () {
      this.selectedItems = {}
    }
  },
  methods: {
    throwAction: function (action, item) {
      this.$emit(action, item)
    },
    isSelected (item) {
      return this.selectedItems[item.id]
    },
    deselect: function (item) {
      this.$delete(this.selectedItems, item.id)
      this.$emit('input', Object.values(this.selectedItems))
    },
    select: function (item) {
      if (this.selectedItems !== {} && this.selection === 'single') {
        this.selectedItems = {}
      }
      this.$set(this.selectedItems, item.id, item)
      this.$emit('input', Object.values(this.selectedItems))
    },
    selectUnselectAll: function () {
      if (Object.values(this.selectedItems).length > 0) {
        this.selectedItems = {}
      } else {
        this.selectedItems = {}
        this.filteredItems.forEach((item, i) => {
          this.$set(this.selectedItems, item.id, item)
        })
      }
      this.$emit('input', Object.values(this.selectedItems))
    },
    areAllSelected: function () {
      return Object.values(this.selectedItems).length === this.items.length
    },
    isAnySelected: function () {
      return Object.values(this.selectedItems).length > 0
    },
    updateFiltered: function (items) {
      if (this.$refs.table) {
        try {
          this.filteredItems = this.$refs.table.$children[0].filteredItems
        } catch (e) {
          this.filteredItems = items
        }
      } else {
        this.filteredItems = items
      }
    }
  }
}
</script>
