<template>
  <v-data-table
    ref="table"
    :headers="columnHeaders"
    :items="enrichedItems"
    :search="filters.search"
    :footer-props="footerProps"
    show-expand
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="executionDate"
    @current-items="updateFiltered"
  >
    <!-- TOP BAR -->
    <template #top>
      <v-toolbar
        flat
        color="white"
      >
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          :label="$t('label.search')"
          single-line
          hide-details
        />
        <v-spacer />
      </v-toolbar>
    </template>
    <!-- EXECUTION DATE -->
    <template #[`item.executionDate`]="{item}">
      {{ item.executionDate | formatDate($locale, timeZone) }}
    </template>
    <!-- STATUS -->
    <template #[`item.status`]="{ item }">
      {{ $t('orderExecutionItemStatus.'+item.status) }}
    </template>
    <!-- TYPE -->
    <template #[`item.device.status.type`]="{ item }">
      {{ item.device.status && item.device.status.type ? $t('deviceType.'+item.device.status.type):'' }}
    </template>
    <!-- EXPANDED PART -->
    <template #expanded-item="{ headers, item }">
      <td
        v-if="item.status === 'OKAY'"
        :colspan="headers.length"
      >
        <br>
        <v-row>
          <v-col>
            <v-text-field
              v-model="item.response.code"
              readonly
              :label="$t('orderExecutionInfo.responseCode')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            {{ $t('orderExecutionInfo.responseInformation') }}:
            <vue-json-pretty
              v-if="isJson(item.response.data)"
              :data="parsedJson(item.response.data)"
              :deep="vueJsonPrettyProperties.deep"
              :show-double-quotes="vueJsonPrettyProperties.showDoubleQuotes"
              :show-length="vueJsonPrettyProperties.showLength"
              :show-line="vueJsonPrettyProperties.showLine"
              :show-line-number="vueJsonPrettyProperties.showLineNumber"
              :highlight-mouseover-node="vueJsonPrettyProperties.highlightMouseoverNode"
              :collapsed-on-click-brackets="vueJsonPrettyProperties.collapsedOnClickBrackets"
              :show-icon="vueJsonPrettyProperties.showIcon"
              :show-key-value-space="vueJsonPrettyProperties.showKeyValueSpace"
              style="position: relative"
            />
            <p v-if="!isJson(item.response.data)">
              {{ item.response.data }}
            </p>
          </v-col>
        </v-row>
        <br>
      </td>
      <td
        v-if="item.status === 'ERROR'"
        :colspan="headers.length"
      >
        <v-row>
          <v-col>
            <v-text-field
              v-model="item.error.error"
              maxlength="100"
              :label="$t('orderExecutionInfo.errorCode')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            {{ $t('orderExecutionInfo.errorMessage') }}:
            <p>
              {{ item.error.message }}
            </p>
            <p v-if="isJson(item.response.data)">
              <vue-json-pretty
                v-if="isJson(item.response.data)"
                :data="parsedJson(item.response.data)"
                :deep="vueJsonPrettyProperties.deep"
                :show-double-quotes="vueJsonPrettyProperties.showDoubleQuotes"
                :show-length="vueJsonPrettyProperties.showLength"
                :show-line="vueJsonPrettyProperties.showLine"
                :show-line-number="vueJsonPrettyProperties.showLineNumber"
                :highlight-mouseover-node="vueJsonPrettyProperties.highlightMouseoverNode"
                :collapsed-on-click-brackets="vueJsonPrettyProperties.collapsedOnClickBrackets"
                :show-icon="vueJsonPrettyProperties.showIcon"
                :show-key-value-space="vueJsonPrettyProperties.showKeyValueSpace"
                style="position: relative"
              />
            </p>
          </v-col>
        </v-row>
      </td>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: 'OrderExecutionInfoTable',
  components: {
    VueJsonPretty
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => [] // executionDate, deviceId, venueId, contractId
    }
  },
  data () {
    return {
      expanded: [],
      singleExpand: false,
      filters: {
        search: null
      },
      possibleHeaders: {
        executionDate: {
          text: this.$t('orderExecutionInfo.executedAt'),
          align: 'start',
          sortable: false,
          value: 'executionDate'
        },
        contractId: {
          text: this.$t('orderExecutionInfo.contractId'),
          align: 'left',
          sortable: true,
          value: 'device.skyIt.status.contractId'
        },
        venueId: {
          text: this.$t('orderExecutionInfo.venueId'),
          align: 'left',
          sortable: true,
          value: 'device.tabcorp.status.uniqueVenueID'
        },
        deviceId: {
          text: this.$t('orderExecutionInfo.deviceId'),
          align: 'left',
          sortable: true,
          value: 'device.id'
        },
        status: {
          text: this.$t('orderExecutionInfo.status'),
          align: 'left',
          sortable: true,
          value: 'status'
        },
        deviceType: {
          text: this.$t('orderExecutionInfo.deviceType'),
          align: 'left',
          sortable: true,
          value: 'device.status.type'
        }
      },
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('orderExecutionInfo.executedOrdersPerPage')
      },
      enrichedItems: [],
      vueJsonPrettyProperties: {
        showIcon: true,
        showLength: true,
        showLine: true,
        showLineNumber: false,
        showDoubleQuotes: true,
        highlightMouseoverNode: true,
        collapsedOnClickBrackets: true,
        useNodeValueSlot: false,
        useNodeKeySlot: false,
        deep: 3,
        showKeyValueSpace: true
      }
    }
  },
  computed: {
    ...mapGetters('customization', ['timeZone']),
    ...mapGetters({
      getHeadendById: 'getHeadendById'
    }),
    columnHeaders: function () {
      const headers = []
      this.columns.forEach(item => {
        headers.push(this.possibleHeaders[item])
      })
      headers.push({ text: '', value: 'data-table-expand' })
      return headers
    }
  },
  watch: {
    items (vals) {
      this.parseData(vals)
    }
  },
  mounted () {
    if (this.items) {
      this.parseData(this.items)
    }
  },
  methods: {
    parseData: function (items) {
      this.enrichedItems = []
      for (const item of items) {
        const cloneItem = JSON.parse(JSON.stringify(item))
        cloneItem.device = this.getHeadendById(item.deviceId)
        if (cloneItem.device !== undefined) {
          this.enrichedItems.push(cloneItem)
        }
      }
    },
    updateFiltered: function (items) {
      if (this.$refs.table) {
        try {
          this.filteredItems = this.$refs.table.$children[0].filteredItems
        } catch (e) {
          this.filteredItems = items
        }
      } else {
        this.filteredItems = items
      }
    },
    isJson: function (str) {
      if (str === undefined || str === null) {
        return false
      }
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    parsedJson: function (json) {
      const object = JSON.parse(json, (key, value) => {
        if (typeof value === 'string' && this.isJson(value)) {
          return JSON.parse(value)
        }
        return value
      })
      return object
    }
  }
}
</script>
<style scoped>
a {
  color: blue;
}
</style>
