<template>
  <v-form
    ref="orderRecurrencyForm"
    v-model="commandRecurrencyForm"
    lazy-validation
  >
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-select
            v-model="execution.mode"
            :prepend-icon="
              execution.mode === 'ONCE'
                ? 'mdi-repeat-off'
                : 'mdi-repeat'
            "
            :label="$t('newOrder.repeatMode')"
            :items="repeatOptions"
            :rules="[rules.required]"
            @change="repeatModeChange"
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <EditDateTime
            v-model="execution.startDate"
            :min-date="new Date().toISOString()"
            :min-time="false"
            :time-label="$t('executionTime')"
            :date-label="$t('executionDate')"
          />
          <EditDateTime
            v-if="execution.mode === 'UNTIL_DATE'"
            v-model="execution.endDate"
            :min-date="execution.startDate"
            :min-time="false"
            :time-label="$t('newOrder.finalTime')"
            :date-label="$t('newOrder.finalDate')"
          />
          <v-text-field
            v-if="execution.mode === 'MULTIPLE_TIMES'"
            v-model.number="execution.numberOfTimes"
            min="0"
            type="number"
            :suffix="$t('newOrder.times')"
            :rules="[rules.required]"
          />
          <EditInterval
            v-if="execution.mode !== 'ONCE'"
            v-model="execution.frequency"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card-actions>
            <v-spacer />
            <slot name="actions" />
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import EditDateTime from '@/components/common/EditDateTime.vue'
import EditInterval from '@/components/common/EditInterval.vue'

export default {
  name: 'OrderRecurrency',
  components: {
    EditDateTime,
    EditInterval
  },
  props: {
    commandType: {
      type: Object,
      default: () => {
        return { repeatMode: ['ONCE', 'MULTIPLE_TIMES', 'UNTIL_DATE', 'FOREVER'] }
      }
    },
    value: {
      type: Object,
      default: () => {
        return {
          mode: 'ONCE',
          startDate: new Date().toISOString()
        }
      }
    }
  },
  data () {
    return {
      commandRecurrencyForm: false,
      periodicity: 'P0DT0H40M',
      maxRepeats: 1,
      repeatUntil: null,
      execution: {
        mode: 'ONCE',
        startDate: new Date().toISOString()
      },
      rules: {
        required: value => !!value || this.$t('validation.requiredField')
      },
      minTime: false
    }
  },
  computed: {
    repeatOptions: function () {
      const header = []
      this.commandType.repeatMode.forEach(item => {
        switch (item) {
          case 'ONCE':
            header.push({
              text: this.$t('repeatModeOption.ONCE'),
              value: 'ONCE'
            })
            break
          case 'MULTIPLE_TIMES':
            header.push({
              text: this.$t('repeatModeOption.MULTIPLE_TIMES'),
              value: 'MULTIPLE_TIMES'
            })
            break
          case 'UNTIL_DATE':
            header.push({
              text: this.$t('repeatModeOption.UNTIL_DATE'),
              value: 'UNTIL_DATE'
            })
            break
          case 'FOREVER':
            header.push({
              text: this.$t('repeatModeOption.FOREVER'),
              value: 'FOREVER'
            })
            break
        }
      })
      return header
    }
  },
  watch: {
    recurrency: {
      handler: function (newData) {
        this.$emit('input', newData)
      },
      deep: true
    },
    value: {
      handler: function (newData) {
        this.execution = newData
      },
      deep: true
    },
    'commandType.repeatMode': function () {
      const firstOption = this.repeatOptions[0]
      this.execution.mode = firstOption.value
      this.repeatModeChange(firstOption.value)
    }
  },
  mounted () {},
  methods: {
    repeatModeChange: function (value) {
      if (value === 'ONCE') {
        delete this.execution.frequency
        delete this.execution.numberOfTimes
        delete this.execution.endDate
      } else if (value === 'FOREVER') {
        delete this.execution.numberOfTimes
      } else if (value === 'MULTIPLE_TIMES') {
        delete this.execution.endDate
      } else if (value === 'UNTIL_DATE') {
        delete this.execution.numberOfTimes
      }
    }
  }
}
</script>
