import {
  SNACKBAR_RESET,
  SNACKBAR_ERROR,
  SNACKBAR_SUCCESS,
  SNACKBAR_WARNING
} from '@/store/actions/snackbar'

export default {
  state: {
    message: '',
    color: '',
    timeout: 6000
  },
  mutations: {
    [SNACKBAR_RESET] (state) {
      state.message = ''
      state.color = ''
      state.timeout = 6000
    },
    [SNACKBAR_ERROR] (state, message) {
      state.message = message
      state.color = 'error'
    },
    [SNACKBAR_SUCCESS] (state, message) {
      state.message = message
      state.color = 'success'
    },
    [SNACKBAR_WARNING] (state, message) {
      state.message = message
      state.color = 'warning'
    }
  },
  actions: {
    [SNACKBAR_RESET]: ({ commit }) => {
      commit(SNACKBAR_RESET)
    },
    [SNACKBAR_ERROR]: ({ commit }, message) => {
      commit(SNACKBAR_ERROR, message)
    },
    [SNACKBAR_SUCCESS]: ({ commit }, message) => {
      commit(SNACKBAR_SUCCESS, message)
    },
    [SNACKBAR_WARNING]: ({ commit }, message) => {
      commit(SNACKBAR_WARNING, message)
    }
  }
}
