<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t('channelMaps.title') }}
      </v-card-title>
      <v-card-text>
        <v-data-table
          :items="channelMaps"
          :headers="headers"
          :footer-props="footerProps"
        >
          <template #top>
            <v-toolbar
              flat
              color="white"
            >
              <v-spacer />
              <v-btn
                right
                class="ma-1"
                color="primary"
                @click="createItem"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                {{ $t("channelMaps.newChannelMap") }}
              </v-btn>
              <v-btn
                right
                class="ma-1"
                color="primary"
                @click.close="dialogControl.importChannelMappingDialog = true"
              >
                <v-icon left>
                  mdi-file-import
                </v-icon>
                {{ $t("channelMaps.importChannelMap") }}
              </v-btn>
            </v-toolbar>
          </template>
          <template
            #[`item.actions`]="{ item }"
          >
            <v-btn
              text
              icon
              color="primary"
              :title="$t('channelMaps.download')"
              @click="downloadJson(item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn
              text
              icon
              color="primary"
              :title="$t('channelMaps.apply')"
              @click="applyChannelMap(item)"
            >
              <v-icon>mdi-file-send</v-icon>
            </v-btn>
            <v-btn
              text
              icon
              color="primary"
              :title="$t('channelMaps.edit')"
              @click="editItem(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              class="pr-1"
              text
              icon
              color="primary"
              :title="$t('channelMaps.delete')"
              @click.stop="confirmDeleteItem(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <ConfirmDialog
      v-model="dialogControl.showDeleteConfirmDialog"
      :item-id="accessedItem"
      :title="$t('channelMaps.confirmDeleteTitle')"
      :message="dialogControl.dialogMessage"
      @confirmed="deleteItem"
    />
    <v-dialog
      v-model="dialogControl.importChannelMappingDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('channelMappingImport.title') }}
        </v-card-title>

        <v-card-text>
          {{ $t('channelMappingImport.message') }}
          <v-file-input
            v-model="importJsonFile"
            accept="application/json"
            show-size
            clearable
            :label="$t('channelMappingImport.inputLabel')"
            :placeholder="$t('channelMappingImport.placeholder')"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click.stop="dialogControl.importChannelMappingDialog = false"
          >
            {{ $t('button.cancel') }}
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            :disabled="importJsonFile === null"
            @click.stop="launchImportJsonFile"
          >
            {{ $t('button.import') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogControl.applyChannelMapDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <ApplyChannelMap
        :channel-map="selectedChannelMap"
        @close="dialogControl.applyChannelMapDialog = false"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { CHANNEL_MAP_TEMPLATES_GET_ALL, CHANNEL_MAP_TEMPLATES_DELETE } from '@/store/actions/channelMapTemplates'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import downloadJson from '@/mixins/downloadJson.js'
import timeoutCancel from '@/mixins/timeoutCancel.js'
import Ajv from 'ajv'
import schemaCM from '@/jsonSchema/channelMapping.js'
import ApplyChannelMap from '@/views/channelMaps/components/ApplyChannelMap.vue'
export default {
  name: 'ChannelMapList',
  components: {
    ConfirmDialog,
    ApplyChannelMap
  },
  mixins: [downloadJson, timeoutCancel],
  data () {
    return {
      channelMappingSchema: schemaCM,
      dialogControl: {
        showDeleteConfirmDialog: false,
        importChannelMappingDialog: false,
        applyChannelMapDialog: false,
        dialogMessage: ''
      },
      accessedItem: null,
      selectedChannelMap: null,
      footerProps: { // v-data-table footer props
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('channelMappingPerPageText')
      },
      importJsonFile: null
    }
  },
  computed: {
    channelMaps () {
      return this.$store.state.channelMapTemplates.all
    },
    headers: function () {
      return [
        {
          text: this.$t('channelMaps.name'),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('channelMaps.description'),
          align: 'left',
          sortable: true,
          value: 'description'
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'actions'
        }
      ]
    }
  },
  created () {
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData: function () {
      this.$store.dispatch(CHANNEL_MAP_TEMPLATES_GET_ALL).then(() => {
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('channelMaps.loadedChannelMapData'))
      }).catch((error) => {
        if (error.response.status !== 401) {
          this.$store.dispatch(SNACKBAR_ERROR, this.$t('channelMaps.errorLoadingChannelMaps'))
        }
      }).finally(() => {
        this.orderTimeout = setTimeout(() => {
          this.loadStatus()
        }, 60000)
      })
    },
    loadStatus: function () {
      this.$store.dispatch(CHANNEL_MAP_TEMPLATES_GET_ALL).then(() => {
      }).finally(() => {
        this.orderTimeout = setTimeout(() => {
          this.loadStatus()
        }, 60000)
      })
    },
    deleteItem: function () {
      this.$store.dispatch(CHANNEL_MAP_TEMPLATES_DELETE, this.accessedItem)
        .then(() => {
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('channelMaps.channelMapDeleted'))
        })
        .catch(() => {
          this.$store.dispatch(SNACKBAR_ERROR, this.$t('channelMaps.errorDeletingChannelMap'))
        })
    },
    confirmDeleteItem: function (item) {
      this.accessedItem = item.id
      this.dialogControl.showDeleteConfirmDialog = true
      this.dialogControl.dialogMessage = this.$t('channelMaps.confirmDeleteMessage', item)
    },
    downloadJson: function (item) {
      this.accessedItem = item.id
      this.downloadJsonFile(JSON.stringify(item.info, null, 4), item.name)
    },
    editItem: function (item) {
      this.accessedItem = item.id
      this.$router.push('/channelmaps/edit/' + item.id)
    },
    createItem: function () {
      this.$router.push('/channelmaps/new')
    },
    launchImportJsonFile: function () {
      const reader = new FileReader()
      reader.readAsText(this.importJsonFile)
      reader.onload = () => {
        try {
          const jsonObject = JSON.parse(reader.result)
          const ajv = new Ajv() // options can be passed, e.g. {allErrors: true}
          const validate = ajv.compile(schemaCM)
          const valid = validate(jsonObject)
          if (!valid) {
            this.$store.dispatch(SNACKBAR_ERROR, this.$t('error.invalidJsonStructure'))
          } else {
            localStorage.setItem('channelImportedMapInfo', JSON.stringify(jsonObject))
            localStorage.setItem('channelImportedMapName', this.importJsonFile.name)
            this.$router.push('/channelmaps/import')
          }
          this.dialogControl.importChannelMappingDialog = false
        } catch (e) {
          this.$store.dispatch(SNACKBAR_ERROR, this.$t('channelMaps.invalidJsonFile'))
        }
      }
    },
    applyChannelMap: function (item) {
      this.dialogControl.applyChannelMapDialog = true
      this.selectedChannelMap = item
    }
  }
}
</script>
