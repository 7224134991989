<template>
  <div class="ml-8 mr-8">
    <span>{{ $t('moduleDialog.outputRF.outputLevel') }}: {{ getOutputAttenuationLevel }}</span>
    <v-expansion-panels
      accordion
      focusable
      class="mt-6 mb-6"
    >
      <v-expansion-panel
        v-for="(item, index) in outputs"
        :key="index"
        :disabled="!item.enabled"
      >
        <v-expansion-panel-header :color="getExpansionPanelHeaderColor(item)">
          <span v-if="item.enabled">
            <span class="mr-4">{{ item.id }}</span>&nbsp;
            <span class="data__header__name">{{ getOutputModeAndFrequency(item) }}</span>
          </span>
          <span v-else>
            <span class="mr-4">{{ item.id }}</span>&nbsp;
            <span class="disabled">{{ $t('common.disabled') }}</span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-if="item.enabled"
          class="mt-4"
        >
          <AlarmMessages
            v-if="hasAlarms(item)"
            :alarms="getAlarms(item)"
          />
          <div v-if="isOutputOFDMModeDefined(item)">
            <span class="data__label">{{ $t('moduleDialog.outputRF.labels.ofdmMode') }}</span>
            <span class="data__value">{{ getOutputOFDMMode(item) }}</span>
          </div>
          <div v-if="isOutputBandwidthDefined(item)">
            <span class="data__label">{{ $t('moduleDialog.outputRF.labels.bandwidth') }}</span>
            <span class="data__value">{{ getOutputBandwidth(item) }}</span>
          </div>
          <div v-if="isOutputGuardIntervalDefined(item)">
            <span class="data__label">{{ $t('moduleDialog.outputRF.labels.guardInterval') }}</span>
            <span class="data__value">{{ getOutputGuardInterval(item) }}</span>
          </div>
          <div v-if="isOutputSymbolRateDefined(item)">
            <span class="data__label">{{ $t('moduleDialog.outputRF.labels.symbolRate') }}</span>
            <span class="data__value">{{ getOutputSymbolRate(item) }}</span>
          </div>
          <div v-if="isOutputConstellationDefined(item)">
            <span class="data__label">{{ $t('moduleDialog.outputRF.labels.constellation') }}</span>
            <span class="data__value">{{ getOutputConstellation(item) }}</span>
          </div>
          <div v-if="isOutputCodeRateDefined(item)">
            <span class="data__label">{{ $t('moduleDialog.outputRF.labels.codeRate') }}</span>
            <span class="data__value">{{ getOutputCodeRate(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.outputRF.labels.outputCapacity') }}</span>
            <span class="data__value">{{ getFormattedOutputCapacity(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.outputRF.labels.outputUsage') }}</span>
            <span class="data__value">
              <a
                @click="changeOutputUsageMode()"
              >
                <span class="full__bar">
                  <span
                    class="percentage__bar bar__color"
                    :style="getOutputUsageStyleWidth(item)"
                  >
                    &nbsp;
                  </span>
                  <span
                    class="bar__limit__value"
                    :style="getOutputUsageMinStyleLeft(item)"
                  />
                  <span
                    class="bar__limit__value"
                    :style="getOutputUsageMaxStyleLeft(item)"
                  />
                  <span
                    v-if="showOutputUsagePercentage"
                    class="bar__value"
                  >
                    {{ getFormattedOutputUsagePercentage(item) }}
                  </span>
                  <span
                    v-if="!showOutputUsagePercentage"
                    class="bar__value"
                  >
                    {{ getFormattedOutputUsageMbps(item) }}
                  </span>
                </span>
              </a>
            </span>
          </div>
          <div v-if="isOutputQualityOfServiceDefined(item)">
            <span class="data__label">{{ $t('moduleDialog.outputRF.labels.qualityOfService') }}</span>
            <span class="data__value">{{ getOutputQualityOfService(item) }}</span>
          </div>
          <div v-if="hasOutputServicesAssigned(item)">
            <span class="data__label">{{ $t('moduleDialog.outputRF.labels.serviceList') }}</span>
            <span class="data__value">
              <ul class="data__list">
                <li
                  v-for="(service, serviceIndex) in getOutputServiceList(item)"
                  :key="serviceIndex"
                >
                  {{ getServiceName(service) }}
                </li>
              </ul>
            </span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../store/namespaces'

import {
  ReportConfigurationModuleRFOutputsItemOutputModeValue,
  ReportConfigurationModuleRFOutputsItemOutputModeLabel,
  ReportConfigurationModuleRFOutputsItemOFDMModeValue,
  ReportConfigurationModuleRFOutputsItemOFDMModeLabel,
  ReportConfigurationModuleRFOutputsItemBandwidthValue,
  ReportConfigurationModuleRFOutputsItemBandwidthLabel,
  ReportConfigurationModuleRFOutputsItemGuardIntervalValue,
  ReportConfigurationModuleRFOutputsItemGuardIntervalLabel,
  ReportConfigurationModuleRFOutputsItemConstellationValue,
  ReportConfigurationModuleRFOutputsItemConstellationLabel,
  ReportConfigurationModuleRFOutputsItemCodeRateValue,
  ReportConfigurationModuleRFOutputsItemCodeRateLabel
} from '../../../types/Report'

import AlarmMessages from './AlarmMessages.vue'

export default {
  name: 'OutputRFData',
  components: {
    AlarmMessages
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    outputs: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      showOutputUsagePercentage: true
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getStatusModuleBySlot']),
    getOutputAttenuationLevel: function () {
      const attenuation = this.module.outputs.rf.attenuation * -1
      let outputLevel = attenuation + ' dB'
      if (attenuation > 0) {
        outputLevel = '+' + outputLevel
      }
      return outputLevel
    }
  },
  methods: {
    getStatusModuleOutputRFItem: function (moduleOutput) {
      return this.getStatusModuleBySlot(this.module.slot).outputsRF.find((outputRF) => {
        return outputRF.id === moduleOutput.id
      })
    },
    hasAlarms: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputRFItem(moduleOutput)
      return outputStatus.alarms !== undefined && outputStatus.alarms.length > 0
    },
    getAlarms: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputRFItem(moduleOutput)
      return outputStatus.alarms
    },
    getExpansionPanelHeaderColor: function (moduleOutput) {
      if (moduleOutput.enabled) {
        if (this.hasAlarms(moduleOutput)) {
          return '#D31F1F'
        }
      }
      return ''
    },
    getOutputModeAndFrequency: function (moduleOutput) {
      let outputMode = ''

      switch (moduleOutput.standard) {
        case ReportConfigurationModuleRFOutputsItemOutputModeValue.TERRESTRIAL:
          outputMode = ReportConfigurationModuleRFOutputsItemOutputModeLabel.TERRESTRIAL
          break
        case ReportConfigurationModuleRFOutputsItemOutputModeValue.CABLE:
          outputMode = ReportConfigurationModuleRFOutputsItemOutputModeLabel.CABLE
          break
      }
      const frequency = moduleOutput.frequency / 1000 + ' MHz'
      return outputMode + ' - ' + frequency
    },
    isOutputOFDMModeDefined: function (moduleOutput) {
      return moduleOutput.ofdmMode !== undefined && moduleOutput.ofdmMode !== null
    },
    getOutputOFDMMode: function (moduleOutput) {
      let ofdmMode
      switch (moduleOutput.ofdmMode) {
        case ReportConfigurationModuleRFOutputsItemOFDMModeValue.ZERO:
          ofdmMode = ReportConfigurationModuleRFOutputsItemOFDMModeLabel.ZERO
          break
        case ReportConfigurationModuleRFOutputsItemOFDMModeValue.ONE:
          ofdmMode = ReportConfigurationModuleRFOutputsItemOFDMModeLabel.ONE
          break
      }
      return ofdmMode
    },
    isOutputBandwidthDefined: function (moduleOutput) {
      return moduleOutput.bandwidth !== undefined && moduleOutput.bandwidth !== null
    },
    getOutputBandwidth: function (moduleOutput) {
      let bandwidth
      switch (moduleOutput.bandwidth) {
        case ReportConfigurationModuleRFOutputsItemBandwidthValue.ZERO:
          bandwidth = ReportConfigurationModuleRFOutputsItemBandwidthLabel.ZERO
          break
        case ReportConfigurationModuleRFOutputsItemBandwidthValue.ONE:
          bandwidth = ReportConfigurationModuleRFOutputsItemBandwidthLabel.ONE
          break
        case ReportConfigurationModuleRFOutputsItemBandwidthValue.TWO:
          bandwidth = ReportConfigurationModuleRFOutputsItemBandwidthLabel.TWO
          break
      }
      return bandwidth
    },
    isOutputGuardIntervalDefined: function (moduleOutput) {
      return moduleOutput.guardInterval !== undefined && moduleOutput.guardInterval !== null
    },
    getOutputGuardInterval: function (moduleOutput) {
      let guardInterval
      switch (moduleOutput.guardInterval) {
        case ReportConfigurationModuleRFOutputsItemGuardIntervalValue.ZERO:
          guardInterval = ReportConfigurationModuleRFOutputsItemGuardIntervalLabel.ZERO
          break
        case ReportConfigurationModuleRFOutputsItemGuardIntervalValue.ONE:
          guardInterval = ReportConfigurationModuleRFOutputsItemGuardIntervalLabel.ONE
          break
        case ReportConfigurationModuleRFOutputsItemGuardIntervalValue.TWO:
          guardInterval = ReportConfigurationModuleRFOutputsItemGuardIntervalLabel.TWO
          break
        case ReportConfigurationModuleRFOutputsItemGuardIntervalValue.THREE:
          guardInterval = ReportConfigurationModuleRFOutputsItemGuardIntervalLabel.THREE
          break
      }
      return guardInterval
    },
    isOutputConstellationDefined: function (moduleOutput) {
      return moduleOutput.constellation !== undefined && moduleOutput.constellation !== null
    },
    getOutputConstellation: function (moduleOutput) {
      let constellation
      switch (moduleOutput.constellation) {
        case ReportConfigurationModuleRFOutputsItemConstellationValue.ZERO:
          constellation = ReportConfigurationModuleRFOutputsItemConstellationLabel.ZERO
          break
        case ReportConfigurationModuleRFOutputsItemConstellationValue.ONE:
          constellation = ReportConfigurationModuleRFOutputsItemConstellationLabel.ONE
          break
        case ReportConfigurationModuleRFOutputsItemConstellationValue.TWO:
          constellation = ReportConfigurationModuleRFOutputsItemConstellationLabel.TWO
          break
      }
      return constellation
    },
    isOutputSymbolRateDefined: function (moduleOutput) {
      return moduleOutput.symbolRate !== undefined && moduleOutput.symbolRate !== null
    },
    getOutputSymbolRate: function (moduleOutput) {
      const symbolRate = moduleOutput.symbolRate
      return (symbolRate / 1000) + ' Msyms/s'
    },
    isOutputCodeRateDefined: function (moduleOutput) {
      return moduleOutput.codeRate !== undefined && moduleOutput.codeRate !== null
    },
    getOutputCodeRate: function (moduleOutput) {
      let codeRate
      switch (moduleOutput.codeRate) {
        case ReportConfigurationModuleRFOutputsItemCodeRateValue.ZERO:
          codeRate = ReportConfigurationModuleRFOutputsItemCodeRateLabel.ZERO
          break
        case ReportConfigurationModuleRFOutputsItemCodeRateValue.ONE:
          codeRate = ReportConfigurationModuleRFOutputsItemCodeRateLabel.ONE
          break
        case ReportConfigurationModuleRFOutputsItemCodeRateValue.TWO:
          codeRate = ReportConfigurationModuleRFOutputsItemCodeRateLabel.TWO
          break
        case ReportConfigurationModuleRFOutputsItemCodeRateValue.THREE:
          codeRate = ReportConfigurationModuleRFOutputsItemCodeRateLabel.THREE
          break
        case ReportConfigurationModuleRFOutputsItemCodeRateValue.FOUR:
          codeRate = ReportConfigurationModuleRFOutputsItemCodeRateLabel.FOUR
          break
      }
      return codeRate
    },
    getOutputCapacity: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputRFItem(moduleOutput)
      return outputStatus.bitrate
    },
    getFormattedOutputCapacity: function (moduleOutput) {
      return this.getOutputCapacity(moduleOutput) + ' Mbps'
    },
    getOutputUsage: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputRFItem(moduleOutput)
      return 100 - outputStatus.free.current
    },
    getFormattedOutputUsagePercentage: function (moduleOutput) {
      return this.getOutputUsage(moduleOutput) + '%'
    },
    getFormattedOutputUsageMbps: function (moduleOutput) {
      return Math.round((this.getOutputUsage(moduleOutput) * this.getOutputCapacity(moduleOutput))) + ' Mbps'
    },
    getOutputUsageStyleWidth: function (moduleOutput) {
      return 'width: ' + this.getOutputUsage(moduleOutput) + '%;'
    },
    getOutputUsageMinStyleLeft: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputRFItem(moduleOutput)
      const min = 100 - outputStatus.free.min
      return 'left: ' + min + '%;'
    },
    getOutputUsageMaxStyleLeft: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputRFItem(moduleOutput)
      const max = 100 - outputStatus.free.max
      return 'left: ' + max + '%;'
    },
    changeOutputUsageMode: function () {
      this.showOutputUsagePercentage = !this.showOutputUsagePercentage
    },
    isOutputQualityOfServiceDefined: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputRFItem(moduleOutput)
      return outputStatus.nominalSeconds !== undefined && outputStatus.nominalSeconds !== null &&
        outputStatus.impairedSeconds !== undefined && outputStatus.impairedSeconds !== null &&
        outputStatus.noLinkSeconds !== undefined && outputStatus.noLinkSeconds !== null
    },
    getOutputQualityOfService: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputRFItem(moduleOutput)
      // Formula:  nominalSeconds * 100 / (nominalSeconds + impairedSeconds + noLinkSeconds)
      const denominator = outputStatus.nominalSeconds + outputStatus.impairedSeconds + outputStatus.noLinkSeconds
      if (denominator > 0) {
        return Math.round(outputStatus.nominalSeconds * 100 / denominator) + '%'
      }
      return ''
    },
    getOutputServiceList: function (moduleOutput) {
      const services = this.module.services.rf.filter((service) => {
        return service.output === moduleOutput.id
      })
      return services.sort((a, b) => {
        return (a.id > b.id)
          ? 1
          : (a.id < b.id)
            ? -1
            : 0
      })
    },
    hasOutputServicesAssigned: function (moduleOutput) {
      return this.getOutputServiceList(moduleOutput).length > 0
    },
    getServiceName: function (service) {
      return service.outputName
    }
  }
}
</script>
<style scoped>
.data__header__name {
  display: inline-block;
  width: 14.5rem;
}
.data__label {
  display: inline-block;
  width: 15em;
  margin-left: 2em;
  vertical-align: top;
}
.data__value {
  display: inline-block;
  width: 55%;
}
.full__bar {
  position: relative;
  display: inline-block;
  background-color: #9E9E9E;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
}
.percentage__bar {
  position: absolute;
  display: inline-block;
}
.bar__color {
  background-color: #628C36;
}
.bar__value {
  position: relative;
  margin-left: 0.5em;
  color: black;
}
.bar__limit__value {
  position: absolute;
  height: 100%;
  border: solid 1px red;
  background-color: red;
}
.data__list {
  list-style: none;
  margin-left: -25px;
}
</style>
