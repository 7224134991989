<template>
  <v-card>
    <v-card-title>
      {{ $t('backups.widgetTitle') }}
      <v-spacer />
      {{ numberOfUserCreatedBackups >= 3 ? $t('backups.userMaximumBackups') : '' }}
      <v-btn
        v-if="hasRights('flt:da')"
        :disabled="!isOnline() || numberOfUserCreatedBackups >= 3"
        text
        icon
        :title="$t('backups.createBackup')"
        color="primary"
        @click="createBackup"
      >
        <v-icon>mdi-cloud-upload</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="backups"
        hide-default-footer
      >
        <!-- CREATED AT -->
        <template #[`item.createdAt`]="{ item }">
          {{ item.createdAt | formatDate($locale, timeZone) }}
        </template>
        <!-- AUTOMATIC -->
        <template #[`item.isAutomatic`]="{ item }">
          {{ item.isAutomatic | formatBoolean($t('Yes'), $t('No')) }}
        </template>
        <template #[`item.restore`]="{ item }">
          <v-btn
            icon
            color="primary"
            :title="$t('backups.applyBackup')"
            :disabled="!isOnline()"
            @click="applyBackup(item)"
          >
            <v-icon>
              mdi-backup-restore
            </v-icon>
          </v-btn>
          <v-btn
            icon
            color="primary"
            :title="$t('backups.deleteBackup')"
            :disabled="!isOnline()"
            @click="deleteBackup(item)"
          >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HeadendBackups',
  props: {
    headend: {
      type: Object,
      default: () => {}
    },
    backups: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      confirmDialog: {
        show: false
      },
      infoHeaders: [
        {
          text: this.$t('backups.name'),
          align: 'left',
          sortable: true,
          value: 'url'
        },
        {
          text: this.$t('backups.date'),
          align: 'right',
          sortable: true,
          value: 'createdAt'
        },
        {
          text: this.$t('backups.automatic'),
          align: 'right',
          sortable: true,
          value: 'isAutomatic'
        }
      ],
      restoreHeader: {
        text: '',
        align: 'end',
        sortable: false,
        value: 'restore'
      }
    }
  },
  computed: {
    ...mapGetters('customization', ['timeZone']),
    ...mapGetters(['hasRights']),
    headers: function () {
      if (this.hasRights('flt:da')) {
        return this.infoHeaders.concat([this.restoreHeader])
      }
      return this.infoHeaders
    },
    numberOfUserCreatedBackups: function () {
      return this.backups.filter(backup => !backup.isAutomatic).length
    }
  },
  methods: {
    isOnline: function () {
      return this.headend.status.connection === 'online'
    },
    applyBackup: function (backup) {
      this.$emit('apply', backup)
    },
    createBackup: function () {
      this.$emit('create')
    },
    deleteBackup: function (backup) {
      this.$emit('delete', backup)
    }
  }
}
</script>
