<template>
  <v-card
    elevation="2"
  >
    <v-card-title
      class="title"
    >
      {{ $t('channelMapEdit.output') }}
      <v-spacer />
      <v-btn
        v-if="localValue.length === 1"
        color="primary"
        class="ma-2"
        @click="addEmptySlot(0)"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        {{ $t('channelMapEdit.addEmptyService') }}
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-expansion-panels
        multiple
        accordion
        focusable
      >
        <v-expansion-panel
          v-for="(module, index) in localValue"
          :key="index"
        >
          <v-expansion-panel-header class="subtitle-2">
            <span>
              <v-btn
                v-if="editModuleNumber"
                color="primary"
                icon
                small
                :title="$t('channelMapEdit.remove')"
                @click.stop="removeModule(index)"
              >
                <v-icon> mdi-minus </v-icon>
              </v-btn>
              {{ $t('channelMapEdit.module') + ' ' + (parseInt(index, 10) + 1) }}
            </span>
            <v-spacer />
            {{ module.services.length }} {{ $t('channelMapEdit.outputServices') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              hide-default-footer
              disable-pagination
              :items="module.services"
              :headers="headersForModuleServices"
            >
              <template #[`item.name`]="{ item }">
                <v-icon
                  v-if="!checkServiceInput(item)"
                  color="red"
                  :title="$t('channelMapEdit.serviceNotAtInput')"
                >
                  mdi-alert
                </v-icon>
                {{ item.name }}
              </template>
              <template #[`item.serviceId`]="{ item }">
                {{ parseInt(item.serviceId) === 0 ? $t('channelMapEdit.emptyService') : item.serviceId }}
              </template>
              <template #[`item.output`]="{ item }">
                <v-select
                  v-model="item.output"
                  :items="selectOptions.output"
                  :rules="[ rules.required ]"
                />
              </template>
              <template #[`item.decrypt`]="{ item }">
                <v-select
                  v-model="item.decrypt"
                  :items="selectOptions.decrypt"
                />
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  color="primary"
                  icon
                  small
                  @click="removeServiceFromOutput(item, index)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-btn
        v-if="editModuleNumber"
        color="primary"
        icon
        small
        :title="$t('channelMapEdit.addModule')"
        @click="addModule()"
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  name: 'ChannelMapOutputOptions',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    channelMaps: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      localValue: [{ services: [] }],
      editModuleNumber: false,
      lcnInit: 601,
      selectOptions: {
        decrypt: [
          { text: this.$t('Off'), value: 0 },
          { text: this.$t('CAM') + ' 1', value: 1 },
          { text: this.$t('CAM') + ' 2', value: 2 }
        ],
        output: [
          { text: '1', value: 1 },
          { text: '2', value: 2 },
          { text: '3', value: 3 },
          { text: '4', value: 4 }
        ]
      },
      headersForModuleServices: [
        { text: this.$t('name'), value: 'inputName', sortable: this.stepperControl !== 3 },
        { text: this.$t('inputSid'), value: 'inputServiceId', width: '15%', sortable: true },
        { text: this.$t('output'), value: 'output', width: '15%', sortable: true },
        { text: this.$t('decrypt'), value: 'decrypt', width: '15%', sortable: true },
        {
          text: '',
          value: 'actions',
          width: 24 // icon size
        }
      ],
      rules: { // Specific rules so that the form can de validated
        required: value => !!value || this.$t('validation.requiredField')
      }
    }
  },
  computed: {
    globalOutputList: function () { // All the outputs with added module info joint in one array
      let outputList = []
      this.localValue.forEach(module => {
        outputList = outputList.concat(...module.services)
      })
      return outputList
    }
  },
  watch: {
    localValue: {
      deep: true,
      handler: function (newValue) {
        this.$emit('input', newValue)
      }
    },
    value: {
      deep: true,
      handler: function (newValue) {
        this.localValue = newValue
      }
    }
  },
  methods: {
    checkServiceInput: function (service) {
      if (parseInt(service.serviceId) === 0 || this.channelMaps.length === 0) {
        // Los empty services no sacaran error
        return true
      } else if (typeof this.channelMaps[service.module] === 'undefined') {
        return false
      } else {
        return Object.entries(this.channelMaps[service.module]).some(([inputKey, inputData]) => {
          if (parseInt(service.input) === parseInt(inputKey)) {
            return inputData.inputServices.some(inputService => {
              return parseInt(service.serviceId) === parseInt(inputService.serviceId)
            })
          } else {
            return false
          }
        })
      }
    },
    addEmptySlot: function (moduleIndex) {
      let lcn
      for (let i = this.lcnInit; i < 999; i++) {
        if (this.globalOutputList.findIndex(service => { return parseInt(service.lcn, 10) === i }) < 0) {
          lcn = i
          break
        }
      }
      let sid
      for (let i = 1; i < 65535; i++) {
        if (this.globalOutputList.findIndex(service => { return parseInt(service.outputServiceId, 10) === i }) < 0) {
          sid = i
          break
        }
      }
      const outputService = {
        input: 1,
        inputServiceId: 0,
        inputName: '',
        output: 1,
        outputServiceId: sid,
        outputName: 'Spare',
        lcn: lcn,
        decrypt: 0,
        language: 'eng',
        module: moduleIndex
      }
      this.localValue[moduleIndex].services.push(outputService)
    },
    removeServiceFromOutput: function (service, moduleSlot) {
      this.localValue[moduleSlot].services.splice(this.localValue[moduleSlot].services.findIndex(activeService => {
        return service === activeService
      }), 1)
    },
    removeModule: function (index) {
      this.localValue.splice(index, 1)
    },
    addModule: function () {
      this.localValue.push({ services: [] })
    }
  }
}
</script>
