var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.items,"search":_vm.filters.search,"footer-props":_vm.footerProps,"dense":""},on:{"current-items":_vm.updateFiltered},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('label.search'),"single-line":"","hide-details":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('v-spacer'),_vm._t("default")],2)]},proxy:true},{key:"item.headend.skyIt.status.systemType",fn:function(ref){
var item = ref.item;
return [(item.headend)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('headendSystemType.' + item.headend.skyIt.status.systemType))+" ")]):_vm._e()]}},{key:"header.skyIt.status.systemType",fn:function(){return [_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._v(" "+_vm._s(_vm.$t('systemType'))+" "),_c('v-btn',_vm._g({attrs:{"icon":"","color":_vm.filters.sstype.length ? 'primary' : ''}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-filter-menu ")])],1)]}}])},[_c('v-card',[_c('v-card-title',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('filtersSType'))+" ")]),_c('v-card-text',_vm._l((_vm.systemTypes),function(item){return _c('v-checkbox',{key:item.value,attrs:{"label":item.text,"value":item.value,"color":"primary","on-icon":"mdi-checkbox-marked-outline","off-icon":"mdi-checkbox-blank-outline","dense":"","hide-details":""},model:{value:(_vm.filters.sstype),callback:function ($$v) {_vm.$set(_vm.filters, "sstype", $$v)},expression:"filters.sstype"}})}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.filters.sstype.length,"text":""},on:{"click":function($event){_vm.filters.sstype = []}}},[_vm._v(" "+_vm._s(_vm.$t('clearFilters'))+" ")])],1)],1)],1)]},proxy:true},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [(_vm.showAction('delete'))?_c('v-btn',{attrs:{"text":"","icon":"","color":"primary","title":_vm.$t('installerAssignments.delete')},on:{"click":function($event){return _vm.throwAction('deleteItem', item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"header.selections",fn:function(){return [(_vm.selection == 'multiple')?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.selectUnselectAll()}}},[(_vm.areAllSelected())?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked-outline ")]):(_vm.isAnySelected())?_c('v-icon',[_vm._v(" mdi-minus-box-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1):_vm._e()]},proxy:true},{key:"item.selections",fn:function(ref){
var item = ref.item;
return [(_vm.selection != 'none')?_c('span',[(_vm.isSelected(item))?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.deselect(item)}}},[(_vm.selection === 'multiple')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked-outline ")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-radiobox-marked ")])],1):_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.select(item)}}},[(_vm.selection === 'multiple')?_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-circle-outline ")])],1)],1):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }