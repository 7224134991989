<template>
  <v-snackbar
    v-model="show"
    :color="color"
    :timeout="timeout"
    absolute
    top
    style="z-index:9999;"
  >
    {{ message }}
    <template #action="{ attrs }">
      <v-btn
        dark
        text
        icon
        v-bind="attrs"
        :title="$t('button.close')"
        @click="show = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>

import {
  SNACKBAR_RESET
} from '@/store/actions/snackbar'

export default {
  data () {
    return {
      show: false,
      color: '',
      message: '',
      timeout: null
    }
  },
  created: function () {
    this.$store.watch(state => state.snackbar.message, () => {
      if (this.$store.state.snackbar.message !== '') {
        this.show = true
        this.color = this.$store.state.snackbar.color
        this.timeout = this.$store.state.snackbar.timeout
        this.message = this.$store.state.snackbar.message

        this.$store.dispatch(SNACKBAR_RESET)
      }
    })
  }
}
</script>
