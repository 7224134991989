<template>
  <v-col>
    <v-form @submit="save">
      <v-row dense>
        <v-col cols="3">
          {{ $t('section.info.label.alias') }}
        </v-col>
        <v-col>
          <v-text-field
            ref="alias"
            v-model="alias"
            :readonly="!editing"
            dense
            color="primary"
            :append-icon="editingIcon"
            @click:append="startEditingAlias"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          {{ $t('section.info.label.comment') }}
        </v-col>
        <v-col>
          <v-textarea
            ref="comment"
            v-model="comment"
            rows="3"
            :readonly="!editing"
            dense
            maxlength="400"
            :append-icon="editingIcon"
            @click:append="startEditingComment"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="3">
          {{ $t('section.info.label.groups') }}
        </v-col>
        <v-col>
          <div v-if="!editing">
            <v-chip
              v-for="group in groups"
              :key="group"
              class="ma-1"
            >
              {{ getGroupNameById(group) }}
            </v-chip>
          </div>
          <div v-else>
            <v-autocomplete
              v-model="groups"
              :items="getAllGroups"
              item-text="name"
              item-value="id"
              chips
              deletable-chips
              multiple
            />
          </div>
        </v-col>
        <v-btn
          v-if="!editing"
          icon
          @click="startEditingGroups"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-row>
      <v-row
        v-show="editing"
        dense
      >
        <v-spacer />
        <v-btn
          color="primary"
          class="mr-2"
          @click="cancel()"
        >
          {{ $t('common.button.cancel') }}
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          @submit="save"
        >
          {{ $t('common.button.save') }}
        </v-btn>
      </v-row>
    </v-form>
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_AUTHENTICATION, NS_CUSTOMIZATION, NS_GROUPS, NS_HEADEND, NS_LOADING, NS_SNACKBAR } from '../../store/namespaces'

import { LOADING_INCREASE, LOADING_DECREASE } from '../../store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '../../store/actions/snackbar'
import { HEADEND_EDIT_INFO, HEADEND_EDIT_GROUPS } from '../../store/actions/headend'
import { GROUPS_UPDATE } from '../../store/actions/groups'

export default {
  name: 'InfoEditableData',
  data () {
    return {
      editing: false,
      alias: undefined,
      comment: undefined,
      groups: undefined,
      editingIcon: 'mdi-pencil'
    }
  },
  computed: {
    ...mapGetters(NS_AUTHENTICATION, ['hasRights']),
    ...mapGetters(NS_CUSTOMIZATION, ['primaryColor']),
    ...mapGetters(NS_GROUPS, ['getGroupById', 'getGroupNameById', 'getAllGroups']),
    ...mapGetters(NS_HEADEND, ['getHeadend']),
    headend: function () {
      return this.getHeadend
    }
  },
  mounted () {
    this.resetData()
  },
  methods: {
    resetData: function () {
      this.alias = this.headend.config.alias
      this.comment = this.headend.config.comment
      this.groups = this.headend.config.groups
      this.editingIcon = 'mdi-pencil'
    },
    startEditingAlias: function () {
      this.editing = true
      this.editingIcon = null
      this.$refs.alias.focus()
    },
    startEditingComment: function () {
      this.editing = true
      this.editingIcon = null
      this.$refs.comment.focus()
    },
    startEditingGroups: function () {
      this.editing = true
      this.editingIcon = null
    },
    cancel: function () {
      this.editing = false
      this.resetData()
    },
    save: function (e) {
      e.preventDefault()

      const promises = []

      if (this.alias !== this.headend.config.alias || this.comment !== this.headend.config.comment) {
        const payload = {
          headendId: this.headend.id,
          alias: this.alias,
          comment: this.comment
        }
        promises.push(this.$store.dispatch(NS_HEADEND + '/' + HEADEND_EDIT_INFO, payload))
      }

      if (this.groups !== this.headend.config.groups) {
        const updatedGroups = []

        const removedGroupIds = this.headend.config.groups.filter((groupId) => {
          return !this.groups.includes(groupId)
        })
        removedGroupIds.forEach((groupId) => {
          const originalGroup = this.getGroupById(groupId)
          const group = JSON.parse(JSON.stringify(originalGroup))
          if (group !== undefined && group !== null && group.devices.includes(this.headend.id)) {
            const updatedDevices = group.devices.filter((value, index, arr) => {
              return value !== this.headend.id
            })
            group.devices = updatedDevices
            updatedGroups.push(group)
          }
        })

        const addedGroupIds = []
        this.groups.forEach((groupId) => {
          if (!this.headend.config.groups.includes(groupId)) {
            addedGroupIds.push(groupId)
          }
        })
        addedGroupIds.forEach((groupId) => {
          const originalGroup = this.getGroupById(groupId)
          const group = JSON.parse(JSON.stringify(originalGroup))
          if (group !== undefined && group !== null && !group.devices.includes(this.headend.id)) {
            group.devices.push(this.headend.id)
            updatedGroups.push(group)
          }
        })

        updatedGroups.forEach((group) => {
          promises.push(this.$store.dispatch(NS_GROUPS + '/' + GROUPS_UPDATE, group))
        })
      }

      if (promises.length > 0) {
        this.$store.dispatch(NS_LOADING + '/' + LOADING_INCREASE)
        Promise.all(promises)
          .then(() => {
            this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
            this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('section.info.headendUpdated'))
            this.$store.dispatch(NS_HEADEND + '/' + HEADEND_EDIT_GROUPS, this.groups)
            this.editing = false
            this.editingIcon = 'mdi-pencil'
          })
          .catch((error) => {
            this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
            if (error.status !== 401) {
              this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_ERROR, this.$t('section.info.errorUpdatingHeadend'))
            }
          })
      }
    }
  }
}
</script>
<style></style>
