<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          multi-sort
          :headers="headers"
          :items="items"
          :search="filters.search"
          :footer-props="footerProps"
          dense
        >
          <!-- TOP BAR -->
          <template #top>
            <v-toolbar
              flat
              color="white"
            >
              <v-text-field
                v-model="filters.search"
                append-icon="mdi-magnify"
                :label="$t('label.search')"
                single-line
                hide-details
              />
              <v-spacer />
              <slot name="actions" />
            </v-toolbar>
          </template>
          <!-- Tags: header -->
          <template #[`item.tags`]="{ item }">
            <v-chip
              v-for="(element, index) in item.tags"
              :key="index"
              class="ma-1"
              small
              label
            >
              {{
                index === 'always'
                  ? $t('deployChannelMap.always')
                  : $t('deployChannelMap.' + index) + ': ' + $t(element)
              }}
            </v-chip>
          </template>
          <!-- Settings: cell -->
          <template #[`item.settings`]="{ item }">
            <!-- EDIT BUTTON -->
            <v-btn
              v-if="showAction('edit')"
              text
              icon
              color="primary"
              :title="$t('deployChannelMap.edit')"
              @click="throwAction('editItem', item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <!-- DELETE BUTTON -->
            <v-btn
              v-if="showAction('delete')"
              text
              icon
              color="primary"
              :title="$t('deployChannelMap.delete')"
              @click="throwAction('deleteItem', item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <!-- SELECTIONS HEADER -->
          <template #[`header.selections`]>
            <v-btn
              v-if="selection == 'multiple'"
              text
              icon
              @click="selectUnselectAll()"
            >
              <v-icon
                v-if="areAllSelected()"
                color="primary"
              >
                mdi-checkbox-marked-outline
              </v-icon>
              <v-icon v-else-if="isAnySelected()">
                mdi-minus-box-outline
              </v-icon>
              <v-icon v-else>
                mdi-checkbox-blank-outline
              </v-icon>
            </v-btn>
          </template>
          <!-- Selections: cell -->
          <template #[`item.selections`]="{ item }">
            <span v-if="selection != 'none'">
              <v-btn
                v-if="isSelected(item)"
                text
                icon
                @click="deselect(item)"
              >
                <v-icon
                  v-if="selection === 'multiple'"
                  color="primary"
                >
                  mdi-checkbox-marked-outline
                </v-icon>
                <v-icon
                  v-else
                  color="primary"
                >
                  mdi-radiobox-marked
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                text
                icon
                @click="select(item)"
              >
                <v-icon v-if="selection === 'multiple'">
                  mdi-checkbox-blank-outline
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-circle-outline
                </v-icon>
              </v-btn>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'DeployChannelTable',
  components: {},
  props: {
    items: {
      type: Array,
      default: () => []
    }, // The list of headends
    selection: {
      type: String,
      default: 'none' // single or multiple
    },
    actions: {
      type: Array,
      default: () => [] // Actions types: delete
    },
    columns: {
      type: Array,
      default: () => [] // Columns to show:
    }
  },
  data () {
    return {
      selectedItems: {},
      filters: {
        search: null
      },
      possibleHeaders: {
        order: {
          text: this.$t('deployChannelMap.order'),
          align: 'left',
          sortable: true,
          value: 'order'
        },
        name: {
          text: this.$t('deployChannelMap.name'),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        description: {
          text: this.$t('deployChannelMap.description'),
          align: 'left',
          sortable: true,
          value: 'description'
        },
        tags: {
          text: this.$t('deployChannelMap.tags'),
          align: 'left',
          sortable: false,
          value: 'tags'
        },
        settings: {
          text: '',
          align: 'end',
          sortable: false,
          value: 'settings'
        },
        selections: {
          text: '',
          align: 'end',
          sortable: false,
          value: 'selections'
        }
      },
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('deployChannelMap.PerPageText')
      }
    }
  },
  computed: {
    ...mapState({
      groups: state => {
        return state.groupsNew.all
      }
    }),
    headers: function () {
      const headers = []
      this.columns.forEach(item => {
        headers.push(this.possibleHeaders[item])
      })
      if (this.actions.length > 0) {
        headers.push(this.possibleHeaders.settings)
      }
      if (this.selection === 'multiple' || this.selection === 'single') {
        headers.push(this.possibleHeaders.selections)
      }
      return headers
    }
  },
  watch: {
    items: function () {
      this.selectedItems = {}
    }
  },
  mounted () {},
  methods: {
    showAction: function (action) {
      return this.actions.includes(action)
    },
    throwAction: function (action, item) {
      this.$emit(action, item)
    },
    isSelected (item) {
      return this.selectedItems[item.id]
    },
    deselect: function (item) {
      this.$delete(this.selectedItems, item.id)
      this.$emit('input', Object.values(this.selectedItems))
    },
    select: function (item) {
      if (this.selectedItems !== {} && this.selection === 'single') {
        this.selectedItems = {}
      }
      this.$set(this.selectedItems, item.id, item)
      this.$emit('input', Object.values(this.selectedItems))
    },
    selectUnselectAll: function () {
      if (Object.values(this.selectedItems).length > 0) {
        this.selectedItems = {}
      } else {
        this.selectedItems = {}
        this.items.forEach((item, i) => {
          this.$set(this.selectedItems, item.id, item)
        })
      }
      this.$emit('input', Object.values(this.selectedItems))
    },
    areAllSelected: function () {
      return Object.values(this.selectedItems).length === this.items.length
    },
    isAnySelected: function () {
      return Object.values(this.selectedItems).length > 0
    }
  }
}
</script>
