<template>
  <div class="d-flex">
    <ModuleDialog
      v-model="usbDialog.show"
      :title="usbDialog.title"
      type="networkUSB"
      :module="module"
      :usb="usb"
    />
    <a
      :class="getClassName"
      title="Network USB"
      @click="showUSBDialog"
    />
  </div>
</template>

<script>
// common
import ModuleDialog from '../../../common/ModuleDialog.vue'

import { ReportStatusModuleNetworkControlPresent } from '../../../../types/Report'

export default {
  name: 'SlotNetworkUSB',
  components: {
    ModuleDialog
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    usb: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    }
  },
  data () {
    return {
      usbDialog: {
        show: false
      }
    }
  },
  computed: {
    isUSBEnabled: function () {
      return this.usb !== undefined && this.usb !== null && this.usb.control !== undefined && this.usb.control !== null && this.usb.control.present === ReportStatusModuleNetworkControlPresent.yes
    },
    getClassName: function () {
      let className = 'module__usb'
      if (this.isUSBEnabled) {
        className = className.concat(' enabled')
      }
      return className
    },
    isNetworkUSBData: function () {
      return (this.usb !== undefined && this.usb !== null)
    }
  },
  methods: {
    showUSBDialog: function () {
      if (this.isNetworkUSBData) {
        this.usbDialog.show = true
        this.usbDialog.title = this.$t('moduleDialog.usb.title', { moduleInfo: this.getModuleDialogTitle() })
      }
    },
    getModuleDialogTitle: function () {
      return this.$t('moduleDialog.title', { moduleModel: this.module.name, slot: this.module.slot })
    }
  }
}
</script>

<style scoped>
.module__usb {
  background-image: url('../../../../assets/images/network-usb.svg');
  width: 2.188rem;
  height: 1.688rem;
  background-size: 2.188rem 1.688rem;
  margin-bottom: 0.563rem;
  cursor: pointer;
}
.module__usb.enabled {
  background-image: url('../../../../assets/images/network-usb-enabled.svg');
}
</style>
