<template>
  <v-container fluid>
    <ConfirmDialog
      v-model="deleteConfirmDialog.show"
      :item-id="deleteConfirmDialog.itemId"
      :title="$t('firmwares.deleteItemTitle')"
      :message="deleteConfirmDialog.message"
      @confirmed="deleteItem"
    />
    <ConfirmDialog
      v-model="tagConfirmDialog.show"
      :item-id="tagConfirmDialog.itemId"
      :title="$t('firmwares.setTagItemTitle')"
      :message="tagConfirmDialog.message"
      @confirmed="setMainTag"
    />
    <v-card>
      <v-card-title>
        {{ $t('firmwares.viewTitle') }}
      </v-card-title>
      <v-card-text>
        <FirmwareTable
          :items="firmwares"
          :columns="['type', 'filename', 'tag']"
          :actions="tableActions()"
          @deleteItem="confirmDeleteFirmware"
          @tagItem="confirmMainTag"
          @downloadItem="downloadItem"
        >
          <template #default>
            <v-dialog
              v-model="uploadFirmware.show"
              width="750"
            >
              <template #activator="{ on }">
                <v-btn
                  v-if="hasRights('flt:cfw')"
                  color="primary"
                  dark
                  class="mb-2"
                  :title="$t('firmwares.addFirmware')"
                  v-on="on"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                  {{ $t('firmwares.addFirmware') }}
                </v-btn>
              </template>

              <UploadFirmware
                :showing="uploadFirmware.show"
                @uploadFirmwareCanceled="uploadFirmware.show = false"
                @uploadFirmwareDone="uploadFirmware.show = false"
              />
            </v-dialog>
          </template>
        </FirmwareTable>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import FirmwareTable from '@/views/firmwares/components/FirmwaresTable.vue'
import UploadFirmware from '@/views/firmwares/components/UploadFirmware.vue'
import { mapGetters } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { HEADEND_TYPES_GET_ALL } from '@/store/actions/headendTypes'
import { FIRMWARES_GET_ALL, FIRMWARES_DELETE, FIRMWARES_UPDATE_TAG } from '@/store/actions/firmwares'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import FirmwaresService from '@/services/fleets/FirmwaresService'

export default {
  name: 'Firmwares',
  components: {
    FirmwareTable,
    UploadFirmware,
    ConfirmDialog
  },
  data () {
    return {
      deleteConfirmDialog: { show: false },
      tagConfirmDialog: { show: false },
      uploadFirmware: {
        swow: false
      }
    }
  },
  computed: {
    ...mapGetters(['hasRights']),
    firmwares () {
      const response = []
      this.$store.state.firmwares.byHeadendType.forEach(
        (firmwareArray, headendType) => {
          firmwareArray.forEach(
            (firmware, i) => {
              response.push(firmware)
            }
          )
        }
      )
      return response
    }
  },
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    Promise.all([
      this.$store.dispatch(HEADEND_TYPES_GET_ALL),
      this.$store.dispatch(FIRMWARES_GET_ALL)
    ])
      .then(() => {
        // console.log('[views.firmwares] Loaded all data')
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_SUCCESS,
          this.$t('firmwares.loadedFirmwares')
        )
      })
      .catch(() => {
        // console.log('[views.firmwares] Error loading data', error)
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_ERROR,
          this.$t('firmwares.errorLoadingFirmwares')
        )
      })
  },
  methods: {
    uploadItem: function () {
      this.uploadFirmware.show = true
    },
    tableActions: function () {
      const actions = []
      if (this.hasRights('flt:cfw')) {
        actions.push('tag')
        actions.push('delete')
      }
      actions.push('download')
      return actions
    },
    confirmDeleteFirmware: function (item) {
      this.deleteConfirmDialog.show = true
      this.deleteConfirmDialog.message = this.$t(
        'firmwares.deleteItemMessage',
        item
      )
      this.deleteConfirmDialog.itemId = item.id
    },
    deleteItem: function (firmwareId) {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(FIRMWARES_DELETE, firmwareId)
        .then(data => {
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('firmwares.firmwareRemoved')
          )
          this.$store.dispatch(LOADING_DECREASE)
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.response.status !== 401) {
            this.$store.dispatch(
              SNACKBAR_ERROR,
              this.$t('firmwares.errorRemovingFirmwares')
            )
          }
        })
    },
    confirmMainTag: function (item) {
      this.tagConfirmDialog.show = true
      this.tagConfirmDialog.message = this.$t(
        'firmwares.setTagItemMessage',
        item
      )
      this.tagConfirmDialog.itemId = item.id
    },
    setMainTag: function (firmwareId) {
      this.$store.dispatch(LOADING_INCREASE)
      var info = {
        tag: 'main',
        firmwareId: firmwareId
      }
      this.$store
        .dispatch(FIRMWARES_UPDATE_TAG, info)
        .then(data => {
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('firmwares.tagUpdated')
          )
          this.$store.dispatch(LOADING_DECREASE)
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.response.status !== 401) {
            this.$store.dispatch(
              SNACKBAR_ERROR,
              this.$t('firmwares.errorSettingTag')
            )
          }
        })
    },
    downloadItem: function (firmware) {
      this.$store.dispatch(LOADING_INCREASE)
      FirmwaresService.downloadFirmware(firmware.id).then(response => {
        this.$store.dispatch(LOADING_DECREASE)
        var file = response.data
        // var filename = firmware.filename + '.bin'
        var filename = firmware.filename
        var a = document.createElement('a')
        var url = window.URL.createObjectURL(file)
        a.href = url
        a.download = filename
        document.body.appendChild(a)
        a.click()
        setTimeout(function () {
          document.body.removeChild(a)
          window.URL.revokeObjectURL(url)
        }, 0)
      }).catch((error) => {
        this.$store.dispatch(LOADING_DECREASE)
        if (error.response.status !== 401) {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('firmwares.errorSettingTag')
          )
        }
      })
    }
  }
}
</script>
