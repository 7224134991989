import AuthorizationApi from '@/services/api/AuthorizationApi'

export default {
  getAll () {
    return AuthorizationApi().get('/users')
  },
  get (id) {
    return AuthorizationApi().get('/users/' + id)
  },
  create (user) {
    return AuthorizationApi().post('/users', user)
  },
  update (id, user) {
    return AuthorizationApi().put('/users/' + id, user)
  },
  delete (id) {
    return AuthorizationApi().delete('/users/' + id)
  }
}
