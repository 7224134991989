<template>
  <div class="ml-8 mr-8">
    <AlarmMessages
      v-if="hasAlarms"
      :alarms="getAlarms"
    />
    <v-expansion-panels
      accordion
      focusable
      class="mt-6 mb-6"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>{{ $t('moduleDialog.rj45.sections.controlInterface.title') }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-4">
          <ControlData
            :module="module"
            :rj45="rj45"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>{{ $t('moduleDialog.rj45.sections.streamingInterface.title') }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-4">
          <StreamingData
            :module="module"
            :rj45="rj45"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header :color="getIPTVInputsExpansionPanelHeaderColor()">
          <span>{{ $t('moduleDialog.rj45.sections.iptvInputs.title') }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-4">
          <IPTVInputsData
            :module="module"
            :rj45="rj45"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header :color="getIPTVMPTSOutputsExpansionPanelHeaderColor()">
          <span>{{ $t('moduleDialog.rj45.sections.iptvMPTSOutputs.title') }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-4">
          <IPTVMPTSOutputsData
            :module="module"
            :rj45="rj45"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="showIPTVSPTSOutputs">
        <v-expansion-panel-header :color="getIPTVSPTSOutputsExpansionPanelHeaderColor()">
          <span>{{ $t('moduleDialog.rj45.sections.iptvSPTSOutputs.title') }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mt-4">
          <IPTVSPTSOutputsData
            :module="module"
            :rj45="rj45"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../store/namespaces'

import AlarmMessages from './AlarmMessages.vue'
import ControlData from './NetworkRJ45/ControlData.vue'
import StreamingData from './NetworkRJ45/StreamingData.vue'
import IPTVInputsData from './NetworkRJ45/IPTVInputsData.vue'
import IPTVMPTSOutputsData from './NetworkRJ45/IPTVMPTSOutputsData.vue'
import IPTVSPTSOutputsData from './NetworkRJ45/IPTVSPTSOutputsData.vue'

export default {
  name: 'NetworkRJ45Data',
  components: {
    AlarmMessages,
    ControlData,
    StreamingData,
    IPTVInputsData,
    IPTVMPTSOutputsData,
    IPTVSPTSOutputsData
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    rj45: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, [
      'getStatusModuleBySlot',
      'hasStatusModuleIPTVInputsAlarmsByModule',
      'hasStatusModuleIPTVMPTSOutputsAlarmsByModule',
      'hasStatusModuleIPTVSPTSOutputsAlarmsByModule',
      'getConfigurationInternetAccess'
    ]),
    getStatusModule: function () {
      return this.getStatusModuleBySlot(this.module.slot)
    },
    hasAlarms: function () {
      const statusModule = this.getStatusModule
      let alarms = []
      if (statusModule.alarms !== undefined && statusModule.alarms.length > 0) {
        alarms = this.getAlarms
      }
      return alarms.length > 0
    },
    getAlarms: function () {
      const statusModule = this.getStatusModule
      return statusModule.alarms.filter((alarm) => {
        return alarm.name.includes('RJ45')
      })
    },
    haveIPTVInputsAlarms: function () {
      return this.hasStatusModuleIPTVInputsAlarmsByModule(this.module)
    },
    haveIPTVMPTSOutputsAlarms: function () {
      return this.hasStatusModuleIPTVMPTSOutputsAlarmsByModule(this.module)
    },
    haveIPTVSPTSOutputsAlarms: function () {
      return this.hasStatusModuleIPTVSPTSOutputsAlarmsByModule(this.module)
    },
    showIPTVSPTSOutputs: function () {
      return this.module.outputs.rf.outputs.length === 0
    }
  },
  methods: {
    getIPTVInputsExpansionPanelHeaderColor: function () {
      if (this.haveIPTVInputsAlarms) {
        return '#D31F1F'
      }
      return ''
    },
    getIPTVMPTSOutputsExpansionPanelHeaderColor: function () {
      if (this.haveIPTVMPTSOutputsAlarms) {
        return '#D31F1F'
      }
      return ''
    },
    getIPTVSPTSOutputsExpansionPanelHeaderColor: function () {
      if (this.haveIPTVSPTSOutputsAlarms) {
        return '#D31F1F'
      }
      return ''
    }
  }
}
</script>
<style scoped>
.data__header__name {
  display: inline-block;
  width: 15rem;
}
.data__label {
  display: inline-block;
  width: 15em;
  margin-left: 2em;
  vertical-align: top;
}
.data__value {
  display: inline-block;
  width: 55%;
}
</style>
