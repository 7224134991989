<template>
  <v-data-table
    :headers="headers"
    :items="historyOrders"
    :footer-props="footerProps"
  >
    <template #[`item.creationDate`]="{item}">
      {{ item.creationDate | formatDate($locale, $timezone) }}
    </template>
    <template #[`item.nextOrderDate`]="{item}">
      {{ item.nextOrderDate | formatDate($locale, $timezone) }}
    </template>
    <template #[`item.userId`]="{item}">
      {{ getUserName(item.userId) }}
    </template>
    <template #[`item.status`]="{item}">
      <span
        :class="statusColor[item.status]"
        class="text-uppercase"
        :title="statusTitle[item.status]"
      >
        {{ statusName[item.status] }}
      </span>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex'
import { NS_USERS, NS_ORDER_SCHEDULES } from '../../store/namespaces'

export default {
  name: 'OrdersHistory',
  data () {
    return {
      statusName: {
        CREATED: this.$t('section.orders.history.status.created'),
        FINISHED: this.$t('section.orders.history.status.finished'),
        INITIALIZED: this.$t('section.orders.history.status.initialized')
      },
      statusColor: {
        CREATED: 'grey--text',
        FINISHED: 'success--text',
        INITIALIZED: 'yellow--text text--darken-3'
      },
      statusTitle: {
        CREATED: this.$t('section.orders.history.status.created'),
        FINISHED: this.$t('section.orders.history.status.finished'),
        INITIALIZED: this.$t('section.orders.history.status.initialized')
      },
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('section.orders.table.itemsPerPage')
      }
    }
  },
  computed: {
    ...mapGetters(NS_USERS, ['getUserById']),
    ...mapGetters(NS_ORDER_SCHEDULES, ['getFinishedOrders']),
    headers: function () {
      return [
        {
          text: this.$t('section.orders.table.headers.alias'),
          align: 'left',
          value: 'name'
        },
        {
          text: this.$t('section.orders.table.headers.dateCreated'),
          align: 'left',
          value: 'creationDate'
        },
        {
          text: this.$t('section.orders.table.headers.dateFinished'),
          align: 'left',
          value: 'nextOrderDate'
        },
        {
          text: this.$t('section.orders.table.headers.user'),
          align: 'left',
          value: 'userId'
        },
        {
          text: this.$t('section.orders.table.headers.status'),
          align: 'left',
          value: 'status'
        }
      ]
    },
    historyOrders: function () {
      return this.getFinishedOrders
    }
  },
  methods: {
    getUserName: function (userId) {
      const user = this.getUserById(userId)
      let name = userId
      if (user !== undefined && user !== null) {
        name = user.firstName + ' ' + user.secondName
      }
      return name
    }
  }
}
</script>
