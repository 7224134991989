<template>
  <div class="d-flex">
    <ModuleDialog
      v-model="rj45Dialog.show"
      :title="rj45Dialog.title"
      type="networkRJ45"
      :module="module"
      :rj45="rj45"
    />
    <a
      :class="getClassName"
      title="Network RJ45"
      @click="showRJ45Dialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../../store/namespaces'

// common
import ModuleDialog from '../../../common/ModuleDialog.vue'

import { ReportStatusModuleNetworkControlPresent } from '../../../../types/Report'

export default {
  name: 'SlotNetworkRJ45',
  components: {
    ModuleDialog
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    rj45: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    }
  },
  data () {
    return {
      rj45Dialog: {
        show: false
      }
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, [
      'getStatusModuleBySlot',
      'hasStatusModuleIPTVInputsAlarmsByModule',
      'hasStatusModuleIPTVMPTSOutputsAlarmsByModule',
      'hasStatusModuleIPTVSPTSOutputsAlarmsByModule'
    ]),
    hasAlarms: function () {
      const statusModule = this.getStatusModuleBySlot(this.module.slot)
      let alarms = []
      if (statusModule.alarms !== undefined && statusModule.alarms.length > 0) {
        alarms = statusModule.alarms.filter((alarm) => {
          return alarm.name.includes('RJ45')
        })
      }
      return alarms.length > 0 || this.haveIPTVInputsAlarms || this.haveIPTVMPTSOutputsAlarms || this.haveIPTVSPTSOutputsAlarms
    },
    haveIPTVInputsAlarms: function () {
      return this.hasStatusModuleIPTVInputsAlarmsByModule(this.module)
    },
    haveIPTVMPTSOutputsAlarms: function () {
      return this.hasStatusModuleIPTVMPTSOutputsAlarmsByModule(this.module)
    },
    haveIPTVSPTSOutputsAlarms: function () {
      return this.hasStatusModuleIPTVSPTSOutputsAlarmsByModule(this.module)
    },
    getClassName: function () {
      let className = 'module__network-rj'
      if (this.rj45 !== undefined && this.rj45 !== null && this.rj45.control !== undefined && this.rj45.control !== null && this.rj45.control.present === ReportStatusModuleNetworkControlPresent.yes) {
        if (this.hasAlarms) {
          className = className.concat(' warning-red')
        } else {
          className = className.concat(' enabled')
        }
      }
      return className
    },
    isNetworkRJ45Data: function () {
      return (this.rj45 !== undefined && this.rj45 !== null)
    }
  },
  methods: {
    showRJ45Dialog: function () {
      if (this.isNetworkRJ45Data) {
        this.rj45Dialog.show = true
        this.rj45Dialog.title = this.$t('moduleDialog.rj45.title', { moduleInfo: this.getModuleDialogTitle() })
      }
    },
    getModuleDialogTitle: function () {
      return this.$t('moduleDialog.title', { moduleModel: this.module.name, slot: this.module.slot })
    }
  }
}
</script>

<style scoped>
.module__network-rj {
  background-image: url('../../../../assets/images/network-rj45.svg');
  width: 1.969rem;
  height: 2.188rem;
  background-size: 1.969rem 2.188rem;
  margin-bottom: 0.625rem;
  cursor: auto;
}
.module__network-rj.enabled {
  background-image: url('../../../../assets/images/network-rj45-enabled.svg');
  cursor: pointer;
}
.module__network-rj.warning-red {
  background-image: url('../../../../assets/images/network-rj45-red.svg');
  cursor: pointer;
}
</style>
