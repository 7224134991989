<template>
  <v-card>
    <v-form @submit="save">
      <v-card-title>
        {{ $t('info') | capitalize }}
        <v-spacer />
        <v-btn
          v-if="hasRights('flt:mhe')"
          :disabled="editing"
          text
          icon
          color="primary"
          @click="startEditing"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row v-if="showing.alias">
          <v-col>
            <v-text-field
              ref="alias"
              v-model="localHeadend.config.alias"
              :label="$t('alias')"
              :readonly="!editing"
            />
          </v-col>
        </v-row>
        <v-row v-if="showing.comment">
          <v-col>
            {{ $t('comment') | capitalize }}
            <v-textarea
              ref="comment"
              v-model="localHeadend.config.comment"
              no-resize
              class="mb4"
              maxlength="400"
              :readonly="!editing"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-show="editing">
        <v-spacer />
        <v-btn
          color="primary"
          @click="cancel()"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          @submit="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { HEADENDS_UPDATE } from '@/store/actions/headends'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'

export default {
  name: 'HeadendComment',
  components: {},
  props: {
    headend: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      localHeadend: JSON.parse(JSON.stringify(this.headend)),
      editing: false,
      showing: {
        alias: false,
        comment: false

      }
    }
  },
  computed: {
    ...mapGetters(['hasRights'])
  },
  mounted () {
    if (this.$isTabcorp) {
      this.showing.alias = true
      this.showing.comment = true
    } else if (this.$isSky) {
      this.showing.alias = false
      this.showing.comment = true
    } else {
      this.showing.alias = true
      this.showing.comment = true
    }
  },
  methods: {
    startEditing: function () {
      this.editing = true
      if (this.showing.alias) {
        this.$refs.alias.focus()
      } else {
        this.$refs.comment.focus()
      }
    },
    cancel: function () {
      this.editing = false
      this.localHeadend.config.alias = this.headend.config.alias
      this.localHeadend.config.comment = this.headend.config.comment
    },
    save: function (e) {
      e.preventDefault()
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(HEADENDS_UPDATE, this.localHeadend)
        .then(response => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('headends.headendUpdated')
          )
          this.editing = false
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('headends.errorUpdatingHeadend')
          )
        })
    }
  }
}
</script>
