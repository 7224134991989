<template>
  <v-form
    ref="form"
    v-model="validForm"
    value="false"
  >
    <v-card>
      <v-card-title>
        {{ $t('changePassword.viewTitle') }}
      </v-card-title>
      <v-card-text>
        <v-text-field
          v-model="oldPassword"
          prepend-icon="mdi-lock"
          :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :label="$t('changePassword.currentPassword')"
          :type="showOldPassword ? 'text' : 'password'"
          :rules="[ rules.required, rules.passwordMaxLength, rules.passwordMinLength, rules.passwordMatch ]"
          counter="20"
          @click:append="showOldPassword = !showOldPassword"
          @click="validateForm"
          @keyup="validateForm"
        />
        <v-icon>
          mdi-information
        </v-icon>
        {{ $t('changePassword.passwordTipText') }}
        <v-text-field
          v-model="newPassword"
          prepend-icon="mdi-lock"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :label="$t('changePassword.newPassword')"
          :type="showNewPassword ? 'text' : 'password'"
          :rules="[ rules.required, rules.passwordMatch, rules.passwordMaxLength, rules.passwordMinLength ]"
          counter="20"
          @click:append="showNewPassword = !showNewPassword"
          @click="validateForm"
          @keyup="validateForm"
        />
        <v-text-field
          v-model="repeatNewPassword"
          prepend-icon="mdi-lock"
          :append-icon="showRepeatNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :label="$t('changePassword.newPasswordRepeat')"
          :type="showRepeatNewPassword ? 'text' : 'password'"
          :rules="[ rules.required, rules.passwordMaxLength, rules.passwordMatch ]"
          counter="20"
          @click:append="showRepeatNewPassword = !showRepeatNewPassword"
          @click="validateForm"
          @keyup="validateForm"
        />
        <password
          v-model="newPassword"
          :strength-meter-only="true"
          @score="updatePasswordStrengthScore"
        />
        <div class="text-center">
          <span class="caption">
            {{ $t('changePassword.passwordStrengthMeter') }}:
          </span>
          <span class="font-weight-bold">
            {{ passwordStrengthText[passwordStrengthScore] }}
          </span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary darken-1"
          text
          @click.stop="cancelPasswordChange"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
          color="primary darken-1"
          :disabled="!validForm"
          @click="savePassword"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import Password from 'vue-password-strength-meter'
import { ACCOUNT_CHANGE_PASSWORD } from '@/store/actions/account'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'

export default {
  name: 'ChangePassword',
  components: {
    Password
  },
  data () {
    return {
      validForm: false, // Checks if the form is valid
      passwordStrengthScore: 0,
      passwordStrengthText: [
        this.$t('changePassword.veryWeak'),
        this.$t('changePassword.weak'),
        this.$t('changePassword.medium'),
        this.$t('changePassword.hard'),
        this.$t('changePassword.veryHard')
      ],
      rules: {
        required: value => !!value || this.$t('validation.requiredField'),
        passwordMatch: () => this.newPassword === this.repeatNewPassword || this.$t('validation.passwordMismatch'),
        passwordMaxLength: value => value.length < 21 || this.$t('validation.passwordMaxLength', { max: 20 }),
        passwordMinLength: value => value.length > 6 || this.$t('validation.passwordMinLength', { min: 6 })
      },
      oldPassword: '',
      showOldPassword: false,
      newPassword: '',
      showNewPassword: false,
      repeatNewPassword: '',
      showRepeatNewPassword: false
    }
  },
  methods: {
    updatePasswordStrengthScore: function (score) {
      this.passwordStrengthScore = score
    },
    validateForm: function () {
      this.$refs.form.validate()
    },
    savePassword: function () {
      this.validateForm()
      if (this.validForm) {
        const changePasswordData = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        }
        this.$store.commit('increaseLoading', null, { root: true })
        this.$store.dispatch('account/' + ACCOUNT_CHANGE_PASSWORD, changePasswordData)
          .then(response => {
            this.$store.commit('decreaseLoading', null, { root: true })
            this.cleanPasswords()
            this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('success.editingPassword'))
            this.$emit('changePasswordDone')
          })
          .catch(() => {
            this.$store.commit('decreaseLoading', null, { root: true })
            this.$store.dispatch(SNACKBAR_ERROR, this.$t('error.editingPassword'))
          })
      }
    },
    cleanPasswords: function () {
      this.oldPassword = ''
      this.newPassword = ''
      this.repeatNewPassword = ''
    },
    cancelPasswordChange: function () {
      this.$emit('changePasswordCanceled')
    }
  }
}

</script>
