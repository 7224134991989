import Api from '@/services/api/ReportsApi'

export default {
  getAll () {
    return Api()
      .get('/generated')
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },
  delete (id) {
    return Api()
      .delete('/generated/' + id)
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },
  download (id) {
    return Api()
      .get('/generated/' + id)
      .then(response => {
        const csvData = []
        if (typeof response.data === 'object') {
          response.data.forEach(line => {
            const csvLine = []
            line.forEach(element => {
              const stringify = JSON.stringify(element)
              const value = stringify.replaceAll('\\n', '\n')
              csvLine.push(value)
            })
            csvData.push(csvLine)
          })
        }
        return csvData
      })
      .catch(error => {
        throw error
      })
  }
}
