<template>
  <v-card tile>
    <v-toolbar
      flat
      dark
      color="primary"
    >
      <v-btn
        icon
        dark
        @click="throwAction('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ $t('newGroup.importHeadendsDialog.title') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step step="1">
                {{ $t('newGroup.importHeadendsDialog.addInfo') }}
              </v-stepper-step>
              <v-stepper-step step="2">
                {{ $t('newGroup.importHeadendsDialog.preview') }}
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card>
                  <v-card-title>
                    {{ $t('newGroup.importHeadendsDialog.insertAListOfHeadendsIds') }}
                  </v-card-title>
                  <v-card-text>
                    {{ $t('newGroup.importHeadendsDialog.descriptionToInsertData') }}
                    <v-textarea
                      ref="comment"
                      v-model="headendsIds"
                      outlined
                      no-resize
                      rows="10"
                      class="mb4"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="
                        nextStep(2)
                        parseData()
                      "
                    >
                      {{ $t('button.continue') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card>
                  <v-card-title>
                    {{ $t('newGroup.importHeadendsDialog.detectedElements') }}
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table
                      dense
                      height="350px"
                    >
                      <template #default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t('newGroup.importHeadendsDialog.clientHeadendId') }}
                            </th>
                            <th class="text-left">
                              {{ $t('newGroup.importHeadendsDialog.headendId') }}
                            </th>
                            <th class="text-left">
                              {{ $t('newGroup.importHeadendsDialog.headendName') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in preProcessedData"
                            :key="item.id"
                          >
                            <td>{{ item.clientHeadendId }}</td>
                            <td>{{ item.headendId }}</td>
                            <td>{{ item.name }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="nextStep(1)"
                    >
                      {{ $t('button.back') }}
                    </v-btn>
                    <v-btn
                      type="submit"
                      color="primary"
                      :disabled="preProcessedData.length === 0"
                      @click="save"
                    >
                      {{ $t('button.confirm') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        @click="throwAction('close')"
      >
        {{ $t('button.close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'GroupImportHeadends',
  components: {},
  props: {
    load: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      step: 1,
      headendsIds: '',
      preProcessedData: [],
      savedData: []
    }
  },
  computed: {
    ...mapGetters({
      getGroupById: 'getGroupById',
      getHeadendByContractId: 'getHeadendByContractId',
      getHeadendByVenueId: 'getHeadendByVenueId',
      getHeadendById: 'getHeadendById'
    }),
    ...mapState({
      headends: state => {
        return state.headendsNew.all
      }
    })
  },
  watch: {
    load: function (val) {
      if (val === true) {
        this.step = 1
        this.headendsIds = ''
        this.preProcessedData = []
      }
    }
  },
  methods: {
    throwAction: function (action, item) {
      this.$emit(action, item)
    },
    nextStep: function (nextStep) {
      this.step = nextStep
    },
    parseData: function () {
      let items = this.headendsIds.split('\n')
      items = [...new Set(items)]
      items = items.map(item => item.trim())
      items = items.filter(item => item !== '')
      this.preProcessedData = []
      items.forEach(item => {
        let headend
        if (this.$isSky) {
          headend = this.getHeadendByContractId(item)
        }
        if (this.$isTabcorp) {
          headend = this.getHeadendByVenueId(item)
        }
        if (headend === undefined) {
          headend = this.getHeadendById(item)
          if (headend !== undefined) {
            this.preProcessedData.push({
              id: item,
              headendId: headend.id,
              name: headend.status.remoteSiteDeviceName,
              headend: headend
            })
          }
        } else {
          this.preProcessedData.push({
            id: item,
            clientHeadendId: item,
            headendId: headend.id,
            name: headend.status.remoteSiteDeviceName,
            headend: headend
          })
        }
      })
    },
    save: function () {
      this.savedData.length = 0
      this.preProcessedData.forEach((item) => {
        this.savedData.push(item.headend)
      })
      this.throwAction('save', this.savedData)
      this.throwAction('close')
    }
  }
}
</script>
