<template>
  <v-dialog
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-card-title>
        {{ $t("headends.editHeadend") }}
      </v-card-title>
      <v-card-text>
        <v-row v-if="showing.alias">
          <v-col
            md="6"
            sm="12"
          >
            <v-text-field
              v-model="headendEdit.alias"
              :label="$t('headends.alias')"
            />
          </v-col>
        </v-row>
        <v-row v-if="showing.comment">
          <v-col sm="12">
            <v-textarea
              ref="comment"
              v-model="headendEdit.comment"
              no-resize
              class="mb4"
              maxlength="400"
              :label="$t('comments')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="ma-2"
          dark
          :title="$t('button.cancel')"
          @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="ma-2"
          dark
          :title="$t('button.confirm')"
          @click="confirmed"
        >
          {{ $t('button.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  components: {
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      headendEdit: {
        alias: undefined,
        comment: undefined
      },
      showing: {
        alias: false,
        comment: false

      }
    }
  },
  watch: {
    value: function (newValue) {
      if (newValue) {
        this.headendEdit.alias = this.item.config.alias
        this.headendEdit.comment = this.item.config.comment
      }
    }
  },
  mounted () {
    if (this.$isTabcorp) {
      this.showing.alias = true
      this.showing.comment = true
    } else if (this.$isSky) {
      this.showing.alias = false
      this.showing.comment = true
    } else {
      this.showing.alias = true
      this.showing.comment = true
    }
  },
  methods: {
    close: function () {
      this.$emit('input', false)
    },
    confirmed: function () {
      this.$emit('confirmed', this.headendEdit)
      this.close()
    }
  }
}
</script>
