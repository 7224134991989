<template>
  <v-app-bar
    app
    dark
    color="#999fa5"
    fixed
    clipped-left
    flat
  >
    <v-row
      no-gutters
      class="d-flex flex-nowrap"
    >
      <v-col
        cols="10"
        sm="10"
        md="4"
        class="d-flex align-center text-truncate"
      >
        <v-toolbar-title class="headline text-uppercase">
          <v-app-bar-nav-icon
            v-if="!isANonAuthenticatedPage && !isSky"
            :class="isIkusi||isTabcorp ? '' : 'hidden-lg-and-up'"
            :title="$t('showMenu')"
            @click="theMenuButtonClicked"
          />
          <span v-if="isTabcorp"> {{ $t('toolbarTitle.tabcorp') }} </span>
          <span v-if="isIkusi"> {{ $t('toolbarTitle.ikusi') }}</span>
          <span v-if="isSky"> {{ $t('toolbarTitle.sky') }} </span>
        </v-toolbar-title>
      </v-col>
      <v-col
        v-if="$vuetify.breakpoint.mdAndUp"
        class="d-flex justify-space-around"
      >
        <v-toolbar-title class="text-sm-center">
          <span
            v-if="isTabcorp"
            class="title_logo"
          >
            <router-link to="/">
              <img
                class="title_logo"
                src="@/assets/tabcorp/Logo_tab.png"
              >
              <img
                class="title_logo"
                src="@/assets/tabcorp/Logo_sky_racing.png"
              >
            </router-link>
          </span>
          <span
            v-if="isIkusi"
            class="title_logo"
          >
            <router-link to="/">
              <v-img
                src="@/assets/triax/triax.png"
                height="50"
                contain
              />
            </router-link>
          </span>
          <span
            v-if="isSky"
            class="title_logo"
          >
            <router-link to="/">
              <v-img
                src="@/assets/sky/sky.png"
                @click="ifWeAreInHeadendsReload"
              />
            </router-link>
          </span>
        </v-toolbar-title>
      </v-col>
      <v-col
        cols="2"
        sm="2"
        md="4"
        class="d-flex justify-end align-center"
      >
        <v-app-bar-nav-icon
          v-if="!isANonAuthenticatedPage && isSky"
          :class="isSky ? '' : 'hidden-lg-and-up'"
          :title="$t('showMenu')"
          @click="theMenuButtonClicked"
        />
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: 'TheAppBar',
  props: {
    showMenu: Boolean
  },
  data () {
    return {
      isSky: false,
      isTabcorp: false,
      isIkusi: false
    }
  },
  computed: {
    isANonAuthenticatedPage: function () {
      return this.$route.path === '/login' || this.$route.path === '/forgotPassword' || this.$route.path === '/restorePassword'
    }
  },
  created: function () {
    if (this.$store.getters['customization/isSky']) {
      this.isSky = true
    } else if (this.$store.getters['customization/isTabcorp']) {
      this.isTabcorp = true
    } else {
      this.isIkusi = true
    }
  },
  methods: {
    theMenuButtonClicked: function () {
      // console.log('[theappbar] The menu button is clicked')
      this.$emit('clickOnTheMenuButton')
    },
    ifWeAreInHeadendsReload: function () {
      if (this.$route.path === '/headends') {
        this.$router.go(this.$router.currentRoute)
      }
    }
  }
}
</script>
<style scoped>
.title_logo {
  max-height: 70px;
  height: 40px;
  padding: 0 2px 0 2px;
}
.title_logo img {
  vertical-align: middle;
}
</style>
