const repeatOrderFormatMixins = {
  methods: {
    parseISOtoArray: function (isoString) {
      return isoString.match(/^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/)
    },
    formatRepeatOrder: function (isoArray) {
      let isoString = ''
      if (isoArray[1]) {
        if (isoArray[1] > 1) {
          isoString += isoArray[1] + ' ' + this.$t('repeatformat.years', { count: isoArray[1] }) + ' '
        } else {
          isoString += isoArray[1] + ' ' + this.$t('repeatformat.year', { count: isoArray[1] }) + ' '
        }
      }
      if (isoArray[2]) {
        if (isoArray[2] > 1) {
          isoString += isoArray[2] + ' ' + this.$t('repeatformat.months', { count: isoArray[2] }) + ' '
        } else {
          isoString += isoArray[2] + ' ' + this.$t('repeatformat.month', { count: isoArray[2] }) + ' '
        }
      }
      if (isoArray[3]) {
        if (isoArray[3] > 1) {
          isoString += isoArray[3] + ' ' + this.$t('repeatformat.weeks', { count: isoArray[3] }) + ' '
        } else {
          isoString += isoArray[3] + ' ' + this.$t('repeatformat.week', { count: isoArray[3] }) + ' '
        }
      }
      if (isoArray[4]) {
        if (isoArray[4] > 1) {
          isoString += isoArray[4] + ' ' + this.$t('repeatformat.days', { count: isoArray[4] }) + ' '
        } else {
          isoString += isoArray[4] + ' ' + this.$t('repeatformat.day', { count: isoArray[4] }) + ' '
        }
      }
      if (isoArray[5]) {
        if (isoArray[5] > 1) {
          isoString += isoArray[5] + ' ' + this.$t('repeatformat.hours', { count: isoArray[5] }) + ' '
        } else {
          isoString += isoArray[5] + ' ' + this.$t('repeatformat.hour', { count: isoArray[5] }) + ' '
        }
      }
      if (isoArray[6]) {
        if (isoArray[6] > 1) {
          isoString += isoArray[6] + ' ' + this.$t('repeatformat.minutes', { count: isoArray[6] }) + ' '
        } else {
          isoString += isoArray[6] + ' ' + this.$t('repeatformat.minute', { count: isoArray[6] }) + ' '
        }
      }
      return isoString
    }
  }
}

export default repeatOrderFormatMixins
