var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.historyOrders,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.creationDate,_vm.$locale, _vm.$timezone))+" ")]}},{key:"item.nextOrderDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.nextOrderDate,_vm.$locale, _vm.$timezone))+" ")]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserName(item.userId))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase",class:_vm.statusColor[item.status],attrs:{"title":_vm.statusTitle[item.status]}},[_vm._v(" "+_vm._s(_vm.statusName[item.status])+" ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }