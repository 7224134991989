<template>
  <v-card :loading="loading">
    <v-form @submit="save">
      <v-card-title>
        {{ $t('headendTitle.editPhsInfo') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            v-if="showing.contractId"
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="localHeadend.skyIt.status.contractId"
              class="mb4"
              maxlength="30"
              :label="$t('contractId')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          @click="cancel()"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
          type="submit"
          color="primary"
          @submit="save"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { HEADENDS_GET_ALL } from '@/store/actions/headends'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import DeviceService from '@/services/fleets/DevicesService'
export default {
  name: 'HeadendInfoEdit',
  props: {
    headend: {
      // Id of the headend to get info for
      type: Object,
      required: true
    }
  },
  data () {
    return {
      localHeadend: {},
      showing: {
        contractId: false
      },
      loading: false
    }
  },
  mounted () {
    /* if (this.$isTabcorp) {
    } else */if (this.$isSky) {
      this.showing.contractId = true
    }/* else {
    } */
    this.localHeadend = JSON.parse(JSON.stringify(this.headend))
    if (this.$isSky) {
      if (!this.localHeadend.skyIt) {
        this.localHeadend.skyIt = { status: { contractId: '' } }
      }
    }
  },
  methods: {
    cancel: function () {
      this.localHeadend = JSON.parse(JSON.stringify(this.headend))
      if (this.$isSky) {
        if (!this.localHeadend.skyIt) {
          this.localHeadend.skyIt = { status: { contractId: '' } }
        }
      }
      this.$emit('close')
    },
    save: function (e) {
      e.preventDefault()
      this.loading = true
      DeviceService.putContractId(this.headend.id, this.localHeadend.skyIt.status.contractId)
        .then(() => {
          this.$store
            .dispatch(HEADENDS_GET_ALL)
            .then(response => {
              this.$store.dispatch(
                SNACKBAR_SUCCESS,
                this.$t('headends.updatedSaccessfully')
              )
              this.$emit('close')
              this.loading = false
            })
        })
        .catch(() => {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('headends.errorUpdating')
          )
          this.loading = false
        })
    }
  }
}
</script>
