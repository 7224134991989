<template>
  <v-card tile>
    <v-toolbar
      flat
      dark
      color="primary"
    >
      <v-btn
        icon
        dark
        @click="throwAction('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ $t('channelMaps.applyChannelMapTitle', channelMap) }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <!-- <v-row>
        <v-col>
          <v-text-field
            v-model="localValue.name"
            :label="$t('channelMaps.name')"
            maxlength="64"
            :readonly="true"
          />
          <span class="title">{{ $t('channelMaps.description') }}</span>
          <v-textarea
            ref="comment"
            v-model="localValue.description"
            no-resize
            class="mb4"
            maxlength="256"
            :readonly="true"
          />
        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <span class="title">{{ $t('channelMaps.applyChannelMapExplanation') }}</span>
        </v-col>
      </v-row>
      {{ $t('channelMaps.onlineHeadends') }}:
      <HeadendsTable
        v-model="selectedHeadend"
        :items="onlineHeadends"
        :columns="headensColumns()"
        selection="single"
      />
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        @click="throwAction('close')"
      >
        {{ $t('button.close') }}
      </v-btn>
      <v-btn
        color="primary"
        :disabled="selectedHeadend.length===0"
        @click="confirmApplyChannelMap"
      >
        <v-icon left>
          mdi-file-send
        </v-icon>
        {{ $t('channelMaps.apply') }}
      </v-btn>
    </v-card-actions>
    <ConfirmDialog
      v-model="showConfirmDialog"
      :title="$t('channelMaps.confirmApplyChannelMapTitle')"
      :message="confirmMessage"
      @confirmed="applyChannelMap"
    />
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { HEADENDS_GET_ALL } from '@/store/actions/headends'
import HeadendsTable from '@/views/headends/components/HeadendsTable.vue'
import headends from '@/mixins/headends.js'
import DeviceService from '@/services/fleets/DevicesService'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

export default {
  name: 'ApplyChannelMap',
  components: {
    HeadendsTable,
    ConfirmDialog
  },
  mixins: [headends],
  props: {
    channelMap: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      selectedHeadend: [],
      localValue: {},
      showConfirmDialog: false,
      confirmMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      getGroupById: 'getGroupById'
    }),
    onlineHeadends: function () {
      return this.$store.state.headendsNew.all.filter(headend => headend.status.connection === 'online')
    },
    customizationType: function () {
      return this.$store.getters['customization/customizationType']
    }
  },
  mounted () {
    this.loadData()
    this.localValue = JSON.parse(JSON.stringify(this.channelMap))
  },
  methods: {
    loadData: function () {
      this.$store.dispatch(LOADING_INCREASE)
      Promise.all([this.$store.dispatch(HEADENDS_GET_ALL)])
        .then(() => {
          this.$store.dispatch(LOADING_DECREASE)
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.response.status !== 401) {
            this.$store.dispatch(
              SNACKBAR_ERROR,
              this.$t('channelMaps.errorLoadingHeadends')
            )
          }
        })
    },
    headensColumns: function () {
      return this.headendsHeadersColumns(this.customizationType)
    },
    throwAction: function (action, item) {
      this.$emit(action, item)
    },
    confirmApplyChannelMap () {
      this.showConfirmDialog = true
      this.confirmMessage = this.$t('channelMaps.confirmApplyChannelMapMessage', { channelMap: this.channelMap.name, device: this.selectedHeadend[0].id })
    },
    applyChannelMap () {
      this.$store.dispatch(LOADING_INCREASE)
      DeviceService.putChannelMapOnDevice(this.selectedHeadend[0].id, this.channelMap.id)
        .then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('channelMaps.channelMapApplied', { channelMap: this.channelMap.name, device: this.selectedHeadend[0].id })
          )
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('channelMaps.errorApplyingChannelMap', { channelMap: this.channelMap.name, device: this.selectedHeadend[0].id })
          )
        })
    }
  }
}
</script>
