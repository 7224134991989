<template>
  <v-data-table
    multi-sort
    :headers="headers"
    :items="items"
    :sort-by="['type', 'filename']"
    :search="filters.search"
    :footer-props="footerProps"
    dense
  >
    <!-- TOP BAR -->
    <template #top>
      <v-toolbar
        flat
        color="white"
      >
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          :label="$t('label.search')"
          single-line
          hide-details
        />
        <v-spacer />
        <slot />
      </v-toolbar>
    </template>
    <!-- Device type -->
    <template #[`item.type`]="{ item }">
      {{ $t('deviceType.'+item.type) }}
    </template>
    <!-- Tag: cell -->
    <template
      #[`item.tag`]="{ item }"
    >
      <v-chip
        v-if="item.tag"
        class="ma-1"
        small
        label
      >
        {{ item.tag }}
      </v-chip>
    </template>
    <!-- Settings template -->
    <template #[`item.settings`]="{ item }">
      <!-- TAG BUTTON -->
      <v-btn
        v-if="showAction('tag')"
        text
        icon
        color="primary"
        :disabled="item.tag==='main'"
        :title="$t('firmwares.setMainTag')"
        @click="throwAction('tagItem', item)"
      >
        <v-icon>mdi-bookmark</v-icon>
      </v-btn>
      <!-- Download BUTTON -->
      <v-btn
        v-if="showAction('download')"
        text
        icon
        color="primary"
        :title="$t('firmwares.download')"
        @click="throwAction('downloadItem', item)"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <!-- DELETE BUTTON -->
      <v-btn
        v-if="showAction('delete')"
        text
        icon
        color="primary"
        :title="$t('firmware.delete')"
        @click="throwAction('deleteItem', item)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <!-- SELECTIONS HEADER -->
    <template #[`header.selections`]>
      <v-btn
        v-if="selection == 'multiple'"
        text
        icon
        @click="selectUnselectAll()"
      >
        <v-icon
          v-if="areAllSelected()"
          color="primary"
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon v-else-if="isAnySelected()">
          mdi-minus-box-outline
        </v-icon>
        <v-icon v-else>
          mdi-checkbox-blank-outline
        </v-icon>
      </v-btn>
    </template>
    <!-- Selections: cell -->
    <template #[`item.selections`]="{ item }">
      <span v-if="selection != 'none'">
        <v-btn
          v-if="isSelected(item)"
          text
          icon
          @click="deselect(item)"
        >
          <v-icon
            v-if="selection === 'multiple'"
            color="primary"
          >
            mdi-checkbox-marked-outline
          </v-icon>
          <v-icon
            v-else
            color="primary"
          >
            mdi-radiobox-marked
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          text
          icon
          @click="select(item)"
        >
          <v-icon v-if="selection === 'multiple'">
            mdi-checkbox-blank-outline
          </v-icon>
          <v-icon v-else>
            mdi-checkbox-blank-circle-outline
          </v-icon>
        </v-btn>
      </span>
    </template>
  </v-data-table>
</template>
<script>

export default {
  name: 'FirmwaresTable',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selection: {
      type: String,
      default: 'none' // single or multiple
    },
    actions: {
      type: Array,
      default: () => [] // Actions types: master, download, delete
    },
    columns: {
      type: Array,
      default: () => [] // Columns to show: type, filename
    }
  },
  data () {
    return {
      filters: {
        search: null
      },
      selectedItems: {},
      possibleHeaders: {
        type: {
          text: this.$t('firmwares.headendType'),
          align: 'start',
          sortable: true,
          value: 'type'
        },
        filename: {
          text: this.$t('filename'),
          align: 'start',
          sortable: true,
          value: 'filename'
        },
        tag: {
          text: this.$t('firmwares.tag'),
          align: 'start',
          sortable: true,
          value: 'tag'
        },
        settings: {
          text: '',
          align: 'end',
          sortable: false,
          value: 'settings'
        },
        selections: {
          text: '',
          align: 'end',
          sortable: false,
          value: 'selections'
        }
      },
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('firmwares.firmwaresPerPageText')
      }
    }
  },
  computed: {
    headers () {
      const headers = []
      this.columns.forEach(item => {
        headers.push(this.possibleHeaders[item])
      })
      if (this.actions.length > 0) {
        headers.push(this.possibleHeaders.settings)
      }
      if (this.selection === 'multiple' || this.selection === 'single') {
        headers.push(this.possibleHeaders.selections)
      }
      return headers
    }
  },
  watch: {
    items: function (newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.selectedItems = {}
        return
      }
      for (let i = 0; i < newVal.length; i++) {
        if (newVal[i].id !== oldVal[i].id) {
          this.selectedItems = {}
          return
        }
      }
    }
  },
  methods: {
    showAction: function (action) {
      return this.actions.includes(action)
    },
    throwAction: function (action, item) {
      this.$emit(action, item)
    },
    isSelected (item) {
      return this.selectedItems[item.id]
    },
    deselect: function (item) {
      this.$delete(this.selectedItems, item.id)
      this.$emit('input', Object.values(this.selectedItems))
    },
    select: function (item) {
      if (this.selectedItems !== {} && this.selection === 'single') {
        this.selectedItems = {}
      }
      this.$set(this.selectedItems, item.id, item)
      this.$emit('input', Object.values(this.selectedItems))
    },
    selectUnselectAll: function () {
      if (Object.values(this.selectedItems).length > 0) {
        this.selectedItems = {}
      } else {
        this.selectedItems = {}
        this.filteredItems.forEach((item, i) => {
          this.$set(this.selectedItems, item.id, item)
        })
      }
      this.$emit('input', Object.values(this.selectedItems))
    },
    areAllSelected: function () {
      return Object.values(this.selectedItems).length === this.items.length
    },
    isAnySelected: function () {
      return Object.values(this.selectedItems).length > 0
    }
  }
}
</script>
