const orderTypeList = {
  methods: {
    orderTypeList: function (mode) {
      const orderCommandTypes = {
        channelMap: {
          text: this.$t('orderType.channelMap'),
          value: 'channelMap',
          apply: ['headends', 'groups'],
          repeatMode: ['ONCE', 'MULTIPLE_TIME', 'UNTIL_DATE', 'FOREVER']
        },
        changePassword: {
          text: this.$t('orderType.changePassword'),
          value: 'changePassword',
          apply: ['headends', 'groups'],
          repeatMode: ['ONCE', 'MULTIPLE_TIMES', 'UNTIL_DATE', 'FOREVER']
        },
        transferFirmware: {
          text: this.$t('orderType.transferFirmware'),
          value: 'transferFirmware',
          apply: ['headends', 'groups'],
          repeatMode: ['ONCE', 'MULTIPLE_TIMES']
        },
        launchFirmware: {
          text: this.$t('orderType.launchFirmware'),
          value: 'launchFirmware',
          apply: ['headends', 'groups'],
          repeatMode: ['ONCE']
        },
        backup: {
          text: this.$t('orderType.headendBackup'),
          value: 'backup',
          apply: ['headends', 'groups'],
          repeatMode: ['ONCE', 'UNTIL_DATE', 'FOREVER']
        },
        reboot: {
          text: this.$t('orderType.reboot'),
          value: 'reboot',
          apply: ['headends', 'groups'],
          repeatMode: ['ONCE', 'UNTIL_DATE', 'FOREVER']
        },
        scanInputs: {
          text: this.$t('orderType.scanInputs'),
          value: 'scanInputs',
          apply: ['headends', 'groups'],
          repeatMode: ['ONCE', 'UNTIL_DATE', 'FOREVER']
        },
        custom: {
          text: this.$t('orderType.custom'),
          value: 'custom',
          apply: ['headends', 'groups'],
          repeatMode: ['ONCE', 'MULTIPLE_TIMES', 'UNTIL_DATE', 'FOREVER']
        }
      }
      if (mode === 'skyit') {
        // SKY headers in order
        return [
          orderCommandTypes.transferFirmware,
          orderCommandTypes.launchFirmware,
          orderCommandTypes.reboot,
          orderCommandTypes.scanInputs
        ]
      } else if (mode === 'tabcorp') {
        // Tabcorp headers in order
        return [
          orderCommandTypes.channelMap,
          orderCommandTypes.changePassword,
          orderCommandTypes.transferFirmware,
          orderCommandTypes.launchFirmware,
          orderCommandTypes.reboot,
          orderCommandTypes.custom
        ]
      } else {
        // Default headers in order
        return [
          orderCommandTypes.transferFirmware,
          orderCommandTypes.launchFirmware,
          orderCommandTypes.reboot,
          orderCommandTypes.scanInputs,
          orderCommandTypes.backup,
          orderCommandTypes.custom
        ]
      }
    }
  }
}

export default orderTypeList
