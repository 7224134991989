<template>
  <v-card :loading="loading">
    <v-card-title>
      {{ $t('headendLineUp.title') }}
    </v-card-title>
    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-if="lineUp.lineupIP">
          <v-expansion-panel-header>
            {{ $t('headendLineUp.IPLineUp') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table
              dense
            >
              <template #default>
                <thead>
                  <tr>
                    <th>{{ $t('headendLineUp.nameInput') }}</th>
                    <th>{{ $t('headendLineUp.nameOutput') }}</th>
                    <th>{{ $t('headendLineUp.url') }}</th>
                    <th>{{ $t('headendLineUp.audioLanguages') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, i) in lineUp.lineupIP"
                    :key="i"
                  >
                    <td>{{ item.nameInput }}</td>
                    <td>{{ item.nameOutput }}</td>
                    <td>{{ item.url }}</td>
                    <td>{{ item.audioLanguages.filter(a => a.trim() != "").join(", ") }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel v-if="lineUp.lineupRF">
          <v-expansion-panel-header>
            {{ $t('headendLineUp.RFLineUp') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table dense>
              <template #default>
                <thead>
                  <tr>
                    <th>{{ $t('headendLineUp.lcn') }}</th>
                    <th>{{ $t('headendLineUp.nameInput') }}</th>
                    <th>{{ $t('headendLineUp.nameOutput') }}</th>
                    <th>{{ $t('headendLineUp.audioLanguages') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, i) in lineUp.lineupRF"
                    :key="i"
                  >
                    <td>{{ item.lcn }}</td>
                    <td>{{ item.nameInput }}</td>
                    <td>{{ item.nameOutput }}</td>
                    <td>{{ item.audioLanguages.join(", ") }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-subheader
        v-if="noLineUp"
      >
        {{ $t('headendLineUp.noLineUpFound') }}
      </v-subheader>
    </v-card-text>
  </v-card>
</template>

<script>
import LineupService from '@/services/fleets/LineupService'

export default {
  name: 'HeadendLineUp',
  components: {},
  props: {
    headend: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      noLineUp: false,
      lineUp: {}
    }
  },
  computed: {},
  mounted () {
    this.loading = true
    LineupService
      .getLineups(this.headend.id)
      .then(response => {
        this.loading = false
        this.lineUp = response.data
      })
      .catch(err => {
        if (err.status === 404) {
          this.noLineUp = true
        }
        this.loading = false
      })
  },
  methods: {}
}
</script>
