<template>
  <div class="ml-8 mr-8">
    <AlarmMessages
      v-if="hasAlarms"
      :alarms="getAlarms"
    />
    <div v-if="isModuleModelDefined()">
      <span class="data__label">{{ $t('moduleDialog.module.labels.model') }}</span>
      <span class="data__value">{{ getModuleModel() }}</span>
    </div>
    <div v-if="isModuleSerialDefined()">
      <span class="data__label">{{ $t('moduleDialog.module.labels.serial') }}</span>
      <span class="data__value">{{ getModuleSerial() }}</span>
    </div>
    <div v-if="isModuleHWVersionDefined()">
      <span class="data__label">{{ $t('moduleDialog.module.labels.hwVersion') }}</span>
      <span class="data__value">{{ getModuleHWVersion() }}</span>
    </div>
    <div v-if="isModuleFWVersionDefined()">
      <span class="data__label">{{ $t('moduleDialog.module.labels.fwVersion') }}</span>
      <span class="data__value">{{ getModuleFWVersion() }}</span>
    </div>
    <div v-if="isModuleStatusAvailable()">
      <span class="data__label">{{ $t('moduleDialog.module.labels.temperature') }}</span>
      <span class="data__value">{{ getModuleTemperature() }}</span>
    </div>
    <div v-if="isModuleStatusAvailable()">
      <span class="data__label">{{ $t('moduleDialog.module.labels.lastReboot') }}</span>
      <span class="data__value">{{ getFormattedUptime() }}</span>
    </div>
    <div v-if="isModuleOutputModeDefined()">
      <span class="data__label">{{ $t('moduleDialog.module.labels.outputMode') }}</span>
      <span class="data__value">{{ getModuleOutputMode() }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../store/namespaces'

import { ReportConfigurationModuleRFOutputsItemOutputModeValue } from '../../../types/Report'

import AlarmMessages from './AlarmMessages.vue'

export default {
  name: 'ModuleData',
  components: {
    AlarmMessages
  },
  props: {
    module: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getStatusModuleBySlot']),
    getStatusModule: function () {
      return this.getStatusModuleBySlot(this.module.slot)
    },
    hasAlarms: function () {
      const statusModule = this.getStatusModule
      let alarms = []
      if (statusModule !== undefined && statusModule !== null && statusModule.alarms !== undefined && statusModule.alarms.length > 0) {
        alarms = this.getAlarms
      }
      return alarms.length > 0
    },
    getAlarms: function () {
      const statusModule = this.getStatusModule
      return statusModule.alarms.filter((alarm) => {
        return !alarm.name.includes('RJ45')
      })
    }
  },
  methods: {
    isModuleModelDefined: function () {
      return this.module.model !== undefined && this.module.model !== null
    },
    getModuleModel: function () {
      return this.module.model
    },
    isModuleSerialDefined: function () {
      return this.module.serial !== undefined && this.module.serial !== null
    },
    getModuleSerial: function () {
      return this.module.serial
    },
    isModuleHWVersionDefined: function () {
      return this.module.hardware !== undefined && this.module.hardware !== null
    },
    getModuleHWVersion: function () {
      return this.module.hardware
    },
    isModuleFWVersionDefined: function () {
      return this.module.firmware !== undefined && this.module.firmware !== null
    },
    getModuleFWVersion: function () {
      return this.module.firmware
    },
    isModuleStatusAvailable: function () {
      return this.getStatusModuleBySlot(this.module.slot) !== undefined && this.getStatusModuleBySlot(this.module.slot) !== null
    },
    getModuleTemperature: function () {
      return this.getStatusModuleBySlot(this.module.slot)?.temperature?.pcbTemp + 'ºC' ?? ''
    },
    getFormattedUptime: function () {
      const uptime = this.getStatusModuleBySlot(this.module.slot)?.uptime
      if (uptime !== undefined && uptime !== null) {
        const seconds = Number(uptime)
        const d = Math.floor(seconds / (3600 * 24))
        const h = Math.floor(seconds % (3600 * 24) / 3600)
        const m = Math.floor(seconds % 3600 / 60)
        const s = Math.floor(seconds % 60)

        const dDisplay = d > 0 ? d + ' ' + (d === 1 ? this.$t('common.date.day') + ', ' : this.$t('common.date.days') + ', ') : ''
        const hDisplay = h > 0 ? h + ' ' + (h === 1 ? this.$t('common.date.hour') + ', ' : this.$t('common.date.hours') + ', ') : ''
        const mDisplay = m > 0 ? m + ' ' + (m === 1 ? this.$t('common.date.minute') + ', ' : this.$t('common.date.minutes') + ', ') : ''
        const sDisplay = s > 0 ? s + ' ' + (s === 1 ? this.$t('common.date.second') : this.$t('common.date.seconds')) : ''
        return dDisplay + hDisplay + mDisplay + sDisplay
      }
    },
    isModuleOutputModeDefined: function () {
      return this.module.outputs !== undefined && this.module.outputs !== null
    },
    getModuleOutputMode: function () {
      if (this.module.outputs !== undefined && this.module.outputs !== null) {
        if (this.module.outputs.rf.outputs.length === 0) {
          return this.$t('moduleDialog.module.outputModes.outputsRFEmpty')
        } else {
          if (this.module.outputs.rf.outputs[0].standard === ReportConfigurationModuleRFOutputsItemOutputModeValue.TERRESTRIAL) {
            return this.$t('moduleDialog.module.outputModes.outputsRFTerrestrial')
          } else {
            return this.$t('moduleDialog.module.outputModes.outputsRFCable')
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.data__label {
  display: inline-block;
  width: 15em;
  margin-left: 2em;
}
.data__value {
  display: inline-block;
  width: 55%;
}
</style>
