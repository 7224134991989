<template>
  <v-container fluid>
    <ConfirmDialog
      v-model="deleteConfirmDialog.show"
      :item-id="deleteConfirmDialog.itemId"
      :title="$t('orderTemplates.deleteItemTitle')"
      :message="deleteConfirmDialog.message"
      @confirmed="deleteItem"
    />
    <v-card>
      <v-card-title>{{ $t('orderTemplates.viewTitle') }}</v-card-title>
      <v-card-text>
        <OrderTemplatesTable
          :items="orders"
          :actions="['action', 'edit', 'delete']"
          :columns="[
            'name',
            'description',
            'type',
            'devices',
            'groups'
          ]"
          @editItem="editItem"
          @deleteItem="confirmDeleteItem"
          @actionItem="actionItem"
        >
          <template #default>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="newItem"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
              {{ $t('orderTemplates.newOrderTemplate') }}
            </v-btn>
          </template>
        </OrderTemplatesTable>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { ORDER_TEMPLATES_GET_ALL, ORDER_TEMPLATES_DELETE } from '@/store/actions/ordersTemplates'

import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import OrderTemplatesTable from '@/views/orderTemplates/components/OrderTemplatesTable.vue'

export default {
  name: 'OrderTemplates',
  components: {
    ConfirmDialog,
    OrderTemplatesTable
  },
  data () {
    return {
      deleteConfirmDialog: { show: false }
    }
  },
  computed: {
    orders () {
      return this.$store.state.ordersTemplates.all
    }
  },
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    this.$store
      .dispatch(ORDER_TEMPLATES_GET_ALL)
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('orderTemplates.loaded'))
      })
      .catch(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_ERROR,
          this.$t('orderTemplates.errorLoadingOrderTemplates')
        )
      })
  },
  methods: {
    editItem (item) {
      this.$router.push('/orderTemplates/edit/' + item.id)
    },
    newItem () {
      this.$router.push('/orderTemplates/new')
    },
    confirmDeleteItem (item) {
      this.deleteConfirmDialog.show = true
      this.deleteConfirmDialog.message = this.$t(
        'orderTemplates.deleteItemMessage',
        item
      )
      this.deleteConfirmDialog.itemId = item.id
    },
    deleteItem (itemId) {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(ORDER_TEMPLATES_DELETE, itemId)
        .then(value => {
          this.$store.dispatch(LOADING_DECREASE)
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('orderTemplates.errorDeletingOrderTemplate')
          )
        })
    },
    actionItem (item) {
      this.$router.push('/ordersSchedules/fromTemplate/' + item.id)
    }
  }
}
</script>
