<template>
  <v-dialog
    :value="value"
    max-width="750px"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-card-title class="text-h2 grey pt-4 pb-4 mb-5">
        <span class="headline">
          {{ title }}
        </span>
      </v-card-title>
      <ModuleData
        v-if="isTypeModule"
        :module="module"
      />
      <InputRFData
        v-if="isTypeInputRF"
        :module="module"
        :input="input"
      />
      <OutputRFData
        v-if="isTypeOutputRF"
        :module="module"
        :outputs="outputs"
      />
      <CAMData
        v-if="isTypeCAM"
        :module="module"
        :cam="cam"
      />
      <NetworkUSBData
        v-if="isTypeNetworkUSB"
        :module="module"
        :usb="usb"
      />
      <NetworkRJ45Data
        v-if="isTypeNetworkRJ45"
        :module="module"
        :rj45="rj45"
      />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          :title="$t('common.button.close')"
          @click="close"
        >
          {{ $t('common.button.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// Dialog data
import ModuleData from './moduleDialogs/ModuleData.vue'
import InputRFData from './moduleDialogs/InputRFData.vue'
import OutputRFData from './moduleDialogs/OutputRFData.vue'
import CAMData from './moduleDialogs/CAMData.vue'
import NetworkUSBData from './moduleDialogs/NetworkUSBData.vue'
import NetworkRJ45Data from './moduleDialogs/NetworkRJ45Data.vue'

export default {
  name: 'ModuleDialog',
  components: {
    ModuleData,
    InputRFData,
    OutputRFData,
    CAMData,
    NetworkUSBData,
    NetworkRJ45Data
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    },
    module: {
      type: Object,
      required: true
    },
    input: {
      type: Object,
      default: null
    },
    outputs: {
      type: Array,
      default: null
    },
    cam: {
      type: Object,
      default: null
    },
    usb: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    },
    rj45: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    }
  },
  computed: {
    isTypeModule: function () {
      return this.type === 'module'
    },
    isTypeInputRF: function () {
      return this.type === 'inputRF'
    },
    isTypeOutputRF: function () {
      return this.type === 'outputRF'
    },
    isTypeCAM: function () {
      return this.type === 'CAM'
    },
    isTypeNetworkUSB: function () {
      return this.type === 'networkUSB'
    },
    isTypeNetworkRJ45: function () {
      return this.type === 'networkRJ45'
    }
  },
  methods: {
    close: function () {
      this.$emit('input', false)
    }
  }
}
</script>
