<template>
  <div class="d-flex">
    <ModuleDialog
      v-model="inputDialog.show"
      :title="inputDialog.title"
      type="inputRF"
      :module="module"
      :input="input"
    />
    <a
      :class="getClassName"
      :title="getInputTitle"
      @click="showInputDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import {
  ReportConfigurationModuleRFInputsConfigModeValue
} from '../../../../types/Report'

import { NS_REPORT } from '../../../../store/namespaces'

// common
import ModuleDialog from '../../../common/ModuleDialog.vue'

export default {
  name: 'SlotInputRF',
  components: {
    ModuleDialog
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    input: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      inputDialog: {
        show: false
      }
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getStatusModuleBySlot']),
    getStatusModuleInputRFItem: function () {
      return this.getStatusModuleBySlot(this.module.slot)?.inputsRF?.find((inputRF) => {
        return inputRF.id === this.input.id
      })
    },
    hasAlarms: function () {
      const inputStatus = this.getStatusModuleInputRFItem
      return inputStatus !== undefined && inputStatus !== null && inputStatus.alarms !== undefined && inputStatus.alarms.length > 0
    },
    getClassName: function () {
      let className = 'module__input-rf'

      // ONLY IN TRANSMOD MODULE INDEX IS SET
      if (this.index != null) {
        const rfInputConfigMode = this.module?.inputs?.rf?.configMode ?? 0
        const inputsRF = this.module?.inputs?.rf?.inputs ?? []
        let isEnabled
        let hasAlarms
        switch (rfInputConfigMode) {
          case ReportConfigurationModuleRFInputsConfigModeValue.ONE:
            isEnabled = false
            hasAlarms = false
            if (this.index === 0) { // CONECTOR 1
              if (inputsRF !== null && inputsRF.length > 0) {
                inputsRF.forEach((input) => {
                  isEnabled = isEnabled || input.enabled
                  hasAlarms = hasAlarms || this.hasInputRFAlarmsByInput(input)
                })
                className = className.concat(this.getClassNameModificator(isEnabled, hasAlarms))
              }
            }
            break
          case ReportConfigurationModuleRFInputsConfigModeValue.TWO:
            isEnabled = false
            hasAlarms = false
            if (this.index === 0) { // CONECTOR 1
              if (inputsRF !== null && inputsRF.length > 0) {
                inputsRF.forEach((input) => {
                  if (input.id !== 3) {
                    isEnabled = isEnabled || input.enabled
                    hasAlarms = hasAlarms || this.hasInputRFAlarmsByInput(input)
                  }
                })
                className = className.concat(this.getClassNameModificator(isEnabled, hasAlarms))
              }
            } else if (this.index === 2) { // CONECTOR 3
              if (inputsRF !== null && inputsRF.length > 0) {
                inputsRF.forEach((input) => {
                  if (input.id === 3) {
                    isEnabled = input.enabled
                    hasAlarms = this.hasInputRFAlarmsByInput(input)
                  }
                })
                className = className.concat(this.getClassNameModificator(isEnabled, hasAlarms))
              }
            }
            break
          case ReportConfigurationModuleRFInputsConfigModeValue.THREE:
            isEnabled = false
            hasAlarms = false
            if (this.index === 0) { // CONECTOR 1
              if (inputsRF !== null && inputsRF.length > 0) {
                inputsRF.forEach((input) => {
                  if (input.id !== 4) {
                    isEnabled = isEnabled || input.enabled
                    hasAlarms = hasAlarms || this.hasInputRFAlarmsByInput(input)
                  }
                })
                className = className.concat(this.getClassNameModificator(isEnabled, hasAlarms))
              }
            } else if (this.index === 3) { // CONECTOR 4
              if (inputsRF !== null && inputsRF.length > 0) {
                inputsRF.forEach((input) => {
                  if (input.id === 4) {
                    isEnabled = input.enabled
                    hasAlarms = this.hasInputRFAlarmsByInput(input)
                  }
                })
                className = className.concat(this.getClassNameModificator(isEnabled, hasAlarms))
              }
            }
            break
          case ReportConfigurationModuleRFInputsConfigModeValue.FOUR:
            isEnabled = false
            hasAlarms = false
            if (this.index === 0) { // CONECTOR 1
              if (inputsRF !== null && inputsRF.length > 0) {
                inputsRF.forEach((input) => {
                  if (input.id === 1 || input.id === 2) {
                    isEnabled = isEnabled || input.enabled
                    hasAlarms = hasAlarms || this.hasInputRFAlarmsByInput(input)
                  }
                })
                className = className.concat(this.getClassNameModificator(isEnabled, hasAlarms))
              }
            } else if (this.index === 2) { // CONECTOR 3
              if (inputsRF !== null && inputsRF.length > 0) {
                inputsRF.forEach((input) => {
                  if (input.id === 3) {
                    isEnabled = input.enabled
                    hasAlarms = this.hasInputRFAlarmsByInput(input)
                  }
                })
                className = className.concat(this.getClassNameModificator(isEnabled, hasAlarms))
              }
            } else if (this.index === 3) { // CONECTOR 4
              if (inputsRF !== null && inputsRF.length > 0) {
                inputsRF.forEach((input) => {
                  if (input.id === 4) {
                    isEnabled = input.enabled
                    hasAlarms = this.hasInputRFAlarmsByInput(input)
                  }
                })
                className = className.concat(this.getClassNameModificator(isEnabled, hasAlarms))
              }
            }
            break
          case ReportConfigurationModuleRFInputsConfigModeValue.ZERO:
          default:
            if (this.input !== undefined && this.input !== null && this.input.enabled) {
              className = className.concat(this.getClassNameModificator(this.input.enabled, this.hasAlarms))
            }
            break
        }
      }
      return className
    },
    getInputTitle: function () {
      return 'Input RF ' + (this.input?.id ?? '')
    }
  },
  methods: {
    showInputDialog: function () {
      if (this.input.enabled) {
        this.inputDialog.show = true
        this.inputDialog.title = this.$t('moduleDialog.inputRF.title', { moduleInfo: this.getModuleDialogTitle() })
      }
    },
    getModuleDialogTitle: function () {
      return this.$t('moduleDialog.title', { moduleModel: this.module.name, slot: this.module.slot })
    },
    getStatusModuleInputRFItemByInput: function (input) {
      return this.getStatusModuleBySlot(this.module.slot)?.inputsRF?.find((inputRF) => {
        return inputRF.id === input.id
      })
    },
    hasInputRFAlarmsByInput: function (input) {
      const inputStatus = this.getStatusModuleInputRFItemByInput(input)
      return inputStatus !== undefined && inputStatus !== null && inputStatus.alarms !== undefined && inputStatus.alarms.length > 0
    },
    getClassNameModificator: function (isEnabled, hasAlarms) {
      const classNameEnabled = ' enabled'
      const classNameWarning = ' warning-red'
      let classNameModificator = ''
      if (isEnabled) {
        if (hasAlarms) {
          classNameModificator = classNameWarning
        } else {
          classNameModificator = classNameEnabled
        }
      }
      return classNameModificator
    }
  }
}
</script>

<style scoped>
.module__input-rf {
  background-image: url('../../../../assets/images/input-rf.svg');
  width: 1.344rem;
  height: 1.344rem;
  background-size: 1.344rem 1.344rem;
  margin-bottom: 0.875rem;
  cursor: auto;
}
.module__input-rf.enabled {
  background-image: url('../../../../assets/images/input-rf-enabled.svg');
  cursor: pointer;
}
.module__input-rf.warning-orange {
  background-image: url('../../../../assets/images/input-rf-orange.svg');
  cursor: pointer;
}
.module__input-rf.warning-red {
  background-image: url('../../../../assets/images/input-rf-red.svg');
  cursor: pointer;
}
</style>
