import reportApi from '@/services/api/ReportsApi'

export default {
  getAll () {
    return reportApi()
      .get('/templates')
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },
  create (reportTemplate) {
    return reportApi().post('/templates/', reportTemplate)
  },
  delete (id) {
    return reportApi().delete('/templates/' + id)
  }
}
