<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t('installers.viewTitle') }}
      </v-card-title>
      <v-card-text>
        <InstallersTable
          :items="installers"
          selection="none"
          :actions="['edit']"
          @editItem="editInstaller"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { USERS_GET_ALL } from '@/store/actions/users'
import { ASSIGNMENTS_GET_ALL } from '@/store/actions/assignments'

import InstallersTable from '@/views/installers/components/InstallersTable.vue'

export default {
  name: 'Installers',
  components: {
    InstallersTable
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['getUserAssignments']),
    ...mapState({ myAccount: state => state.authentication.account }),
    installers () {
      const installers = this.$store.state.users.all.filter(
        user => user.role === 'installer'
      )
      return installers.map(user => {
        user.roleTranslation = this.$t('roles.' + user.role)
        user.assignments = this.getUserAssignments(user.id)
        return user
      })
    }
  },
  watch: {},
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    Promise.all([
      this.$store.dispatch(ASSIGNMENTS_GET_ALL),
      this.$store.dispatch(USERS_GET_ALL)
    ])
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_SUCCESS,
          this.$t('installers.loadedInstallers')
        )
      })
      .catch(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_ERROR,
          this.$t('installers.errorLoadingInstallers')
        )
      })
  },
  methods: {
    editInstaller: function (item) {
      this.$router.push('/installerAssignments/' + item.id)
    }
  }
}
</script>
