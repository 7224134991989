<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="receiversTab">
          <v-tab
            v-if="target.groups.length > 0"
          >
            <v-badge
              color="primary"
              :content="target.groups.length > 0 ? target.groups.length : '0'"
              :title="
                $t('selectedGroups', {
                  value: target.groups.length
                })
              "
            >
              {{ $t('newOrder.groups') }}
            </v-badge>
          </v-tab>
          <v-tab
            v-if="target.devices.length > 0"
          >
            <v-badge
              color="primary"
              :content="
                target.devices.length > 0 ? target.devices.length : '0'
              "
              :title="
                $t('selectedHeadends', {
                  value: target.devices.length
                })
              "
            >
              {{ $t('newOrder.headends') }}
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="receiversTab">
          <!-- GROUPS TAB -->
          <v-tab-item
            v-if="target.groups.length > 0"
          >
            <GroupsTable
              v-model="showing.groups"
              :items="showing.groups"
              selection="none"
              :actions="['delete']"
            />
          </v-tab-item>
          <!-- HEADENDS TAB -->
          <v-tab-item
            v-if="target.devices.length > 0"
          >
            <HeadendsTable
              v-model="showing.devices"
              :items="showing.devices"
              :columns="headersColumns()"
              selection="none"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import HeadendsTable from '@/views/headends/components/HeadendsTable.vue'
import GroupsTable from '@/views/groups/components/GroupsTable.vue'
import headends from '@/mixins/headends.js'

export default {
  name: 'OrderScheduleTarget',
  components: {
    HeadendsTable,
    GroupsTable
  },
  mixins: [headends],
  props: {
    target: {
      type: Object,
      default: () => {
        return {
          devices: [],
          groups: []
        }
      }
    }
  },
  data () {
    return {
      receiversTab: 0,
      showing: {
        devices: [],
        groups: []
      }
    }
  },
  computed: {
    ...mapGetters(['getHeadendById', 'getGroupById']),
    ...mapState({
      devices: state => {
        return state.headendsNew.all
      }
    }),
    ...mapState({
      groups: state => {
        return state.groupsNew.all
      }
    }),
    customizationType: function () {
      return this.$store.getters['customization/customizationType']
    }
  },
  watch: {
    target: function () {
      this.showing.devices.length = 0
      this.target.devices.forEach(idHeadend => {
        this.showing.devices.push(this.getHeadendById(idHeadend))
      })
      this.showing.groups.length = 0
      this.target.groups.forEach(idGroup => {
        if (typeof idGroup === 'string') {
          this.showing.groups.push(this.getGroupById(idGroup))
        }
      })
    }
  },
  mounted () {
    this.showing.devices.length = 0
    this.target.devices.forEach(idHeadend => {
      this.showing.devices.push(this.getHeadendById(idHeadend))
    })
    this.showing.groups.length = 0
    this.target.groups.forEach(idGroup => {
      if (typeof idGroup === 'string') {
        this.showing.groups.push(this.getGroupById(idGroup))
      }
    })
  },
  methods: {
    headersColumns: function () {
      return this.headendsHeadersColumns(this.customizationType)
    }
  }
}
</script>
