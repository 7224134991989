<template>
  <v-dialog
    :value="value"
    max-width="750px"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-card-title
        class="text-h3 error pt-8 pb-8 mb-5"
      >
        <v-row>
          <v-icon
            class="mr-5"
            large
            color="white"
          >
            mdi-alert-circle
          </v-icon>
          <span
            class="headline"
          >
            {{ title }}
          </span>
        </v-row>
      </v-card-title>
      <v-list
        v-for="(mapItem, mapItemIndex) in getAlarmMapEntries"
        :key="mapItemIndex"
        :item="mapItem"
        disabled
        dense
        class="pl-5 pr-5"
      >
        <v-subheader>{{ getAlarmGroupTitle(mapItem.key) }}</v-subheader>
        <v-list-item
          v-for="(data, i) in mapItem.value"
          :key="i"
        >
          <v-list-item-content class="text--primary">
            <v-list-item-title
              v-text="getAlarmMessage(data)"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          :title="$t('common.button.close')"
          @click="close"
        >
          {{ $t('common.button.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'AlarmDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: null
    },
    map: {
      type: Map,
      default: null
    }
  },
  computed: {
    getAlarmMapEntries: function () {
      if (this.map !== undefined && this.map !== null) {
        const entries = []
        this.map.forEach((value, key) => {
          entries.push({
            key,
            value
          })
        })
        return entries
      }
      return []
    }
  },
  methods: {
    getAlarmGroupTitle: function (key) {
      const keyParts = key.split('-')
      return this.$t('alarmDialog.groupTitle', { base: keyParts[0], slot: keyParts[1] })
    },
    getAlarmMessage: function (item) {
      let message
      switch (item.type) {
        case 'cam':
          message = this.$t('alarmDialog.alarmMessage.cam', { message: item.description, id: item.id })
          break
        case 'inputRF':
        case 'inputIP':
          message = this.$t('alarmDialog.alarmMessage.input', { message: item.description, id: item.id })
          break
        case 'outputRF':
        case 'outputMPTS':
        case 'outputSPTS':
          message = this.$t('alarmDialog.alarmMessage.output', { message: item.description, id: item.id })
          break
        case 'generic':
          message = item.description
          break
      }
      return message
    },
    close: function () {
      this.$emit('input', false)
    }
  }
}
</script>
<style>
.headline {
  color: white
}
</style>
