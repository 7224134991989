<template>
  <div class="data__alarm__list">
    <span
      v-for="(alarm, index) in alarms"
      :key="index"
      class="data__alarm"
    >
      {{ alarm.description }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'AlarmMessages',
  props: {
    alarms: {
      type: Array,
      required: true
    }
  }
}
</script>
<style scoped>
.data__alarm__list {
  margin-bottom: 1em;
  margin-left: 2em;
}
.data__alarm {
  color: red;
  display: inline-block;
  width: 100%;
}
</style>
