<template>
  <div>
    <div>
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.controlInterface.labels.preferred') }}</span>
      <span class="data__value">{{ getRJ45Preferred() }}</span>
    </div>
    <div>
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.controlInterface.labels.mode') }}</span>
      <span class="data__value">{{ getRJ45Mode() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.controlInterface.labels.mac') }}</span>
      <span class="data__value">{{ getRJ45MAC() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.controlInterface.labels.link') }}</span>
      <span class="data__value">{{ getRJ45Link() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes && isModeEnabled && isRJ45IPAddressDefined">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.controlInterface.labels.ipAddress') }}</span>
      <span class="data__value">{{ getRJ45IPAddress() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes && isModeEnabled && isRJ45NetmaskDefined">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.controlInterface.labels.netmask') }}</span>
      <span class="data__value">{{ getRJ45Netmask() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes && isModeEnabled && isRJ45GatewayDefined">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.controlInterface.labels.gateway') }}</span>
      <span class="data__value">{{ getRJ45Gateway() }}</span>
    </div>
    <div v-if="isNetworkRJ45PresentYes && isModeEnabled && isRJ45DNSDefined">
      <span class="data__label">{{ $t('moduleDialog.rj45.sections.controlInterface.labels.dns') }}</span>
      <span class="data__value">{{ getRJ45DNS() }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../../store/namespaces'

import {
  ReportStatusModuleNetworkControlPresent,
  ReportStatusModuleNetworkControlLink
} from '../../../../types/Report'

export default {
  name: 'ControlData',
  props: {
    module: {
      type: Object,
      required: true
    },
    rj45: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getConfigurationInternetAccess']),
    isNetworkRJ45PresentYes: function () {
      return (this.rj45 !== undefined && this.rj45 !== null && this.rj45.control !== undefined && this.rj45.control !== null && this.rj45.control.present === ReportStatusModuleNetworkControlPresent.yes)
    },
    isModeEnabled: function () {
      return this.module.slot === 2
        ? this.getConfigurationInternetAccess.interface.rj45.enabled
        : true
    },
    isRJ45IPAddressDefined: function () {
      return (this.rj45.control?.ip !== undefined && this.rj45.control?.ip !== null) ?? false
    },
    isRJ45NetmaskDefined: function () {
      return (this.rj45.control?.mask !== undefined && this.rj45.control?.mask !== null) ?? false
    },
    isRJ45GatewayDefined: function () {
      return (this.rj45.control?.gateway !== undefined && this.rj45.control?.gateway !== null) ?? false
    },
    isRJ45DNSDefined: function () {
      return (this.rj45.control?.dns !== undefined && this.rj45.control?.dns !== null) ?? false
    }
  },
  methods: {
    getRJ45Preferred: function () {
      if (this.module.slot !== 2) {
        return this.$t('common.yes')
      } else {
        return this.getConfigurationInternetAccess.interface.rj45.preferred
          ? this.$t('common.yes')
          : this.$t('common.no')
      }
    },
    getRJ45Mode: function () {
      if (this.module.slot !== 2) {
        return this.$t('common.networkInterface.modes.dhcp')
      } else {
        if (this.getConfigurationInternetAccess.interface.rj45.enabled) {
          return this.getConfigurationInternetAccess.interface.rj45.dhcp
            ? this.$t('common.networkInterface.modes.dhcp')
            : this.$t('common.networkInterface.modes.fixedIP')
        } else {
          return this.$t('common.networkInterface.modes.disabled')
        }
      }
    },
    getRJ45MAC: function () {
      return this.rj45.control.mac
    },
    getRJ45Link: function () {
      let link
      switch (this.rj45.control.link) {
        case ReportStatusModuleNetworkControlLink.up:
          link = this.$t('common.networkInterface.link.up')
          break
        case ReportStatusModuleNetworkControlLink.down:
          link = this.$t('common.networkInterface.link.down')
          break
      }
      return link
    },
    getRJ45IPAddress: function () {
      return this.rj45.control.ip
    },
    getRJ45Netmask: function () {
      return this.rj45.control.mask
    },
    getRJ45Gateway: function () {
      return this.rj45.control.gateway
    },
    getRJ45DNS: function () {
      return this.rj45.control.dns?.join(', ')
    }
  }
}
</script>
<style scoped>
.data__label {
  display: inline-block;
  width: 15em;
  margin-left: 2em;
  vertical-align: top;
}
.data__value {
  display: inline-block;
  width: 55%;
}
</style>
