import {
  REPORTS_SCHEDULES_GET_ALL,
  REPORTS_SCHEDULES_CREATE,
  REPORTS_SCHEDULES_DELETE,
  REPORTS_SCHEDULES_CLEAR
} from '@/store/actions/reportsSchedules'

import { AUTH_LOGOUT } from '@/store/actions/authentication'

import reportsSchedulesApi from '@/services/api/reports/ReportsSchedules'

export default {
  state: {
    all: []
  },
  mutations: {
    [REPORTS_SCHEDULES_GET_ALL]: (state, data) => {
      state.all = data
    },
    [REPORTS_SCHEDULES_CREATE]: (state, reportTemplate) => {
      state.all.push(reportTemplate)
    },
    [REPORTS_SCHEDULES_DELETE]: (state, id) => {
      var index = state.all.findIndex(
        reportTemplate => reportTemplate.id === id
      )
      state.all.splice(index, 1)
    },
    [REPORTS_SCHEDULES_CLEAR]: state => {
      state.all.length = 0
    }
  },
  actions: {
    [REPORTS_SCHEDULES_GET_ALL]: ({ commit, state, dispatch }) => {
      return new Promise((resolve, reject) => {
        reportsSchedulesApi
          .getNotFinishedSchedules()
          .then(response => {
            // console.log('[store.reportsSchedules] Report schedules lodaded')
            commit(REPORTS_SCHEDULES_GET_ALL, response)
            resolve(response)
          })
          .catch(error => {
            // console.error('[store.reportsSchedules] Error loading report schedules')
            if (error.response !== undefined && error.response.status === 401) {
              dispatch(AUTH_LOGOUT)
            }
            reject(error)
          })
      })
    },
    [REPORTS_SCHEDULES_CREATE]: ({ commit }, reportSchedule) => {
      return new Promise((resolve, reject) => {
        reportsSchedulesApi
          .create(reportSchedule)
          .then(() => {
            // console.log('[store.reportsSchedules] Report schedule ' + reportSchedule.id + ' created')
            commit(REPORTS_SCHEDULES_CREATE, reportSchedule)
            resolve()
          })
          .catch(error => {
            // console.error('[store.reportsSchedules] Error creating the report template with the id: ' + reportSchedule.id)
            reject(error)
          })
      })
    },
    [REPORTS_SCHEDULES_DELETE]: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        reportsSchedulesApi
          .delete(id)
          .then(() => {
            // console.log('[store.reportsSchedules] Report schedule ' + id + ' removed')
            commit(REPORTS_SCHEDULES_DELETE, id)
            resolve()
          })
          .catch(error => {
            // console.error('[store.reportsSchedules] Error deleting the report schedule with the id: ' + id)
            reject(error)
          })
      })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      commit(REPORTS_SCHEDULES_CLEAR)
    }
  },
  getters: {}
}
