<template>
  <v-form
    ref="reportScheduledInfoForm"
    v-model="reportScheduledInfoForm"
    lazy-validation
  >
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="information.name"
            maxlength="100"
            :label="$t('reportScheduledCreate.name')"
            :rules="[rules.required]"
            @click="validateForm"
            @keyup="validateForm"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <span class="title">{{ $t('description') }}</span>
          <v-textarea
            v-model="information.description"
            no-resize
            class="mb4"
            maxlength="400"
            :label="$t('comments')"
            outlined
            max-width="680"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card-actions>
            <v-spacer />
            <slot name="actions" />
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
export default {
  name: 'ReportsScheduledInformation',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          name: '',
          description: ''
        }
      }
    }
  },
  data () {
    return {
      reportScheduledInfoForm: false, // Checks if the form is valid
      information: {
        name: '',
        description: ''
      },
      rules: {
        required: value => !!value || this.$t('validation.requiredField')
      }
    }
  },
  computed: {},
  watch: {
    reportScheduledInfoForm: function () {
      this.$emit('isValid', this.reportScheduledInfoForm)
      this.$emit('input', this.information)
    }
  },
  mounted () {
    this.validateForm()
  },
  methods: {
    validateForm: function () {
      this.$refs.reportScheduledInfoForm.validate()
    }
  }
}
</script>
