<template>
  <v-container fluid>
    <!-- Dialog for tags data -->
    <ChannelMapDeployTagDialog
      v-model="dialog.show"
      :title="dialog.title"
      :tag="dialog.value"
      @confirmed="dialogConfirmed"
    />
    <ChannelMapLoadDialog
      v-model="showLoadDialog"
      @confirmed="loadChannelMapTemplate"
    />
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-btn
            icon
            color="primary"
            :title="$t('back')"
            @click="backToDeviceChannelMap"
          >
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-toolbar-title v-if="mode === 'EDIT'">
            {{ $t('deployChannelMap.editTitle') }}
          </v-toolbar-title>
          <v-toolbar-title v-if="mode === 'NEW'">
            {{ $t('deployChannelMap.newTitle') }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!validForm || !channelMapJson"
            @click="save"
          >
            {{ $t('button.save') }}
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="channelMap.order"
                    type="number"
                    min="1"
                    :rules="[rules.required]"
                    :label="$t('deployChannelMap.order')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="channelMap.name"
                    maxlength="50"
                    :rules="[rules.required]"
                    :label="$t('deployChannelMap.name')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="channelMap.description"
                    maxlength="450"
                    :label="$t('deployChannelMap.description')"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-toolbar flat>
                    <v-btn
                      :color="selectedTag('always')"
                      @click="selectTag('always')"
                    >
                      {{ $t('deployChannelMap.always') }}
                    </v-btn>
                    <v-btn
                      :color="selectedTag('region')"
                      @click="selectTag('region')"
                    >
                      {{ $t('deployChannelMap.region') }}
                      {{
                        channelMap.tags.region ? ': ' + channelMap.tags.region : ''
                      }}
                    </v-btn>
                    <v-btn
                      :color="selectedTag('venueId')"
                      @click="selectTag('venueId')"
                    >
                      {{ $t('deployChannelMap.venueId') }}
                      {{
                        channelMap.tags.venueId ? ': ' + channelMap.tags.venueId : ''
                      }}
                    </v-btn>

                    <v-spacer />
                    <v-btn
                      class="ma-2"
                      color="primary"
                      @click="loadChannelMap"
                    >
                      <v-icon> mdi-file-document </v-icon>
                      {{ $t('deployChannelMap.load') }}
                    </v-btn>
                  </v-toolbar>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  sm="12"
                  md="8"
                >
                  <v-textarea
                    v-model="channelMapJson"
                    dense
                    auto-grow
                    :rules="
                      requiredAndValidJson()
                    "
                    :label="$t('deployChannelMap.channelMappingData')"
                  />
                </v-col>
                <v-col
                  sm="12"
                  md="4"
                >
                  {<br>
                  <span style="padding-left:20px">modules: [ {</span><br>
                  <span style="padding-left:40px">services: [ {</span><br>
                  <span style="padding-left:60px">input: 1,</span><br>
                  <span style="padding-left:60px">inputServiceId: 5634,</span><br>
                  <span style="padding-left:60px">output: 1,</span><br>
                  <span style="padding-left:60px">outputServiceId: 3413,</span><br>
                  <span style="padding-left:60px">lcn: 22,</span><br>
                  <span style="padding-left:60px">decrypt: 1,</span><br>
                  <span style="padding-left:60px">language: "eng", </span><br>
                  <span style="padding-left:60px">outputName: "Channel one"</span><br>
                  <span style="padding-left:60px">}, ...</span><br>
                  <span style="padding-left:40px">]}, ...</span><br>
                  <span style="padding-left:20px">]</span><br>
                  }
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :disabled="!validForm || !channelMapJson"
                @click="save"
              >
                {{ $t('button.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { uuid } from 'vue-uuid'
import { mapGetters } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import {
  DEVICE_CHANNEL_MAPS_GET_ALL,
  DEVICE_CHANNEL_MAPS_CREATE,
  DEVICE_CHANNEL_MAPS_UPDATE
} from '@/store/actions/deviceChannelMaps'
import { CHANNEL_MAP_TEMPLATES_GET_ALL } from '@/store/actions/channelMapTemplates'
import ChannelMapDeployTagDialog from '@/views/channelMapDeploy/components/ChannelMapDeployTagDialog.vue'
import ChannelMapLoadDialog from '@/views/channelMapDeploy/components/ChannelMapLoadDialog.vue'
import Ajv from 'ajv'
import schemaCM from '@/jsonSchema/channelMapping.js'

export default {
  name: 'ChannelMapDeployEdit',
  components: {
    ChannelMapDeployTagDialog,
    ChannelMapLoadDialog
  },
  data () {
    return {
      validForm: null,
      mode: '',
      rules: {
        required: value => !!value || this.$t('Required')
      },
      channelMapJson: '',
      channelMap: {
        order: 1,
        name: '',
        description: '',
        tags: { always: true }
      },
      dialog: {
        show: false,
        title: '',
        type: '',
        value: ''
      },
      showLoadDialog: false
    }
  },
  computed: {
    ...mapGetters(['getDeviceChannelMap'])
  },
  mounted () {
    this.loadData()
  },
  methods: {
    requiredAndValidJson: function () {
      const validJson = function (value) {
        try {
          const jsonObject = JSON.parse(value.replace(/\n/g, ''))
          const ajv = new Ajv() // options can be passed, e.g. {allErrors: true}
          const validate = ajv.compile(schemaCM)
          const valid = validate(jsonObject)
          if (!valid) {
            return this.$t('deployChannelMap.jsonNotFormedLikeChannelMap')
          }
          return valid
        } catch {
          return this.$t('deployChannelMap.validJsonNeeded')
        }
      }
      return [this.rules.required, validJson]
    },
    loadData: function () {
      this.$store.dispatch(CHANNEL_MAP_TEMPLATES_GET_ALL)
      if (this.$route.params.deviceChannemMapId) {
        this.mode = 'EDIT'
        this.$store
          .dispatch(DEVICE_CHANNEL_MAPS_GET_ALL).then(() => {
            const channelMapDB = this.getDeviceChannelMap(
              this.$route.params.deviceChannemMapId
            )
            if (this.channelMap === undefined) {
              this.backToDeviceChannelMap()
            }
            this.channelMap = {
              id: channelMapDB.id,
              order: channelMapDB.order,
              name: channelMapDB.name,
              description: channelMapDB.description,
              tags: channelMapDB.tags
            }
            this.channelMapJson = JSON.stringify(
              channelMapDB.channelMapping,
              null,
              2
            )
          })
      } else {
        this.mode = 'NEW'
        this.channelMap = {
          id: uuid.v4(),
          order: 1,
          name: undefined,
          description: '',
          tags: { always: true }
        }
      }
    },
    save: function () {
      this.channelMap.order = parseInt(this.channelMap.order)
      this.channelMap.channelMapping = JSON.parse(
        this.channelMapJson.replace(/\n/g, '')
      )
      if (this.mode === 'NEW') {
        this.saveNewChannelMap()
      } else {
        this.saveEditedChannelMap()
      }
    },
    saveEditedChannelMap: function () {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(DEVICE_CHANNEL_MAPS_UPDATE, this.channelMap)
        .then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('deployChannelMap.saved'))
          this.backToDeviceChannelMap()
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.status !== 401) {
            this.$store.dispatch(SNACKBAR_ERROR, this.$t('deployChannelMap.errorSaving'))
          }
        })
    },
    saveNewChannelMap: function () {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(DEVICE_CHANNEL_MAPS_CREATE, this.channelMap)
        .then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('deployChannelMap.saved'))
          this.backToDeviceChannelMap()
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.status !== 401) {
            this.$store.dispatch(SNACKBAR_ERROR, this.$t('deployChannelMap.errorSaving'))
          }
        })
    },
    backToDeviceChannelMap: function () {
      this.$router.push('/channelMapDeploys')
    },
    selectedTag: function (type) {
      if (this.channelMap.tags.always !== undefined && type === 'always') {
        return 'primary'
      }
      if (this.channelMap.tags.venueId !== undefined && type === 'venueId') {
        return 'primary'
      }
      if (this.channelMap.tags.region !== undefined && type === 'region') {
        return 'primary'
      }
    },
    selectTag: function (type) {
      switch (type) {
        case 'always':
          this.$set(this.dialog, 'value', undefined)
          this.channelMap.tags = { always: true }
          break
        case 'venueId':
          this.dialog.title = this.$t('deployChannelMap.venueId')
          this.dialog.type = 'venueId'
          this.dialog.show = true
          if (this.channelMap.tags.venueId !== undefined) {
            this.dialog.value = this.channelMap.tags.venueId
          } else {
            this.$set(this.dialog, 'value', undefined)
          }
          break
        case 'region':
          this.dialog.title = this.$t('deployChannelMap.region')
          this.dialog.type = 'region'
          this.dialog.show = true
          if (this.channelMap.tags.region !== undefined) {
            this.dialog.value = this.channelMap.tags.region
          } else {
            this.$set(this.dialog, 'value', undefined)
          }
          break
      }
    },
    dialogConfirmed: function (tag) {
      this.dialog.value = tag
      this.channelMap.tags = {}
      this.channelMap.tags[this.dialog.type] = tag
    },
    loadChannelMap: function () {
      this.showLoadDialog = true
    },
    loadChannelMapTemplate: function (channelMap) {
      this.channelMapJson = JSON.stringify(channelMap.info, null, 4)
    }
  }
}
</script>
