<template>
  <v-form
    ref="reportScheduleRecurrencyForm"
    v-model="commandRecurrencyForm"
    lazy-validation
  >
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-select
            v-model="recurrency.mode"
            :prepend-icon="
              recurrency.mode === 'none' ? 'mdi-repeat-off' : 'mdi-repeat'
            "
            :label="$t('reportScheduledCreate.executionMode')"
            :items="repeatOptions"
            :rules="[rules.required]"
            @change="repeatModeChange"
          />
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <EditDateTime
            v-model="recurrency.executionInfo.startDate"
            :min-date="new Date().toISOString()"
            :time-label="$t('executionTime')"
            :date-label="$t('executionDate')"
          />
          <EditDateTime
            v-if="recurrency.mode === 'UNTIL_DATE'"
            v-model="endDate"
            :min-date="recurrency.executionInfo.endDate"
            :min-time="minTime"
            :time-label="$t('reportScheduledCreate.finalTime')"
            :date-label="$t('reportScheduledCreate.finalDate')"
          />
          <v-text-field
            v-if="recurrency.mode === 'MULTIPLE_TIMES'"
            v-model.number="numberOfTimes"
            min="0"
            type="number"
            :suffix="$t('reportScheduledCreate.times')"
            :rules="[rules.required]"
          />
          <EditInterval
            v-if="recurrency.mode !== 'ONCE'"
            v-model="frequency"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card-actions>
            <v-spacer />
            <slot name="actions" />
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { DateTime } from 'luxon'
import EditDateTime from '@/components/common/EditDateTime.vue'
import EditInterval from '@/components/common/EditInterval.vue'

export default {
  name: 'ReportsScheduledRecurrency',
  components: {
    EditDateTime,
    EditInterval
  },
  props: {
    commandType: {
      type: Object,
      default: () => {
        return { mode: ['ONCE', 'MULTIPLE_TIMES', 'UNTIL_DATE', 'FOREVER'] }
      }
    },
    value: {
      type: Object,
      default: () => {
        return {
          mode: 'ONCE',
          executionInfo: {
            startDate: null,
            endDate: null,
            numberOfTimes: null,
            frequency: null
          }
        }
      }
    }
  },
  data () {
    return {
      commandRecurrencyForm: false,
      frequency: 'P1DT0H0M',
      numberOfTimes: 1,
      endDate: null,
      recurrency: {
        mode: 'ONCE',
        executionInfo: {
          startDate: null,
          endDate: null,
          numberOfTimes: null,
          frequency: null
        }
      },
      rules: {
        required: value => !!value || this.$t('validation.requiredField')
      },
      minTime: false
    }
  },
  computed: {
    repeatOptions: function () {
      const header = []
      this.commandType.mode.forEach(item => {
        switch (item) {
          case 'ONCE':
            header.push({
              text: this.$t('repeatModeOption.ONCE'),
              value: 'ONCE'
            })
            break
          case 'MULTIPLE_TIMES':
            header.push({
              text: this.$t('repeatModeOption.MULTIPLE_TIMES'),
              value: 'MULTIPLE_TIMES'
            })
            break
          case 'UNTIL_DATE':
            header.push({
              text: this.$t('repeatModeOption.UNTIL_DATE'),
              value: 'UNTIL_DATE'
            })
            break
          case 'FOREVER':
            header.push({
              text: this.$t('repeatModeOption.FOREVER'),
              value: 'FOREVER'
            })
            break
        }
      })
      return header
    }
  },
  watch: {
    recurrency: {
      handler: function (newData) {
        this.$emit('input', newData)
      },
      deep: true
    },
    'recurrency.executionInfo.startDate': function () {
      this.updateEndDateDateTime()
    },
    frequency: function () {
      this.recurrency.executionInfo.frequency = this.frequency
    },
    numberOfTimes: function () {
      this.recurrency.executionInfo.numberOfTimes = this.numberOfTimes
    },
    endDate: function () {
      this.recurrency.executionInfo.endDate = this.endDate
      this.updateEndDateDateTime()
    }
  },
  mounted () {},
  methods: {
    repeatModeChange: function (value) {
      if (value === 'ONCE') {
        this.recurrency.executionInfo.numberOfTimes = null
        this.recurrency.executionInfo.frequency = null
        this.recurrency.executionInfo.endDate = null
      } else if (value === 'FOREVER') {
        this.recurrency.executionInfo.numberOfTimes = null
        this.recurrency.executionInfo.endDate = null
        this.recurrency.executionInfo.frequency = this.frequency
      } else if (value === 'MULTIPLE_TIMES') {
        this.recurrency.executionInfo.numberOfTimes = this.numberOfTimes
        this.recurrency.executionInfo.frequency = this.frequency
        this.recurrency.executionInfo.endDate = null
      } else if (value === 'UNTIL_DATE') {
        this.recurrency.executionInfo.numberOfTimes = null
        this.recurrency.executionInfo.frequency = this.frequency
        this.recurrency.executionInfo.endDate = this.endDate
        this.updateEndDateDateTime()
      }
    },
    updateEndDateDateTime: function () {
      if (this.recurrency.executionInfo.mode !== 'UNTIL_DATE') return
      const dateUntil = DateTime.fromISO(this.endDate)
      const dateExecution = DateTime.fromISO(
        this.recurrency.executionInfo.startDate
      )
      if (dateExecution.toMillis() >= dateUntil.toMillis()) {
        this.endDate = this.recurrency.executionInfo.startDate
        this.minTime = true
      } else if (dateUntil.hasSame(dateExecution, 'days')) {
        this.minTime = true
      } else {
        // same day
        this.minTime = false
      }
    }
  }
}
</script>
