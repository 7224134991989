var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('ConfirmDialog',{attrs:{"item-id":_vm.deleteConfirmDialog.itemId,"title":_vm.$t('users.deleteItemTitle'),"message":_vm.deleteConfirmDialog.message},on:{"confirmed":_vm.deleteItem},model:{value:(_vm.deleteConfirmDialog.show),callback:function ($$v) {_vm.$set(_vm.deleteConfirmDialog, "show", $$v)},expression:"deleteConfirmDialog.show"}}),_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('users.viewTitle'))+" ")]),_c('v-card-text',[_c('UsersTable',{attrs:{"items":_vm.users,"actions":['edit', 'delete'],"columns":[
          'code',
          'email',
          'firstName',
          'secondName',
          'telephone',
          'information',
          'role'
        ]},on:{"editItem":_vm.editItem,"deleteItem":_vm.confirmDeleteItem},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.newItem}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('users.addUser'))+" ")],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }