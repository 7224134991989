<template>
  <div class="ml-8 mr-8">
    <span>{{ $t('moduleDialog.inputRF.inputMode') }}: {{ getInputModeTopology }}</span>
    <v-expansion-panels
      accordion
      focusable
      class="mt-6 mb-6"
    >
      <v-expansion-panel
        v-for="(item, index) in getRFInputsSortedById"
        :key="index"
        :disabled="!item.enabled"
      >
        <v-expansion-panel-header :color="getExpansionPanelHeaderColor(item)">
          <span v-if="item.enabled">
            <span class="mr-4">{{ item.id }}</span>&nbsp;
            <span class="data__header__name">{{ getInputModeAndFrequency(item) }}</span>&nbsp;
            <span class="data__header__locked">{{ getInputLockedStatus(item) }}</span>
          </span>
          <span v-else>
            <span class="mr-4">{{ item.id }}</span>&nbsp;
            <span class="disabled">{{ $t('common.disabled') }}</span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-if="item.enabled"
          class="mt-4"
        >
          <AlarmMessages
            v-if="hasAlarms(item)"
            :alarms="getAlarms(item)"
          />
          <div>
            <span class="data__label">{{ $t('moduleDialog.inputRF.labels.frequency') }}</span>
            <span class="data__value">{{ getInputFrequency(item) }}</span>
          </div>
          <div v-if="isInputSatelliteDefined(item)">
            <span class="data__label">{{ $t('moduleDialog.inputRF.labels.satellite') }}</span>
            <span class="data__value">{{ getInputSatellite(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.inputRF.labels.signalLevel') }}</span>
            <span class="data__value">{{ getInputSignalLevel(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.inputRF.labels.cn') }}</span>
            <span class="data__value">{{ getInputCN(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.inputRF.labels.cber') }}</span>
            <span class="data__value">{{ getInputCBER(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.inputRF.labels.signalStrength') }}</span>
            <span class="data__value">
              <span class="full__bar">
                <span
                  :class="getBarColorClassName(item)"
                  :style="getInputSignalStrengthStyleWidth(item)"
                >
                  &nbsp;
                </span>
                <span class="bar__value">{{ getFormattedInputSignalStrength(item) }}</span>
              </span>
            </span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.inputRF.labels.signalQuality') }}</span>
            <span class="data__value">
              <span class="full__bar">
                <span
                  :class="getBarColorClassName(item)"
                  :style="getInputSignalQualityStyleWidth(item)"
                >
                  &nbsp;
                </span>
                <span class="bar__value">{{ getFormattedInputSignalQuality(item) }}</span>
              </span>
            </span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.inputRF.labels.qualityOfService') }}</span>
            <span class="data__value">{{ getInputQualityOfService(item) }}</span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../store/namespaces'

import {
  ReportConfigurationModuleRFInputsConfigModeValue,
  ReportConfigurationModuleRFInputsItemInputModeValue,
  ReportConfigurationModuleRFInputsItemInputModeLabel
} from '../../../types/Report'

import AlarmMessages from './AlarmMessages.vue'

export default {
  name: 'InputRFData',
  components: {
    AlarmMessages
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    input: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getStatusModuleBySlot', 'getConfigurationMultiswitch']),
    getInputModeTopology: function () {
      let configMode
      switch (this.module.inputs.rf.configMode) {
        case ReportConfigurationModuleRFInputsConfigModeValue.ZERO:
          configMode = this.$t('moduleDialog.inputRF.configMode.independent')
          break
        case ReportConfigurationModuleRFInputsConfigModeValue.ONE:
          configMode = this.$t('moduleDialog.inputRF.configMode.allLoop')
          break
        case ReportConfigurationModuleRFInputsConfigModeValue.TWO:
          configMode = this.$t('moduleDialog.inputRF.configMode.loop3Detached')
          break
        case ReportConfigurationModuleRFInputsConfigModeValue.THREE:
          // TABCORP -> loopScan
          // OTHERS -> loop4Detached
          configMode = this.$t('moduleDialog.inputRF.configMode.loopScan')
          break
        case ReportConfigurationModuleRFInputsConfigModeValue.FOUR:
          configMode = this.$t('moduleDialog.inputRF.configMode.loop34Detached')
          break
      }
      return configMode
    },
    getRFInputsSortedById: function () {
      const originalInputs = this.module.inputs?.rf?.inputs ?? []
      const inputs = JSON.parse(JSON.stringify(originalInputs))
      return inputs.sort((a, b) => {
        return (a.id > b.id)
          ? 1
          : (a.id < b.id)
            ? -1
            : 0
      })
    }
  },
  methods: {
    getStatusModuleInputRFItem: function (moduleInput) {
      return this.getStatusModuleBySlot(this.module.slot).inputsRF.find((inputRF) => {
        return inputRF.id === moduleInput.id
      })
    },
    hasAlarms: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputRFItem(moduleInput)
      return inputStatus.alarms !== undefined && inputStatus.alarms.length > 0
    },
    getAlarms: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputRFItem(moduleInput)
      return inputStatus.alarms
    },
    getExpansionPanelHeaderColor: function (moduleInput) {
      if (moduleInput.enabled) {
        if (this.hasAlarms(moduleInput)) {
          return '#D31F1F'
        }
      }
      return ''
    },
    getInputModeAndFrequency: function (moduleInput) {
      let inputMode = ''
      let frequency

      switch (moduleInput.standard) {
        case ReportConfigurationModuleRFInputsItemInputModeValue.SATELLITE:
          inputMode = ReportConfigurationModuleRFInputsItemInputModeLabel.SATELLITE
          break
        case ReportConfigurationModuleRFInputsItemInputModeValue.TERRESTRIAL:
          inputMode = ReportConfigurationModuleRFInputsItemInputModeLabel.TERRESTRIAL
          break
        case ReportConfigurationModuleRFInputsItemInputModeValue.CABLE:
          inputMode = ReportConfigurationModuleRFInputsItemInputModeLabel.CABLE
          break
      }

      if (moduleInput.standard === ReportConfigurationModuleRFInputsItemInputModeValue.SATELLITE) {
        frequency = moduleInput.satelliteFrequency + ' MHz'
      } else {
        frequency = moduleInput.frequency / 1000 + ' MHz'
      }
      return inputMode + ' - ' + frequency
    },
    getInputLockedStatus: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputRFItem(moduleInput)
      if (inputStatus !== undefined && inputStatus !== null) {
        return inputStatus.locked
          ? this.$t('common.locked')
          : this.$t('common.unlocked')
      } else {
        return ''
      }
    },
    getInputFrequency: function (moduleInput) {
      const frequencyString = moduleInput.frequency + ''
      if (frequencyString.length > 5) {
        return (moduleInput.frequency / 1000) + ' MHz'
      }
      return moduleInput.frequency + ' MHz'
    },
    isInputSatelliteDefined: function (moduleInput) {
      return moduleInput.multiswitchInput !== undefined && moduleInput.multiswitchInput !== null
    },
    getInputSatellite: function (moduleInput) {
      const multiswitch = this.getConfigurationMultiswitch
      const multiswitchInput = moduleInput.multiswitchInput
      const multiswitchItem = multiswitch.find((item) => {
        return item.id === multiswitchInput
      })
      if (multiswitchItem !== undefined && multiswitchItem !== null) {
        return multiswitchItem.name
      }
      return ''
    },
    getInputSignalLevel: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputRFItem(moduleInput)
      return inputStatus?.signalLevel + ' dBuV' ?? ''
    },
    getInputCN: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputRFItem(moduleInput)
      if (inputStatus.cn !== undefined) {
        const cnValue = inputStatus.cn
        return parseFloat(cnValue / 100).toFixed(2) + ' dB'
      }
      return ''
    },
    getInputCBER: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputRFItem(moduleInput)
      if (inputStatus.cber !== undefined) {
        const cberValue = inputStatus.cber
        return parseFloat(cberValue / 10000000).toExponential()
      }
      return ''
    },
    getInputSignalStrength: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputRFItem(moduleInput)
      return inputStatus.signalStrength
    },
    getFormattedInputSignalStrength: function (moduleInput) {
      return this.getInputSignalStrength(moduleInput) + '%'
    },
    getInputSignalStrengthStyleWidth: function (moduleInput) {
      return 'width: ' + this.getInputSignalStrength(moduleInput) + '%;'
    },
    getInputSignalQuality: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputRFItem(moduleInput)
      return inputStatus.signalQuality
    },
    getFormattedInputSignalQuality: function (moduleInput) {
      return this.getInputSignalQuality(moduleInput) + '%'
    },
    getInputSignalQualityStyleWidth: function (moduleInput) {
      return 'width: ' + this.getInputSignalQuality(moduleInput) + '%;'
    },
    getInputQualityOfService: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputRFItem(moduleInput)
      // Formula:  nominalSeconds * 100 / (nominalSeconds + impairedSeconds + noLinkSeconds)
      const denominator = inputStatus.nominalSeconds + inputStatus.impairedSeconds + inputStatus.noLinkSeconds
      if (denominator > 0) {
        return Math.round(inputStatus.nominalSeconds * 100 / denominator) + '%'
      }
      return ''
    },
    getBarColorClassName: function (moduleInput) {
      const inputStatus = this.getStatusModuleInputRFItem(moduleInput)
      let className = 'percentage__bar'
      if (inputStatus.locked === 1 && inputStatus.quality === 2) {
        className += ' bar__good__color'
      } else if (inputStatus.locked === 1 && inputStatus.quality === 1) {
        className += ' bar__marginal__color'
      } else if (inputStatus.locked === 1 && inputStatus.quality === 0) {
        className += ' bar__bad__color'
      }
      return className
    }
  }
}
</script>
<style scoped>
.data__header__name {
  display: inline-block;
  width: 14.5rem;
}
.data__label {
  display: inline-block;
  width: 15em;
  margin-left: 2em;
}
.data__value {
  display: inline-block;
  width: 55%;
}
.full__bar {
  position: relative;
  display: inline-block;
  background-color: #9E9E9E;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
}
.percentage__bar {
  position: absolute;
  display: inline-block;
}
.bar__bad__color {
  background-color: #D76B33;
}
.bar__marginal__color {
  background-color: #999900;
}
.bar__good__color {
  background-color: #01824E;
}
.bar__value {
  position: relative;
  margin-left: 0.5em;
}
</style>
