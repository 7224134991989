var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-lock","append-icon":_vm.showPassword1 ? 'mdi-eye' : 'mdi-eye-off',"label":_vm.$t('passwordAdmin'),"type":_vm.showPassword1 ? 'text' : 'password',"rules":[
          _vm.rules.requiredPassword,
          _vm.rules.passwordAdminMatch
        ]},on:{"click:append":function($event){_vm.showPassword1 = !_vm.showPassword1},"click":_vm.validateForm,"keyup":_vm.validateForm,"change":_vm.setPasswords},model:{value:(_vm.passwordAdmin),callback:function ($$v) {_vm.passwordAdmin=$$v},expression:"passwordAdmin"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-lock","append-icon":_vm.showPassword2 ? 'mdi-eye' : 'mdi-eye-off',"label":_vm.$t('passwordAdminepeat'),"type":_vm.showPassword2 ? 'text' : 'password',"rules":[
          _vm.rules.requiredPassword,
          _vm.rules.passwordAdminMatch
        ]},on:{"click:append":function($event){_vm.showPassword2 = !_vm.showPassword2},"click":_vm.validateForm,"keyup":_vm.validateForm,"change":_vm.setPasswords},model:{value:(_vm.passwordAdmin2),callback:function ($$v) {_vm.passwordAdmin2=$$v},expression:"passwordAdmin2"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-lock","append-icon":_vm.showPassword3 ? 'mdi-eye' : 'mdi-eye-off',"label":_vm.$t('passwordInstaller'),"type":_vm.showPassword3 ? 'text' : 'password',"rules":[
          _vm.rules.requiredPassword,
          _vm.rules.passwordInstallerMatch
        ]},on:{"click:append":function($event){_vm.showPassword3 = !_vm.showPassword3},"click":_vm.validateForm,"keyup":_vm.validateForm,"change":_vm.setPasswords},model:{value:(_vm.passwordInstaller),callback:function ($$v) {_vm.passwordInstaller=$$v},expression:"passwordInstaller"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"prepend-icon":"mdi-lock","append-icon":_vm.showPassword4 ? 'mdi-eye' : 'mdi-eye-off',"label":_vm.$t('passwordInstallerRepeat'),"type":_vm.showPassword4 ? 'text' : 'password',"rules":[
          _vm.rules.requiredPassword,
          _vm.rules.passwordInstallerMatch
        ]},on:{"click:append":function($event){_vm.showPassword4 = !_vm.showPassword4},"click":_vm.validateForm,"keyup":_vm.validateForm,"change":_vm.setPasswords},model:{value:(_vm.passwordInstaller2),callback:function ($$v) {_vm.passwordInstaller2=$$v},expression:"passwordInstaller2"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }