<template>
  <div class="d-flex">
    <SlotRegulator v-if="isTypeRegulator" />
    <SlotInputRF
      v-if="isTypeInputRF"
      :input="input"
      :module="module"
      :index="index"
    />
    <SlotInputSAT v-if="isTypeInputSAT" />
    <SlotCAM
      v-if="isTypeCAM"
      :cam="cam"
      :module="module"
    />
    <SlotNetworkRJ45
      v-if="isTypeNetworkRJ45"
      :rj45="rj45"
      :module="module"
    />
    <SlotNetworkUSB
      v-if="isTypeNetworkUSB"
      :usb="usb"
      :module="module"
    />
    <SlotOutputRF
      v-if="isTypeOutputRF"
      :outputs="outputs"
      :module="module"
    />
    <SlotPlug v-if="isTypePlug" />
  </div>
</template>
<script>
import SlotRegulator from './SlotRegulator.vue'
import SlotInputRF from './SlotInputRF.vue'
import SlotInputSAT from './SlotInputSAT.vue'
import SlotCAM from './SlotCAM.vue'
import SlotNetworkRJ45 from './SlotNetworkRJ45.vue'
import SlotNetworkUSB from './SlotNetworkUSB.vue'
import SlotOutputRF from './SlotOutputRF.vue'
import SlotPlug from './SlotPlug.vue'

export default {
  name: 'SlotElement',
  components: {
    SlotRegulator,
    SlotInputRF,
    SlotInputSAT,
    SlotCAM,
    SlotNetworkRJ45,
    SlotNetworkUSB,
    SlotOutputRF,
    SlotPlug
  },
  props: {
    type: {
      type: String,
      required: true
    },
    module: {
      type: Object,
      default: null
    },
    input: {
      type: Object,
      default: null
    },
    outputs: {
      type: Array,
      default: null
    },
    cam: {
      type: Object,
      default: null
    },
    usb: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    },
    rj45: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    },
    index: {
      type: Number,
      default: null
    }
  },
  computed: {
    isTypeRegulator: function () {
      return this.type === 'regulator'
    },
    isTypeInputRF: function () {
      return this.type === 'inputRF'
    },
    isTypeInputSAT: function () {
      return this.type === 'inputSAT'
    },
    isTypeCAM: function () {
      return this.type === 'CAM'
    },
    isTypeNetworkRJ45: function () {
      return this.type === 'networkRJ45'
    },
    isTypeNetworkUSB: function () {
      return this.type === 'networkUSB'
    },
    isTypeOutputRF: function () {
      return this.type === 'outputRF'
    },
    isTypePlug: function () {
      return this.type === 'plug'
    }
  }
}
</script>
<style scoped></style>
