<template>
  <v-card>
    <v-carousel>
      <v-carousel-item
        v-for="(item, index) in pictures"
        :key="index"
        :href="$externalScheme + publicDomain + '/uploads/images/' + headend.id + '/' +item.url"
        :src="$externalScheme + publicDomain + '/uploads/images/' + headend.id + '/' +item.url"
        reverse-transition="fade-transition"
        target="_blank"
      />
    </v-carousel>
  </v-card>
</template>
<script>
export default {
  name: 'PictureCarousel',
  props: {
    headend: {
      type: Object,
      default: () => {}
    },
    pictures: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    publicDomain: function () {
      return process.env.VUE_APP_PUBLIC_DOMAIN
    }
  }
}
</script>
