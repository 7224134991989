<template>
  <div>
    <v-row>
      <v-col
        cols="9"
      >
        <v-text-field
          v-model="action.info.relativeURL"
          :label="$t('apiData.relativeUrl')"
          :rules="[ rules.CommandRelativeURL ]"
          @input="action.info.relativeURL=$event"
        />
      </v-col>
      <v-col
        cols="3"
      >
        <v-select
          v-model="action.info.method"
          :items="methodOptions"
          :label="$t('apiData.method')"
          :rules="[ rules.Method ]"
          @input="action.info.method=$event"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-textarea
          v-model="action.info.payload"
          :label="$t('apiData.payload')"
          outlined
          @input="action.info.payload=$event"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-switch
          v-model="useUsernameAndPassword"
          :label="$t('apiData.usePassword?')"
          @change="usernameAndPasswordChanged"
        />
        <v-text-field
          v-if="useUsernameAndPassword"
          v-model="action.info.user"
          :label="$t('apiData.username')"
          :rules="[ rules.UsernameAndPassword ]"
          @input="action.info.user=$event"
        />
        <v-text-field
          v-if="useUsernameAndPassword"
          v-model="action.info.password"
          :label="$t('apiData.password')"
          :rules="[ rules.UsernameAndPassword ]"
          @input="action.info.password=$event"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: 'CustomOrder',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          type: 'custom',
          info: {
            relativeURL: '',
            method: '',
            payload: ''
          }
        }
      }
    }
  },
  data () {
    return {
      action: {
        type: 'custom',
        info: {
          relativeURL: '',
          method: '',
          payload: '',
          user: '',
          password: ''
        }
      },
      rules: {
        CommandRelativeURL: value => !!value || this.$t('validation.requiredField'),
        Method: value => !!value || this.$t('validation.requiredField'),
        UsernameAndPassword: () =>
          !!this.action.info.user ||
          !!this.action.info.password ||
          this.$t('validation.requiredField')
      },
      useUsernameAndPassword: false
    }
  },
  computed: {
    methodOptions: function () {
      return [
        { text: 'GET', value: 'GET' },
        { text: 'POST', value: 'POST' },
        { text: 'PUT', value: 'PUT' },
        { text: 'DELETE', value: 'DELETE' }
      ]
    }
  },
  watch: {
    value: function (newValue) {
      this.action = newValue
      this.$emit('input', newValue)
    },
    action: {
      handler: function (newData) {
        this.$emit('input', newData)
      },
      deep: true
    }
  },
  mounted: function () {
    this.action = {
      type: 'custom',
      info: {
        relativeURL: '',
        method: '',
        payload: '',
        user: '',
        password: ''
      }
    }
    if (this.value.info !== undefined && this.value.type === 'custom') {
      if (this.value.info.relativeURL !== undefined && this.value.info.relativeURL !== null) {
        this.action.info.relativeURL = this.value.info.relativeURL
      }
      if (this.value.info.method !== undefined && this.value.info.method !== null) {
        this.action.info.method = this.value.info.method
      }
      if (this.value.info.payload !== undefined && this.value.info.payload !== null) {
        this.action.info.payload = this.value.info.payload
      }
      if (this.value.info.user !== undefined && this.value.info.user !== null && this.value.info.user !== '' && this.value.info.password !== undefined && this.value.info.password !== null && this.value.info.password !== '') {
        this.action.info.user = this.value.info.user
        this.action.info.password = this.value.info.password
        this.useUsernameAndPassword = true
      }
    }
  },
  methods: {
    usernameAndPasswordChanged: function (newValue) {
      if (!newValue) {
        delete this.action.info.user
        delete this.action.info.password
        this.action = Object.assign({}, this.action)
      }
    }
  }
}
</script>
