import axios from 'axios'
import router from '@/router'

export default () => {
  const client = axios.create({
    baseURL: '/reports',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  client.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        // catcheamos el error por si intentamos ir a la misma página, para ocultar el error
        router.push('/login').catch(() => {})
      }
      return Promise.reject(error.response)
    }
  )
  return client
}
