<template>
  <div>
    <v-expansion-panels
      accordion
      focusable
      class="mt-6 mb-6"
    >
      <v-expansion-panel
        v-for="(item, index) in module.outputs.ip.mpts"
        :key="index"
        :disabled="!item.enabled"
      >
        <v-expansion-panel-header :color="getExpansionPanelHeaderColor(item)">
          <span v-if="item.enabled">
            <span class="mr-4">{{ (index + 1) }}</span>&nbsp;
            <span class="data__header__name">{{ getOutputIPAndPort(item) }}</span>&nbsp;
          </span>
          <span v-else>
            <span class="mr-4">{{ (index + 1) }}</span>&nbsp;
            <span class="disabled">{{ $t('common.disabled') }}</span>
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          v-if="item.enabled"
          class="mt-4"
        >
          <AlarmMessages
            v-if="hasAlarms(item)"
            :alarms="getAlarms(item)"
          />
          <div>
            <span class="data__label">{{ $t('moduleDialog.rj45.sections.iptvMPTSOutputs.labels.protocol') }}</span>
            <span class="data__value">{{ getOutputProtocol(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.rj45.sections.iptvMPTSOutputs.labels.capacity') }}</span>
            <span class="data__value">{{ getFormattedOutputCapacity(item) }}</span>
          </div>
          <div>
            <span class="data__label">{{ $t('moduleDialog.rj45.sections.iptvMPTSOutputs.labels.dataUsage') }}</span>
            <span class="data__value">
              <a
                @click="changeOutputDataUsageMode()"
              >
                <span class="full__bar">
                  <span
                    class="percentage__bar bar__color"
                    :style="getOutputDataUsageStyleWidth(item)"
                  >
                    &nbsp;
                  </span>
                  <span
                    class="bar__limit__value"
                    :style="getOutputDataUsageMinStyleLeft(item)"
                  />
                  <span
                    class="bar__limit__value"
                    :style="getOutputDataUsageMaxStyleLeft(item)"
                  />
                  <span
                    v-if="showOutputDataUsagePercentage"
                    class="bar__value"
                  >
                    {{ getFormattedOutputDataUsagePercentage(item) }}
                  </span>
                  <span
                    v-if="!showOutputDataUsagePercentage"
                    class="bar__value"
                  >
                    {{ getFormattedOutputDataUsageMbps(item) }}
                  </span>
                </span>
              </a>
            </span>
          </div>
          <div v-if="isOutputQualityOfServiceDefined(item)">
            <span class="data__label">{{ $t('moduleDialog.rj45.sections.iptvMPTSOutputs.labels.qualityOfService') }}</span>
            <span class="data__value">{{ getOutputQualityOfService(item) }}</span>
          </div>
          <div v-if="hasOutputServicesAssigned(item)">
            <span class="data__label">{{ $t('moduleDialog.rj45.sections.iptvMPTSOutputs.labels.serviceList') }}</span>
            <span class="data__value">
              <ul class="data__list">
                <li
                  v-for="(service, serviceIndex) in getOutputServiceList(item)"
                  :key="serviceIndex"
                >
                  {{ getServiceName(service) }}
                </li>
              </ul>
            </span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../../store/namespaces'

import {
  ReportConfigurationModuleIPOutputsItemProtocolValue,
  ReportConfigurationModuleIPOutputsItemProtocolLabel
} from '../../../../types/Report'

import AlarmMessages from '../AlarmMessages.vue'

export default {
  name: 'IPTVMPTSOutputsData',
  components: {
    AlarmMessages
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    rj45: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    }
  },
  data () {
    return {
      showOutputDataUsagePercentage: true
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getStatusModuleBySlot'])
  },
  methods: {
    getStatusModuleOutputMPTSItem: function (moduleOutput) {
      return this.getStatusModuleBySlot(this.module.slot).outputsMPTS.find((outputMPTS) => {
        return outputMPTS.id === moduleOutput.id
      })
    },
    hasAlarms: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputMPTSItem(moduleOutput)
      return outputStatus.alarms !== undefined && outputStatus.alarms.length > 0
    },
    getAlarms: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputMPTSItem(moduleOutput)
      return outputStatus.alarms
    },
    getExpansionPanelHeaderColor: function (moduleOutput) {
      if (moduleOutput.enabled) {
        if (this.hasAlarms(moduleOutput)) {
          return '#D31F1F'
        }
      }
      return ''
    },
    getOutputIPAndPort: function (moduleOutput) {
      return moduleOutput.ipAddress + ':' + moduleOutput.ipPort
    },
    getOutputProtocol: function (moduleOutput) {
      let protocol
      switch (moduleOutput.protocol) {
        case ReportConfigurationModuleIPOutputsItemProtocolValue.UDP:
          protocol = ReportConfigurationModuleIPOutputsItemProtocolLabel.UDP
          break
        case ReportConfigurationModuleIPOutputsItemProtocolValue.UDP_RTP:
          protocol = ReportConfigurationModuleIPOutputsItemProtocolLabel.UDP_RTP
          break
      }
      return protocol
    },
    getOutputCapacity: function (moduleOutput) {
      return moduleOutput.bitrate
    },
    getFormattedOutputCapacity: function (moduleOutput) {
      return (this.getOutputCapacity(moduleOutput) / 1000000) + ' Mbps'
    },
    getOutputDataUsage: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputMPTSItem(moduleOutput)
      return 100 - outputStatus.free.current
    },
    getFormattedOutputDataUsagePercentage: function (moduleOutput) {
      return this.getOutputDataUsage(moduleOutput) + '%'
    },
    getFormattedOutputDataUsageMbps: function (moduleOutput) {
      return Math.round((this.getOutputDataUsage(moduleOutput) * this.getOutputCapacity(moduleOutput)) / 1000000) + ' Mbps'
    },
    getOutputDataUsageStyleWidth: function (moduleOutput) {
      return 'width: ' + this.getOutputDataUsage(moduleOutput) + '%;'
    },
    getOutputDataUsageMinStyleLeft: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputMPTSItem(moduleOutput)
      const min = 100 - outputStatus.free.min
      return 'left: ' + min + '%;'
    },
    getOutputDataUsageMaxStyleLeft: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputMPTSItem(moduleOutput)
      const max = 100 - outputStatus.free.max
      return 'left: ' + max + '%;'
    },
    changeOutputDataUsageMode: function () {
      this.showOutputDataUsagePercentage = !this.showOutputDataUsagePercentage
    },
    isOutputQualityOfServiceDefined: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputMPTSItem(moduleOutput)
      return outputStatus.nominalSeconds !== undefined && outputStatus.nominalSeconds !== null &&
        outputStatus.impairedSeconds !== undefined && outputStatus.impairedSeconds !== null &&
        outputStatus.noLinkSeconds !== undefined && outputStatus.noLinkSeconds !== null
    },
    getOutputQualityOfService: function (moduleOutput) {
      const outputStatus = this.getStatusModuleOutputMPTSItem(moduleOutput)
      // Formula:  nominalSeconds * 100 / (nominalSeconds + impairedSeconds + noLinkSeconds)
      const denominator = outputStatus.nominalSeconds + outputStatus.impairedSeconds + outputStatus.noLinkSeconds
      if (denominator > 0) {
        return Math.round(outputStatus.nominalSeconds * 100 / denominator) + '%'
      }
      return ''
    },
    getOutputServiceList: function (moduleOutput) {
      const services = this.module.services.ip.filter((service) => {
        return service.output === moduleOutput.id
      })
      return services.sort((a, b) => {
        return (a.id > b.id)
          ? 1
          : (a.id < b.id)
            ? -1
            : 0
      })
    },
    hasOutputServicesAssigned: function (moduleOutput) {
      return this.getOutputServiceList(moduleOutput).length > 0
    },
    getServiceName: function (service) {
      return service.outputName
    }
  }
}
</script>
<style scoped>
.data__header__name {
  display: inline-block;
  width: 14.5rem;
}
.data__label {
  display: inline-block;
  width: 15em;
  margin-left: 3em;
  vertical-align: top;
}
.data__value {
  display: inline-block;
  width: 49%;
}
.full__bar {
  position: relative;
  display: inline-block;
  background-color: #9E9E9E;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
}
.percentage__bar {
  position: absolute;
  display: inline-block;
}
.bar__color {
  background-color: #628C36;
}
.bar__value {
  position: relative;
  margin-left: 0.5em;
  color: black;
}
.bar__limit__value {
  position: absolute;
  height: 100%;
  border: solid 1px red;
  background-color: red;
}
.data__list {
  list-style: none;
  margin-left: -25px;
}
</style>
