<template>
  <div class="module">
    <ModuleDialog
      v-model="moduleDialog.show"
      :title="moduleDialog.title"
      type="module"
      :module="module"
    />
    <v-img
      contain
      src="../../../assets/images/module-header.svg"
    />
    <div
      :class="getClassName"
      @click="showModuleDialog"
    >
      <div class="module__top">
        <div class="module__left">
          <SlotElement
            v-for="(input, index) in getConfigurationModuleRFInputs"
            :key="input.id"
            :input="input"
            :index="index"
            type="inputRF"
            :module="module"
          />
        </div>
        <div class="module__right">
          <div
            v-if="areCamsPresent"
            class="cam"
          >
            <SlotElement
              v-for="(cam, index) in getCamsData"
              :key="cam.id"
              :cam="cam"
              :index="index"
              type="CAM"
              :module="module"
            />
          </div>
          <div
            v-else
            class="cam"
          >
            <SlotElement
              type="CAM"
              :module="module"
            />
            <SlotElement
              type="CAM"
              :module="module"
            />
          </div>
        </div>
      </div>
      <div class="module__middle">
        <SlotElement
          v-if="isNetworkUSBData"
          :usb="getNetworkUSBData"
          type="networkUSB"
          :module="module"
        />
        <div class="module__name">
          <a :title="getModelTitle">{{ getModuleModel }}</a>
        </div>
      </div>
      <div class="module__bottom">
        <SlotElement
          v-if="isNetworkRJ45Data"
          :rj45="getNetworkRJ45Data"
          type="networkRJ45"
          :module="module"
        />
        <div class="module__group">
          <SlotElement
            type="outputRF"
            :module="module"
          />
          <SlotElement
            :outputs="getConfigurationModuleRFOutputs"
            type="outputRF"
            :module="module"
          />
        </div>
      </div>
      <div class="module__number">
        <div>{{ module.slot }}</div>
      </div>
    </div>
    <v-img
      contain
      src="../../../assets/images/module-footer.svg"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../store/namespaces'

import ModuleDialog from '../../common/ModuleDialog.vue'
import SlotElement from './slots/Slot.vue'

export default {
  name: 'ModuleTransmod',
  components: {
    ModuleDialog,
    SlotElement
  },
  props: {
    module: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      moduleDialog: {
        show: false
      }
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, [
      'getStatusModuleBySlot',
      'getStatusModuleNetworkRJ45',
      'getStatusModuleNetworkUSB',
      'getStatusModuleCams'
    ]),
    hasAlarms: function () {
      const statusModule = this.getStatusModuleBySlot(this.module.slot)
      let alarms = []
      if (statusModule.alarms !== undefined && statusModule.alarms.length > 0) {
        alarms = statusModule.alarms.filter((alarm) => {
          return !alarm.name.includes('RJ45')
        })
      }
      return alarms.length > 0
    },
    getClassName: function () {
      let className = 'module__body'
      if (this.hasAlarms) {
        className = className.concat(' module__body__error')
      }
      return className
    },
    getModelTitle: function () {
      return this.$t('section.info.model')
    },
    getModuleModel: function () {
      return this.module.model
    },
    getConfigurationModuleRFInputs: function () {
      const originalInputs = this.module.inputs?.rf?.inputs ?? []
      const inputs = JSON.parse(JSON.stringify(originalInputs))
      return inputs.sort((a, b) => {
        return (a.id > b.id)
          ? 1
          : (a.id < b.id)
            ? -1
            : 0
      })
    },
    getConfigurationModuleRFOutputs: function () {
      const originalOutputs = this.module.outputs?.rf?.outputs ?? []
      const outputs = JSON.parse(JSON.stringify(originalOutputs))
      return outputs.sort((a, b) => {
        return (a.id > b.id)
          ? 1
          : (a.id < b.id)
            ? -1
            : 0
      })
    },
    isNetworkRJ45Data: function () {
      const rj45Data = this.getStatusModuleNetworkRJ45(this.module.slot)
      return (rj45Data !== undefined && rj45Data !== null)
    },
    isNetworkUSBData: function () {
      const usbData = this.getStatusModuleNetworkUSB(this.module.slot)
      return (usbData !== undefined && usbData !== null)
    },
    getNetworkRJ45Data: function () {
      return this.getStatusModuleNetworkRJ45(this.module.slot)
    },
    getNetworkUSBData: function () {
      return this.getStatusModuleNetworkUSB(this.module.slot)
    },
    getCamsData: function () {
      const originalCams = this.getStatusModuleCams(this.module.slot)
      if (originalCams !== undefined) {
        const cams = JSON.parse(JSON.stringify(originalCams))
        return cams.sort((a, b) => {
          return (a.id > b.id)
            ? 1
            : (a.id < b.id)
              ? -1
              : 0
        })
      }
      return []
    },
    areCamsPresent: function () {
      return this.getCamsData !== undefined && this.getCamsData !== null
    }
  },
  methods: {
    showModuleDialog: function (event) {
      this.moduleDialog.show = false
      if (event && event.target.tagName === 'DIV') {
        this.moduleDialog.show = true
        this.moduleDialog.title = this.getModuleDialogTitle()
      }
    },
    getModuleDialogTitle: function () {
      const masterLabel = this.module.master ? ' - ' + this.$t('common.master') : ''
      return this.$t('moduleDialog.title', { moduleModel: this.module.name, slot: this.module.slot }) + masterLabel
    }
  }
}
</script>

<style scoped>
.module__top {
  flex-direction: row;
  padding: 0.875rem 0 1.875rem;
  cursor: pointer;
}
.module__middle {
  padding: 1.5rem 0 0;
  cursor: pointer;
}
.module__bottom {
  padding: 0 0 1.75rem;
  cursor: pointer;
}
.module__left {
  width:55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}
.module__right {
  width:45%;
  display: flex;
  align-items: flex-end;
  align-self: end;
}
.cam {
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.module__number {
  cursor: pointer;
}
</style>
