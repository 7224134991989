<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="8">
        <v-card
          elevation="0"
          class="mr-3"
          color="#F5F5F5"
        >
          <div v-if="reportHasData">
            <InfoStatus />
          </div>
          <span
            v-if="headendHasError"
            class="errorMessage"
          >
            {{ headendErrorMessage }}
          </span>
          <span
            v-if="reportHasError"
            class="errorMessage"
          >
            {{ reportErrorMessage }}
          </span>
        </v-card>
        <v-row
          v-if="reportHasData && isHeadendFirmwareValid"
          class="mt-2 text-center"
        >
          <v-chip
            class="ma-1 pl-8 pr-8"
            label
            outlined
            large
          >
            {{ $t('section.info.chips.maximumTemperature') }}<span class="ml-1">{{ formattedMaxTemp }}</span>
          </v-chip>
          <v-chip
            class="ma-1 pl-8 pr-8"
            label
            outlined
            large
          >
            {{ getFormattedTotalRFServices() }}
          </v-chip>
          <v-chip
            class="ma-1 pl-8 pr-8"
            label
            outlined
            large
          >
            {{ getFormattedTotalIPServices() }}
          </v-chip>
          <v-chip
            class="ma-1 pl-8 pr-8"
            label
            outlined
            large
          >
            {{ getFormattedInputQualityOfService() }}
          </v-chip>
          <v-chip
            class="ma-1 pl-8 pr-8"
            label
            outlined
            large
          >
            {{ getFormattedOutputQualityOfService() }}
          </v-chip>
          <v-chip
            class="ma-1 pl-8 pr-8"
            label
            outlined
            large
          >
            {{ getFormattedCamQualityOfService() }}
          </v-chip>
        </v-row>
      </v-col>
      <v-col>
        <v-card
          class="info-form"
          elevation="0"
          color="#F5F5F5"
        >
          <v-col>
            <v-row dense>
              <v-col cols="3">
                {{ $t('section.info.label.venueId') }}
              </v-col>
              <v-col>
                <v-text-field
                  readonly
                  dense
                  :value="tabcorpVenueId"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3">
                {{ $t('section.info.label.name') }}
              </v-col>
              <v-col>
                <v-text-field
                  readonly
                  dense
                  :value="headend.status.remoteSiteDeviceName"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3">
                {{ $t('section.info.label.systemType') }}
              </v-col>
              <v-col>
                <v-text-field
                  readonly
                  dense
                  :value="getSystemType()"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3">
                {{ $t('section.info.label.headendId') }}
              </v-col>
              <v-col>
                <v-text-field
                  readonly
                  dense
                  :value="headend.id"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3">
                {{ $t('section.info.label.firmware') }}
              </v-col>
              <v-col>
                <v-text-field
                  readonly
                  dense
                  :value="headend.status.firmwareVersion"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3">
                {{ $t('section.info.label.lastConnection') }}
              </v-col>
              <v-col>
                <v-text-field
                  readonly
                  dense
                  :value="headend.status.lastConnection | formatDate($locale, $timezone)"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="3">
                {{ $t('section.info.label.uptime') }}
              </v-col>
              <v-col>
                <v-text-field
                  readonly
                  dense
                  :value="getFormattedUptime()"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-card>
        <v-card
          elevation="0"
          color="#F5F5F5"
          class="info-form mt-3"
        >
          <InfoEditableData />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import { NS_GROUPS, NS_HEADEND, NS_REPORT } from '../store/namespaces'

import InfoEditableData from './info/InfoEditableData.vue'
import InfoStatus from './info/InfoStatus.vue'

export default {
  name: 'Info',
  components: {
    InfoEditableData,
    InfoStatus
  },
  computed: {
    ...mapGetters(NS_GROUPS, ['getGroupNameById']),
    ...mapGetters(NS_HEADEND, ['getHeadend', 'isHeadendFirmwareValid', 'getHeadendErrorMessage']),
    ...mapGetters(NS_REPORT, [
      'getReportConfiguration',
      'getReportStatus',
      'getConfigurationInstallationDataVenueSystemType',
      'getMasterModuleUptime',
      'getTotalRFServices',
      'getTotalIPServices',
      'getInputQualityOfService',
      'getOutputQualityOfService',
      'getCamQualityOfService',
      'getReportErrorMessage'
    ]),
    headend: function () {
      return this.getHeadend
    },
    headendHasError: function () {
      return this.getHeadendErrorMessage !== null
    },
    headendErrorMessage: function () {
      return this.getHeadendErrorMessage
    },
    formattedMaxTemp: function () {
      return this.$t('section.info.formattedTemperature', { temperature: this.headend.status.maxTemp })
    },
    tabcorpVenueId: function () {
      return this.headend.tabcorp !== null
        ? this.headend.tabcorp.status.uniqueVenueID
        : ''
    },
    reportHasData: function () {
      return (this.getReportConfiguration !== null && this.getReportStatus !== null)
    },
    reportHasError: function () {
      return this.getReportErrorMessage !== null
    },
    reportErrorMessage: function () {
      return this.getReportErrorMessage
    }
  },
  methods: {
    getSystemType: function () {
      return this.getConfigurationInstallationDataVenueSystemType
    },
    getFormattedUptime: function () {
      const uptime = this.getMasterModuleUptime
      const seconds = Number(uptime)
      const d = Math.floor(seconds / (3600 * 24))
      const h = Math.floor(seconds % (3600 * 24) / 3600)
      const m = Math.floor(seconds % 3600 / 60)
      const s = Math.floor(seconds % 60)

      const dDisplay = d > 0 ? d + ' ' + (d === 1 ? this.$t('common.date.day') + ', ' : this.$t('common.date.days') + ', ') : ''
      const hDisplay = h > 0 ? h + ' ' + (h === 1 ? this.$t('common.date.hour') + ', ' : this.$t('common.date.hours') + ', ') : ''
      const mDisplay = m > 0 ? m + ' ' + (m === 1 ? this.$t('common.date.minute') + ', ' : this.$t('common.date.minutes') + ', ') : ''
      const sDisplay = s > 0 ? s + ' ' + (s === 1 ? this.$t('common.date.second') : this.$t('common.date.seconds')) : ''
      return dDisplay + hDisplay + mDisplay + sDisplay
    },
    getFormattedTotalRFServices: function () {
      return this.$t('section.info.chips.totalRFServices', { total: this.getTotalRFServices })
    },
    getFormattedTotalIPServices: function () {
      return this.$t('section.info.chips.totalIPServices', { total: this.getTotalIPServices })
    },
    getFormattedInputQualityOfService: function () {
      return this.$t('section.info.chips.inputQualityOfService', { quality: this.getInputQualityOfService })
    },
    getFormattedOutputQualityOfService: function () {
      return this.$t('section.info.chips.outputQualityOfService', { quality: this.getOutputQualityOfService })
    },
    getFormattedCamQualityOfService: function () {
      return this.$t('section.info.chips.camQualityOfService', { quality: this.getCamQualityOfService })
    }
  }
}
</script>

<style>
.info-form {
  font-size: 0.875rem;
}
.info-form .v-input,
.info-form .v-input textarea {
  font-size: 0.875rem;
}
.info-form .row {
  align-items: baseline;
}
.info-form .row + .row--dense {
  margin-top: 0;
}
.errorMessage {
  display: flex;
}
</style>
