<template>
  <v-data-table
    ref="table"
    :headers="headers"
    :items="items"
    :search="filters.search"
    :footer-props="footerProps"
    @current-items="updateFiltered"
  >
    <!-- TOP BAR -->
    <template #top>
      <v-toolbar
        flat
        color="white"
      >
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          :label="$t('label.search')"
          single-line
          hide-details
        />
        <v-spacer />
        <slot />
      </v-toolbar>
    </template>
    <!-- REPORT SCHEDULED DATE -->
    <template #[`item.scheduledDate`]="{ item }">
      {{ item.scheduledDate | formatDate($locale, timeZone) }}
    </template>
    <!-- REPORT STATUS -->
    <template #[`item.status`]="{ item }">
      {{ $t('generatedReports.'+item.status) }}
    </template>
    <!-- SETTINGS -->
    <template #[`item.settings`]="{ item }">
      <!-- DOWNLOAD BUTTON -->
      <v-btn
        v-if="actions.indexOf('download') >= 0"
        text
        icon
        color="primary"
        :disabled="item.status === 'ERROR'"
        :title="$t('generatedReports.download')"
        @click="throwAction('downloadItem', item)"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <!-- DELETE BUTTON -->
      <v-btn
        v-if="actions.indexOf('delete') >= 0"
        text
        icon
        color="primary"
        :title="$t('generatedReports.delete')"
        @click="throwAction('deleteItem', item)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <!-- SELECTIONS HEADER -->
    <template #[`header.selections`]>
      <v-btn
        v-if="selection == 'multiple'"
        text
        icon
        @click="selectUnselectAll()"
      >
        <v-icon
          v-if="areAllSelected()"
          color="primary"
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon v-else-if="isAnySelected()">
          mdi-minus-box-outline
        </v-icon>
        <v-icon v-else>
          mdi-checkbox-blank-outline
        </v-icon>
      </v-btn>
    </template>
    <!-- Selections: cell -->
    <template #[`item.selections`]="{ item }">
      <span v-if="selection != 'none'">
        <v-btn
          v-if="isSelected(item)"
          text
          icon
          @click="deselect(item)"
        >
          <v-icon
            v-if="selection === 'multiple'"
            color="primary"
          >
            mdi-checkbox-marked-outline
          </v-icon>
          <v-icon
            v-else
            color="primary"
          >
            mdi-radiobox-marked
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          text
          icon
          @click="select(item)"
        >
          <v-icon v-if="selection === 'multiple'">
            mdi-checkbox-blank-outline
          </v-icon>
          <v-icon v-else>
            mdi-checkbox-blank-circle-outline
          </v-icon>
        </v-btn>
      </span>
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ReportsGeneratedTable',
  components: {},
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    selection: {
      type: String,
      default: 'none' // single or multiple
    },
    actions: {
      type: Array,
      default: () => [] // Actions types: delete
    }
  },
  data () {
    return {
      selectedItems: {},
      filteredItems: [],
      filters: { search: null },
      possibleHeaders: [
        {
          text: this.$t('generatedReports.scheduledDate'),
          align: 'start',
          sortable: true,
          value: 'scheduledDate'
        },
        {
          text: this.$t('generatedReports.scheduleName'),
          align: 'start',
          sortable: true,
          value: 'scheduleName'
        },
        {
          text: this.$t('generatedReports.templateName'),
          align: 'start',
          sortable: true,
          value: 'templateName'
        },
        {
          text: this.$t('generatedReports.status'),
          align: 'start',
          sortable: true,
          value: 'status'
        }
      ],
      settingsHeader: {
        text: '',
        align: 'end',
        sortable: false,
        value: 'settings'
      },
      selectionsHeader: {
        text: '',
        align: 'end',
        sortable: false,
        value: 'selections'
      },
      footerProps: {
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('generatedReports.perPageText')
      }
    }
  },
  computed: {
    ...mapGetters('customization', ['timeZone']),
    headers: function () {
      const headers = [].concat(this.possibleHeaders)
      if (this.actions.length > 0) {
        headers.push(this.settingsHeader)
      }
      if (this.selection === 'multiple' || this.selection === 'single') {
        headers.push(this.selectionsHeader)
      }
      return headers
    }
  },
  watch: {
    items: function () {
      this.selectedItems = {}
    }
  },
  methods: {
    throwAction: function (action, item) {
      this.$emit(action, item)
    },
    isSelected (item) {
      return this.selectedItems[item.id]
    },
    deselect: function (item) {
      this.$delete(this.selectedItems, item.id)
      this.$emit('input', Object.values(this.selectedItems))
    },
    select: function (item) {
      if (this.selectedItems !== {} && this.selection === 'single') {
        this.selectedItems = {}
      }
      this.$set(this.selectedItems, item.id, item)
      this.$emit('input', Object.values(this.selectedItems))
    },
    selectUnselectAll: function () {
      if (Object.values(this.selectedItems).length > 0) {
        this.selectedItems = {}
      } else {
        this.selectedItems = {}
        this.filteredItems.forEach((item, i) => {
          this.$set(this.selectedItems, item.id, item)
        })
      }
      this.$emit('input', Object.values(this.selectedItems))
    },
    areAllSelected: function () {
      return Object.values(this.selectedItems).length === this.items.length
    },
    isAnySelected: function () {
      return Object.values(this.selectedItems).length > 0
    },
    updateFiltered: function (items) {
      if (this.$refs.table) {
        try {
          this.filteredItems = this.$refs.table.$children[0].filteredItems
        } catch (e) {
          this.filteredItems = items
        }
      } else {
        this.filteredItems = items
      }
    }
  }
}
</script>
