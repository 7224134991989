<template>
  <v-data-table
    ref="table"
    multi-sort
    :headers="headers"
    :items="items"
    :search="filters.search"
    :footer-props="footerProps"
    @current-items="updateFiltered"
  >
    <!-- TOP BAR -->
    <template #top>
      <v-toolbar
        flat
        color="white"
      >
        <v-text-field
          v-model="filters.search"
          append-icon="mdi-magnify"
          :label="$t('label.search')"
          single-line
          hide-details
        />
        <v-spacer />
        <slot />
      </v-toolbar>
    </template>
    <!-- ROLE COLUMN -->
    <template #[`header.roleTranslation`]>
      <v-menu
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          {{ $t('users.role') }}
          <v-btn
            icon
            :color="filters.roles.length ? 'primary' : ''"
            v-on="on"
          >
            <v-icon small>
              mdi-filter-menu
            </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="subtitle-1">
            {{ $t('users.filtersRol') }}
          </v-card-title>
          <v-card-text>
            <v-checkbox
              v-for="item in roles"
              :key="item.value"
              v-model="filters.roles"
              :label="item.name"
              :value="item.id"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="!filters.roles.length"
              text
              @click="filters.roles = []"
            >
              {{ $t('clearFilters') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
    <template #[`item.rol`]="{ item }">
      {{ item.rol }}
    </template>
    <!-- INFORMATION -->
    <template #[`item.information`]="{ item }">
      {{ (item.information && item.information.length > 20) ? item.information.substring(0,20) : item.information }}
      {{ (item.information && item.information.length > 20) ? '...' : '' }}
    </template>
    <!-- SETTINGS -->
    <template #[`item.settings`]="{ item }">
      <!-- EDIT -->
      <v-btn
        v-if="actions.indexOf('edit') >= 0"
        text
        icon
        color="primary"
        :title="$t('users.edit')"
        :disabled="isSameUser(item)"
        @click="throwAction('editItem', item)"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <!-- DELETE BUTTON -->
      <v-btn
        v-if="actions.indexOf('delete') >= 0"
        text
        icon
        color="primary"
        :disabled="isSameUser(item)"
        :title="$t('reportsScheduledTable.delete')"
        @click="throwAction('deleteItem', item)"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <!-- SELECTIONS HEADER -->
    <template #[`header.selections`]>
      <v-btn
        v-if="selection == 'multiple'"
        text
        icon
        @click="selectUnselectAll()"
      >
        <v-icon
          v-if="areAllSelected()"
          color="primary"
        >
          mdi-checkbox-marked-outline
        </v-icon>
        <v-icon v-else-if="isAnySelected()">
          mdi-minus-box-outline
        </v-icon>
        <v-icon v-else>
          mdi-checkbox-blank-outline
        </v-icon>
      </v-btn>
    </template>
    <!-- Selections: cell -->
    <template #[`item.selections`]="{ item }">
      <span v-if="selection != 'none'">
        <v-btn
          v-if="isSelected(item)"
          text
          icon
          @click="deselect(item)"
        >
          <v-icon
            v-if="selection === 'multiple'"
            color="primary"
          >
            mdi-checkbox-marked-outline
          </v-icon>
          <v-icon
            v-else
            color="primary"
          >
            mdi-radiobox-marked
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          text
          icon
          @click="select(item)"
        >
          <v-icon v-if="selection === 'multiple'">
            mdi-checkbox-blank-outline
          </v-icon>
          <v-icon v-else>
            mdi-checkbox-blank-circle-outline
          </v-icon>
        </v-btn>
      </span>
    </template>
  </v-data-table>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'UsersTable',
  components: {},
  props: {
    items: {
      type: Array,
      default: () => []
    }, // The list of headends
    selection: {
      type: String,
      default: 'none' // single or multiple
    },
    actions: {
      type: Array,
      default: () => [] // Actions types: delete
    },
    columns: {
      type: Array,
      default: () => [] // Columns: email, code, firstName, secondName, telephone, information, role
    }
  },
  data () {
    return {
      selectedItems: {},
      filteredItems: [],
      filters: { search: null, roles: [] },
      possibleHeaders: {
        email: {
          text: this.$t('users.email'),
          align: 'start',
          sortable: true,
          value: 'email'
        },
        code: {
          text: this.$t('users.code'),
          align: 'start',
          sortable: true,
          value: 'code'
        },
        firstName: {
          text: this.$t('users.firstName'),
          align: 'start',
          sortable: true,
          value: 'firstName'
        },
        secondName: {
          text: this.$t('users.secondName'),
          align: 'start',
          sortable: true,
          value: 'secondName'
        },
        telephone: {
          text: this.$t('users.telephone'),
          align: 'start',
          sortable: true,
          value: 'telephone'
        },
        information: {
          text: this.$t('users.information'),
          align: 'start',
          sortable: true,
          value: 'information'
        },
        role: {
          text: this.$t('users.role'),
          align: 'start',
          sortable: true,
          value: 'roleTranslation',
          filter: (value, search, item) => {
            if (!this.filters.roles.length) return true
            return this.filters.roles.some(filter => filter === item.role)
          }
        }
      },
      settingsHeader: {
        text: '',
        align: 'end',
        sortable: false,
        value: 'settings'
      },
      selectionsHeader: {
        text: '',
        align: 'end',
        sortable: false,
        value: 'selections'
      },
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('users.perPageText')
      }
    }
  },
  computed: {
    ...mapState({ myAccount: state => state.authentication.account }),
    roles () {
      return this.$store.state.roles.all.map(role => {
        role.name = this.$t('roles.' + role.id)
        return role
      })
    },
    headers: function () {
      const headers = []
      this.columns.forEach(item => {
        headers.push(this.possibleHeaders[item])
      })
      if (this.actions.length > 0) {
        headers.push(this.settingsHeader)
      }
      if (this.selection === 'multiple' || this.selection === 'single') {
        headers.push(this.selectionsHeader)
      }
      return headers
    }
  },
  watch: {
    items: function () {
      this.selectedItems = {}
    }
  },
  mounted () {},
  methods: {
    isSameUser: function (item) {
      return this.myAccount.userId === item.id
    },
    throwAction: function (action, item) {
      this.$emit(action, item)
    },
    isSelected (item) {
      return this.selectedItems[item.id]
    },
    deselect: function (item) {
      this.$delete(this.selectedItems, item.id)
      this.$emit('input', Object.values(this.selectedItems))
    },
    select: function (item) {
      if (this.selectedItems !== {} && this.selection === 'single') {
        this.selectedItems = {}
      }
      this.$set(this.selectedItems, item.id, item)
      this.$emit('input', Object.values(this.selectedItems))
    },
    selectUnselectAll: function () {
      if (Object.values(this.selectedItems).length > 0) {
        this.selectedItems = {}
      } else {
        this.selectedItems = {}
        this.filteredItems.forEach((item, i) => {
          this.$set(this.selectedItems, item.id, item)
        })
      }
      this.$emit('input', Object.values(this.selectedItems))
    },
    areAllSelected: function () {
      return Object.values(this.selectedItems).length === this.items.length
    },
    isAnySelected: function () {
      return Object.values(this.selectedItems).length > 0
    },
    updateFiltered: function (items) {
      if (this.$refs.table) {
        try {
          this.filteredItems = this.$refs.table.$children[0].filteredItems
        } catch (e) {
          this.filteredItems = items
        }
      } else {
        this.filteredItems = items
      }
    }
  }
}
</script>
