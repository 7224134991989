import AuthorizationApi from '@/services/api/AuthorizationApi'

export default {
  get () {
    return AuthorizationApi().get('/account')
  },
  update (firstName, secondName, email, telephone, language, timeZone) {
    const payload = {
      firstName: firstName,
      secondName: secondName,
      email: email,
      telephone: telephone,
      language: language,
      timeZone: timeZone
    }
    return AuthorizationApi().put('/account', payload)
  },
  changePassword (oldPassword, newPassword) {
    const payload = {
      oldPassword: oldPassword,
      newPassword: newPassword
    }
    return AuthorizationApi().put('/account/authentication', payload)
  }
}
