import {
  LANGUAGES_GET,
  LANGUAGES_CLEAR
} from '@/store/actions/languages'
import {
  AUTH_LOGOUT
} from '@/store/actions/authentication'

import languagesApi from '@/services/api/authorization/Languages'

export default {
  state: {
    all: []
  },
  mutations: {
    [LANGUAGES_GET]: (state, data) => {
      state.all = data
    },
    [LANGUAGES_CLEAR]: state => {
      state.all.length = 0
    }
  },
  actions: {
    [LANGUAGES_GET]: ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        if (state.all.length > 0) {
          resolve(state.all)
          return
        }
        languagesApi.get().then((response) => {
          // console.log('[store.languages] Languages lodaded')
          const info = response.data
          commit(LANGUAGES_GET, info)
          resolve(info)
        })
          .catch((error) => {
            // console.error('[store.languages] Error loading languages')
            reject(error)
          })
      })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      commit(LANGUAGES_CLEAR)
    }
  },
  getters: {
  }
}
