<template>
  <a
    class="module__regulator"
    title="Audio"
  />
</template>

<script>
export default {
  name: 'SlotRegulator'
}
</script>

<style scoped>
.module__regulator {
  background-image: url('../../../../assets/images/regulator.svg');
  width: 0.75rem;
  height: 0.75rem;
  background-size: 0.75rem 0.75rem;
  margin-top: auto;
  cursor: auto;
}
</style>
