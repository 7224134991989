<template>
  <div class="module">
    <ModuleDialog
      v-model="moduleDialog.show"
      :title="moduleDialog.title"
      type="module"
      :module="module"
    />
    <v-img
      contain
      src="../../../assets/images/module-header.svg"
    />
    <div
      :class="getClassName"
      @click="showModuleDialog"
    >
      <div class="module__top" />
      <div class="module__middle">
        <SlotElement type="plug" />
        <div class="module__name">
          <a :title="getModelTitle">{{ getModuleModel }}</a>
        </div>
      </div>
      <div class="module__bottom" />
      <div class="module__number">
        <div>{{ module.slot }}</div>
      </div>
    </div>
    <v-img
      contain
      src="../../../assets/images/module-footer.svg"
    />
  </div>
</template>

<script>
import ModuleDialog from '../../common/ModuleDialog.vue'
import SlotElement from './slots/Slot.vue'

export default {
  name: 'ModulePsu',
  components: {
    ModuleDialog,
    SlotElement
  },
  props: {
    module: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      moduleDialog: {
        show: false
      }
    }
  },
  computed: {
    getClassName: function () {
      return 'module__body'
    },
    getModelTitle: function () {
      return this.$t('section.info.model')
    },
    getModuleModel: function () {
      return this.module.model
    }
  },
  methods: {
    showModuleDialog: function () {
      this.moduleDialog.show = true
      this.moduleDialog.title = this.getModuleDialogTitle()
    },
    getModuleDialogTitle: function () {
      return this.$t('moduleDialog.title', { moduleModel: this.module.model, slot: this.module.slot })
    }
  }
}
</script>

<style scoped>
.module__body {
  cursor: pointer;
}
.module__number {
  cursor: pointer;
}
</style>
