<template>
  <v-container fluid>
    <v-col cols="8">
      <v-card>
        <v-card-title class="title">
          {{ $t('section.orders.table.active') }}
        </v-card-title>
        <v-card-text>
          <OrdersActive />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="8">
      <v-card>
        <v-card-title class="title">
          {{ $t('section.orders.table.history') }}
        </v-card-title>
        <v-card-text>
          <OrdersHistory />
        </v-card-text>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
import OrdersActive from './orders/OrdersActive.vue'
import OrdersHistory from './orders/OrdersHistory.vue'

export default {
  name: 'Orders',
  components: {
    OrdersActive,
    OrdersHistory
  }
}
</script>

<style scoped></style>
