<template>
  <v-data-table
    :headers="headers"
    :items="activeOrders"
    :footer-props="footerProps"
  >
    <template #[`item.nextOrderDate`]="{item}">
      {{ item.nextOrderDate | formatDate($locale, $timezone) }}
    </template>
    <template #[`item.userId`]="{item}">
      {{ getUserName(item.userId) }}
    </template>
  </v-data-table>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  NS_AUTHENTICATION,
  NS_USERS,
  NS_ORDER_SCHEDULES
} from '../../store/namespaces'

export default {
  name: 'OrdersActive',
  data () {
    return {
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('section.orders.table.itemsPerPage')
      }
    }
  },
  computed: {
    ...mapGetters(NS_AUTHENTICATION, ['getUserId']),
    ...mapGetters(NS_USERS, ['getUserById']),
    ...mapGetters(NS_ORDER_SCHEDULES, ['getActiveOrders']),
    headers: function () {
      return [
        {
          text: this.$t('section.orders.table.headers.alias'),
          align: 'left',
          value: 'name'
        },
        {
          text: this.$t('section.orders.table.headers.dateScheduled'),
          align: 'left',
          value: 'nextOrderDate'
        },
        {
          text: this.$t('section.orders.table.headers.user'),
          align: 'left',
          value: 'userId'
        }
      ]
    },
    activeOrders: function () {
      return this.getActiveOrders
    }
  },
  methods: {
    getUserName: function (userId) {
      const user = this.getUserById(userId)
      let name = userId
      if (user !== undefined && user !== null) {
        name = user.firstName + ' ' + user.secondName
      }
      return name
    }
  }
}
</script>
