<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        offset-sm="3"
      >
        <v-card :loading="loading">
          <v-card-title>
            {{ $t('forgotPassword.title') }}
          </v-card-title>
          <v-card-subtitle>
            {{ $t('forgotPassword.subtitle') }}
          </v-card-subtitle>
          <v-form @submit.prevent="requestNewPassword">
            <v-card-text>
              <v-container>
                <v-text-field
                  v-model="email"
                  prepend-icon="mdi-email"
                  name="Email"
                  :label="$t('forgotPassword.email')"
                  :error="error.status"
                  :error-messages="error.message"
                  autofocus
                  required
                />
              </v-container>
              <p v-if="okMessage">
                {{ $t('forgotPassword.checkYourEmail') }}
              </p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :title="$t('forgotPassword.cancel')"
                @click="backToLogin"
              >
                {{ $t('forgotPassword.cancel') }}
              </v-btn>
              <v-btn
                type="submit"
                color="primary"
                :title="$t('forgotPassword.request')"
                :disabled="!(loading || !okMessage)"
              >
                {{ $t('forgotPassword.request') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import router from '@/router'
import AuthenticateService from '@/services/auth/AuthenticationService'

export default {
  name: 'ForgotPassword',
  components: {},
  data () {
    return {
      email: '',
      loading: false,
      error: { status: false, message: '' },
      okMessage: false
    }
  },
  methods: {
    requestNewPassword: function () {
      this.loading = true
      AuthenticateService.authenticationLost(this.email)
        .then(() => {
          this.okMessage = true
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          if (error.status === 400) {
            this.error.status = true
            this.error.message = this.$t('forgotPassword.emailNotValid')
          } else if (error.status === 404) {
            this.error.status = true
            this.error.message = this.$t('forgotPassword.emailNotFound')
          } else {
            this.error.status = true
            this.error.message = this.$t('forgotPassword.serverErrorTryLater')
          }
        })
    },
    backToLogin: function () {
      // this.$router.push('/login')
      router.push('/login')
    }
  }
}
</script>
