<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="6"
        md="3"
      >
        <v-select
          v-model="selectedHeadendType"
          :items="headendTypesTranslated"
          :label="$t('headendType')"
          :rules="[rules.required]"
          @change="headendTypeChanged"
        />
      </v-col>
      <v-col
        cols="6"
        md="9"
      >
        <v-select
          v-model="selectedFirmware"
          :items="firmwareSelectList"
          item-text="filename"
          item-value="filename"
          :label="$t('firmwareFile')"
          :rules="[rules.required]"
          return-object
          @change="prepareActionCall"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'LaunchFirmwareOrder',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          type: 'launchFirmware'
        }
      }
    }
  },
  data () {
    return {
      action: {
        type: 'launchFirmware',
        info: {
          id: ''
        }
      },
      rules: {
        required: value => !!value || this.$t('validation.requiredField')
      },
      firmwareSelectList: [],
      selectedHeadendType: undefined,
      selectedFirmware: undefined
    }
  },
  computed: {
    ...mapGetters({ getFirmwareById: 'getFirmwareById' }),
    ...mapState({
      firmwaresByType: state => {
        return state.firmwares.byHeadendType
      },
      headendTypes: state => {
        return state.headendTypes.all
      }
    }),
    headendTypesTranslated: function () {
      const translated = []
      for (const headendType of this.headendTypes) {
        translated.push({
          text: this.$t('deviceType.' + headendType),
          value: headendType
        })
      }
      return translated
    }
  },
  watch: {
    action: {
      handler: function (newData) {
        this.$emit('input', newData)
      },
      deep: true
    },
    headendTypes: function (newVal) {
      if (newVal && newVal.length === 1) {
        this.selectedHeadendType = newVal
      }
    }
  },
  mounted: function () {
    if (this.headendTypes && this.headendTypes.length === 1) {
      this.selectedHeadendType = this.headendTypes[0]
      this.headendTypeChanged(this.selectedHeadendType)
    }
    this.action = {
      type: 'launchFirmware',
      info: {
        id: ''
      }
    }
    if (this.value.info !== undefined && this.value.type === 'launchFirmware') {
      if (this.value.info.id !== undefined && this.value.info.id !== null) {
        this.action.info.id = this.value.info.id
        const firmware = this.getFirmwareById(this.value.info.id)
        this.selectedHeadendType = firmware.type
        this.selectedFirmware = firmware
      }
    }
  },
  methods: {
    headendTypeChanged: function (value) {
      this.firmwareSelectList = this.firmwaresByType.get(value)
    },
    prepareActionCall: function (firmware) {
      this.action = {
        type: 'launchFirmware',
        info: {
          id: firmware.id
        }
      }
    }
  }
}
</script>
