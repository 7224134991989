import Vue from 'vue'
import {
  USERS_GET,
  USERS_GET_ALL,
  USERS_CREATE,
  USERS_UPDATE,
  USERS_DELETE,
  USERS_CLEAR
} from '@/store/actions/users'

import { AUTH_LOGOUT } from '@/store/actions/authentication'

import usersApi from '@/services/api/authorization/Users'

export default {
  state: {
    all: []
  },
  mutations: {
    [USERS_GET_ALL]: (state, data) => {
      state.all = data
    },
    [USERS_CREATE]: (state, data) => {
      state.all.push(data)
    },
    [USERS_UPDATE]: (state, data) => {
      var index = state.all.findIndex(user => user.id === data.id)
      Vue.set(state.all, index, data)
    },
    [USERS_DELETE]: (state, id) => {
      var index = state.all.findIndex(user => user.id === id)
      state.all.splice(index, 1)
    },
    [USERS_CLEAR]: state => {
      state.all.length = 0
    }
  },
  actions: {
    [USERS_GET]: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        usersApi
          .get(id)
          .then(response => {
            // console.log('[store.users] User ' + id + ' lodaded')
            const info = response.data
            resolve(info)
          })
          .catch(error => reject(error))
      })
    },
    [USERS_GET_ALL]: ({ commit, dispatch }) => {
      return new Promise((resolve, reject) => {
        usersApi
          .getAll()
          .then(response => {
            // console.log('[store.users] Users lodaded')
            const info = response.data
            commit(USERS_GET_ALL, info)
            resolve(info)
          })
          .catch(error => {
            // console.error('[store.users] Error loading users')
            if (error.response !== undefined && error.response.status === 401) {
              dispatch(AUTH_LOGOUT)
            }
            reject(error)
          })
      })
    },
    [USERS_CREATE]: ({ commit }, user) => {
      return new Promise((resolve, reject) => {
        usersApi
          .create(user)
          .then(() => {
            // console.log('[store.users] User ' + user.id + ' created')
            commit(USERS_CREATE, user)
            resolve()
          })
          .catch(error => {
            // console.error('[store.users] Error creating user with the id: ' + user.id)
            reject(error)
          })
      })
    },
    [USERS_UPDATE]: ({ commit }, user) => {
      return new Promise((resolve, reject) => {
        usersApi
          .update(user.id, user)
          .then(() => {
            // console.log('[store.users] User ' + user.id + ' updated')
            commit(USERS_UPDATE, user)
            resolve()
          })
          .catch(error => {
            // console.error('[store.users] Error updating the user with the id: ' + user.id)
            reject(error)
          })
      })
    },
    [USERS_DELETE]: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        usersApi
          .delete(id)
          .then(response => {
            // console.log('[store.users] User ' + id + ' deleted')
            commit(USERS_DELETE, id)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      commit(USERS_CLEAR)
    }
  },
  getters: {
    getUserById: state => userId => {
      var index = state.all.findIndex(user => user.id === userId)
      if (index >= 0) {
        return state.all[index]
      }
    }
  }
}
