import Api from '@/services/api/Api'
export default {
  getDummy () {
    return ''
  },
  getVersion () {
    return Api().get('/server/version').then(response => {
      return response.data
    })
  },
  getTime () {
    return Api().get('/server/time').then(response => {
      return response.data
    })
  }
}
