<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-data-table
          multi-sort
          :headers="headers"
          :items="items"
          :search="filters.search"
          :footer-props="footerProps"
          dense
          @current-items="updateFiltered"
        >
          <!-- TOP BAR -->
          <template #[`top`]>
            <v-toolbar
              flat
              color="white"
            >
              <v-text-field
                v-model="filters.search"
                append-icon="mdi-magnify"
                :label="$t('label.search')"
                single-line
                hide-details
              />
              <v-spacer />
              <slot />
            </v-toolbar>
          </template>
          <!-- System Type: header -->
          <template #[`item.headend.skyIt.status.systemType`]="{ item }">
            <span v-if="item.headend">
              {{
                $t('headendSystemType.' + item.headend.skyIt.status.systemType)
              }}
            </span>
          </template>
          <!-- SYSTEM TYPE : cell -->
          <template #[`header.skyIt.status.systemType`]>
            <v-menu
              offset-y
              :close-on-content-click="false"
            >
              <template #activator="{ on }">
                {{ $t('systemType') }}
                <v-btn
                  icon
                  :color="filters.sstype.length ? 'primary' : ''"
                  v-on="on"
                >
                  <v-icon small>
                    mdi-filter-menu
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="subtitle-1">
                  {{ $t('filtersSType') }}
                </v-card-title>
                <v-card-text>
                  <v-checkbox
                    v-for="item in systemTypes"
                    :key="item.value"
                    v-model="filters.sstype"
                    :label="item.text"
                    :value="item.value"
                    color="primary"
                    on-icon="mdi-checkbox-marked-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    dense
                    hide-details
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    :disabled="!filters.sstype.length"
                    text
                    @click="filters.sstype = []"
                  >
                    {{ $t('clearFilters') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </template>
          <!-- Settings: cell -->
          <template #[`item.settings`]="{ item }">
            <!-- DELETE BUTTON -->
            <v-btn
              v-if="showAction('delete')"
              text
              icon
              color="primary"
              :title="$t('installerAssignments.delete')"
              @click="throwAction('deleteItem', item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <!-- SELECTIONS HEADER -->
          <template #[`header.selections`]>
            <v-btn
              v-if="selection == 'multiple'"
              text
              icon
              @click="selectUnselectAll()"
            >
              <v-icon
                v-if="areAllSelected()"
                color="primary"
              >
                mdi-checkbox-marked-outline
              </v-icon>
              <v-icon v-else-if="isAnySelected()">
                mdi-minus-box-outline
              </v-icon>
              <v-icon v-else>
                mdi-checkbox-blank-outline
              </v-icon>
            </v-btn>
          </template>
          <!-- Selections: cell -->
          <template #[`item.selections`]="{ item }">
            <span v-if="selection != 'none'">
              <v-btn
                v-if="isSelected(item)"
                text
                icon
                @click="deselect(item)"
              >
                <v-icon
                  v-if="selection === 'multiple'"
                  color="primary"
                >
                  mdi-checkbox-marked-outline
                </v-icon>
                <v-icon
                  v-else
                  color="primary"
                >
                  mdi-radiobox-marked
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                text
                icon
                @click="select(item)"
              >
                <v-icon v-if="selection === 'multiple'">
                  mdi-checkbox-blank-outline
                </v-icon>
                <v-icon v-else>
                  mdi-checkbox-blank-circle-outline
                </v-icon>
              </v-btn>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import referenceData from '@/mixins/referenceData.js'
export default {
  name: 'AssignmentsTable',
  mixins: [referenceData],
  props: {
    items: {
      type: Array,
      default: () => []
    }, // The list of headends
    selection: {
      type: String,
      default: 'none' // single or multiple
    },
    actions: {
      type: Array,
      default: () => [] // Actions types: delete
    },
    columns: {
      type: Array,
      default: () => [] // Columns to show:
    }
  },
  data () {
    return {
      selectedItems: {},
      filteredItems: [],
      filters: {
        search: null,
        sstype: []
      },
      possibleHeaders: {
        remoteSiteDeviceName: {
          text: this.$t('siteName'),
          align: 'left',
          sortable: true,
          value: 'headend.status.remoteSiteDeviceName'
        },
        contractId: {
          text: this.$t('contractId'),
          align: 'left',
          sortable: true,
          value: 'headend.skyIt.status.contractId'
        },
        id: {
          text: this.$t('id'),
          align: 'left',
          sortable: true,
          value: 'headendId'
        },
        systemType: {
          text: this.$t('systemType'),
          align: 'left',
          sortable: false,
          value: 'headend.skyIt.status.systemType',
          filter: (value, search, item) => {
            if (!this.filters.sstype.length) {
              return true
            }
            for (const i of this.filters.sstype) {
              if (item.skyIt && 'systemType' in item.skyIt.status) {
                if (item.skyIt.status.systemType.includes(i)) {
                  return true
                }
              }
            }
            return false
          }
        },
        venueId: {
          text: this.$t('venueId'),
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'headend.tabcorp.status.uniqueVenueID'
        },
        alias: {
          text: this.$t('alias'),
          align: 'left',
          sortable: true,
          value: 'config.alias'
        },
        settings: {
          text: '',
          align: 'end',
          sortable: false,
          value: 'settings'
        },
        selections: {
          text: '',
          align: 'end',
          sortable: false,
          value: 'selections'
        }
      },
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('installerAssignments.assignmentsPerPageText')
      }
    }
  },
  computed: {
    ...mapState({
      groups: state => {
        return state.groupsNew.all
      }
    }),
    headers: function () {
      const headers = []
      this.columns.forEach(item => {
        headers.push(this.possibleHeaders[item])
      })
      if (this.actions.length > 0) {
        headers.push(this.possibleHeaders.settings)
      }
      if (this.selection === 'multiple' || this.selection === 'single') {
        headers.push(this.possibleHeaders.selections)
      }
      return headers
    }
  },
  watch: {
    items: function () {
      this.selectedItems = {}
    }
  },
  mounted () {},
  methods: {
    showAction: function (action) {
      return this.actions.includes(action)
    },
    throwAction: function (action, item) {
      this.$emit(action, item)
    },
    isSelected (item) {
      return this.selectedItems[item.id]
    },
    deselect: function (item) {
      this.$delete(this.selectedItems, item.id)
      this.$emit('input', Object.values(this.selectedItems))
    },
    select: function (item) {
      if (this.selectedItems !== {} && this.selection === 'single') {
        this.selectedItems = {}
      }
      this.$set(this.selectedItems, item.id, item)
      this.$emit('input', Object.values(this.selectedItems))
    },
    selectUnselectAll: function () {
      if (Object.values(this.selectedItems).length > 0) {
        this.selectedItems = {}
      } else {
        this.selectedItems = {}
        this.filteredItems.forEach((item, i) => {
          this.$set(this.selectedItems, item.id, item)
        })
      }
      this.$emit('input', Object.values(this.selectedItems))
    },
    areAllSelected: function () {
      return Object.values(this.selectedItems).length === this.items.length
    },
    isAnySelected: function () {
      return Object.values(this.selectedItems).length > 0
    },
    updateFiltered: function (filteredItems) {
      this.filteredItems = filteredItems
    }
  }
}
</script>
