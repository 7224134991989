export default {
  $schema: 'http://json-schema.org/draft-07/schema#',
  $id: 'http://ikusi.com/headend/channelMapping/channelMapping.json',
  type: 'object',
  title: 'Channel Mapping Schema',
  required: [
    'modules'
  ],
  additionalProperties: false,
  properties: {
    modules: {
      $id: '#/properties/modules',
      type: 'array',
      minItems: 1,
      title: 'The Modules channel mapping Schema',
      items: {
        $id: '#/properties/modules/items',
        type: 'object',
        title: 'Modules Items Schema',
        required: [
          'services'
        ],
        additionalProperties: false,
        properties: {
          services: {
            $id: '#/properties/modules/items/properties/services',
            type: 'array',
            uniqueItems: true,
            title: 'The module Services Schema',
            items: {
              $id: '#/properties/modules/items/properties/services/items',
              type: 'object',
              title: 'The services Items Schema',
              required: [
                'input',
                'inputServiceId',
                'output',
                'outputServiceId',
                'lcn',
                'decrypt',
                'language',
                'outputName'
              ],
              properties: {
                input: {
                  $id: '#/properties/modules/items/properties/services/items/properties/input',
                  type: 'integer',
                  title: 'The service input RF id',
                  minimum: 1,
                  maximum: 4,
                  description: 'Service RF input ID, from 1 to 4'
                },
                inputServiceId: {
                  $id: '#/properties/modules/items/properties/services/items/properties/inputServiceId',
                  type: 'integer',
                  title: 'The service input SID',
                  minimum: 0,
                  maximum: 65535,
                  description: 'Service RF input service ID, from 1 to 65535'
                },
                output: {
                  $id: '#/properties/modules/items/properties/services/items/properties/output',
                  type: 'integer',
                  title: 'The Output RF ID',
                  minimum: 1,
                  maximum: 4,
                  description: 'Service RF output ID, from 1 to 4'
                },
                outputServiceId: {
                  $id: '#/properties/modules/items/properties/services/items/properties/outputServiceId',
                  type: 'integer',
                  title: 'The service output SID',
                  minimum: 1,
                  maximum: 65535,
                  description: 'Service RF output service ID, from 1 to 65535'
                },
                lcn: {
                  $id: '#/properties/modules/items/properties/services/items/properties/lcn',
                  type: 'integer',
                  title: 'The service LCN',
                  minimum: 1,
                  maximum: 999,
                  description: 'Service output logical channel number, from 1 to 999'
                },
                decrypt: {
                  $id: '#/properties/modules/items/properties/services/items/properties/decrypt',
                  type: 'integer',
                  title: 'Service decrypt',
                  minimum: 0,
                  maximum: 2,
                  description: 'Service decrypt value. 0: No decrypt; > 0 CAM ID'
                },
                language: {
                  $id: '#/properties/modules/items/properties/services/items/properties/language',
                  type: 'string',
                  title: 'Service language',
                  pattern: '[a-z]{3}$',
                  description: 'Service language in ISO 639-3 lower case'
                },
                outputName: {
                  $id: '#/properties/modules/items/properties/services/items/properties/outputName',
                  type: 'string',
                  title: 'Service output name',
                  pattern: '^(.*)$',
                  description: 'Service output name string. Any character except line break'
                }
              }
            }
          }
        }
      }
    }
  }
}
