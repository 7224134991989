<template>
  <v-container fluid>
    <v-row>
      <!-- ALARM TYPES -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <ItemsFilter
          v-model="filter.alarmTypes"
          :title="$t('filterErrors')"
          :items="getAlarmTypes()"
        />
      </v-col>
      <!-- CONNECTION TYPES -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <ItemsFilter
          v-model="filter.connectionTypes"
          :title="$t('filterConnection')"
          :items="connectionTypes"
        />
      </v-col>
      <!-- SYSTEM TYPES -->
      <v-col
        v-if="customizationType === 'skyit'"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <ItemsFilter
          v-model="filter.systemTypes"
          :title="$t('filterSType')"
          :items="systemTypes"
        />
      </v-col>
      <!-- DRM TYPES -->
      <v-col
        v-if="customizationType === 'skyit'"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <ItemsFilter
          v-model="filter.drmTypes"
          :title="$t('filterDRMs')"
          :items="drmTypes"
        />
      </v-col>
      <!-- HEADEND TYPES -->
      <v-col
        v-if="customizationType === 'skyit'"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <ItemsFilter
          v-model="filter.headendTypes"
          :title="$t('filterHeadendType')"
          :items="headendTypesFilter"
        />
      </v-col>
      <!-- GROUPS -->
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <ItemsFilter
          v-model="filter.groups"
          :title="$t('filterGroups')"
          :items="groups"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ItemsFilter from '@/views/reportOnDemand/components/ItemsFilter.vue'
import { mapState } from 'vuex'
import referenceData from '@/mixins/referenceData.js'
export default {
  name: 'ReportsFilter',
  components: {
    ItemsFilter
  },
  mixins: [referenceData],
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          alarmTypes: [],
          connectionTypes: [],
          systemTypes: [],
          drmTypes: [],
          headendTypes: [],
          groups: []
        }
      }
    }
  },
  data () {
    return {
      filter: {
        alarmTypes: [],
        connectionTypes: [],
        systemTypes: [],
        drmTypes: [],
        headendTypes: [],
        groups: []
      }
    }
  },
  computed: {
    ...mapState({
      headendTypes: state => {
        return state.headendTypes.all
      },
      groups: function (state) {
        const groups = state.groupsNew.all.map(group => {
          return { value: group.id, text: group.name }
        })
        const noGroup = {
          id: 'WITHOUT',
          name: this.$t('filterNoGroups')
        }
        groups.unshift({
          value: noGroup.id,
          text: noGroup.name
        })
        return groups
      },
      customizationType: function () {
        return this.$store.getters['customization/customizationType']
      }
    }),
    headendTypesFilter: function () {
      return this.headendTypes.map(headendType => {
        return {
          value: headendType,
          text: this.$t('deviceType.' + headendType)
        }
      })
    }
  },
  watch: {
    value: function (newValue) {
      this.filter = newValue
    },
    filter: {
      handler: function (newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    }
  },
  mounted () {
    // Tooks the values from mixin referenceData and adds no item values
    this.drmTypes.unshift({
      value: 'WITHOUT',
      text: this.$t('noDrm')
    })
    this.alarmTypes.unshift({
      value: 'WITHOUT',
      text: this.$t('alarmList.noAlarms')
    })
  },
  methods: {
    resetFilters: function () {
      this.filter = {
        alarmTypes: [],
        connectionTypes: [],
        systemTypes: [],
        drmTypes: [],
        headendTypes: [],
        groups: []
      }
    },
    getAlarmTypes: function () {
      if (this.customizationType === 'tabcorp') {
        return this.tabcorpAlarmTypes
      }
      return this.alarmTypes
    }
  }
}
</script>
