import {
  ACCOUNT_GET,
  ACCOUNT_UPDATE,
  ACCOUNT_CHANGE_PASSWORD,
  ACCOUNT_CLEAR
} from '@/store/actions/account'
import {
  CUSTOMIZATION_LANGUAGE,
  CUSTOMIZATION_TIME_ZONE
} from '@/store/actions/customization'

import { AUTH_LOGOUT } from '@/store/actions/authentication'

import accountApi from '@/services/api/authorization/Account'

export default {
  namespaced: true,
  state: {
    account: undefined
  },
  mutations: {
    [ACCOUNT_GET]: (state, data) => {
      state.account = data
    },
    [ACCOUNT_CLEAR]: state => {
      state.account = undefined
    }
  },
  actions: {
    [ACCOUNT_GET]: ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        accountApi
          .get()
          .then(response => {
            const info = response.data
            const account = new Account(
              info.id,
              info.code,
              info.firstName,
              info.secondName,
              info.email,
              info.telephone,
              info.language,
              info.timeZone,
              info.role
            )
            commit(ACCOUNT_GET, account)
            resolve(account)
          })
          .catch(error => reject(error))
      })
    },
    [ACCOUNT_UPDATE]: ({ commit, dispatch }, account) => {
      return new Promise((resolve, reject) => {
        accountApi
          .update(
            account.firstName,
            account.secondName,
            account.email,
            account.telephone,
            account.language,
            account.timeZone
          )
          .then(response => {
            dispatch(
              'customization/' + CUSTOMIZATION_LANGUAGE,
              account.language,
              { root: true }
            )
            dispatch(
              'customization/' + CUSTOMIZATION_TIME_ZONE,
              account.timeZone,
              { root: true }
            )
            commit(ACCOUNT_GET, account)
            resolve(response.data)
          })
          .catch(err => reject(err))
      })
    },
    [ACCOUNT_CHANGE_PASSWORD]: ({ commit }, changePasswordData) => {
      return accountApi.changePassword(
        changePasswordData.oldPassword,
        changePasswordData.newPassword
      )
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      commit(ACCOUNT_CLEAR)
    }
  },
  getters: {}
}

class Account {
  constructor (
    id,
    code,
    firstName,
    secondName,
    email,
    telephone,
    language,
    timeZone,
    role
  ) {
    this.id = id
    this.code = code
    this.firstName = firstName
    this.secondName = secondName
    this.email = email
    this.telephone = telephone
    this.language = language
    this.timeZone = timeZone
    this.role = role
  }
}
