<template>
  <v-container fluid />
</template>
<script>
export default {
  name: 'RebootOrder',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          type: 'reboot'
        }
      }
    }
  },
  data () {
    return {
      action: {
        type: 'reboot'
      }
    }
  },
  watch: {
    action: {
      handler: function (newData) {
        this.$emit('input', newData)
      },
      deep: true
    }
  },
  mounted: function () {
    this.action = {
      type: 'reboot'
    }
  }
}
</script>
