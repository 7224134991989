<template>
  <div>
    <v-tabs
      v-model="tab"
      background-color="primary"
      dark
    >
      <v-tab key="authorization">
        {{ $t("apiDoc.authorization") }}
      </v-tab>
      <v-tab
        v-if="$showPrivateApis"
        key="clientsRoles"
      >
        {{ $t("apiDoc.clientsRoles") }}
      </v-tab>
      <v-tab key="fleets">
        {{ $t("apiDoc.deviceFleet") }}
      </v-tab>
      <v-tab
        v-if="$isTabcorp || $showPrivateApis"
        key="tabcorp"
      >
        {{ $t("apiDoc.tabcorp") }}
      </v-tab>
      <v-tab
        v-if="$isSky || $showPrivateApis"
        key="skyit"
      >
        {{ $t("apiDoc.skyit") }}
      </v-tab>
      <v-tab key="reports">
        {{ $t("apiDoc.reports") }}
      </v-tab>
      <v-tab key="orders">
        {{ $t("apiDoc.orders") }}
      </v-tab>
      <v-tab
        v-if="$showPrivateApis"
        key="pushApi"
      >
        {{ $t("apiDoc.pushApi") }}
      </v-tab>
      <v-tab
        v-if="$showPrivateApis"
        key="equipmentApi"
      >
        {{ $t("apiDoc.equipmentApi") }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="authorization">
        <rapi-doc
          spec-url="/swagger/authorization.yaml"
          show-header="false"
          show-info="true"
          allow-authentication="true"
          allow-server-selection="false"
          allow-api-list-style-selection="false"
          render-style="read"
          style="height:calc(100vh - 150px); width:100%"
        />
      </v-tab-item>
      <v-tab-item
        v-if="$showPrivateApis"
        key="clientsRoles"
      >
        <rapi-doc
          spec-url="/swagger/authorization_internal.yaml"
          show-header="false"
          show-info="true"
          allow-authentication="true"
          allow-server-selection="false"
          allow-api-list-style-selection="false"
          render-style="read"
          style="height:calc(100vh - 150px); width:100%"
        />
      </v-tab-item>
      <v-tab-item key="fleets">
        <rapi-doc
          spec-url="/swagger/fleets.yaml"
          show-header="false"
          show-info="true"
          allow-authentication="true"
          allow-server-selection="false"
          allow-api-list-style-selection="false"
          render-style="read"
          style="height:calc(100vh - 150px); width:100%"
        />
      </v-tab-item>
      <v-tab-item
        v-if="$isTabcorp || $showPrivateApis"
        key="tabcorp"
      >
        <rapi-doc
          spec-url="/swagger/fleets_tabcorp.yaml"
          show-header="false"
          show-info="true"
          allow-authentication="true"
          allow-server-selection="false"
          allow-api-list-style-selection="false"
          render-style="read"
          style="height:calc(100vh - 150px); width:100%"
        />
      </v-tab-item>
      <v-tab-item
        v-if="$isSky || $showPrivateApis"
        key="skyit"
      >
        <rapi-doc
          spec-url="/swagger/fleets_skyit.yaml"
          show-header="false"
          show-info="true"
          allow-authentication="true"
          allow-server-selection="false"
          allow-api-list-style-selection="false"
          render-style="read"
          style="height:calc(100vh - 150px); width:100%"
        />
      </v-tab-item>
      <v-tab-item key="reports">
        <rapi-doc
          spec-url="/swagger/reports.yaml"
          show-header="false"
          show-info="true"
          allow-authentication="true"
          allow-server-selection="false"
          allow-api-list-style-selection="false"
          render-style="read"
          style="height:calc(100vh - 150px); width:100%"
        />
      </v-tab-item>
      <v-tab-item key="orders">
        <rapi-doc
          spec-url="/swagger/orders.yaml"
          show-header="false"
          show-info="true"
          allow-authentication="true"
          allow-server-selection="false"
          allow-api-list-style-selection="false"
          render-style="read"
          style="height:calc(100vh - 150px); width:100%"
        />
      </v-tab-item>
      <v-tab-item
        v-if="$showPrivateApis"
        key="pushApi"
      >
        <rapi-doc
          spec-url="/swagger/fleets_devices.yaml"
          show-header="false"
          show-info="true"
          allow-authentication="false"
          allow-server-selection="false"
          allow-api-list-style-selection="false"
          render-style="read"
          style="height:calc(100vh - 150px); width:100%"
        />
      </v-tab-item>
      <v-tab-item
        v-if="$showPrivateApis"
        key="equipmentApi"
      >
        <rapi-doc
          spec-url="/swagger/equipment.yaml"
          show-header="false"
          show-info="true"
          allow-authentication="false"
          allow-server-selection="false"
          allow-api-list-style-selection="false"
          render-style="read"
          style="height:calc(100vh - 150px); width:100%"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>

import 'rapidoc'

export default {
  name: 'ApiDoc',
  components: {},
  data () {
    return {
      tab: 'authorization'
    }
  },
  methods: {

  }
}
</script>
