<template>
  <v-card tile>
    <v-toolbar
      flat
      dark
      color="primary"
    >
      <v-btn
        icon
        dark
        @click="throwAction('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ $t('installerAssignments.addTitle') }}
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step step="1">
                {{ $t('installerAssignments.addInfo') }}
              </v-stepper-step>
              <v-stepper-step step="2">
                {{ $t('installerAssignments.preview') }}
              </v-stepper-step>
              <v-stepper-step step="3">
                {{ $t('installerAssignments.saving') }}
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card>
                  <v-card-title>
                    {{ $t('installerAssignments.insertAListOfContractsIds') }}
                  </v-card-title>
                  <v-card-text>
                    {{ $t('installerAssignments.descriptionToInsertData') }}
                    <v-textarea
                      ref="comment"
                      v-model="contractsIds"
                      outlined
                      no-resize
                      rows="10"
                      class="mb4"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="
                        nextStep(2)
                        parseData()
                      "
                    >
                      {{ $t('installerAssignments.continue') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card>
                  <v-card-title>
                    {{ $t('installerAssignments.detectedElements') }}
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table
                      dense
                      height="350px"
                    >
                      <template #default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t('installerAssignments.clientHeadendId') }}
                            </th>
                            <th class="text-left">
                              {{ $t('installerAssignments.headendId') }}
                            </th>
                            <th class="text-left">
                              {{ $t('installerAssignments.headendName') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in preProcessedData"
                            :key="item.id"
                          >
                            <td>{{ item.clientHeadendId }}</td>
                            <td>{{ item.headendId }}</td>
                            <td>{{ item.name }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="nextStep(1)"
                    >
                      {{ $t('installerAssignments.back') }}
                    </v-btn>
                    <v-btn
                      type="submit"
                      color="primary"
                      :disabled="preProcessedData.length === 0"
                      @click="save"
                    >
                      {{ $t('button.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card>
                  <v-card-title>
                    {{ $t('installerAssignments.saving') }}
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table
                      dense
                      height="350px"
                    >
                      <template #default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t('installerAssignments.clientHeadendId') }}
                            </th>
                            <th class="text-left">
                              {{ $t('installerAssignments.headendId') }}
                            </th>
                            <th class="text-left">
                              {{ $t('installerAssignments.headendName') }}
                            </th>
                            <th class="text-left">
                              {{ $t('installerAssignments.state') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in savedData"
                            :key="item.id"
                          >
                            <td>{{ item.clientHeadendId }}</td>
                            <td>{{ item.headendId }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.state }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        @click="throwAction('close')"
      >
        {{ $t('button.close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { uuid } from 'vue-uuid'
import { ASSIGNMENTS_CREATE } from '@/store/actions/assignments'

export default {
  name: 'AddInstallerAssignments',
  components: {},
  props: {
    installer: {
      type: Object,
      required: true,
      default: () => {}
    },
    load: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      step: 1,
      contractsIds: '',
      preProcessedData: [],
      savedData: []
    }
  },
  computed: {
    ...mapGetters({
      getGroupById: 'getGroupById',
      getHeadendByContractId: 'getHeadendByContractId',
      getHeadendByVenueId: 'getHeadendByVenueId',
      getHeadendById: 'getHeadendById'
    }),
    ...mapState({
      headends: state => {
        return state.headendsNew.all
      }
    })
  },
  watch: {
    load: function (val) {
      if (val === true) {
        this.step = 1
        this.contractsIds = ''
        this.preProcessedData = []
      }
    }
  },
  mounted () {},
  methods: {
    throwAction: function (action, item) {
      this.$emit(action, item)
    },
    nextStep: function (nextStep) {
      this.step = nextStep
    },
    parseData: function () {
      let items = this.contractsIds.split('\n')
      items = [...new Set(items)]
      items = items.map(item => item.trim())
      items = items.filter(item => item !== '')
      this.preProcessedData = []
      items.forEach(item => {
        let headend
        if (this.$isSky) {
          headend = this.getHeadendByContractId(item)
        }
        if (this.$isTabcorp) {
          headend = this.getHeadendByVenueId(item)
        }
        if (headend === undefined) {
          headend = this.getHeadendById(item)
          if (headend !== undefined) {
            this.preProcessedData.push({
              id: uuid.v4(),
              userId: this.installer.id,
              headendId: headend.id,
              name: headend.status.remoteSiteDeviceName
            })
          }
        } else {
          this.preProcessedData.push({
            id: uuid.v4(),
            userId: this.installer.id,
            clientHeadendId: item,
            headendId: headend.id,
            name: headend.status.remoteSiteDeviceName
          })
        }
      })
    },
    save: function () {
      this.nextStep(3)
      this.savedData = []
      this.preProcessedData.forEach(item => {
        this.$store
          .dispatch(ASSIGNMENTS_CREATE, item)
          .then(() => {
            item.state = this.$t('installerAssignments.ok')
            this.savedData.push(item)
          })
          .catch(err => {
            if (err.status === 400) {
              item.state = this.$t('installerAssignments.duplicated')
            } else {
              item.state = this.$t('installerAssignments.error')
            }
            this.savedData.push(item)
          })
      })
    }
  }
}
</script>
