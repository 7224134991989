<template>
  <v-card>
    <v-card-title>
      <v-icon
        v-if="!isOffline"
        class="ma-2"
        :color="connectionColor"
        :title="connectionTitle"
      >
        mdi-server-network
      </v-icon>
      <v-icon
        v-if="isOffline"
        class="ma-2"
        :title="$t('offline')"
      >
        mdi-server-network-off
      </v-icon>
      {{ headendInfoTittle }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <!-- VENUE ID -->
        <v-col
          v-if="showing.venueId"
          cols="6"
          md="4"
        >
          <v-text-field
            v-model="mutableHeadend.tabcorp.status.uniqueVenueID"
            :label="$t('venueId')"
            readonly
          />
        </v-col>
        <!-- CONTRACT ID -->
        <v-col
          v-if="showing.contractId && hasRights('flt:mhe')"
          cols="6"
          md="4"
        >
          <v-text-field
            v-model="mutableHeadend.skyIt.status.contractId"
            :label="$t('contractId')"
            readonly
          >
            <template #append-outer>
              <v-icon
                v-if="showing.edit"
                :disabled="headend.status.connection !== 'online'"
                color="primary"
                :title="$t('headendAction.edit')"
                @click.stop="editHeadendInfo"
              >
                mdi-pencil
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
        <!-- REMOTE SITE NAME -->
        <v-col
          v-if="showing.remoteSiteDeviceName"
          cols="6"
          md="4"
        >
          <v-text-field
            v-model="mutableHeadend.status.remoteSiteDeviceName"
            :label="$t('name')"
            readonly
          />
        </v-col>
        <!-- HEADEND ID -->
        <v-col
          v-if="showing.headendId"
          cols="6"
          md="4"
        >
          <v-text-field
            v-model="mutableHeadend.id"
            :label="$t('headendId')"
            readonly
          />
        </v-col>
        <!-- FIRMWARE VERSION -->
        <v-col
          v-if="showing.firmwareVersion"
          cols="6"
          md="4"
        >
          <v-text-field
            v-model="mutableHeadend.status.firmwareVersion"
            :label="$t('swVersion')"
            readonly
          />
        </v-col>
        <!-- SYSTEM TYPE -->
        <v-col
          v-if="showing.systemType"
          cols="6"
          md="4"
        >
          <v-text-field
            :label="$t('systemType')"
            :value="$t('headendSystemType.' + mutableHeadend.skyIt.status.systemType)"
            readonly
          />
        </v-col>
        <!-- Installer -->
        <v-col
          v-if="showing.installerCode"
          cols="6"
          md="4"
        >
          <v-text-field
            :label="$t('installer')"
            :value="mutableHeadend.skyIt.status.installerCode"
            readonly
          />
        </v-col>
        <!-- LAST CONNECTION -->
        <v-col
          v-if="showing.lastConnection"
          cols="6"
          md="4"
        >
          <v-text-field
            :value="
              headend.status.lastConnection | formatDate($locale, timeZone)
            "
            :label="$t('lastConnection')"
            readonly
          />
        </v-col>
        <!-- REMOTE SITE CONNECTION -->
        <v-col
          v-if="showing.remoteSiteLocation"
          cols="6"
          xl="4"
          md="8"
        >
          <v-text-field
            v-model="mutableHeadend.status.remoteSiteLocation"
            :label="$t('location')"
            readonly
          />
        </v-col>
        <!-- Province -->
        <v-col
          v-if="showing.province"
          cols="6"
          xl="4"
          md="4"
        >
          <v-text-field
            :label="$t('province')"
            :value="mutableHeadend.skyIt.status.province"
            readonly
          />
        </v-col>
        <!-- REMOTE SITE DESCRIPTION -->
        <v-col
          v-if="showing.remoteSiteDescription"
          cols="6"
          xl="4"
          md="6"
        >
          <v-text-field
            v-model="mutableHeadend.status.remoteSiteDescription"
            :label="$t('description')"
            readonly
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="showEditDialog"
      max-width="750"
    >
      <HeadendInfoEdit
        :headend="headend"
        @close="showEditDialog = false"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import HeadendInfoEdit from '@/views/headend/components/HeadendInfoEdit.vue'
export default {
  name: 'HeadendInfo',
  components: { HeadendInfoEdit },
  props: {
    headend: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showing: {
        venueId: false,
        contractId: false,
        headendId: false,
        firmwareVersion: true,
        installerCode: false,
        systemType: false,
        lastConnection: true,
        remoteSiteDeviceName: false,
        remoteSiteLocation: false,
        province: false,
        remoteSiteDescription: false,
        actions: true,
        edit: true
      },
      showEditDialog: false
    }
  },
  computed: {
    ...mapGetters('customization', ['timeZone']),
    ...mapGetters(['hasRights']),
    headendInfoTittle: function () {
      /* if (this.$isSky) {
        return this.headend.status.remoteSiteDeviceName
      }
      if (this.$isTabcorp) {
        return this.headend.status.remoteSiteDeviceName
      } */
      return this.headend.status.remoteSiteDeviceName
    },
    isOffline: function () {
      return this.headend.status.connection === 'offline'
    },
    connectionColor: function () {
      if (this.headend.status.connection === 'online') {
        return 'success'
      } else {
        return 'warning'
      }
    },
    connectionTitle: function () {
      if (this.headend.status.connection === 'online') {
        return this.$t('online')
      } else {
        return this.$t('connecting')
      }
    },
    mutableHeadend: function () {
      const clone = JSON.parse(JSON.stringify(this.headend))
      if (this.$isTabcorp) {
        if (!clone.tabcorp) {
          clone.tabcorp = {
            status: {
              uniqueVenueID: '-'
            }
          }
        }
      }
      if (this.$isSky) {
        if (!clone.skyIt) {
          clone.skyIt = {
            status: {
              contractId: '',
              systemType: 'undefined'
            }
          }
        }
      }
      return clone
    }
  },
  mounted () {
    if (this.$isTabcorp) {
      this.showing.headendId = true
      this.showing.venueId = true
      this.showing.remoteSiteDeviceName = true
    } else if (this.$isSky) {
      this.showing.headendId = true
      this.showing.contractId = true
      this.showing.systemType = true
      this.showing.remoteSiteDeviceName = false
      this.showing.remoteSiteLocation = true
      this.showing.remoteSiteDescription = true
      this.showing.province = true
      this.showing.installerCode = true
    } else {
      this.showing.headendId = true
      this.showing.remoteSiteDeviceName = true
      this.showing.remoteSiteLocation = true
      this.showing.remoteSiteDescription = true
    }
  },
  methods: {
    editHeadendInfo: function () {
      this.showEditDialog = true
    }
  }
}
</script>
