<template>
  <v-snackbar
    v-model="show"
    :color="color"
    :timeout="timeout"
    absolute
    top
    style="z-index:9999;"
  >
    <span id="just-line-break">{{ message }}</span>
    <template #action="{ attrs }">
      <v-btn
        dark
        text
        icon
        v-bind="attrs"
        :title="$t('common.button.close')"
        @click="show = false"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>

import {
  NS_SNACKBAR
} from '../../store/namespaces'

import {
  SNACKBAR_RESET
} from '../../store/actions/snackbar'

export default {
  data () {
    return {
      show: false,
      color: '',
      message: '',
      timeout: null
    }
  },
  created: function () {
    this.$store.watch(state => state.hcpSnackbar.message, () => {
      if (this.$store.state.hcpSnackbar.message !== '') {
        this.show = true
        this.color = this.$store.state.hcpSnackbar.color
        this.timeout = this.$store.state.hcpSnackbar.timeout
        this.message = this.$store.state.hcpSnackbar.message

        setTimeout(() => {
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_RESET)
        }, 500)
      }
    })
  }
}
</script>
<style scoped>
#just-line-break {
  white-space: pre-line;
}
</style>
