<template>
  <v-container fluid>
    <v-data-table
      ref="table"
      multi-sort
      :headers="headers"
      :items="items"
      :search="filters.search"
      :footer-props="footerProps"
      dense
      @current-items="updateFiltered"
    >
      <!-- TOP SEARCH BAR -->
      <template #[`top`]>
        <v-toolbar
          flat
          color="white"
        >
          <v-text-field
            v-model="filters.search"
            append-icon="mdi-magnify"
            :label="$t('section.logs.search')"
            single-line
            hide-details
          />
          <v-spacer />
          <slot />
        </v-toolbar>
      </template>
      <!-- Category: header -->
      <template #[`header.level`]="{ header }">
        {{ header.text }}
        <v-menu
          offset-y
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn
              icon
              :color="filters.category.length ? 'primary' : ''"
              v-on="on"
            >
              <v-icon small>
                mdi-filter-menu
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="subheading font-weight-bold">
              {{ $t('section.logs.table.filters.category') }}
            </v-card-title>
            <v-card-text>
              <v-checkbox
                v-model="filters.category"
                value="ERROR"
                color="primary"
                on-icon="mdi-checkbox-marked-outline"
                off-icon="mdi-checkbox-blank-outline"
                dense
                hide-details
              >
                <template #label>
                  {{ $t('section.logs.table.filters.categories.error') }}
                </template>
              </v-checkbox>
              <v-checkbox
                v-model="filters.category"
                value="WARNING"
                color="primary"
                on-icon="mdi-checkbox-marked-outline"
                off-icon="mdi-checkbox-blank-outline"
                dense
                hide-details
              >
                <template #label>
                  {{ $t('section.logs.table.filters.categories.warning') }}
                </template>
              </v-checkbox>
              <v-checkbox
                v-model="filters.category"
                value="NOTICE"
                color="primary"
                on-icon="mdi-checkbox-marked-outline"
                off-icon="mdi-checkbox-blank-outline"
                dense
                hide-details
              >
                <template #label>
                  {{ $t('section.logs.table.filters.categories.notice') }}
                </template>
              </v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :disabled="!filters.category.length"
                text
                @click="filters.category = []"
              >
                {{ $t('clearFilters') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
      <!-- Date/Time: cell -->
      <template #[`item.timestamp`]="{ item }">
        {{ getDateTime(item.timestamp) | formatDate($locale, $timezone) }}
      </template>
      <!-- Source: header -->
      <template #[`header.source`]="{ header }">
        {{ header.text }}
        <v-menu
          offset-y
          :close-on-content-click="false"
        >
          <template #activator="{ on }">
            <v-btn
              icon
              :color="filters.source.length ? 'primary' : ''"
              v-on="on"
            >
              <v-icon small>
                mdi-filter-menu
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="subheading font-weight-bold">
              {{ $t('section.logs.table.filters.source') }}
            </v-card-title>
            <v-card-text>
              <v-checkbox
                v-for="source in getSources"
                :key="source"
                v-model="filters.source"
                :value="source"
                color="primary"
                on-icon="mdi-checkbox-marked-outline"
                off-icon="mdi-checkbox-blank-outline"
                dense
                hide-details
              >
                <template #label>
                  {{ source }}
                </template>
              </v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :disabled="!filters.source.length"
                text
                @click="filters.source = []"
              >
                {{ $t('clearFilters') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>
    </v-data-table>
    <v-divider class="mt-8 mb-8" />
    <v-row class="document__buttons">
      <v-btn
        color="primary"
        @click="refreshData"
      >
        <v-icon left>
          mdi-refresh
        </v-icon>
        {{ $t('section.logs.buttons.refresh') }}
      </v-btn>
      <v-btn
        color="primary"
        @click="downloadLogs"
      >
        <v-icon left>
          mdi-tray-arrow-down
        </v-icon>
        {{ $t('section.logs.buttons.download') }}
      </v-btn>
      <v-btn
        color="primary"
        @click="deleteLogs"
      >
        <v-icon left>
          mdi-delete
        </v-icon>
        {{ $t('section.logs.buttons.clear') }}
      </v-btn>
    </v-row>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { NS_LOGS } from '../../store/namespaces'

const initLogsTableFilter = {
  search: '',
  category: [],
  source: []
}

export default Vue.extend({
  name: 'LogsTable',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedItems: {},
      filteredItems: [],
      filters: initLogsTableFilter,
      possibleHeaders: {
        category: {
          text: this.$t('section.logs.table.headers.category'),
          align: 'left',
          sortable: true,
          value: 'level',
          width: '10%',
          filter: (value, search, item) => {
            if (!this.filters.category.length) {
              return true
            }
            for (const i of this.filters.category) {
              if (item.level === i) {
                return true
              }
            }
            return false
          }
        },
        dateTime: {
          text: this.$t('section.logs.table.headers.dateTime'),
          align: 'left',
          sortable: true,
          value: 'timestamp',
          width: '15%'
        },
        source: {
          text: this.$t('section.logs.table.headers.source'),
          align: 'left',
          sortable: true,
          value: 'source',
          width: '15%',
          filter: (value, search, item) => {
            if (!this.filters.source.length) {
              return true
            }
            for (const i of this.filters.source) {
              if (item.source === i) {
                return true
              }
            }
            return false
          }
        },
        message: {
          text: this.$t('section.logs.table.headers.message'),
          align: 'left',
          sortable: true,
          value: 'message'
        }
      },
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [50, 150, 250, 500, -1],
        itemsPerPageText: this.$t('section.logs.table.itemsPerPage')
      }
    }
  },
  computed: {
    ...mapGetters(NS_LOGS, ['getSources']),
    headers () {
      const headers = []
      this.columns.forEach(item => {
        headers.push(this.possibleHeaders[item])
      })
      return headers
    }
  },
  watch: {
    items: function (newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.selectedItems = {}
        return
      }
      for (let i = 0; i < newVal.length; i++) {
        if (newVal[i].id !== oldVal[i].id) {
          this.selectedItems = {}
          return
        }
      }
    }
  },
  methods: {
    updateFiltered: function (items) {
      if (this.$refs.table) {
        try {
          this.filteredItems = this.$refs.table.$children[0].filteredItems
        } catch (e) {
          this.filteredItems = items
        }
      } else {
        this.filteredItems = items
      }
    },
    getDateTime (timestamp) {
      const dateTime = new Date(timestamp * 1000)
      return dateTime
    },
    refreshData: function () {
      this.$emit('refreshData')
    },
    downloadLogs: function () {
      this.$emit('downloadLogs', this.filteredItems)
    },
    deleteLogs: function () {
      this.$emit('deleteLogs')
    }
  }
})
</script>
