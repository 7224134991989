<template>
  <v-container fluid>
    <HeadendEditDialog
      v-model="dialogs.showEditDialog"
      :item="accessedItem"
      @confirmed="updateHeadend"
    />

    <ConfirmDialog
      v-model="dialogs.showDeleteConfirmDialog"
      :item-id="accessedItem.id"
      :title="$t('headendDeleteTitle')"
      :message="$t('headendDeleteText')"
      @confirmed="deleteHeadend"
    />
    <ConfirmDialog
      v-model="dialogs.showDisconnectConfirmDialog"
      :item-id="accessedItem.id"
      :title="$t('headendDisconnectTitle')"
      :message="$t('headendDisconnectText')"
      @confirmed="disconnectHeadend"
    />
    <v-card :flat="true">
      <v-card-title>
        {{ $t('headendManager') }}
      </v-card-title>
      <v-card-text>
        <transition
          name="scroll-x-transition"
        >
          <HeadendsAlerts
            @alarmTypeSelected="applyAlarmFilter"
            @cancelErrorAlarms="cancelAlarmFilter"
          />
        </transition>
        <HeadendsTable
          :items="headendList"
          :columns="headendsColumns()"
          :actions="headendsActions()"
          selection="none"
          :alerts-filter="applyErrorFilter"
          @viewItem="viewHeadend"
          @editItem="editHeadend"
          @accessItem="accessHeadend"
          @download="downloadHeadendInfo"
          @deleteOfflineItem="confirmDeleteHeadend"
          @deleteItem="confirmDeleteHeadend"
          @accessLynk="accessLynk"
          @disconnectItem="confirmDisconnectHeadend"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { HEADENDS_INIT, HEADENDS_PAUSE, HEADENDS_DELETE, HEADENDS_UPDATE, HEADENDS_DISCONNECT } from '@/store/actions/headends'
import { GROUPS_GET_ALL } from '@/store/actions/groups'
import DevicesService from '@/services/fleets/DevicesService'
import HeadendsAlerts from '@/views/headends/components/HeadendsAlerts.vue'
import HeadendsTable from '@/views/headends/components/HeadendsTable.vue'
import HeadendEditDialog from '@/views/headends/components/HeadendEditDialog.vue'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import headends from '@/mixins/headends.js'

export default {
  name: 'Headends',
  components: {
    HeadendsAlerts,
    HeadendsTable,
    HeadendEditDialog,
    ConfirmDialog
  },
  mixins: [headends],
  data () {
    return {
      applyErrorFilter: [],
      accessedItem: { id: undefined },
      dialogs: {
        showDeleteConfirmDialog: false,
        showDisconnectConfirmDialog: false,
        showEditDialog: false
      },
      firstTime: true
    }
  },
  computed: {
    ...mapGetters(['hasRights', 'getAccountUserRole']),
    headendList: function () {
      return this.$store.state.headendsNew.all
    },
    customizationType: function () {
      return this.$store.getters['customization/customizationType']
    }
  },
  mounted () {
    this.loadData()
  },
  destroyed () {
    this.$store.dispatch(HEADENDS_PAUSE)
  },
  methods: {
    loadData: function () {
      if (this.firstTime) {
        this.$store.dispatch(LOADING_INCREASE)
      }
      Promise.all([this.$store.dispatch(GROUPS_GET_ALL), this.$store.dispatch(HEADENDS_INIT)]).then(() => {
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('success.loadingData'))
        if (this.firstTime) {
          this.$store.dispatch(LOADING_DECREASE)
          this.firstTime = false
        }
      }).catch((error) => {
        if (error.response.status !== 401) {
          this.$store.dispatch(SNACKBAR_ERROR, this.$t('error.loadingData'))
        }
        if (this.firstTime) {
          this.$store.dispatch(LOADING_DECREASE)
          this.firstTime = false
        }
      })
    },
    applyAlarmFilter: function (alarmType) {
      if (alarmType) {
        this.applyErrorFilter = [alarmType]
      }
    },
    cancelAlarmFilter: function () {
      this.applyErrorFilter = []
    },
    headendsColumns: function () {
      return this.headendsHeadersColumns(this.customizationType)
    },
    headendsActions: function () {
      let actions
      if (this.$isSky) {
        actions = ['view', 'edit', 'access', 'disconnect', 'deleteOffline']
      } else if (this.$isTabcorp) {
        actions = ['view', 'edit', 'access', 'download', 'disconnect', 'deleteOffline']
      } else {
        actions = ['view', 'edit', 'access', 'download', 'lynk', 'disconnect', 'deleteOffline']
      }
      if (!this.hasRights('flt:dq')) {
        const index = actions.indexOf('download')
        if (index > -1) {
          actions.splice(index, 1)
        }
      }
      if (!this.hasRights('flt:a2m')) {
        const index = actions.indexOf('access')
        if (index > -1) {
          actions.splice(index, 1)
        }
      }
      if (!this.hasRights('flt:mhe')) {
        let index = actions.indexOf('edit')
        if (index > -1) {
          actions.splice(index, 1)
        }
        index = actions.indexOf('deleteOffline')
        if (index > -1) {
          actions.splice(index, 1)
        }
      }
      return actions
    },
    viewHeadend: function (headend) {
      if (headend.status.type === 'tsr') {
        this.$router.push('/tsr/view/' + headend.id)
      } else {
        this.$router.push('/headends/view/' + headend.id)
      }
    },
    editHeadend: function (headend) {
      this.dialogs.showEditDialog = true
      this.accessedItem = headend
    },
    accessHeadend: function (headend) {
      var url = this.$externalScheme +
          headend.id +
          '-http.' +
          process.env.VUE_APP_PUBLIC_DOMAIN
      if (this.$isTabcorp && headend.status.type === 'tsr') {
        if (this.getAccountUserRole === 'installer') {
          url = url + '/index.iku?action=logout'
        }
      }
      window.open(
        url,
        '_blank'
      )
    },
    downloadHeadendInfo: function (headend) {
      this.$store.dispatch(LOADING_INCREASE)
      DevicesService.deviceReport(headend.id)
        .then(response => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('success.downloadingDetailedReport')
          )
          var link = document.createElement('a')
          var file = new Blob([JSON.stringify(response.data, null, 4)], { type: 'text/plain' })
          link.href = URL.createObjectURL(file)
          const date = new Date()
          link.download = headend.id + '-' + date.toISOString() + '.json'
          link.click()
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.response.status !== 401) {
            this.$store.dispatch(
              SNACKBAR_ERROR,
              this.$t('error.downloadingDetailedReport')
            )
          }
        })
    },
    confirmDeleteHeadend: function (headend) {
      this.dialogs.showDeleteConfirmDialog = true
      this.accessedItem = headend
    },
    deleteHeadend: function (headendId) {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store.dispatch(HEADENDS_DELETE, headendId).then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('success.deleteHeadend'))
      }).catch(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_ERROR, this.$t('error.deleteHeadend'))
      })
    },
    accessLynk: function (headend) {
      window.open(
        this.$externalScheme +
          headend.id +
          '-lynk.' +
          process.env.VUE_APP_PUBLIC_DOMAIN,
        '_blank'
      )
    },
    updateHeadend: function (headendNewInfo) {
      const previousConfig = {
        alias: this.accessedItem.config.alias,
        comment: this.accessedItem.config.comment
      }
      this.accessedItem.config.alias = headendNewInfo.alias
      this.accessedItem.config.comment = headendNewInfo.comment
      this.$store.dispatch(LOADING_INCREASE)
      this.$store.dispatch(HEADENDS_UPDATE, this.accessedItem).then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('headends.headendUpdated'))
      }).catch(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_ERROR, this.$t('headends.errorUpdatingHeadend'))
        this.accessedItem.config.alias = previousConfig.alias
        this.accessedItem.config.comment = previousConfig.comment
      })
    },
    confirmDisconnectHeadend: function (headend) {
      this.dialogs.showDisconnectConfirmDialog = true
      this.accessedItem = headend
    },
    disconnectHeadend: function (headendId) {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store.dispatch(HEADENDS_DISCONNECT, headendId).then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('success.disconnectHeadend'))
      }).catch(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_ERROR, this.$t('error.disconnectHeadend'))
      })
    }
  }
}
</script>
