<template>
  <v-row no-gutters>
    <v-col cols="5">
      <v-menu
        v-model="menu.date"
        :close-on-content-click="false"
        max-width="290"
        min-width="290"
        transition="scale-transition"
      >
        <template #activator="{ on }">
          <v-text-field
            v-model="date"
            :label="dateLabel"
            readonly
            prepend-icon="mdi-calendar"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-if="menu.date"
          v-model="date"
          :locale="$locale"
          :first-day-of-week="1"
          scrollable
          color="primary"
          :min="minimunDate"
          @change="updateDate"
          @input="
            menu.date = false
            onDateBlur()
          "
        />
      </v-menu>
    </v-col>
    <v-col cols="4">
      <v-menu
        v-model="menu.time"
        :close-on-content-click="false"
        max-width="290"
        min-width="290"
        transition="scale-transition"
      >
        <template #activator="{ on }">
          <v-text-field
            v-model="time"
            :label="timeLabel"
            readonly
            prepend-icon="mdi-clock-outline"
            v-on="on"
          />
        </template>
        <v-time-picker
          v-if="menu.time"
          v-model="time"
          color="primary"
          scrollable
          format="24hr"
          ampm-in-title
          :min="minimumTime"
          @change="updateTime"
          @click:minute="menu.time = false"
          @input="onTimeBlur()"
        />
      </v-menu>
    </v-col>
    <v-col cols="3">
      <v-text-field
        v-model="timeZone"
        :label="$t('timezone')"
        readonly
        prepend-icon="mdi-timezone"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
export default {
  name: 'EditDateTime',
  props: {
    value: {
      type: String,
      default: null
    },
    timeLabel: {
      type: String,
      default: 'time'
    },
    dateLabel: {
      type: String,
      default: 'date'
    },
    minDate: {
      type: String,
      default: undefined
    },
    minTime: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      date: null,
      time: null,
      menu: {
        time: false,
        date: false
      },
      minimumTime: undefined,
      minimumTimeBackup: undefined,
      minimunDate: undefined
    }
  },
  computed: {
    ...mapGetters('customization', ['timeZone'])
  },
  watch: {
    value: function (newValue) {
      const tmpDate = new Date(newValue)
      const formatter = new Intl.DateTimeFormat(this.$locale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        timeZone: this.timeZone,
        timeZoneName: 'short'
      })
      const dataArray = formatter.formatToParts(tmpDate)
      this.getDateTimestring(dataArray)
      // this.date = tmpDate.getFullYear() + '-' + ('0' + (tmpDate.getMonth() + 1)).slice(-2) + '-' + ('0' + tmpDate.getDate()).slice(-2)
      // this.time = ('0' + tmpDate.getHours()).slice(-2) + ':' + ('0' + tmpDate.getMinutes()).slice(-2)
    },
    minDate: function (newValue) {
      this.updateMinValues()
    },
    minTime: function (newValue) {
      this.updateMinTime()
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      if (this.value) {
        const tmpDate = new Date(this.value)
        const formatter = new Intl.DateTimeFormat(this.$locale, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
          timeZone: this.timeZone,
          timeZoneName: 'short'
        })
        const dataArray = formatter.formatToParts(tmpDate)
        this.getDateTimestring(dataArray)
        // const tmpDate = new Date(this.value)
        // this.date = tmpDate.getFullYear() + '-' + ('0' + (tmpDate.getMonth() + 1)).slice(-2) + '-' + ('0' + tmpDate.getDate()).slice(-2)
        // this.time = ('0' + tmpDate.getHours()).slice(-2) + ':' + ('0' + tmpDate.getMinutes()).slice(-2)
      } else {
        const tmpDate = new Date()
        const formatter = new Intl.DateTimeFormat(this.$locale, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
          timeZone: this.timeZone,
          timeZoneName: 'short'
        })
        const dataArray = formatter.formatToParts(tmpDate)
        this.getDateTimestring(dataArray)
        // const tmpDate = new Date()
        // this.date = tmpDate.getFullYear() + '-' + ('0' + (tmpDate.getMonth() + 1)).slice(-2) + '-' + ('0' + tmpDate.getDate()).slice(-2)
        // this.time = ('0' + tmpDate.getHours()).slice(-2) + ':' + ('0' + tmpDate.getMinutes()).slice(-2)
        // this.updateTime(this.time)
        // this.updateDate(this.date)
        this.updateMinValues()
        this.$emit('input', tmpDate.toISOString())
      }
    })
  },
  methods: {
    updateMinValues: function () {
      if (this.minDate) {
        const tmpDate = new Date(this.minDate)
        const formatter = new Intl.DateTimeFormat(this.$locale, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
          timeZone: this.timeZone,
          timeZoneName: 'short'
        })
        const dataArray = formatter.formatToParts(tmpDate)
        let year = 0
        let month = 0
        let day = 0
        let hour = 0
        let minute = 0
        dataArray.forEach(({ type, value }) => {
          if (type === 'year') {
            year = value
          } else if (type === 'month') {
            month = value
            if (parseInt(value) < 10) {
              month = '0' + parseInt(value)
            }
          } else if (type === 'day') {
            day = value
            if (parseInt(value) < 10) {
              day = '0' + parseInt(value)
            }
          } else if (type === 'hour') {
            hour = value
            if (parseInt(value) < 10) {
              hour = '0' + parseInt(value)
            }
          } else if (type === 'minute') {
            minute = value
            if (parseInt(value) < 10) {
              minute = '0' + parseInt(value)
            }
          }
        })
        this.minimunDate = year + '-' + month + '-' + day
        this.minimumTimeBackup = hour + ':' + minute
        this.updateMinTime()
      }
    },
    updateMinTime: function () {
      if (this.minTime === false) {
        this.minimumTime = undefined
      } else {
        this.minimumTime = this.minimumTimeBackup
      }
    },
    getDateTimestring: function (dateArray) {
      let year = 0
      let month = 0
      let day = 0
      let hour = 0
      let minute = 0
      dateArray.forEach(({ type, value }) => {
        if (type === 'year') {
          year = value
        } else if (type === 'year') {
          year = value
        } else if (type === 'month') {
          month = value
          if (parseInt(value) < 10) {
            month = '0' + parseInt(value)
          }
        } else if (type === 'day') {
          day = value
          if (parseInt(value) < 10) {
            day = '0' + parseInt(value)
          }
        } else if (type === 'hour') {
          hour = value
        } else if (type === 'minute') {
          minute = value
        }
      })
      this.date = year + '-' + month + '-' + day
      this.time = hour + ':' + minute
    },
    updateTime: function (newTime) {
      this.time = newTime
      if (this.date) {
        this.$emit('input', this.generateTimeStr())
      }
    },
    updateDate: function (newDate) {
      this.date = newDate
      if (this.time) {
        this.$emit('input', this.generateTimeStr())
      }
    },
    generateTimeStr: function () {
      // console.log(this.date + 'T' + this.time)
      // const tmpDate = new Date(this.date + ' ' + this.time + ':00')
      const tmpDate = DateTime.fromISO(this.date + 'T' + this.time, {
        zone: this.timeZone
      })
      // console.log(tmpDate.toISO())
      // console.log(tmpDate.toUTC().toISO())
      // const utcDate = tmpDate.setZone('utc')
      // console.log(utcDate.toISO())
      // const tmpDate = new Date(this.date + ' ' + this.time + ':00 (' + this.timeZoneName + ')')
      // tmpDate.setHours(this.time.split(':')[0])
      // tmpDate.setMinutes(this.time.split(':')[1])
      // console.log(tmpDate.toISOString())
      return tmpDate.toUTC().toISO()
    },
    onDateBlur: function () {
      if (!this.time) {
        this.menu.time = true
      }
    },
    onTimeBlur: function () {
      if (!this.date) {
        this.menu.date = true
      }
    }
  }
}
</script>
