<template>
  <v-container
    fluid
  >
    <ConfirmDialog
      v-model="confirmDialog.show"
      :item-id="confirmDialog.itemId"
      :title="confirmDialog.title"
      :message="confirmDialog.message"
      @confirmed="confirmationDialogConfirmed"
    />
    <AlarmDialog
      v-model="alarmDialog.show"
      :title="alarmDialog.title"
      :map="alarmDialog.map"
    />
    <SnackBar />
    <div v-if="headendHasData">
      <v-row
        align="center"
        justify="start"
        class="ml-1 mt-1 mb-2"
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-icon
              v-if="headendIsOnlineOrConnecting"
              :color="headendConnectionStatusColor"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
            >
              mdi-server-network
            </v-icon>
            <v-icon
              v-else
              :color="headendConnectionStatusColor"
              class="mr-2"
              v-bind="attrs"
              v-on="on"
            >
              mdi-server-network-off
            </v-icon>
          </template>
          <span>{{ $t('status.' + headend.status.connection) }}</span>
        </v-tooltip>
        <span>{{ headend.status.remoteSiteDeviceName }}</span>
        <div
          v-if="headendHasAlarms"
          class="ml-5 pa-1 error rounded-circle"
        >
          <v-badge
            :content="totalAlarms"
            color="error"
            bottom
            overlap
            bordered
          >
            <v-btn
              icon
              @click="showHeadendAlarms"
            >
              <v-icon
                large
                color="white"
              >
                mdi-alert-circle
              </v-icon>
            </v-btn>
          </v-badge>
        </div>
        <v-tabs
          v-model="currentTab"
          class="tabs ml-8"
          color="primary"
        >
          <v-tab
            v-for="tab in getTabs"
            :key="tab"
          >
            {{ $t('tabs.' + tab ) }}
          </v-tab>
        </v-tabs>
        <HeadendActions
          :public-domain="publicDomain"
        />
      </v-row>
      <v-tabs-items
        v-model="currentTab"
      >
        <v-tab-item
          v-for="tab in getTabs"
          :key="'tab-' + tab"
        >
          <div v-if="tab === 'info'">
            <Info />
          </div>
          <div v-if="tab === 'serviceStatus'">
            <ServiceStatus />
          </div>
          <div v-if="tab === 'orders'">
            <Orders />
          </div>
          <div v-if="tab === 'backups'">
            <Backups
              :public-domain="publicDomain"
              @applyBackup="confirmApplyBackup"
              @createBackup="confirmCreateBackup"
              @deleteBackup="confirmDeleteBackup"
            />
          </div>
          <div v-if="tab === 'documents'">
            <Documents
              :public-domain="publicDomain"
            />
          </div>
          <div v-if="tab === 'logs'">
            <Logs />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

// Tabs
import Info from './HeadendInfo.vue'
import ServiceStatus from './HeadendServiceStatus.vue'
import Orders from './HeadendOrders.vue'
import Backups from './HeadendBackups.vue'
import Documents from './HeadendDocuments.vue'
import Logs from './HeadendLogs.vue'

// Common
import HeadendActions from './common/Actions.vue'
import ConfirmDialog from './common/ConfirmDialog.vue'
import AlarmDialog from './common/AlarmDialog.vue'
import SnackBar from './common/SnackBar.vue'

import {
  NS_AUTHENTICATION,
  NS_LOADING,
  NS_SNACKBAR,
  NS_HEADEND,
  NS_GROUPS,
  NS_REPORT,
  NS_ORDER_SCHEDULES,
  NS_BACKUPS,
  NS_DOCUMENTS,
  NS_USERS,
  NS_ROLES
} from '../store/namespaces'
import { AUTH_INIT, AUTH_ACCOUNT_INIT } from '../store/actions/authentication'
import { LOADING_INCREASE, LOADING_DECREASE } from '../store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '../store/actions/snackbar'
import { HEADEND_INIT, HEADEND_CLEAR, HEADEND_ERROR } from '../store/actions/headend'
import { GROUPS_GET_ALL } from '../store/actions/groups'
import { REPORT_INIT, REPORT_CLEAR } from '../store/actions/report'
import { ORDERS_SCHEDULES_CLEAR, ORDERS_SCHEDULES_GET_ALL } from '../store/actions/ordersSchedules'
import { BACKUPS_CLEAR, BACKUPS_GET_ALL } from '../store/actions/backups'
import { DOCUMENTS_CLEAR, DOCUMENTS_GET_ALL } from '../store/actions/documents'
import { USERS_GET_ALL, USERS_CLEAR } from '../store/actions/users'
import { ROLES_GET_ALL, ROLES_CLEAR } from '../store/actions/roles'

import BackupsService from '../services/fleets/BackupsService'

export default {
  name: 'HeadendComponent',
  i18nOptions: {
    namespaces: ['translation', 'HeadendComponentTranslation']
  },
  components: {
    HeadendActions,
    Info,
    ServiceStatus,
    Orders,
    Backups,
    Documents,
    Logs,
    ConfirmDialog,
    AlarmDialog,
    SnackBar
  },
  props: {
    headendId: {
      type: String,
      required: true
    },
    publicDomain: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: true
    },
    userRole: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      confirmDialog: {
        show: false
      },
      alarmDialog: {
        show: false
      },
      spinnerAreItemsLoading: undefined,
      currentTab: undefined,
      minimalFirmwareVersionTabs: [
        'info', 'serviceStatus', 'orders', 'backups', 'documents'
      ],
      tabs: [
        'info', 'serviceStatus', 'orders', 'backups', 'documents', 'logs'
      ],
      picturesList: [],
      orderTimeout: null
    }
  },
  computed: {
    ...mapGetters(NS_LOADING, ['areItemsLoading']),
    ...mapGetters(NS_HEADEND, ['getHeadend', 'isHeadendFirmwareValid']),
    ...mapGetters(NS_REPORT, ['getStatusAlarms']),
    getTabs: function () {
      if (this.isHeadendFirmwareValid) {
        return this.tabs
      }
      return this.minimalFirmwareVersionTabs
    },
    headend: function () {
      return this.getHeadend
    },
    headendHasData: function () {
      return this.headend !== null
    },
    headendIsOnlineOrConnecting: function () {
      return this.headend.status.connection === 'online' || this.headend.status.connection === 'connecting'
    },
    headendConnectionStatusColor: function () {
      return this.headend.status.connection === 'online'
        ? 'success'
        : this.headend.status.connection === 'connecting'
          ? 'warning'
          : 'disabled'
    },
    headendHasAlarms: function () {
      return this.statusAlarmMapHasData
    },
    totalAlarms: function () {
      let total = 0
      if (this.statusAlarmMapHasData) {
        this.statusAlarmMap.forEach((value, key) => {
          total = total + value.length
        })
      }
      return total
    },
    statusAlarmMap: function () {
      return this.getStatusAlarms
    },
    statusAlarmMapHasData: function () {
      return this.statusAlarmMap !== undefined && this.statusAlarmMap !== null && this.statusAlarmMap.size > 0
    }
  },
  watch: {
    areItemsLoading: function (newValue) {
      if (newValue) {
        this.spinnerAreItemsLoading = this.$loading.show({
          // Optional parameters
          canCancel: false,
          isFullPage: true,
          loader: 'spinner',
          color: '#007bff',
          backgroundColor: '#000000',
          height: 128,
          width: 128,
          opacity: 0.8
        })
      } else {
        this.spinnerAreItemsLoading.hide()
      }
    },
    totalAlarms: function (newValue) {
      if (newValue !== undefined && newValue !== null && newValue > 0 && this.alarmDialog.show) {
        this.showHeadendAlarms()
      }
    }
  },
  created () {
    this.$store.dispatch(NS_AUTHENTICATION + '/' + AUTH_INIT)
    const account = {
      clientId: this.clientId,
      userId: this.userId,
      role: this.userRole
    }
    this.$store.dispatch(NS_AUTHENTICATION + '/' + AUTH_ACCOUNT_INIT, account)
  },
  destroyed () {
    this.$store.dispatch(NS_HEADEND + '/' + HEADEND_CLEAR)
    this.$store.dispatch(NS_REPORT + '/' + REPORT_CLEAR)
    this.$store.dispatch(NS_ORDER_SCHEDULES + '/' + ORDERS_SCHEDULES_CLEAR)
    this.$store.dispatch(NS_BACKUPS + '/' + BACKUPS_CLEAR)
    this.$store.dispatch(NS_DOCUMENTS + '/' + DOCUMENTS_CLEAR)
    this.$store.dispatch(NS_USERS + '/' + USERS_CLEAR)
    this.$store.dispatch(NS_ROLES + '/' + ROLES_CLEAR)
  },
  mounted () {
    this.loadData()
  },
  beforeDestroy () {
    clearTimeout(this.orderTimeout)
    this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
  },
  methods: {
    loadData: function () {
      this.$store.dispatch(NS_LOADING + '/' + LOADING_INCREASE)
      Promise.all([
        this.$store.dispatch(NS_GROUPS + '/' + GROUPS_GET_ALL),
        this.$store.dispatch(NS_HEADEND + '/' + HEADEND_INIT, this.headendId),
        this.$store.dispatch(NS_REPORT + '/' + REPORT_INIT, this.headendId)
      ]).then(() => {
        this.checkHeadendFirmwareIsValid()
        this.$store.dispatch(NS_ORDER_SCHEDULES + '/' + ORDERS_SCHEDULES_GET_ALL)
        this.$store.dispatch(NS_BACKUPS + '/' + BACKUPS_GET_ALL, this.headendId)
        this.$store.dispatch(NS_DOCUMENTS + '/' + DOCUMENTS_GET_ALL, this.headendId)
        this.$store.dispatch(NS_USERS + '/' + USERS_GET_ALL)
        this.$store.dispatch(NS_ROLES + '/' + ROLES_GET_ALL, this.clientId)
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
        this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('success.loadingData'))
      }).catch((error) => {
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
        if (error.status !== 401) {
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_ERROR, this.$t('error.loadingData'))
        }
      }).finally(() => {
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
        this.orderTimeout = setTimeout(() => {
          this.loadStatus()
        }, 60000)
      })
    },
    loadStatus: function () {
      Promise.all([
        this.$store.dispatch(NS_ORDER_SCHEDULES + '/' + ORDERS_SCHEDULES_GET_ALL),
        this.$store.dispatch(NS_BACKUPS + '/' + BACKUPS_GET_ALL, this.headendId),
        this.$store.dispatch(NS_DOCUMENTS + '/' + DOCUMENTS_GET_ALL, this.headendId)
      ]).finally(() => {
        this.orderTimeout = setTimeout(() => {
          this.loadStatus(false)
        }, 60000)
      })
    },
    checkHeadendFirmwareIsValid: function () {
      if (!this.isHeadendFirmwareValid) {
        const error = {
          error: 'Firmware not supported',
          message: this.$t('headend.errorFirmwareNotSupported')
        }
        this.$store.dispatch(NS_HEADEND + '/' + HEADEND_ERROR, error)
      }
    },
    showHeadendAlarms: function () {
      this.alarmDialog.show = true
      this.alarmDialog.title = this.$t('alarmDialog.headendAlarmsTitle')
      this.alarmDialog.map = this.statusAlarmMap
    },
    confirmApplyBackup: function (backup) {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('section.backups.confirmApplyBackupTitle')
      this.confirmDialog.message = this.$t('section.backups.confirmApplyBackupMessage', backup)
      this.confirmDialog.type = 'applyBackup'
      this.confirmDialog.itemId = backup.url
    },
    confirmCreateBackup: function () {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('section.backups.confirmCreateBackupTitle')
      this.confirmDialog.message = this.$t('section.backups.confirmCreateBackupMessage')
      this.confirmDialog.type = 'createBackup'
    },
    confirmDeleteBackup: function (backup) {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('section.backups.confirmDeleteBackupTitle')
      this.confirmDialog.message = this.$t('section.backups.confirmDeleteBackupMessage', backup)
      this.confirmDialog.type = 'deleteBackup'
      this.confirmDialog.itemId = backup.url
    },
    confirmationDialogConfirmed: function (itemId) {
      if (this.confirmDialog.type === 'applyBackup') {
        this.applyBackup(itemId)
      }
      if (this.confirmDialog.type === 'createBackup') {
        this.createBackup(itemId)
      }
      if (this.confirmDialog.type === 'deleteBackup') {
        this.deleteBackup(itemId)
      }
    },
    applyBackup: function (url) {
      this.$store.dispatch(NS_LOADING + '/' + LOADING_INCREASE)
      BackupsService.setBackups(this.headendId, url)
        .then(() => {
          this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('section.backups.backupApplied'))
        })
        .catch(() => {
          this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_ERROR, this.$t('section.backups.errorApplyingBackup'))
        })
    },
    createBackup: function () {
      this.$store.dispatch(NS_LOADING + '/' + LOADING_INCREASE)
      BackupsService.createBackup(this.headendId).then(() => {
        this.$store.dispatch(NS_BACKUPS + '/' + BACKUPS_GET_ALL, this.headendId)
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
        this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('section.backups.backupCreated'))
      })
        .catch((error) => {
          if (error.status === 400) {
            // Bad request
          }
          this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_ERROR, this.$t('section.backups.errorCreatingBackup'))
        })
    },
    deleteBackup: function (url) {
      this.$store.dispatch(NS_LOADING + '/' + LOADING_INCREASE)
      BackupsService.deleteBackup(this.headendId, undefined, url)
        .then(() => {
          this.$store.dispatch(NS_BACKUPS + '/' + BACKUPS_GET_ALL, this.headendId)
          this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('section.backups.backupDeleted'))
        })
        .catch(() => {
          this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_ERROR, this.$t('section.backups.errorDeletingBackup'))
        })
    }
  }
}
</script>
<style>
.tabs {
  width: 0%;
}
</style>
