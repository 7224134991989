const timeoutCancel = {
  data () {
    return {
      orderTimeout: null
    }
  },
  beforeDestroy () {
    clearTimeout(this.orderTimeout)
  }
}

export default timeoutCancel
