<template>
  <v-form
    ref="orderForm"
    v-model="commandInformationForm"
    lazy-validation
  >
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="order.name"
            maxlength="100"
            :label="$t('name')"
            :rules="[orderRules.required]"
          />
          <span class="title">{{ $t('description') }}</span>
          <v-textarea
            v-model="order.description"
            no-resize
            class="mb4"
            maxlength="400"
            :label="$t('comments')"
            outlined
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <span class="title">{{ $t('Command') }}</span>
          <v-select
            v-model="selectedOrder"
            :label="$t('orderCommand.type')"
            :items="commandTypes"
            :rules="[orderRules.required]"
            @change="changeOrderType"
          />
          <!-- CHANNEL MAPS -->
          <ChannelMapOrder
            v-if="selectedOrder === 'channelMap'"
            v-model="order.action"
          />
          <!-- ORDERS FOR SKY -->
          <TransferFirmwareOrder
            v-if="selectedOrder === 'transferFirmware'"
            v-model="order.action"
          />
          <LaunchFirmwareOrder
            v-if="selectedOrder === 'launchFirmware'"
            v-model="order.action"
          />
          <RebootOrder
            v-if="selectedOrder === 'reboot'"
            v-model="order.action"
          />
          <ScanInputsOrder
            v-if="selectedOrder === 'scanInputs'"
            v-model="order.action"
          />
          <BackupOrder
            v-if="selectedOrder === 'backup'"
            v-model="order.action"
          />
          <CustomOrder
            v-if="selectedOrder === 'custom'"
            v-model="order.action"
          />
          <ChangePasswordOrder
            v-if="selectedOrder === 'changePassword'"
            v-model="order.action"
            @validateForm="validateForm"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card-actions>
            <v-spacer />
            <slot name="actions" />
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
// Description part
import TransferFirmwareOrder from '@/views/orderScheduleCreate/components/orders/TransferFirmwareOrder.vue'
import CustomOrder from '@/views/orderScheduleCreate/components/orders/CustomOrder.vue'
import RebootOrder from '@/views/orderScheduleCreate/components/orders/RebootOrder.vue'
import ScanInputsOrder from '@/views/orderScheduleCreate/components/orders/ScanInputsOrder.vue'
import LaunchFirmwareOrder from '@/views/orderScheduleCreate/components/orders/LaunchFirmwareOrder.vue'
import ChannelMapOrder from '@/views/orderScheduleCreate/components/orders/ChannelMapOrder.vue'
import ChangePasswordOrder from '@/views/orderScheduleCreate/components/orders/ChangePasswordOrder.vue'
import BackupOrder from '@/views/orderScheduleCreate/components/orders/BackupOrder.vue'

export default {
  name: 'OrderInformation',
  components: {
    CustomOrder,
    TransferFirmwareOrder,
    RebootOrder,
    ScanInputsOrder,
    LaunchFirmwareOrder,
    ChannelMapOrder,
    ChangePasswordOrder,
    BackupOrder
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          name: '',
          description: '',
          action: {
            info: {
              type: Object,
              default: {
                id: '',
                relativeURL: '',
                method: '',
                payload: ''
              }
            }
          }
        }
      }
    },
    commandTypes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      commandInformationForm: false, // Checks if the form is valid
      selectedOrder: null,
      selectedLineup: null,
      selectedReport: null,
      order: {
        name: '',
        description: '',
        action: {
          info: {
            type: Object,
            default: {
              id: '',
              relativeURL: '',
              method: '',
              payload: ''
            }
          }
        }
      },
      orderRules: {
        required: value => !!value || this.$t('validation.requiredField')
      }
    }
  },
  computed: {},
  watch: {
    commandInformationForm: function () {
      this.$emit('isValid', this.commandInformationForm)
      this.$emit('input', this.order)
    },
    value: {
      deep: true,
      handler: function (newVal) {
        this.order = newVal
        this.selectedOrder = newVal.action.type
      }
    }
  },
  mounted () {
    this.order = this.value
    this.validateForm()
  },
  methods: {
    validateForm: function () {
      this.$refs.orderForm.validate()
    },
    validateNextForm: function () {
      this.$nextTick(() => {
        this.validateForm()
      })
    },
    changeOrderType: function (value) {
      this.$emit('orderTypeChanged', value)
      this.validateNextForm()
    }
  }
}
</script>
