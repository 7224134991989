<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t('reportOnDemand.viewTitle') }}
      </v-card-title>
      <v-card-text>
        <v-tabs
          v-model="tab"
          grow
        >
          <v-tab>
            {{ $t('reportOnDemand.fromTemplate') }}
          </v-tab>
          <v-tab>
            {{ $t('reportOnDemand.customize') }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <ReportTemplatesTable
              :items="reportsTemplates"
              :actions="['report']"
              @reportAction="viewReportFromTemplate"
            />
          </v-tab-item>
          <v-tab-item>
            <v-toolbar flat>
              <v-spacer />
              <v-btn
                color="primary darken-1"
                class="ma-1"
                @click="resetFilters"
              >
                <v-icon left>
                  mdi-cancel
                </v-icon>
                {{ $t('reportOnDemand.resetFilters') }}
              </v-btn>
              <v-btn
                color="primary darken-1"
                class="ma-1"
                @click="viewReport()"
              >
                <v-icon left>
                  mdi-file-chart
                </v-icon>
                {{ $t('reportOnDemand.viewReport') }}
              </v-btn>
            </v-toolbar>
            <ReportsFilter v-model="filteredItems" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <span class="note mt-3">* {{ $t('reportOnDemand.reportFullDataNote') }}</span>
    <!-- DIALOG OF THE REPORT-->
    <v-dialog
      v-model="dialogReport.show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <ReportPreVisualization
        :title="dialogReport.title"
        :filters="dialogReport.filter"
        :load="dialogReport.show"
        @close="dialogReport.show = false"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'

import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'

import { HEADEND_TYPES_GET_ALL } from '@/store/actions/headendTypes'
import { GROUPS_GET_ALL } from '@/store/actions/groups'
import { REPORTS_TEMPLATES_GET_ALL } from '@/store/actions/reportsTemplates'

import ReportTemplatesTable from '@/views/reportTemplates/components/ReportTemplatesTable'
import ReportsFilter from '@/views/reportOnDemand/components/ReportsFilter'
import ReportPreVisualization from '@/views/reportOnDemand/components/ReportPreVisualization'

export default {
  name: 'OndemandReports',
  components: {
    ReportTemplatesTable,
    ReportsFilter,
    ReportPreVisualization
  },
  data () {
    return {
      tab: 0,
      dialogReport: { show: false, filter: {}, title: '' },
      filterHeadendList: [],
      filteredItems: {
        alarmTypes: [],
        connectionTypes: [],
        systemTypes: [],
        drmTypes: [],
        headendTypes: [],
        groups: []
      }
    }
  },
  computed: {
    ...mapState({ reportsTemplates: state => state.reportsTemplates.all })
  },
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    Promise.all([
      this.$store.dispatch(REPORTS_TEMPLATES_GET_ALL),
      this.$store.dispatch(HEADEND_TYPES_GET_ALL),
      this.$store.dispatch(GROUPS_GET_ALL)
    ])
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('reportOnDemand.loaded'))
      })
      .catch(error => {
        this.$store.dispatch(LOADING_DECREASE)
        if (error.response.status !== 401) {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('reportOnDemand.errorLoadingRequiredData')
          )
        }
      })
  },
  methods: {
    resetFilters: function () {
      this.filteredItems = {
        alarmTypes: [],
        connectionTypes: [],
        systemTypes: [],
        drmTypes: [],
        headendTypes: [],
        groups: []
      }
    },
    viewReportFromTemplate: function (reportTemplate) {
      this.dialogReport.show = true
      this.dialogReport.filter = reportTemplate.headendsFilter
      this.dialogReport.title = reportTemplate.name
    },
    viewReport: function () {
      this.dialogReport.show = true
      this.dialogReport.filter = this.filteredItems
      this.dialogReport.title = ''
    }
  }
}
</script>
<style>
.note {
  display: block;
  font-size: small;
  color: grey;
}
</style>
