<template>
  <v-data-table
    :headers="headers"
    :items="reportInfoData"
    hide-default-footer
  >
    <template #[`item.date`]="{ item }">
      {{ item.date | formatDate($locale, $timezone) }}
    </template>
    <template #[`item.totalPercentage`]="{ item }">
      {{ item.totalPercentage }}%
    </template>
    <template #[`item.connectedPercentage`]="{ item }">
      {{ item.connectedPercentage }}%
    </template>
  </v-data-table>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ReportPreVisualization',
  components: {},
  props: {
    reportHeadends: {
      type: Array,
      required: true,
      default: () => []
    },
    reportDate: {
      type: Date,
      required: true,
      default: () => new Date()
    }
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('reportPreVisualization.date'),
          align: 'start',
          sortable: true,
          value: 'date'
        },
        {
          text: this.$t('reportPreVisualization.totalHeadends'),
          align: 'start',
          sortable: true,
          value: 'numHeadends'
        },
        {
          text: this.$t('reportPreVisualization.totalHeadendsInReport'),
          align: 'start',
          sortable: true,
          value: 'filterNumHeadends'
        },
        {
          text: this.$t('reportPreVisualization.totalPercentage'),
          align: 'start',
          sortable: true,
          value: 'totalPercentage'
        },
        {
          text: this.$t('reportPreVisualization.totalConnected'),
          align: 'start',
          sortable: true,
          value: 'connectedNumHeadends'
        },
        {
          text: this.$t('reportPreVisualization.connectedHeadendsInReport'),
          align: 'start',
          sortable: true,
          value: 'connectedReportHeadends'
        },
        {
          text: this.$t('reportPreVisualization.connectedPercentage'),
          align: 'start',
          sortable: true,
          value: 'connectedPercentage'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      headends: state => {
        return state.headendsNew.all
      }
    }),
    reportInfoData: function () {
      const connectedReportHeadends = this.reportHeadends.filter(headend => {
        return headend.status.connection === 'online'
      })
      const connectedHeadends = this.headends.filter(headend => {
        return headend.status.connection === 'online'
      })
      let totalPercentage = 0
      if (this.headends.length > 0) {
        totalPercentage =
          (this.reportHeadends.length / this.headends.length) * 100
      }
      let connectedPercentage = 0
      if (connectedHeadends.length > 0) {
        connectedPercentage =
          (connectedReportHeadends.length / connectedHeadends.length) * 100
      }
      return [
        {
          date: this.reportDate.toISOString(), // Devuelve 2011-10-05T14:48:00.000Z
          filterNumHeadends: this.reportHeadends.length,
          numHeadends: this.headends.length,
          totalPercentage: totalPercentage.toFixed(2),
          connectedNumHeadends: connectedHeadends.length,
          connectedReportHeadends: connectedReportHeadends.length,
          connectedPercentage: connectedPercentage.toFixed(2)
        }
      ]
    }
  },
  watch: {},
  methods: {}
}
</script>
