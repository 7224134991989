<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="receiversTab">
          <v-tab>
            <v-badge
              color="primary"
              :content="target.groups.length > 0 ? target.groups.length : '0'"
              :title="
                $t('selectedGroups', {
                  value: target.groups.length
                })
              "
            >
              {{ $t('newOrder.groups') }}
            </v-badge>
          </v-tab>
          <v-tab>
            <v-badge
              color="primary"
              :content="
                target.devices.length > 0 ? target.devices.length : '0'
              "
              :title="
                $t('selectedHeadends', {
                  value: target.devices.length
                })
              "
            >
              {{ $t('newOrder.headends') }}
            </v-badge>
          </v-tab>
          <v-spacer />
          <!-- ADD BOTONS FOR THE TABS -->
          <v-btn
            v-show="receiversTab == 0"
            :disabled="target.groups.length === groups.length"
            color="primary"
            class="ma-2"
            dark
            :title="$t('newOrder.selectGroupsForTheOrder')"
            @click="showGroupsDialog"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t('newOrder.groups') }}
          </v-btn>
          <v-btn
            v-show="receiversTab == 1"
            :disabled="target.devices.length === devices.length"
            color="primary"
            class="ma-2"
            dark
            :title="$t('newOrder.selectHeadendsForTheOrder')"
            @click.stop="showHeadendsDialog"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t('newOrder.headends') }}
          </v-btn>
        </v-tabs>
        <v-tabs-items v-model="receiversTab">
          <!-- GROUPS TAB -->
          <v-tab-item>
            <GroupsTable
              v-model="showing.groups"
              :items="showing.groups"
              selection="none"
              :actions="['delete']"
              @deleteItem="deselectGroup"
            />
            <!-- ADD GROUP DIALOG -->
            <v-dialog
              v-model="dialog.groups.showing"
              scrollable
            >
              <v-card>
                <v-card-title>
                  {{ $t('newOrder.selectGroups') }}
                </v-card-title>
                <v-card-text>
                  <GroupsTable
                    v-model="dialog.groups.selection"
                    :items="dialog.groups.displaying"
                    selection="multiple"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    @click="groupsDialogSelectionCanceled"
                  >
                    {{ $t('button.cancel') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="groupsDialogSelectionAcepted"
                  >
                    {{ $t('button.select') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>
          <!-- HEADENDS TAB -->
          <v-tab-item>
            <HeadendsTable
              v-model="showing.devices"
              :items="showing.devices"
              :columns="headersColumns()"
              :actions="['delete']"
              selection="none"
              @deleteItem="deselectHeadend"
            />
            <!-- ADD HEADENDS DIALOG -->
            <v-dialog
              v-model="dialog.devices.showing"
              scrollable
            >
              <v-card>
                <v-card-title>
                  {{ $t('newOrder.selectHeadends') }}
                </v-card-title>
                <v-card-text>
                  <HeadendsTable
                    v-model="dialog.devices.selection"
                    :items="dialog.devices.displaying"
                    :columns="headersColumns()"
                    selection="multiple"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    @click="headendsDialogSelectionCanceled"
                  >
                    {{ $t('button.cancel') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="headendsDialogSelectionAcepted"
                  >
                    {{ $t('button.select') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card-actions>
          <v-spacer />
          <slot name="actions" />
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import HeadendsTable from '@/views/headends/components/HeadendsTable.vue'
import GroupsTable from '@/views/groups/components/GroupsTable.vue'
import headends from '@/mixins/headends.js'

export default {
  name: 'OrderReceivers',
  components: {
    HeadendsTable,
    GroupsTable
  },
  mixins: [headends],
  props: {
    commandType: {
      type: Object,
      default: () => {
        return { apply: ['headends', 'groups'] }
      }
    },
    value: {
      type: Object,
      default: () => {
        return {
          devices: [],
          groups: []
        }
      }
    }
  },
  data () {
    return {
      commandInformationForm: false, // Checks if the form is valid
      dialog: {
        devices: { showing: false, selection: [], displaying: [] },
        groups: { showing: false, selection: [], displaying: [] }
      },
      receiversTab: 0,
      showing: {
        devices: [],
        groups: []
      },
      target: {
        devices: [],
        groups: []
      }
    }
  },
  computed: {
    ...mapGetters(['getHeadendById', 'getGroupById']),
    ...mapState({
      devices: state => {
        return state.headendsNew.all
      }
    }),
    ...mapState({
      groups: state => {
        return state.groupsNew.all
      }
    }),
    selectedHeadendList: function () {
      return this.devices.filter(headend => {
        return this.target.devices.includes(headend.id)
      })
    },
    selectedGroupsList: function () {
      return this.groups.filter(group => {
        return this.target.groups.includes(group.id)
      })
    },
    customizationType: function () {
      return this.$store.getters['customization/customizationType']
    }
  },
  watch: {
    'target.devices': function () {
      this.$emit('input', this.target)
    },
    'target.groups': function () {
      this.$emit('input', this.target)
    },
    value: function () {
      this.showing.devices.length = 0
      this.value.devices.forEach(idHeadend => {
        this.showing.devices.push(this.getHeadendById(idHeadend))
      })
      this.target.devices = this.value.devices
      this.showing.groups.length = 0
      this.value.groups.forEach(idGroup => {
        if (typeof idGroup === 'string') {
          this.showing.groups.push(this.getGroupById(idGroup))
        }
      })
      this.target.groups = this.value.groups
    }
  },
  mounted () {},
  methods: {
    deselectGroup: function (group) {
      this.showing.groups.splice(this.showing.groups.indexOf(group), 1)
      this.target.groups.splice(this.target.groups.indexOf(group.id), 1)
    },
    deselectHeadend: function (headend) {
      this.showing.devices.splice(this.showing.devices.indexOf(headend), 1)
      this.target.devices.splice(this.target.devices.indexOf(headend.id), 1)
    },
    showGroupsDialog: function () {
      this.dialog.groups.showing = true
      const displaying = []
      this.groups.forEach((item, i) => {
        if (this.showing.groups.indexOf(item) < 0) {
          displaying.push(item)
        }
      })
      this.$set(this.dialog.groups, 'displaying', displaying)
    },
    showHeadendsDialog: function () {
      this.dialog.devices.showing = true
      const displaying = []
      this.devices.forEach((item, i) => {
        if (this.showing.devices.indexOf(item) < 0) {
          displaying.push(item)
        }
      })
      this.$set(this.dialog.devices, 'displaying', displaying)
    },
    groupsDialogSelectionCanceled: function () {
      this.dialog.groups.showing = false
      this.dialog.groups.selection.length = 0
    },
    groupsDialogSelectionAcepted: function () {
      this.dialog.groups.showing = false
      this.showing.groups = this.showing.groups.concat(
        this.dialog.groups.selection
      )
      this.target.groups.length = 0
      this.showing.groups.forEach(item => {
        this.target.groups.push(item.id)
      })
      this.dialog.groups.selection.length = 0
    },
    headendsDialogSelectionCanceled: function () {
      this.dialog.devices.showing = false
      this.dialog.devices.selection.length = 0
    },
    headendsDialogSelectionAcepted: function () {
      this.dialog.devices.showing = false
      this.showing.devices = this.showing.devices.concat(
        this.dialog.devices.selection
      )
      this.target.devices.length = 0
      this.showing.devices.forEach(item => {
        this.target.devices.push(item.id)
      })
      this.dialog.devices.selection.length = 0
    },
    headersColumns: function () {
      return this.headendsHeadersColumns(this.customizationType)
    }
  }
}
</script>
