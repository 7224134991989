import {
  REPORTS_GENERATED_GET_ALL,
  REPORTS_GENERATED_DOWNLOAD,
  REPORTS_GENERATED_DELETE,
  REPORTS_GENERATED_CLEAR
} from '@/store/actions/reportsGenerated'

import { AUTH_LOGOUT } from '@/store/actions/authentication'

import reportsApi from '@/services/api/reports/ReportsGenerated'

export default {
  state: {
    all: []
  },
  mutations: {
    [REPORTS_GENERATED_GET_ALL]: (state, data) => {
      state.all = data
    },
    [REPORTS_GENERATED_DELETE]: (state, id) => {
      var index = state.all.findIndex(report => report.id === id)
      state.all.splice(index, 1)
    },
    [REPORTS_GENERATED_CLEAR]: state => {
      state.all.length = 0
    }
  },
  actions: {
    [REPORTS_GENERATED_GET_ALL]: ({ commit, state, dispatch }) => {
      return new Promise((resolve, reject) => {
        reportsApi
          .getAll()
          .then(response => {
            commit(REPORTS_GENERATED_GET_ALL, response)
            resolve(response)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    [REPORTS_GENERATED_DOWNLOAD]: ({ commit }, reportId) => {
      return new Promise((resolve, reject) => {
        reportsApi
          .download(reportId)
          .then(data => {
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [REPORTS_GENERATED_DELETE]: ({ commit }, id) => {
      return new Promise((resolve, reject) => {
        reportsApi
          .delete(id)
          .then(response => {
            // console.log('[store.reports] Report ' + id + ' deleted')
            commit(REPORTS_GENERATED_DELETE, id)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      commit(REPORTS_GENERATED_CLEAR)
    }
  },
  getters: {}
}
