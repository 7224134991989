<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-btn
            icon
            color="primary"
            :title="$t('back')"
            @click="backToUsers"
          >
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-toolbar-title v-if="viewMode === 'edit'">
            {{ $t('newUser.Edit') }}
          </v-toolbar-title>
          <v-toolbar-title v-if="viewMode === 'new'">
            {{ $t('newUser.New') }}
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-form
              ref="form"
              v-model="validForm"
              value="false"
            >
              <!-- ID
              <v-row>
                <v-col
                  md="6"
                  sm="12"
                >
                  <v-text-field
                    v-model="user.id"
                    prepend-icon="mdi-identifier"
                    readonly
                    disabled
                    :label="$t('myAccount.id')"
                  />
                </v-col>
              </v-row>
              -->
              <!-- ROLE -->
              <v-row>
                <v-col
                  md="6"
                  sm="12"
                >
                  <v-select
                    v-model="user.role"
                    prepend-icon="mdi-shield-account"
                    :rules="[rules.required]"
                    :items="roles"
                    item-text="name"
                    item-value="id"
                    :readonly="viewMode === 'edit'"
                    :disabled="viewMode === 'edit'"
                    :label="$t('users.role')"
                  />
                </v-col>
              </v-row>
              <!-- CODE -->
              <v-row>
                <v-col
                  md="3"
                  sm="12"
                >
                  <v-text-field
                    v-model="user.code"
                    prepend-icon="mdi-card-account-details"
                    :label="$t('users.code')"
                  />
                </v-col>
              </v-row>
              <!-- FIRST NAME & SECOND NAME -->
              <v-row>
                <v-col
                  md="6"
                  sm="12"
                >
                  <!-- FIRST NAME -->
                  <v-text-field
                    v-model="user.firstName"
                    prepend-icon="mdi-card-account-details"
                    :rules="[rules.required]"
                    :label="$t('users.firstName')"
                    @click="validateForm"
                  />
                </v-col>
                <v-col
                  md="6"
                  sm="12"
                >
                  <!-- SECOND NAME -->
                  <v-text-field
                    v-model="user.secondName"
                    prepend-icon="mdi-card-account-details"
                    :rules="[rules.required]"
                    :label="$t('users.secondName')"
                    @click="validateForm"
                  />
                </v-col>
              </v-row>
              <!-- EMAIL && TELEPHONE -->
              <v-row>
                <v-col
                  md="8"
                  sm="12"
                >
                  <!-- EMAIL -->
                  <v-text-field
                    v-model="user.email"
                    prepend-icon="mdi-email"
                    :label="$t('users.email')"
                    :rules="[rules.required, rules.email]"
                    :error="errors.email.status"
                    :error-messages="errors.email.message"
                    @click="validateForm"
                    @keyup="validateForm"
                  />
                </v-col>
                <v-col
                  md="4"
                  sm="12"
                >
                  <!-- TELEPHONE -->
                  <v-text-field
                    v-model="user.telephone"
                    prepend-icon="mdi-phone"
                    :label="$t('users.telephone')"
                    :rules="[]"
                    :error="errors.telephone.status"
                    :error-messages="errors.telephone.message"
                    @click="validateForm"
                    @keyup="validateForm"
                  />
                </v-col>
              </v-row>
              <!-- INFORMATION -->
              <v-row>
                <v-col
                  sm="12"
                >
                  <v-text-field
                    v-model="user.information"
                    prepend-icon="mdi-information"
                    :label="$t('users.information')"
                    :rules="[]"
                    :error="errors.information.status"
                    :error-messages="errors.information.message"
                    @click="validateForm"
                    @keyup="validateForm"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="customizationType!=='skyit' && customizationType !=='tabcorp'"
              >
                <v-col
                  md="6"
                  sm="12"
                >
                  <!-- LANGUAGE-->
                  <v-select
                    v-if="viewMode==='new'"
                    v-model="user.language"
                    prepend-icon="mdi-translate"
                    :items="languages"
                    :label="$t('users.language')"
                  />
                </v-col>
                <v-col
                  md="6"
                  sm="12"
                >
                  <!-- TIME ZONES -->
                  <v-select
                    v-if="viewMode==='new'"
                    v-model="user.timeZone"
                    prepend-icon="mdi-earth"
                    :items="timeZones"
                    :label="$t('users.timeZone')"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary darken-1"
              @click="save"
            >
              {{ $t('button.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { uuid } from 'vue-uuid'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { USERS_GET_ALL, USERS_CREATE, USERS_UPDATE } from '@/store/actions/users'
import { LANGUAGES_GET } from '@/store/actions/languages'
import { TIME_ZONES_GET } from '@/store/actions/timeZones'
import { ROLES_GET } from '@/store/actions/roles'

export default {
  name: 'CreateModifyUser',
  components: {},
  data () {
    return {
      viewMode: '',
      validForm: false, // Checks if the form is valid
      rules: {
        required: value => !!value || this.$t('validation.requiredField'),
        email: value =>
          /.+@.+\..+/.test(value) || this.$t('validation.eMailMustBeValid'),
        usernameMaxLength: value =>
          (value && value.length < 21) ||
          this.$t('validation.maxLength', { max: 20 })
      },
      user: {},
      errors: {
        username: { status: false, message: '' },
        email: { status: false, message: '' },
        telephone: { status: false, message: '' },
        information: { status: false, message: '' }
      }
    }
  },
  computed: {
    ...mapGetters(['getUserById']),
    ...mapState({ myAccount: state => state.authentication.account }),
    languages () {
      return this.$store.state.languages.all
    },
    roles () {
      return this.$store.state.roles.all.map(role => {
        role.name = this.$t('roles.' + role.id)
        return role
      })
    },
    timeZones () {
      return this.$store.state.timeZones.all
    },
    customizationType: function () {
      return this.$store.getters['customization/customizationType']
    }
  },
  watch: {
    showing: function () {
      if (this.showing) {
        this.prepareData()
      }
    }
  },
  mounted () {
    if (this.$route.path.includes('edit')) {
      this.viewMode = 'edit'
    } else {
      this.viewMode = 'new'
    }
    this.loadData()
  },
  methods: {
    validateForm: function () {
      this.$refs.form.validate()
    },
    loadData: function () {
      Promise.all([this.$store.dispatch(LANGUAGES_GET),
        this.$store.dispatch(ROLES_GET),
        this.$store.dispatch(TIME_ZONES_GET),
        this.$store.dispatch(USERS_GET_ALL)]).then(() => {
        this.prepareData()
      })
    },
    save: function () {
      this.errors = {
        username: { status: false, message: '' },
        email: { status: false, message: '' },
        telephone: { status: false, message: '' },
        information: { status: false, message: '' }
      }
      this.validateForm()
      if (this.validForm) {
        if (this.viewMode === 'edit') {
          // console.log('[users.createOrModify] Ready to modify the user' + this.user.id)
          this.updateUser(this.user)
        } else if (this.viewMode === 'new') {
          // console.log('[users.createOrModify] Ready to create the user ' + this.user.id)
          this.createUser(this.user)
        }
      }
    },
    updateUser: function (user) {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(USERS_UPDATE, user)
        .then(response => {
          // console.log('[users.createOrModify] User ' + user.id + ' updated')
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('users.userUpdated'))
          this.$emit('createModifyUserDone')
        })
        .catch(error => {
          // console.log('[users.createOrModify] Error updating the user ' + user.id + ' updated')
          this.$store.dispatch(LOADING_DECREASE)
          if (error.status === 500 || error.data === '') {
            this.$store.dispatch(
              SNACKBAR_ERROR,
              this.$t('users.errorUpdatingUser')
            )
            return
          }
          switch (error.data.error) {
            case 'user_email_taken':
              this.errors.email.status = true
              this.errors.email.message = this.$t('users.errorEmailIsTaken')
              break
            default:
            // console.error('[users.createOrModify] Updating user error not managed: ' + error.data.error)
          }
        })
    },
    createUser: function (user) {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(USERS_CREATE, user)
        .then(response => {
          // console.log('[users.createOrModify] User ' + user.id + ' created')
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('users.userCreated'))
          this.$router.push('/users')
        })
        .catch(error => {
          // console.log('[users.createOrModify] Error creating the user ' + user.id + ' updated')
          this.$store.dispatch(LOADING_DECREASE)
          if (error.status === 500 || error.data === '') {
            this.$store.dispatch(
              SNACKBAR_ERROR,
              this.$t('users.errorCreatingUser')
            )
            return
          }
          switch (error.data.error) {
            case 'user_email_taken':
              this.errors.email.status = true
              this.errors.email.message = this.$t('users.errorEmailIsTaken')
              break
            case 'username_too_long':
              this.errors.username.status = true
              this.errors.username.message = this.$t(
                'users.errorUsernameTooLong'
              )
              break
            case 'user_email_not_valid':
              this.errors.email.status = true
              this.errors.email.message = this.$t('users.errorEmailIsNotValid')
              break
            case 'user_telephone_too_long':
              this.errors.telephone.status = true
              this.errors.telephone.message = this.$t(
                'users.errorTelephoneIsTooLong'
              )
              break
            case 'username_or_email_exists':
              this.errors.username.status = true
              this.errors.username.message = this.$t(
                'users.errorUsernameOrEmailExists'
              )
              this.errors.email.status = true
              this.errors.email.message = this.$t(
                'users.errorUsernameOrEmailExists'
              )
              break
            default:
            // console.error('[users.createOrModify] Creating user error not managed: ' + error.data.error)
          }
        })
    },
    prepareData: function () {
      if (this.viewMode === 'edit') {
        this.user = this.getUserById(this.$route.params.userId)
      } else if (this.viewMode === 'new') {
        if (this.customizationType === 'skyit') {
          this.user = {
            id: uuid.v4(),
            role: this.roles[0].id,
            language: 'it-IT',
            timeZone: 'Europe/Rome'
          }
        } else if (this.customizationType === 'tabcorp') {
          this.user = {
            id: uuid.v4(),
            role: this.roles[0].id,
            language: 'en-AU',
            timeZone: 'Australia/Sydney'
          }
        } else {
          this.user = {
            id: uuid.v4(),
            role: this.roles[0].id,
            language: this.myAccount.language,
            timeZone: this.myAccount.timeZone
          }
        }
      }
    },
    backToUsers: function () {
      this.$router.push('/users')
    }
  }
}
</script>
