<template>
  <v-card :loading="loading">
    <v-card-title>
      {{ $t("headendCaInfo.title") }}
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <thead>
          <tr>
            <th>{{ $t("headendCaInfo.id") }}</th>
            <th>{{ $t("headendCaInfo.serialNumber") }}</th>
            <th>{{ $t("headendCaInfo.version") }}</th>
            <th>{{ $t("headendCaInfo.smc") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in caInfo"
            :key="i"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.serialNumber }}</td>
            <td>{{ item.version }}</td>
            <td>{{ item.smc }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import CAInfoService from '@/services/skyit/CAInfoService'

export default {
  name: 'HeadendCaInfo',
  components: {},
  props: {
    headend: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      caInfo: []
    }
  },
  computed: {},
  mounted () {
    this.loading = true
    CAInfoService.getCAInfoServices(this.headend.id)
      .then((response) => {
        this.loading = false
        this.caInfo = response.data
        this.caInfo.map((item) => {
          if (item.serialNumber === undefined) {
            item.smc = this.$t('headendCaInfo.camNotDetected')
          } else {
            if (item.smc !== undefined) {
              item.smc = '000' + item.smc
            } else {
              item.smc = this.$t('headendCaInfo.cardNotDetected')
            }
          }
        })
      })
      .catch(() => {
        this.loading = false
      })
  },
  methods: {}
}
</script>
