<template>
  <v-dialog
    :value="value"
    scrollable
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('deployChannelMap.loadChannelMapTemplateTitle') }}</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :items="channelMaps"
          :sort-by="['name']"
          :search="filters.search"
          :headers="headers"
          :footer-props="footerProps"
        >
          <template #top>
            <v-toolbar
              flat
              color="white"
            >
              <v-text-field
                v-model="filters.search"
                append-icon="mdi-magnify"
                :label="$t('label.search')"
                single-line
                hide-details
              />
              <v-spacer />
            </v-toolbar>
          </template>
          <!-- Selections: cell -->
          <template #[`item.selections`]="{ item }">
            <span>
              <v-btn
                v-if="isSelected(item)"
                text
                icon
                @click="deselect(item)"
              >
                <v-icon
                  color="primary"
                >
                  mdi-radiobox-marked
                </v-icon>
              </v-btn>
              <v-btn
                v-else
                text
                icon
                @click="select(item)"
              >
                <v-icon>
                  mdi-checkbox-blank-circle-outline
                </v-icon>
              </v-btn>
            </span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          :title="$t('button.cancel')"
          @click="close"
        >
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="selectedItem.id === undefined"
          :title="$t('deployChannelMap.select')"
          @click="confirmed"
        >
          {{ $t('deployChannelMap.select') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ChannelMapLoadDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedItem: {},
      filters: {
        search: null
      },
      headers: [
        {
          text: this.$t('channelMaps.name'),
          align: 'left',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('channelMaps.description'),
          align: 'left',
          sortable: true,
          value: 'description'
        },
        {
          text: '',
          align: 'end',
          sortable: false,
          value: 'selections'
        }
      ],
      footerProps: { // v-data-table footer props
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('channelMappingPerPageText')
      }
    }
  },
  computed: {
    channelMaps () {
      return this.$store.state.channelMapTemplates.all
    }
  },
  watch: {
    value: {
      handler: function (newVal) {
        if (newVal === true) {
          this.selectedItem = {}
        }
      }
    }
  },
  methods: {
    isSelected (item) {
      return this.selectedItem.id === item.id
    },
    deselect: function (item) {
      this.selectedItem = {}
    },
    select: function (item) {
      this.selectedItem = item
    },
    close: function () {
      this.$emit('input', false)
    },
    confirmed: function () {
      this.$emit('confirmed', this.selectedItem)
      this.close()
    }
  }
}
</script>
