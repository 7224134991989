<template>
  <v-container v-if="isHeadendFirmwareValid">
    <div class="modules-container">
      <div
        class="d-flex justify-start overflow-x-auto align-stretch modules"
      >
        <!-- FIRST BLOCK OF 5 MODULES -->
        <div class="modules-first module__block">
          <Module
            v-for="(module, index) in getFirstBlockOfModules"
            :key="(index+1)"
            :module="module.module"
            :index="module.index"
          />
        </div>
        <!-- SECOND BLOCK OF 5 MODULES -->
        <div
          v-if="showSecondBlockOfModules"
          class="modules-second module__block"
        >
          <Module
            v-for="(module, index) in getSecondBlockOfModules"
            :key="(index+1)"
            :module="module.module"
            :index="module.index"
          />
        </div>
      </div>
    </div>
    <div class="statusLastUpdate">
      {{ $t('section.info.lastStatusUpdate') }}&nbsp;<span>{{ getReportStatusLastUpdateDate | formatDate($locale, $timezone) }}</span>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import { NS_HEADEND, NS_REPORT } from '../../store/namespaces'

import Module from './modules/Module.vue'

export default {
  name: 'InfoStatus',
  components: {
    Module
  },
  computed: {
    ...mapGetters(NS_HEADEND, ['getHeadend', 'isHeadendFirmwareValid']),
    ...mapGetters(NS_REPORT, [
      'getConfigurationTotalBases',
      'getConfigurationModulesSortedByBaseAndSlot',
      'getConfigurationFirstBaseTotalModules',
      'getConfigurationSecondBaseTotalModules',
      'getReportStatusLastUpdate'
    ]),
    headendHasMultipleBases: function () {
      return this.getConfigurationTotalBases > 1
    },
    getFirstBlockOfModules: function () {
      const modules = []
      const presentModules = this.getConfigurationModulesSortedByBaseAndSlot

      // The headend should have only 1 base of 5 or 10 modules
      if (!this.headendHasMultipleBases) {
        for (let i = 1; i <= 5; i++) {
          const module = presentModules.find((module) => {
            return module.slot === i
          })
          modules.push({
            index: i,
            module: module
          })
        }
      } else {
        const firstBaseTotalModules = this.getConfigurationFirstBaseTotalModules
        if (firstBaseTotalModules === 5) {
          const firstBasePresentModules = this.getConfigurationModulesSortedByBaseAndSlot.filter((module) => {
            return module.base === 1 // We asume that base number is the position order in the array of bases
          })
          for (let i = 1; i <= 5; i++) {
            const module = firstBasePresentModules.find((module) => {
              return module.slot === i
            })
            modules.push({
              index: i,
              module: module
            })
          }
        }
      }
      return modules
    },
    getSecondBlockOfModules: function () {
      const modules = []
      const presentModules = this.getConfigurationModulesSortedByBaseAndSlot

      // The headend should have only 1 base of 5 or 10 modules
      if (!this.headendHasMultipleBases) {
        for (let i = 6; i <= 10; i++) {
          const module = presentModules.find((module) => {
            return module.slot === i
          })
          modules.push({
            index: i,
            module: module
          })
        }
      } else {
        const secondBaseTotalModules = this.getConfigurationSecondBaseTotalModules
        if (secondBaseTotalModules === 5) {
          const secondBasePresentModules = this.getConfigurationModulesSortedByBaseAndSlot.filter((module) => {
            return module.base === 2 // We asume that base number is the position order in the array of bases
          })
          for (let i = 1; i <= 5; i++) {
            const module = secondBasePresentModules.find((module) => {
              return module.slot === i
            })
            modules.push({
              index: (i + 5), // Set the correct display index position of the module
              module: module
            })
          }
        }
      }
      return modules
    },
    getReportStatusLastUpdateDate: function () {
      return this.getReportStatusLastUpdate
    },
    showSecondBlockOfModules: function () {
      const presentModules = this.getSecondBlockOfModules.filter(module => {
        return module.module !== undefined && module.module !== null
      })
      return this.getSecondBlockOfModules.length > 0 && presentModules.length > 0
    }
  }
}
</script>

<style>
.module__block {
  position: relative;
  display: flex;
  column-gap: 0.875rem;
  border-top: 0.625rem solid #999;
  border-bottom: 0.625rem solid #999;
  padding: 0.625rem 1.375rem;
  align-self: flex-start;
}
.module__block:before {
  content:"";
  position: absolute;
  width: 0.25rem;
  height: 1.25rem;
  background-color: #999;
  top: -0.625rem;
  left: 0;
}
.module__block .module:last-child:before {
  content:"";
  position: absolute;
  width: 0.25rem;
  height: 1.25rem;
  background-color: #999;
  top: -0.625rem;
  right: 0;
}
.module__block:after {
  content:"";
  position: absolute;
  width: 0.25rem;
  height: 1.25rem;
  background-color: #999;
  bottom: -0.625rem;
  left: 0;
}
.module__block .module:last-child:after {
  content:"";
  position: absolute;
  width: 0.25rem;
  height: 1.25rem;
  background-color: #999;
  bottom: -0.625rem;
  right: 0;
}
.modules-container {
  display: flex;
  min-height: 38.75rem;
}
.modules {
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 0 auto;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -ms-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
}
.module {
  display:flex;
  flex-direction: column;
}
.module__body {
  border-left: 1px solid #999;
  border-right: 1px solid #999;
  min-height: 32rem;
}
.module__body__error {
  background-color: #F7BBBC;
}
.module__top {
  min-height: 15.063rem;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.module__middle {
  min-height: 5.5rem;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.module__name {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 1.75rem;
}
.modules .module .module__name  a {
  font-size: 0.625rem;
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
}
.module__bottom {
  min-height: 9.938rem;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.module__group {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.module__number {
  min-height: 3.438rem;
  border-top: 1px solid #999;
  display:flex;
  font-size: 1rem;
}
.modules .module .module__number div, .modules .module .module__number span {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-self: stretch;
  color: rgba(0, 0, 0, 0.87);
}
.modules .module .empty-slot {
  background-image: none;
}
.statusLastUpdate {
  font-size: small;
}
.statusLastUpdate span {
  font-weight: bold;
}
</style>
