<template>
  <v-data-table
    :items-per-page="-1"
    hide-default-footer
    :items="globalOutputList"
    :headers="headersForOutputServices"
  >
    <template #[`item.serviceId`]="{ item }">
      {{ parseInt(item.serviceId) === 0 ? $t('emptyService') : item.serviceId }}
    </template>
    <template #[`item.input`]="{ item }">
      <v-icon
        v-if="!checkServiceInput(item)"
        color="red"
        :title="$t('channelMapEdit.serviceNotAtInput')"
      >
        mdi-alert
      </v-icon>
      {{ parseInt(item.inputServiceId) === 0 ? '-' : item.input }}
    </template>
    <template #[`item.lcn`]="{ item }">
      <v-text-field
        v-model="item.lcn"
        :rules="[ rules.required, rules.lcn ]"
        type="number"
      />
    </template>
    <template #[`item.outputName`]="{ item }">
      <v-text-field
        v-model="item.outputName"
        :rules="[ rules.required ]"
      />
    </template>
    <template #[`item.module`]="{ item }">
      {{ item.module === undefined ? '-' : item.module + 1 }}
    </template>
    <template #[`item.outputServiceId`]="{ item }">
      <v-text-field
        v-model="item.outputServiceId"
        :rules="[ rules.required, rules.outputServiceId ]"
        type="number"
      />
    </template>
    <template #[`item.language`]="{ item }">
      <v-select
        v-model="item.language"
        :items="languages"
        :rules="[ rules.required ]"
      />
    </template>
    <template #[`item.actions`]="{ item }">
      <v-btn
        color="primary"
        icon
        small
        :title="$t('channelMapEdit.deleteService')"
        @click="removeServiceFromOutput(item, item.module)"
      >
        <v-icon>
          mdi-delete
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>

export default {
  name: 'ChannelMapTable',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    channelMaps: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      localValue: [],
      originalChannelMapName: '',
      headersForOutputServices: [
        { text: this.$t('module'), value: 'module', sortable: false },
        { text: this.$t('input'), value: 'input' },
        { text: this.$t('lcn'), value: 'lcn', width: '10%', sortable: false },
        { text: this.$t('name'), value: 'outputName', sortable: false },
        { text: this.$t('inputSid'), value: 'inputServiceId', width: '12%', sortable: false },
        { text: this.$t('outputSid'), value: 'outputServiceId', width: '13%', sortable: false },
        { text: this.$t('language'), value: 'language', width: '18%', sortable: false },
        {
          text: '',
          value: 'actions',
          width: 24 // icon size
        }
      ],
      languages: [
        { value: 'eng', text: 'Default' },
        { value: 'dut', text: 'QLD_TAB' },
        { value: 'fre', text: 'SKY_TAB' },
        { value: 'swe', text: 'TAS_TAB' },
        { value: 'ger', text: 'WA_TAB' },
        { value: 'ita', text: 'SA_TAB' },
        { value: 'spa', text: 'NT_TAB' },
        { value: 'slo', text: 'SSR' }
      ],
      lcnMaxValue: 999,
      lcnMinValue: 1,
      outputMaxValue: 65535,
      outputMinValue: 1,
      rules: { // Specific rules so that the form can de validated
        required: value => !!value || this.$t('validation.requiredField'),
        lcn: value => {
          if (value > this.lcnMaxValue) return this.$t('validation.maxValue', { value: 999 })
          else if (value < this.lcnMinValue) return this.$t('validation.minValue', { value: 1 })
          else if (this.localValue.filter(service => {
            return parseInt(service.lcn) === parseInt(value)
          }).length > 1) return this.$t('validation.repeatedValue')
          else return true
        },
        outputServiceId: value => {
          if (value > this.outputMaxValue) return this.$t('validation.maxValue', { value: 65535 })
          else if (value < this.outputMinValue) return this.$t('validation.minValue', { value: 1 })
          else if (this.globalOutputList.filter(items => {
            return parseInt(items.outputServiceId) === parseInt(value)
          }).length > 1) return this.$t('validation.repeatedValue')
          else return true
        }
      }
    }
  },
  computed: {
    globalOutputList: function () { // All the outputs with added module info joint in one array
      let outputList = []
      this.localValue.forEach((module) => {
        outputList = outputList.concat(...module.services)
      })
      return outputList
    }
  },
  watch: {
    localValue (newValue) {
      this.$emit('input', newValue)
    },
    value (newValue) {
      this.localValue = newValue
    }
  },
  methods: {
    checkServiceInput: function (service) {
      if (parseInt(service.inputServiceId) === 0 || this.channelMaps.length === 0) {
        // Los empty services no sacaran error
        return true
      } else if (typeof this.channelMaps[service.module] === 'undefined') {
        return false
      } else {
        return Object.entries(this.channelMaps[service.module]).some(([inputKey, inputData]) => {
          if (parseInt(service.input) === parseInt(inputKey)) {
            return inputData.inputServices.some(inputService => {
              return parseInt(service.inputServiceId) === parseInt(inputService.serviceId)
            })
          } else {
            return false
          }
        })
      }
    },
    removeServiceFromOutput: function (service, moduleSlot) {
      this.localValue[moduleSlot].services.splice(this.localValue[moduleSlot].services.findIndex(activeService => {
        return service === activeService
      }), 1)
    }
  }
}
</script>
