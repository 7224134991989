<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t('ordersExecuted.viewTitle') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="4"
            md="4"
          >
            <EditDateTime
              v-model="search.startAt"
              :max-date="search.endAt"
              :date-label="$t('ordersExecuted.startAtDate')"
              :time-label="$t('ordersExecuted.startAtTime')"
            />
          </v-col>
          <v-col
            cols="4"
            md="4"
          >
            <EditDateTime
              v-model="search.endAt"
              :min-date="search.initAt"
              :max-date="new Date().toISOString()"
              :date-label="$t('ordersExecuted.endAtDate')"
              :time-label="$t('ordersExecuted.endAtTime')"
            />
          </v-col>
          <v-col
            cols="3"
            md="3"
          >
            <v-select
              v-model="search.type"
              :label="$t('ordersExecuted.action')"
              :items="commandTypeOptions()"
            />
          </v-col>
          <v-col
            cols="1"
            md="1"
          >
            <v-spacer />
            <v-btn
              color="primary"
              @click="searchExecutedOrders"
            >
              <v-icon left>
                mdi-magnify
              </v-icon>
              {{ $t('ordersExecuted.search') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <OrdersExecutedTable
              :items="executedOrders"
              :actions="['scheduled','view']"
              :columns="[
                'scheduledDate',
                'name',
                'description',
                'action',
                'devices',
                'groups',
                'okay',
                'error',
                'status',
              ]"
              @scheduledItem="viewScheduledItem"
              @viewItem="viewItem"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!--- ORDER SCHEDULE DIALOG -->
    <OrdersScheduleDialog
      v-model="scheduleDialog.show"
      :title="scheduleDialog.title"
      :schedule-id="scheduleDialog.scheduleId"
    />
    <!-- ORDER EXECUTION INFO DIALOG -->
    <v-dialog
      v-model="executionInfoDialog.show"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <OrderExecutionInfo
        :order="executionInfoDialog.order"
        :load="executionInfoDialog.show"
        @close="executionInfoDialog.show = false"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { CHANNEL_MAP_TEMPLATES_GET_ALL } from '@/store/actions/channelMapTemplates'
import { ORDERS_SCHEDULES_GET_ALL } from '@/store/actions/ordersSchedules'
import { FIRMWARES_GET_ALL } from '@/store/actions/firmwares'
import { HEADENDS_GET_ALL } from '@/store/actions/headends'
import { HEADEND_TYPES_GET_ALL } from '@/store/actions/headendTypes'
import { GROUPS_GET_ALL } from '@/store/actions/groups'
import EditDateTime from '@/components/common/EditDateTime.vue'
import OrdersExecutedTable from '@/views/ordersExecuted/components/OrdersExecutedTable.vue'
import OrdersScheduleDialog from '@/views/ordersExecuted/components/OrdersScheduleDialog.vue'
import OrderExecutionInfo from '@/views/ordersExecuted/components/OrderExecutionInfo.vue'
import orderTypeList from '@/mixins/orderTypeList'

import OrderExecutionsService from '@/services/orders/OrderExecutionsService'

export default {
  name: 'OrdersExecuted',
  components: {
    EditDateTime,
    OrdersExecutedTable,
    OrdersScheduleDialog,
    OrderExecutionInfo
  },
  mixins: [orderTypeList],
  data () {
    return {
      search: {
        startAt: (new Date((new Date()).getTime() - (7 * 24 * 60 * 60 * 1000))).toISOString(),
        endAt: new Date().toISOString(),
        type: 'all'
      },
      executedOrders: [],
      scheduleDialog: {
        show: false,
        title: '',
        scheduleI: ''
      },
      executionInfoDialog: {
        show: false,
        order: {}
      }
    }
  },
  computed: {
    ...mapGetters(['getOrderScheduleById']),
    customizationType: function () {
      return this.$store.getters['customization/customizationType']
    }
  },
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    Promise.all([
      this.$store.dispatch(HEADENDS_GET_ALL)
    ])
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.searchExecutedOrders()
      })
      .catch(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_ERROR,
          this.$t('installerAssignments.errorLoadingInstaller')
        )
      })
  },
  methods: {
    commandTypeOptions: function () {
      const orderTypes = this.orderTypeList(this.customizationType)
      orderTypes.unshift({
        text: this.$t('ordersExecuted.all'),
        value: 'all'
      })
      return orderTypes
    },
    searchExecutedOrders: function () {
      this.$store.dispatch(LOADING_INCREASE)
      let promise
      if (this.search.type === 'all') {
        promise = OrderExecutionsService.getExecutedOrders(this.search.startAt, this.search.endAt)
      } else {
        promise = OrderExecutionsService.getExecutedOrders(this.search.startAt, this.search.endAt, this.search.type)
      }
      promise
        .then((response) => {
          this.$store.dispatch(LOADING_DECREASE)
          this.executedOrders = response.data
        }).catch((error) => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error && error.response && error.response.status !== 401) {
            this.$store.dispatch(SNACKBAR_ERROR, this.$t('ordersExecuted.errorSearching'))
          }
        })
    },
    viewItem: function (item) {
      this.executionInfoDialog = {
        show: true,
        order: item
      }
    },
    viewScheduledItem: function (item) {
      if (this.getOrderScheduleById(item.scheduleId)) {
        this.$store.dispatch(ORDERS_SCHEDULES_GET_ALL)
        this.$store.dispatch(FIRMWARES_GET_ALL)
        this.$store.dispatch(HEADENDS_GET_ALL)
        this.$store.dispatch(GROUPS_GET_ALL)
        if (this.$store.getters['customization/isTabcorp']) {
          this.$store.dispatch(CHANNEL_MAP_TEMPLATES_GET_ALL)
        }
        this.scheduleDialog = {
          show: true,
          title: item.name,
          scheduleId: item.scheduleId
        }
      } else {
        this.$store.dispatch(LOADING_INCREASE)
        const promises = [
          this.$store.dispatch(ORDERS_SCHEDULES_GET_ALL),
          this.$store.dispatch(HEADEND_TYPES_GET_ALL),
          this.$store.dispatch(FIRMWARES_GET_ALL),
          this.$store.dispatch(HEADENDS_GET_ALL),
          this.$store.dispatch(GROUPS_GET_ALL)
        ]
        if (this.$store.getters['customization/isTabcorp']) {
          promises.push(this.$store.dispatch(CHANNEL_MAP_TEMPLATES_GET_ALL))
        }
        Promise.all(promises).then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.scheduleDialog = {
            show: true,
            title: item.name,
            scheduleId: item.scheduleId
          }
        }).catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
        })
      }
    }
  }
}
</script>
