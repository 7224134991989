<template>
  <v-container fluid>
    <ConfirmDialog
      v-model="dialogControl.showDeleteConfirmDialog"
      :item-id="activeGroupId"
      :title="$t('groupDeleteDialog.title')"
      :message="$t('groupDeleteDialog.message')"
      @confirmed="deleteGroup"
    />
    <v-card>
      <v-card-title class="title">
        {{ $t('groups.groups') }}
        <v-spacer />
        <v-btn
          color="primary"
          :title="$t('button.newGroup')"
          @click="createGroup"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('button.newGroup') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <GroupsTable
          :items="groups"
          selection="none"
          :actions="['edit','delete']"
          @editItem="editGroup"
          @deleteItem="confirmDeleteGroup"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { GROUPS_GET_ALL, GROUPS_DELETE } from '@/store/actions/groups'
import { mapState } from 'vuex'
import GroupsTable from '@/views/groups/components/GroupsTable.vue'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

export default {
  name: 'Groups',
  components: {
    GroupsTable,
    ConfirmDialog
  },
  data () {
    return {
      activeGroupId: null,
      dialogControl: {
        showDeleteConfirmDialog: false,
        showViewDialog: false
      }
    }
  },
  computed: {
    ...mapState({
      groups: state => {
        return state.groupsNew.all
      }
    })
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData: function () {
      Promise.all([this.$store.dispatch(GROUPS_GET_ALL)]).then(() => {
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('success.loadingData'))
      }).catch(() => {
        this.$store.dispatch(SNACKBAR_ERROR, this.$t('error.loadingData'))
      })
    },
    confirmDeleteGroup: function (group) {
      this.activeGroupId = group.id
      this.dialogControl.showDeleteConfirmDialog = true
    },
    viewGroup: function (groupId) {
      this.activeGroupId = groupId
      this.dialogControl.showViewDialog = true
    },
    deleteGroup: function (groupId) {
      this.$store.dispatch(GROUPS_DELETE, groupId)
    },
    createGroup: function () {
      this.$router.push('/groups/new')
    },
    editGroup: function (group) {
      this.$router.push('/groups/edit/' + group.id)
    }
  }
}
</script>
