import {
  ROLES_GET,
  ROLES_CLEAR
} from '@/store/actions/roles'

import {
  AUTH_LOGOUT
} from '@/store/actions/authentication'

import rolesApi from '@/services/api/authorization/Roles'

export default {
  state: {
    all: []
  },
  mutations: {
    [ROLES_GET]: (state, data) => {
      state.all = data
    },
    [ROLES_CLEAR]: state => {
      state.all.length = 0
    }
  },
  actions: {
    [ROLES_GET]: ({ commit, state, dispatch }) => {
      return new Promise((resolve, reject) => {
        if (state.all.length > 0) {
          resolve(state.all)
          return
        }
        rolesApi.get().then((response) => {
          // console.log('[store.roles] Roles lodaded')
          const info = response.data
          commit(ROLES_GET, info)
          resolve(info)
        })
          .catch((error) => {
            // console.error('[store.roles] Error loading roles')
            if (error.response !== undefined && error.response.status === 401) {
              dispatch(AUTH_LOGOUT)
            }
            reject(error)
          })
      })
    },
    [AUTH_LOGOUT]: ({ commit }) => {
      commit(ROLES_CLEAR)
    }
  },
  getters: {
  }
}
