<template>
  <v-container fluid>
    <ConfirmDialog
      v-model="deleteConfirmDialog.show"
      :item-id="deleteConfirmDialog.itemId"
      :title="$t('ordersSchedules.deleteItemTitle')"
      :message="deleteConfirmDialog.message"
      @confirmed="deleteItem"
    />
    <v-card>
      <v-card-title>
        {{ $t('ordersSchedules.viewTitle') }}
      </v-card-title>
      <v-card-text>
        <OrderTemplatesTable
          :items="orders"
          :actions="['view','delete']"
          :columns="[
            'name',
            'type',
            'devices',
            'groups',
            'creationDate',
            'nextOrderDate'
          ]"
          @viewItem="viewItem"
          @deleteItem="confirmDeleteItem"
        >
          <template #default>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="newItem"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
              {{ $t('ordersSchedules.newSchedule') }}
            </v-btn>
          </template>
        </OrderTemplatesTable>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import OrderTemplatesTable from '@/views/orderTemplates/components/OrderTemplatesTable.vue'

import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { ORDERS_SCHEDULES_GET_ALL, ORDERS_SCHEDULES_DELETE } from '@/store/actions/ordersSchedules'

import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

export default {
  name: 'OrdersSchedules',
  components: {
    ConfirmDialog,
    OrderTemplatesTable
  },
  data () {
    return {
      orderTimeout: undefined,
      deleteConfirmDialog: { show: false }
    }
  },
  computed: {
    orders () {
      return this.$store.state.ordersSchedules.all
    }
  },
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    this.$store
      .dispatch(ORDERS_SCHEDULES_GET_ALL)
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('orders.loaded'))
      })
      .catch(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_ERROR,
          this.$t('orders.errorLoadingOrders')
        )
      })
      .finally(() => {
        this.orderTimeout = setTimeout(() => {
          this.loadOrdersInfo()
        }, 10000)
      })
  },
  destroyed () {
    clearTimeout(this.orderTimeout)
  },
  methods: {
    loadOrdersInfo: function () {
      this.$store.dispatch(ORDERS_SCHEDULES_GET_ALL).finally(() => {
        this.orderTimeout = setTimeout(() => {
          this.loadOrdersInfo()
        }, 10000)
      })
    },
    newItem: function () {
      this.$router.push('/ordersSchedules/new')
    },
    viewItem: function (item) {
      this.$router.push('/ordersSchedules/view/' + item.id)
    },
    confirmDeleteItem (item) {
      this.deleteConfirmDialog.show = true
      this.deleteConfirmDialog.message = this.$t(
        'ordersSchedules.deleteItemMessage',
        item
      )
      this.deleteConfirmDialog.itemId = item.id
    },
    deleteItem (itemId) {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(ORDERS_SCHEDULES_DELETE, itemId)
        .then(value => {
          this.$store.dispatch(LOADING_DECREASE)
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('orderTemplates.errorDeletingOrderTemplate')
          )
        })
    }
  }
}
</script>
