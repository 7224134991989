<template>
  <div class="d-flex">
    <ModuleDialog
      v-model="outputDialog.show"
      :title="outputDialog.title"
      type="outputRF"
      :module="module"
      :outputs="outputs"
    />
    <a
      :class="getClassName"
      title="Output RF"
      @click="showOutputDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../../store/namespaces'

// common
import ModuleDialog from '../../../common/ModuleDialog.vue'

export default {
  name: 'SlotOutputRF',
  components: {
    ModuleDialog
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    outputs: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      outputDialog: {
        show: false
      }
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getStatusModuleBySlot']),
    isAnyOutputEnabled: function () {
      if (this.outputs !== undefined && this.outputs !== null && this.outputs.length > 0) {
        const enabledOutputs = this.outputs.filter((output) => {
          return output.enabled
        })
        return enabledOutputs.length > 0
      }
      return false
    },
    hasAlarms: function () {
      let alarms = []
      if (this.isAnyOutputEnabled) {
        this.outputs.forEach((output) => {
          const outputStatus = this.getStatusModuleBySlot(this.module.slot)?.outputsRF?.find((outputRF) => {
            return outputRF.id === output.id
          })
          if (outputStatus !== undefined && outputStatus !== null && outputStatus.alarms !== undefined && outputStatus.alarms.length > 0) {
            alarms = alarms.concat(outputStatus.alarms)
          }
        })
      }
      return alarms.length > 0
    },
    getClassName: function () {
      let className = 'module__output-rf'
      if (this.isAnyOutputEnabled) {
        if (this.hasAlarms) {
          className = className.concat(' warning-red')
        } else {
          className = className.concat(' enabled')
        }
      }
      return className
    }
  },
  methods: {
    showOutputDialog: function () {
      if (this.isAnyOutputEnabled) {
        this.outputDialog.show = true
        this.outputDialog.title = this.$t('moduleDialog.outputRF.title', { moduleInfo: this.getModuleDialogTitle() })
      }
    },
    getModuleDialogTitle: function () {
      return this.$t('moduleDialog.title', { moduleModel: this.module.name, slot: this.module.slot })
    }
  }
}
</script>

<style scoped>
.module__output-rf {
  background-image: url('../../../../assets/images/input-rf.svg');
  width: 1.344rem;
  height: 1.344rem;
  background-size: 1.344rem 1.344rem;
  margin-bottom: 0.406rem;
  cursor: auto;
}
.module__output-rf.enabled {
  background-image: url('../../../../assets/images/input-rf-enabled.svg');
  cursor: pointer;
}
.module__output-rf.warning-orange {
  background-image: url('../../../../assets/images/input-rf-orange.svg');
  cursor: pointer;
}
.module__output-rf.warning-red {
  background-image: url('../../../../assets/images/input-rf-red.svg');
  cursor: pointer;
}
</style>
