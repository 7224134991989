<template>
  <v-card :loading="loading">
    <v-card-title>
      {{ $t("firmwares.uploadNewFirmware") }}
    </v-card-title>
    <v-card-text>
      <v-form
        ref="formUploadFirmware"
        v-model="valid"
      >
        <v-row>
          <v-col>
            <v-select
              v-model="selectedHeadendType"
              :items="headendTypes"
              :label="$t('headendType')"
              :rules="[rules.required]"
              required
            >
              <template #selection="{ item }">
                {{ $t("deviceType." + item) }}
              </template>
              <template #item="{ item }">
                {{ $t("deviceType." + item) }}
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-file-input
              v-model="firmwareFile"
              :rules="[rules.required, rules.name]"
              accept="multipart/form-data"
              :placeholder="$t('firmwares.uploadPlaceholder')"
              :label="$t('firmwares.uploadFilelabel')"
              show-size
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-checkbox
              v-model="isMain"
              :label="$t('firmwares.setMainFirmware')"
              value="false"
              color="primary"
              on-icon="mdi-checkbox-marked-outline"
              off-icon="mdi-checkbox-blank-outline"
              dense
              hide-details
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
        :disabled="loading"
        color="primary"
        @click="cancelUpload"
      >
        {{ $t("button.cancel") }}
      </v-btn>
      <v-btn
        :disabled="!valid && !loading"
        color="primary"
        @click="uploadFirmware"
      >
        {{ $t("firmwares.upload") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { uuid } from 'vue-uuid'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { FIRMWARES_UPLOAD } from '@/store/actions/firmwares'

export default {
  name: 'UploadFirmware',
  props: {
    showing: {
      type: Boolean
    }
  },
  data () {
    return {
      valid: false,
      selectedHeadendType: undefined,
      firmwareFile: undefined,
      dialogUploadFirmware: false,
      loading: false,
      isMain: false,
      rules: {
        required: (value) => !!value || this.$t('validation.requiredField'),
        name: (value) => {
          return (
            (!!value &&
              !this.firmwares.some(
                (firmware) => firmware.filename === value.name
              )) ||
            this.$t('validation.duplicatedFile')
          )
        }
      }
    }
  },
  computed: {
    ...mapState({
      firmwares: (state) => {
        return state.firmwares.all
      },
      headendTypes: (state) => {
        return state.headendTypes.all
      }
    })
  },
  watch: {
    showing () {
      if (this.showing) {
        this.firmwareFile = undefined
        this.validate()
      }
    }
  },
  mounted () {
    if (this.headendTypes.length === 1) {
      this.selectedHeadendType = this.headendTypes[0]
    }
  },
  methods: {
    validate () {
      this.$refs.formUploadFirmware.validate()
    },
    uploadFirmware: function () {
      let tag = ''
      if (this.isMain) {
        tag = 'main'
      }
      const data = {
        id: uuid.v4(),
        tag: tag,
        headendType: this.selectedHeadendType,
        firmware: this.firmwareFile
      }
      this.loading = true
      this.$store
        .dispatch(FIRMWARES_UPLOAD, data)
        .then((response) => {
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('firmwares.firmwareUploaded')
          )
          this.loading = false
          this.$emit('uploadFirmwareDone')
        })
        .catch(() => {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('firmwares.errorUploadingFirmware')
          )
          this.loading = false
        })
        .finally(() => {
          this.dialogUploadFirmware = false
        })
    },
    cancelUpload () {
      this.firmwareFile = undefined
      this.$emit('uploadFirmwareCanceled')
    }
  }
}
</script>
