<template>
  <v-toolbar>
    <v-btn
      icon
      color="primary"
      :title="$t('back')"
      @click="backToHeadends"
    >
      <v-icon>mdi-arrow-left-circle</v-icon>
    </v-btn>
    <v-toolbar-title>
      {{ $t('headendView') }}
    </v-toolbar-title>
  </v-toolbar>
</template>
<script>
import router from '@/router'

export default {
  name: 'HeadendActions',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    backToHeadends: function () {
      router.push('/headends')
    }
  }
}
</script>
