var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.items,"sort-by":['type', 'filename'],"search":_vm.filters.search,"footer-props":_vm.footerProps,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('label.search'),"single-line":"","hide-details":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}}),_c('v-spacer'),_vm._t("default")],2)]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('deviceType.'+item.type))+" ")]}},{key:"item.tag",fn:function(ref){
var item = ref.item;
return [(item.tag)?_c('v-chip',{staticClass:"ma-1",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(item.tag)+" ")]):_vm._e()]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [(_vm.showAction('tag'))?_c('v-btn',{attrs:{"text":"","icon":"","color":"primary","disabled":item.tag==='main',"title":_vm.$t('firmwares.setMainTag')},on:{"click":function($event){return _vm.throwAction('tagItem', item)}}},[_c('v-icon',[_vm._v("mdi-bookmark")])],1):_vm._e(),(_vm.showAction('download'))?_c('v-btn',{attrs:{"text":"","icon":"","color":"primary","title":_vm.$t('firmwares.download')},on:{"click":function($event){return _vm.throwAction('downloadItem', item)}}},[_c('v-icon',[_vm._v("mdi-download")])],1):_vm._e(),(_vm.showAction('delete'))?_c('v-btn',{attrs:{"text":"","icon":"","color":"primary","title":_vm.$t('firmware.delete')},on:{"click":function($event){return _vm.throwAction('deleteItem', item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"header.selections",fn:function(){return [(_vm.selection == 'multiple')?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.selectUnselectAll()}}},[(_vm.areAllSelected())?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked-outline ")]):(_vm.isAnySelected())?_c('v-icon',[_vm._v(" mdi-minus-box-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")])],1):_vm._e()]},proxy:true},{key:"item.selections",fn:function(ref){
var item = ref.item;
return [(_vm.selection != 'none')?_c('span',[(_vm.isSelected(item))?_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.deselect(item)}}},[(_vm.selection === 'multiple')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-checkbox-marked-outline ")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-radiobox-marked ")])],1):_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.select(item)}}},[(_vm.selection === 'multiple')?_c('v-icon',[_vm._v(" mdi-checkbox-blank-outline ")]):_c('v-icon',[_vm._v(" mdi-checkbox-blank-circle-outline ")])],1)],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }