<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-btn
            icon
            color="primary"
            :title="$t('back')"
            @click="backToOrders"
          >
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-toolbar-title v-if="viewMode === 'fromTemplate'">
            {{ $t('newOrder.viewFromTemplateTitle') }}
          </v-toolbar-title>
          <v-toolbar-title v-if="viewMode === 'new'">
            {{ $t('newOrder.viewNewTitle') }}
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-stepper
          v-model="step"
          non-linear
        >
          <v-stepper-header>
            <!-- HEAD STEP 1 - SELECT ORDER -->
            <v-stepper-step
              :complete="step > 1"
              :rules="[() => isInfortmationStepValid || maxStepBeing < 2]"
              editable
              step="1"
            >
              {{ $t('newOrder.fillTheInformation') }}
            </v-stepper-step>
            <!-- HEAD STEP 2 - SELECT HEADENDS -->
            <v-stepper-step
              :complete="step > 2"
              :rules="[() => receiversFullfilled || maxStepBeing < 3]"
              editable
              step="2"
            >
              {{ $t('newOrder.selectReceivers') }}
            </v-stepper-step>
            <!-- HEAD STEP 3 - SELECT WHEN -->
            <v-stepper-step
              editable
              step="3"
            >
              {{ $t('newOrder.selectRecurrency') }}
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <OrderInformation
                v-model="orderSchedule"
                :command-types="commandTypeOptions()"
                @isValid="checkOfInformationStepValidation"
                @orderTypeChanged="commandTypeChanged"
              >
                <template #actions>
                  <v-btn
                    color="primary"
                    @click="nextStep(2)"
                  >
                    {{ $t('newOrder.continue') }}
                  </v-btn>
                </template>
              </OrderInformation>
            </v-stepper-content>
            <!-- STEP 2 - SELECT HEADENDS -->
            <v-stepper-content step="2">
              <OrderReceivers
                v-model="orderSchedule.target"
                :command-type="orderTypeSelected"
              >
                <template #actions>
                  <v-btn
                    color="primary"
                    @click="nextStep(1)"
                  >
                    {{ $t('newOrder.back') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="nextStep(3)"
                  >
                    {{ $t('newOrder.continue') }}
                  </v-btn>
                </template>
              </OrderReceivers>
            </v-stepper-content>
            <!-- STEP 3 - SELECT WHEN -->
            <v-stepper-content step="3">
              <OrderRecurrency
                v-model="orderSchedule.execution"
                :command-type="orderTypeSelected"
              >
                <template #actions>
                  <v-btn
                    color="primary"
                    @click="nextStep(2)"
                  >
                    {{ $t('newOrder.back') }}
                  </v-btn>
                  <v-btn
                    :disabled="!receiversFullfilled || !isInfortmationStepValid"
                    color="primary"
                    @click="saveOrder()"
                  >
                    <v-icon left>
                      mdi-content-save
                    </v-icon>
                    {{ $t('newOrder.save') }}
                  </v-btn>
                </template>
              </OrderRecurrency>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { uuid } from 'vue-uuid'
import router from '@/router'
import {
  SNACKBAR_SUCCESS,
  SNACKBAR_ERROR
} from '@/store/actions/snackbar'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { CHANNEL_MAP_TEMPLATES_GET_ALL } from '@/store/actions/channelMapTemplates'
import { ORDER_TEMPLATES_GET_ALL } from '@/store/actions/ordersTemplates'
import { ORDERS_SCHEDULES_CREATE } from '@/store/actions/ordersSchedules'
import { FIRMWARES_GET_ALL } from '@/store/actions/firmwares'
import { HEADENDS_GET_ALL } from '@/store/actions/headends'
import { HEADEND_TYPES_GET_ALL } from '@/store/actions/headendTypes'
import { GROUPS_GET_ALL } from '@/store/actions/groups'
import orderTypeList from '@/mixins/orderTypeList'

// Step 1
import OrderInformation from '@/views/orderScheduleCreate/components/OrderInformation.vue'
// Step 2
import OrderReceivers from '@/views/orderScheduleCreate/components/OrderReceivers.vue'
// Step 3
import OrderRecurrency from '@/views/orderScheduleCreate/components/OrderRecurrency.vue'

export default {
  name: 'OrderScheduleCreate',
  components: {
    OrderInformation,
    OrderReceivers,
    OrderRecurrency
  },
  mixins: [orderTypeList],
  data () {
    return {
      viewMode: 'new',
      step: 1,
      maxStepBeing: 1,
      isInfortmationStepValid: false,
      orderSchedule: {
        id: '',
        name: '',
        description: '',
        action: {
          type: '',
          info: {
            relativeURL: '',
            method: '',
            payload: ''
          }
        },
        target: {
          devices: [],
          groups: []
        },
        execution: {
          mode: 'ONCE',
          startDate: new Date()
        }
      },
      orderTypeSelected: undefined
    }
  },
  computed: {
    ...mapGetters(['getOrderTemplateById']),
    receiversFullfilled: function () {
      return (
        this.orderSchedule.target.devices.length > 0 ||
        this.orderSchedule.target.groups.length > 0
      )
    },
    customizationType: function () {
      return this.$store.getters['customization/customizationType']
    }
  },
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    const promises = [
      this.$store.dispatch(ORDER_TEMPLATES_GET_ALL),
      this.$store.dispatch(HEADEND_TYPES_GET_ALL),
      this.$store.dispatch(FIRMWARES_GET_ALL),
      this.$store.dispatch(HEADENDS_GET_ALL),
      this.$store.dispatch(GROUPS_GET_ALL)
    ]
    if (this.$store.getters['customization/isTabcorp']) {
      promises.push(this.$store.dispatch(CHANNEL_MAP_TEMPLATES_GET_ALL))
    }

    Promise.all(promises)
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('success.loadingOrders'))
        if (this.viewMode === 'fromTemplate') {
          this.loadOrder()
        }
      })
      .catch(error => {
        this.$store.dispatch(LOADING_DECREASE)
        if (error.response.status !== 401) {
          this.$store.dispatch(SNACKBAR_ERROR, this.$t('error.loadingOrders'))
        }
      }).finally(() => {
        if (this.viewMode === 'new') {
          this.orderSchedule = {
            id: uuid.v4(),
            name: '',
            description: '',
            action: {
              type: '',
              info: {
                relativeURL: '',
                method: '',
                payload: ''
              }
            },
            target: {
              devices: [],
              groups: []
            },
            execution: {
              mode: 'ONCE',
              startDate: new Date().toISOString()
            }
          }
        }
      })
    if (this.$route.path.includes('fromTemplate')) {
      this.viewMode = 'fromTemplate'
    } else {
      this.viewMode = 'new'
    }
  },
  methods: {
    commandTypeOptions: function () {
      return this.orderTypeList(this.customizationType)
    },
    loadOrder: function () {
      this.orderSchedule = JSON.parse(JSON.stringify(this.getOrderTemplateById(this.$route.params.orderTemplateId)))
      this.orderSchedule.id = uuid.v4()
      this.orderSchedule.execution = {
        mode: 'ONCE',
        startDate: new Date().toISOString()
      }
    },
    backToOrders: function () {
      router.push('/ordersSchedules')
    },
    nextStep: function (nextStep) {
      if (this.maxStepBeing < nextStep) {
        this.maxStepBeing = nextStep
      }
      this.step = nextStep
    },
    checkOfInformationStepValidation: function (isValid) {
      this.isInfortmationStepValid = isValid
    },
    commandTypeChanged: function (newCommandType) {
      this.orderTypeSelected = this.commandTypeOptions().find(
        item => item.value === newCommandType
      )
    },
    saveOrder: function () {
      if (!this.receiversFullfilled || !this.isInfortmationStepValid) return // THERE IS AN ERROR IN THE FORM
      this.createNewOrder()
    },
    createNewOrder: function () {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(ORDERS_SCHEDULES_CREATE, this.orderSchedule)
        .then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('newOrder.newOrderCreated')
          )
          this.backToOrders()
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('newOrder.errorCreatingAOrder')
          )
        })
    }
  }
}
</script>
