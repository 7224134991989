<template>
  <v-container fluid>
    <!-- IMPORT HEADENDS DIALOG -->
    <v-dialog
      v-model="dialog.showingImportHeadends"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <GroupImportHeadends
        :load="dialog.showingImportHeadends"
        @close="dialog.showingImportHeadends = false"
        @save="importHeadendsToGroup"
      />
    </v-dialog>
    <!--END DIALOG-->
    <!-- ADD HEADENDS DIALOG -->
    <v-dialog
      v-model="dialog.showingSelectHeadends"
      scrollable
    >
      <v-card>
        <v-card-title>
          {{ $t('newGroup.selectHeadends') }}
        </v-card-title>
        <v-card-text>
          <HeadendsTable
            v-model="dialog.selection"
            :items="dialog.displaying"
            :columns="headersColumns()"
            selection="multiple"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="dialogSelectionCanceled"
          >
            {{ $t('button.cancel') }}
          </v-btn>
          <v-btn
            color="primary"
            @click="dialogSelectionAcepted"
          >
            {{ $t('button.select') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--END DIALOG-->
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-btn
            icon
            color="primary"
            :title="$t('back')"
            @click="backToGroups"
          >
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-toolbar-title v-if="viewMode === 'edit'">
            {{ $t('newGroup.Edit') }}
          </v-toolbar-title>
          <v-toolbar-title v-if="viewMode === 'new'">
            {{ $t('newGroup.New') }}
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="group.name"
                maxlength="30"
                :rules="[ rules.required ]"
                :label="$t('newGroup.Name')"
              />
              <v-spacer />
              <v-btn
                color="primary"
                class="ma-2"
                dark
                :title="$t('newGroup.importHeadends')"
                @click="showImportHeadendsDialog"
              >
                <v-icon left>
                  mdi-file-import
                </v-icon>
                {{ $t('newGroup.importHeadends') }}
              </v-btn>
              <v-btn
                :disabled="headends.length === groupHeadends.length"
                color="primary"
                class="ma-2"
                dark
                :title="$t('newGroup.selectHeadends')"
                @click="showSelectHeadendsDialog"
              >
                <v-icon left>
                  mdi-plus
                </v-icon>
                {{ $t('newGroup.selectHeadends') }}
              </v-btn>
            </v-card-title>
            <v-card-text>
              <HeadendsTable
                v-model="groupHeadends"
                :items="groupHeadends"
                :columns="headersColumns()"
                :actions="['delete']"
                selection="none"
                @deleteItem="deselectHeadend"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                :disabled="!validForm"
                @click="saveGroup"
              >
                {{ $t('button.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { uuid } from 'vue-uuid'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { GROUPS_GET_ALL, GROUPS_CREATE, GROUPS_UPDATE } from '@/store/actions/groups'
import { HEADENDS_GET_ALL } from '@/store/actions/headends'
import HeadendsTable from '@/views/headends/components/HeadendsTable.vue'
import GroupImportHeadends from '@/views/groupCreateEdit/components/GroupImportHeadends.vue'
import headends from '@/mixins/headends.js'

export default {
  name: 'GroupCreateEdit',
  components: {
    HeadendsTable,
    GroupImportHeadends
  },
  mixins: [headends],
  data () {
    return {
      viewMode: null,
      validForm: null,
      dialog: {
        showingImportHeadends: false,
        showingSelectHeadends: false,
        selection: [],
        displaying: []
      },
      rules: {
        required: value => !!value || 'Required'
      },
      group: {
        id: '',
        name: '',
        devices: []
      },
      groupHeadends: []
    }
  },
  computed: {
    ...mapGetters(['getGroupById']),
    ...mapState({
      headends: state => {
        return state.headendsNew.all
      }
    }),
    customizationType: function () {
      return this.$store.getters['customization/customizationType']
    }
  },
  mounted () {
    if (this.$route.path.includes('edit')) {
      this.viewMode = 'edit'
    } else {
      this.viewMode = 'new'
    }
    this.loadData()
  },
  methods: {
    loadData: function () {
      Promise.all([this.$store.dispatch(HEADENDS_GET_ALL),
        this.$store.dispatch(GROUPS_GET_ALL)]).then(() => {
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('success.loadingData'))
        if (this.viewMode === 'edit') {
          this.loadGroup()
        } else {
          this.group = {
            id: uuid.v4(),
            name: '',
            devices: []
          }
        }
      }).catch(() => {
        this.$store.dispatch(SNACKBAR_ERROR, this.$t('error.loadingData'))
      })
    },
    loadGroup: function () {
      this.group = this.getGroupById(this.$route.params.groupId)
      this.groupHeadends = this.headends.filter((headend) => {
        return this.group.devices.indexOf(headend.id) > -1
      })
    },
    saveGroup: function () {
      if (!this.$refs.form.validate()) return false
      this.group.devices.length = 0
      this.groupHeadends.forEach(headend => this.group.devices.push(headend.id))
      if (this.viewMode === 'new') {
        this.createNewGroup()
      } else {
        this.updateGroup()
      }
    },
    createNewGroup: function () {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store.dispatch(GROUPS_CREATE, this.group)
        .then(response => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('newGroup.groupCreated'))
          this.$router.push('/groups')
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_ERROR, this.$t('newGroup.errorCreatingGroup'))
        })
    },
    updateGroup: function () {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store.dispatch(GROUPS_UPDATE, this.group)
        .then(response => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('newGroup.groupEdited'))
          this.$router.push('/groups')
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_ERROR, this.$t('newGroup.errorEditingGroup'))
        })
    },
    backToGroups: function () {
      this.$router.push('/groups')
    },
    headersColumns: function () {
      const headends = this.headendsHeadersColumns(this.customizationType)
      const index = headends.indexOf('groups')
      if (index > -1) {
        headends.splice(index, 1)
      }
      return headends
    },
    deselectHeadend: function (headend) {
      const index = this.groupHeadends.indexOf(headend)
      if (index > -1) {
        this.groupHeadends.splice(index, 1)
      }
    },
    showImportHeadendsDialog: function () {
      this.dialog.showingImportHeadends = true
      this.dialog.displaying = this.headends.filter(headend => {
        return this.groupHeadends.indexOf(headend) === -1
      })
      this.dialog.selection.length = 0
    },
    showSelectHeadendsDialog: function () {
      this.dialog.showingSelectHeadends = true
      this.dialog.displaying = this.headends.filter(headend => {
        return this.groupHeadends.indexOf(headend) === -1
      })
      this.dialog.selection.length = 0
    },
    arrayUniqueHeadend: function (array) {
      const a = array.concat()
      for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
          if (a[i].id === a[j].id) {
            a.splice(j--, 1)
          }
        }
      }
      return a
    },
    dialogSelectionCanceled: function () {
      this.dialog.showingSelectHeadends = false
      this.dialog.displaying.length = 0
      this.dialog.selection.length = 0
    },
    dialogSelectionAcepted: function () {
      this.groupHeadends = this.arrayUniqueHeadend(this.groupHeadends.concat(this.dialog.selection))
      this.dialog.showingSelectHeadends = false
      this.dialog.displaying.length = 0
      this.dialog.selection.length = 0
    },
    importHeadendsToGroup: function (items) {
      this.groupHeadends = this.arrayUniqueHeadend(this.groupHeadends.concat(items))
    }
  }
}
</script>
