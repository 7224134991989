<template>
  <v-card
    flat
    outlined
  >
    <v-card-title class="subtitle-1">
      {{ $t('repeatEvery') }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          v-if="showUnits > 4"
        >
          <v-text-field
            v-model="data.years"
            min="0"
            type="number"
            :disabled="disabled"
            :suffix="$t('repeatformat.years')"
            :rules="[ rules.required ]"
          />
        </v-col>
        <v-col
          v-if="showUnits > 3"
        >
          <v-text-field
            v-model="data.months"
            min="0"
            type="number"
            :disabled="disabled"
            :suffix="$t('repeatformat.months')"
            :rules="[ rules.required ]"
            :prepend-icon="showUnits === 4 ? 'mdi-plus-circle-outline' : ''"
            @click:prepend="showUnits++"
          />
        </v-col>
        <v-col
          v-if="showUnits > 2"
        >
          <v-text-field
            v-model="data.weeks"
            min="0"
            type="number"
            :disabled="disabled"
            :suffix="$t('repeatformat.weeks')"
            :rules="[ rules.required ]"
            :prepend-icon="showUnits === 3 ? 'mdi-plus-circle-outline' : ''"
            @click:prepend="showUnits++"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="showUnits > 1"
        >
          <v-text-field
            v-model="data.days"
            min="0"
            type="number"
            :disabled="disabled"
            :suffix="$t('repeatformat.days')"
            :rules="[ rules.required ]"
            :prepend-icon="showUnits === 2 ? 'mdi-plus-circle-outline' : ''"
            @click:prepend="showUnits++"
          />
        </v-col>
        <v-col
          v-if="showUnits > 0"
        >
          <v-text-field
            v-model="data.hours"
            min="0"
            type="number"
            :disabled="disabled"
            :suffix="$t('repeatformat.hours')"
            :rules="[ rules.required ]"
            :prepend-icon="showUnits === 1 ? 'mdi-plus-circle-outline' : ''"
            @click:prepend="showUnits++"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="data.minutes"
            min="0"
            type="number"
            :disabled="disabled"
            :suffix="$t('repeatformat.minutes')"
            :rules="[ rules.required ]"
            :prepend-icon="showUnits === 0 ? 'mdi-plus-circle-outline' : ''"
            :append-outer-icon="showUnits > 0 ? 'mdi-minus-circle-outline' : ''"
            @click:append-outer="showUnits--"
            @click:prepend="showUnits++"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import repeatOrderFormatMixins from '@/mixins/repeatOrderFormat.js'
export default {
  name: 'EditInterval',
  mixins: [repeatOrderFormatMixins],
  props: {
    value: {
      type: String,
      default: 'P0DT0H0M'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showUnits: 0,
      data: {
        years: null,
        months: null,
        weeks: null,
        days: null,
        hours: null,
        minutes: null
      },
      rules: {
        required: value => !!value || this.$t('validation.requiredField')
      }
    }
  },
  watch: {
    value: function (newValue) {
      this.parseISO(newValue)
    },
    showUnits: function (newUnits) {
      if (this.showUnits < 5) {
        this.data.years = 0
      }
      if (this.showUnits < 4) {
        this.data.months = 0
      }
      if (this.showUnits < 3) {
        this.data.weeks = 0
      }
      if (this.showUnits < 2) {
        this.data.days = 0
      }
      if (this.showUnits < 1) {
        this.data.hours = 0
      }
    },
    data: {
      handler: function (newData) {
        let isoString = 'P'
        if (this.data.years && this.showUnits > 4) {
          isoString += this.data.years + 'Y'
        }
        if (this.data.months && this.showUnits > 3) {
          isoString += this.data.months + 'M'
        }
        if (this.data.weeks && this.showUnits > 2) {
          isoString += this.data.weeks + 'W'
        }
        if (this.data.days && this.showUnits > 1) {
          isoString += this.data.days + 'D'
        }
        isoString += 'T'
        if (this.data.hours && this.showUnits > 0) {
          isoString += this.data.hours + 'H'
        }
        if (this.data.minutes) {
          isoString += this.data.minutes + 'M'
        }
        this.$emit('input', isoString)
      },
      deep: true
    }
  },
  mounted () {
    this.parseISO(this.value)
  },
  methods: {
    parseISO: function (value) {
      if (value !== null) {
        // const dividedValue = value.match(/^P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/)
        const dividedValue = this.parseISOtoArray(value)

        this.data.minutes = dividedValue[6]
        this.data.hours = dividedValue[5]
        if (this.data.hours) this.showUnits = 1
        this.data.days = dividedValue[4]
        if (this.data.days) this.showUnits = 2
        this.data.weeks = dividedValue[3]
        if (this.data.weeks) this.showUnits = 3
        this.data.months = dividedValue[2]
        if (this.data.months) this.showUnits = 4
        this.data.years = dividedValue[1]
        if (this.data.years) this.showUnits = 5
      }
    },
    setUnit: function (value) {
      this.unit = value
      if (this.quantity) {
        this.$emit('input', this.quantity + ' ' + this.unit)
      }
    },
    setQuantity: function (value) {
      this.quantity = value
      if (this.unit) {
        this.$emit('input', this.quantity + ' ' + this.unit)
      }
    }
  }
}
</script>
