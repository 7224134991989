<template>
  <div class="ml-8 mr-8">
    <div>
      <span class="data__label">{{ $t('moduleDialog.usb.labels.preferred') }}</span>
      <span class="data__value">{{ getUSBPreferred() }}</span>
    </div>
    <div>
      <span class="data__label">{{ $t('moduleDialog.usb.labels.mode') }}</span>
      <span class="data__value">{{ getUSBMode() }}</span>
    </div>
    <div v-if="isNetworkUSBPresentYes">
      <span class="data__label">{{ $t('moduleDialog.usb.labels.mac') }}</span>
      <span class="data__value">{{ getUSBMAC() }}</span>
    </div>
    <div v-if="isNetworkUSBPresentYes">
      <span class="data__label">{{ $t('moduleDialog.usb.labels.link') }}</span>
      <span class="data__value">{{ getUSBLink() }}</span>
    </div>
    <div v-if="isNetworkUSBPresentYes && isModeEnabled">
      <span class="data__label">{{ $t('moduleDialog.usb.labels.ipAddress') }}</span>
      <span class="data__value">{{ getUSBIPAddress() }}</span>
    </div>
    <div v-if="isNetworkUSBPresentYes && isModeEnabled">
      <span class="data__label">{{ $t('moduleDialog.usb.labels.netmask') }}</span>
      <span class="data__value">{{ getUSBNetmask() }}</span>
    </div>
    <div v-if="isNetworkUSBPresentYes && isModeEnabled">
      <span class="data__label">{{ $t('moduleDialog.usb.labels.gateway') }}</span>
      <span class="data__value">{{ getUSBGateway() }}</span>
    </div>
    <div v-if="isNetworkUSBPresentYes && isModeEnabled">
      <span class="data__label">{{ $t('moduleDialog.usb.labels.dns') }}</span>
      <span class="data__value">{{ getUSBDNS() }}</span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { NS_REPORT } from '../../../store/namespaces'

import { ReportStatusModuleNetworkControlPresent } from '../../../types/Report'

export default {
  name: 'NetworkUSBData',
  props: {
    module: {
      type: Object,
      required: true
    },
    usb: {
      // type: Object, // FIXME: Commented until API error is fixed
      default: null
    }
  },
  computed: {
    ...mapGetters(NS_REPORT, ['getConfigurationInternetAccess']),
    isNetworkUSBPresentYes: function () {
      return (this.usb !== undefined && this.usb !== null && this.usb.control !== undefined && this.usb.control !== null && this.usb.control.present === ReportStatusModuleNetworkControlPresent.yes)
    },
    isModeEnabled: function () {
      return this.module.slot === 2
        ? this.getConfigurationInternetAccess.interface.usb.enabled
        : true
    }
  },
  methods: {
    getUSBPreferred: function () {
      if (this.module.slot !== 2) {
        return this.$t('common.no')
      } else {
        return this.getConfigurationInternetAccess.interface.usb.preferred
          ? this.$t('common.yes')
          : this.$t('common.no')
      }
    },
    getUSBMode: function () {
      if (this.module.slot !== 2) {
        return this.$t('common.networkInterface.modes.dhcp')
      } else {
        if (this.getConfigurationInternetAccess.interface.usb.enabled) {
          return this.getConfigurationInternetAccess.interface.usb.dhcp
            ? this.$t('common.networkInterface.modes.dhcp')
            : this.$t('common.networkInterface.modes.fixedIP')
        } else {
          return this.$t('common.networkInterface.modes.disabled')
        }
      }
    },
    getUSBMAC: function () {
      return this.usb.control.mac
    },
    getUSBLink: function () {
      return this.usb.control.link.toUpperCase()
    },
    getUSBIPAddress: function () {
      return this.usb.control.ip
    },
    getUSBNetmask: function () {
      return this.usb.control.mask
    },
    getUSBGateway: function () {
      return this.usb.control.gateway
    },
    getUSBDNS: function () {
      return this.usb.control.dns.join(', ')
    }
  }
}
</script>
<style scoped>
.data__label {
  display: inline-block;
  width: 15em;
  margin-left: 2em;
}
.data__value {
  display: inline-block;
  width: 55%;
}
</style>
