<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        offset-sm="3"
      >
        <v-form
          ref="form"
          v-model="validForm"
          value="false"
        >
          <v-card>
            <v-card-title>
              {{ $t('restorePassword.viewTitle') }}
            </v-card-title>
            <v-card-text>
              <v-icon>
                mdi-information
              </v-icon>
              {{ $t('changePassword.passwordTipText') }}
              <v-text-field
                v-model="newPassword"
                prepend-icon="mdi-lock"
                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :label="$t('changePassword.newPassword')"
                :type="showNewPassword ? 'text' : 'password'"
                :rules="[ rules.required, rules.passwordMaxLength, rules.passwordMinLength, rules.passwordMatch ]"
                counter="20"
                @click:append="showNewPassword = !showNewPassword"
                @click="validateForm"
                @keyup="validateForm"
              />
              <v-text-field
                v-model="repeatNewPassword"
                prepend-icon="mdi-lock"
                :append-icon="showRepeatNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :label="$t('changePassword.newPasswordRepeat')"
                :type="showRepeatNewPassword ? 'text' : 'password'"
                :rules="[ rules.required, rules.passwordMatch, rules.passwordMaxLength, rules.passwordMinLength ]"
                counter="20"
                @click:append="showRepeatNewPassword = !showRepeatNewPassword"
                @click="validateForm"
                @keyup="validateForm"
              />
              <password
                v-model="newPassword"
                :strength-meter-only="true"
                @score="updatePasswordStrengthScore"
              />
              <div class="text-center">
                <span class="caption">
                  {{ $t('changePassword.passwordStrengthMeter') }}:
                </span>
                <span class="font-weight-bold">
                  {{ passwordStrengthText[passwordStrengthScore] }}
                </span>
              </div>
              <v-row
                v-if="error.status"
                class="error--text"
              >
                <v-col>
                  <br>
                  {{ error.message }}
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary darken-1"
                :disabled="!validForm"
                @click="requestNewPassword"
              >
                {{ $t('button.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import Password from 'vue-password-strength-meter'
import router from '@/router'
import AuthenticateService from '@/services/auth/AuthenticationService'
import {
  CUSTOMIZATION_LANGUAGE
} from '@/store/actions/customization'

export default {
  name: 'RestorePassword',
  components: {
    Password
  },
  data () {
    return {
      key: '',
      validForm: false, // Checks if the form is valid
      passwordStrengthScore: 0,
      passwordStrengthText: [
        this.$t('changePassword.veryWeak'),
        this.$t('changePassword.weak'),
        this.$t('changePassword.medium'),
        this.$t('changePassword.hard'),
        this.$t('changePassword.veryHard')
      ],
      rules: {
        required: value => !!value || this.$t('validation.requiredField'),
        passwordMatch: () => this.newPassword === this.repeatNewPassword || this.$t('validation.passwordMismatch'),
        passwordMaxLength: value => value.length < 21 || this.$t('validation.passwordMaxLength', { max: 20 }),
        passwordMinLength: value => value.length > 6 || this.$t('validation.passwordMinLength', { min: 6 })
      },
      newPassword: '',
      showNewPassword: false,
      repeatNewPassword: '',
      showRepeatNewPassword: false,
      error: { status: true, message: '' }
    }
  },
  mounted () {
    this.key = this.$router.currentRoute.query.key
    if (!this.key) {
      this.backToLogin()
      return
    }
    this.$store.dispatch(
      'customization/' + CUSTOMIZATION_LANGUAGE,
      this.$router.currentRoute.query.language,
      { root: true }
    )
  },
  methods: {
    requestNewPassword: function () {
      this.loading = true
      AuthenticateService.restorePassword(this.key, this.newPassword)
        .then(() => {
          this.backToLogin()
        })
        .catch((error) => {
          this.loading = false
          if (error.status === 400) {
            this.error.status = true
            this.error.message = this.$t('restorePassword.keyExpiredRequestANewOne')
          } else if (error.status === 404) {
            this.error.status = true
            this.error.message = this.$t('restorePassword.keyNotExist')
          } else {
            this.error.status = true
            this.error.message = this.$t('restorePassword.serverErrorTryLater')
          }
        })
    },
    validateForm: function () {
      this.$refs.form.validate()
    },
    updatePasswordStrengthScore: function (score) {
      this.passwordStrengthScore = score
    },
    backToLogin: function () {
      router.push('/login')
    }
  }
}
</script>
