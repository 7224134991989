import AuthorizationApi from '@/services/api/AuthorizationApi'

export default {
  refresh (refreshToken) {
    const payload = {
      refresh_token: refreshToken
    }
    return AuthorizationApi().post('/authorization/refresh', payload)
  },
  revoke (refreshToken) {
    return AuthorizationApi().delete('/authorization/' + refreshToken)
  }
}
