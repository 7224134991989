<template>
  <v-container fluid>
    <ConfirmDialog
      v-model="deleteConfirmDialog.show"
      :item-id="deleteConfirmDialog.itemId"
      :title="$t('users.deleteItemTitle')"
      :message="deleteConfirmDialog.message"
      @confirmed="deleteItem"
    />
    <v-card>
      <v-card-title>
        {{ $t('users.viewTitle') }}
      </v-card-title>
      <v-card-text>
        <UsersTable
          :items="users"
          :actions="['edit', 'delete']"
          :columns="[
            'code',
            'email',
            'firstName',
            'secondName',
            'telephone',
            'information',
            'role'
          ]"
          @editItem="editItem"
          @deleteItem="confirmDeleteItem"
        >
          <template #default>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="newItem"
            >
              <v-icon dark>
                mdi-plus
              </v-icon>
              {{ $t('users.addUser') }}
            </v-btn>
          </template>
        </UsersTable>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { USERS_GET_ALL, USERS_DELETE } from '@/store/actions/users'
import { ROLES_GET } from '@/store/actions/roles'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import UsersTable from '@/views/users/components/UsersTable.vue'

export default {
  name: 'Users',
  components: {
    ConfirmDialog,
    UsersTable
  },
  data () {
    return {
      deleteConfirmDialog: { show: false }
    }
  },
  computed: {
    ...mapState({ myAccount: state => state.authentication.account }),
    users () {
      return this.$store.state.users.all.map(user => {
        user.roleTranslation = this.$t('roles.' + user.role)
        return user
      })
    }
  },
  watch: {},
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    Promise.all([
      this.$store.dispatch(ROLES_GET),
      this.$store.dispatch(USERS_GET_ALL)
    ])
      .then(() => {
        // console.log('[views.users] Loaded all data')
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('users.loadedUsers'))
      })
      .catch(() => {
        // console.log('[views.users] Error loading data')
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(SNACKBAR_ERROR, this.$t('users.errorLoadingUsers'))
      })
  },
  methods: {
    confirmDeleteItem (item) {
      this.deleteConfirmDialog.show = true
      this.deleteConfirmDialog.message = this.$t(
        'users.deleteItemMessage',
        item
      )
      this.deleteConfirmDialog.itemId = item.id
    },
    deleteItem (itemId) {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(USERS_DELETE, itemId)
        .then(value => {
          // console.log('[views.users] User with id: ' + itemId + ' deleted')
          this.$store.dispatch(LOADING_DECREASE)
        })
        .catch(() => {
          // console.error('[views.users] User with id: ' + itemId + ' could not be deleted')
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('users.errorDeletingUsers')
          )
        })
    },
    editItem (item) {
      this.$router.push('/users/edit/' + item.id)
    },
    newItem () {
      this.$router.push('/users/new')
    }
  }
}
</script>
