<template>
  <v-container fluid>
    <v-card :flat="true">
      <v-card-text>
        <LogsTable
          :items="logsList"
          :columns="logsColumns()"
          @refreshData="refreshData"
          @downloadLogs="downloadLogs"
          @deleteLogs="deleteLogs"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { NS_LOADING, NS_SNACKBAR, NS_CUSTOMIZATION, NS_HEADEND, NS_LOGS } from '../store/namespaces'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '../store/actions/snackbar'
import { LOADING_INCREASE, LOADING_DECREASE } from '../store/actions/loading'
import { LOGS_GET_ALL, LOGS_CLEAR, LOGS_DELETE } from '../store/actions/logs'

import LogsTable from './logs/LogsTable.vue'

export default {
  name: 'Logs',
  components: {
    LogsTable
  },
  data () {
    return {
      payload: {
        headendId: '',
        language: ''
      }
    }
  },
  computed: {
    ...mapGetters(NS_CUSTOMIZATION, ['timeZone']),
    ...mapGetters(NS_HEADEND, ['getHeadendId']),
    ...mapGetters(NS_LOGS, ['getLogs']),
    logsList: function () {
      const list = this.getLogs
      return list
    }
  },
  destroyed () {
    this.$store.dispatch(NS_LOGS + '/' + LOGS_CLEAR)
  },
  mounted () {
    this.payload = {
      headendId: this.getHeadendId,
      language: this.getLogLanguage()
    }
    this.loadData()
  },
  methods: {
    getLogLanguage: function () {
      const locale = this.$locale
      if (locale === 'en-AU') {
        return 'en_US'
      } else {
        return this.$locale.replace('-', '_')
      }
    },
    logsHeadersColumns: function () {
      return [
        'category',
        'dateTime',
        'source',
        'message'
      ]
    },
    logsColumns: function () {
      return this.logsHeadersColumns()
    },
    loadData: function () {
      this.$store.dispatch(NS_LOADING + '/' + LOADING_INCREASE)
      Promise.all([
        this.$store.dispatch(NS_LOGS + '/' + LOGS_GET_ALL, this.payload)
      ]).then(() => {
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
        this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('success.loadingData'))
      }).catch((error) => {
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
        if (error.status !== 401) {
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_ERROR, this.$t('error.loadingData'))
        }
      }).finally(() => {
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
      })
    },
    refreshData: function () {
      this.loadData()
    },
    getDateTime: function (timestamp) {
      const dateTime = new Date(timestamp * 1000)
      return dateTime
    },
    getLocalDateTime: function (timestamp) {
      return this.$options.filters.formatDate(this.getDateTime(timestamp), this.$locale, this.timeZone)
    },
    downloadLogs: function (logs) {
      let csv = ''
      csv += this.logsColumns()
        .map(column => this.$t('section.logs.table.headers.' + column))
        .join(',')
      csv += '\n'

      logs.forEach(log => {
        const row = []
        this.logsColumns().forEach(column => {
          switch (column) {
            case 'category':
              row.push(log.level)
              break
            case 'dateTime':
              var dateTimeStringify = JSON.stringify(this.getLocalDateTime(log.timestamp))
              row.push(dateTimeStringify)
              break
            case 'source':
              row.push(log.source)
              break
            case 'message':
              var messageStringify = JSON.stringify(log.message)
              row.push(messageStringify)
              break
          }
        })
        csv += row.join(',')
        csv += '\n'
      })
      const anchor = document.createElement('a')
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
      anchor.target = '_blank'
      const now = new Date()
      anchor.download = this.getHeadendId + '-' + this.$t('section.logs.csvFileName') + '-' + now.toISOString() + '.csv'
      anchor.click()
    },
    deleteLogs: function () {
      Promise.all([
        this.$store.dispatch(NS_LOGS + '/' + LOGS_DELETE, this.payload)
      ]).then(() => {
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
        this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_SUCCESS, this.$t('success.deletingData'))
      }).catch((error) => {
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
        if (error.status !== 401) {
          this.$store.dispatch(NS_SNACKBAR + '/' + SNACKBAR_ERROR, this.$t('error.deletingData'))
        }
      }).finally(() => {
        this.$store.dispatch(NS_LOADING + '/' + LOADING_DECREASE)
      })
    }
  }
}
</script>

<style scoped></style>
