<template>
  <v-card tile>
    <v-toolbar
      flat
      dark
      color="primary"
    >
      <v-btn
        icon
        dark
        @click="throwAction('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        {{ $t('orderExecutionInfo.title') }} {{ order.name ? '- ' + order.name : '' }}
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ $t('orderExecutionInfo.resume') }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col md="4">
                  <v-text-field
                    v-model="orderExecutedAtFormated"
                    readonly
                    :label="$t('orderExecutionInfo.executionStartedAt')"
                  />
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="orderInformation.abstract.okay"
                    readonly
                    :label="$t('orderExecutionInfo.okayExecutions')"
                  />
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="orderInformation.abstract.error"
                    readonly
                    :label="$t('orderExecutionInfo.errorExecutions')"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              {{ $t('orderExecutionInfo.executationTableTitle') }}
            </v-card-title>
            <v-card-text>
              <OrderExecutionInfoTable
                :items="orderResults"
                :columns="executionInfoColumns"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        @click="throwAction('close')"
      >
        {{ $t('orderExecutionInfo.close') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { HEADENDS_GET_ALL } from '@/store/actions/headends'
import { SNACKBAR_ERROR } from '@/store/actions/snackbar'
import OrderExecutionsService from '@/services/orders/OrderExecutionsService'
import OrderExecutionInfoTable from '@/views/ordersExecuted/components/OrderExecutionInfoTable'

export default {
  name: 'OrderExecutionInfo',
  components: {
    OrderExecutionInfoTable
  },
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {}
    },
    load: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      orderResults: [],
      executionInfoColumns: [],
      orderInformation: { abstract: { okay: 0, error: 0 } },
      orderExecutedAtFormated: '-'
    }
  },
  computed: {
    ...mapGetters('customization', ['timeZone']),
    ...mapGetters({
      getGroupById: 'getGroupById'
    }),
    ...mapState({
      headends: state => {
        return state.headendsNew.all
      }
    })
  },
  watch: {
    load: function (newVal) {
      if (newVal) {
        this.loadOrderInfo()
      }
    }
  },
  mounted () {
    if (this.load) {
      this.loadOrderInfo()
    }
    if (this.$isSky) {
      // SKY headers in order
      this.executionInfoColumns = ['executionDate', 'deviceId', 'contractId', 'deviceType', 'status']
    } else if (this.$isTabcorp) {
      // Tabcorp headers in order
      this.executionInfoColumns = ['executionDate', 'deviceId', 'venueId', 'status', 'deviceType']
    } else {
      // Default headers in order
      this.executionInfoColumns = ['executionDate', 'deviceId', 'deviceType', 'status']
    }
  },
  methods: {
    loadOrderInfo: function () {
      this.orderInformation = JSON.parse(JSON.stringify(this.order))
      this.orderExecutedAtFormated = this.$options.filters.formatDate(this.order.executionDate, this.$locale, this.timeZone)
      this.$store.dispatch(LOADING_INCREASE)
      const promiseOrder = OrderExecutionsService.getExecutedOrderDetail(this.order.id)

      promiseOrder.then((response) => {
        this.orderResults = response.data
      })
      Promise.all([promiseOrder, this.$store.dispatch(HEADENDS_GET_ALL)])
        .then(() => {
          this.$store.dispatch(LOADING_DECREASE)
        }, reason => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('orderExecutionInfo.errorGettingOrderData')
          )
          this.throwAction('close')
        })
    },
    throwAction: function (action, item) {
      this.$emit(action, item)
    }
  }
}
</script>
