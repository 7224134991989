const headends = {
  methods: {
    headendsHeadersColumns: function (mode) {
      if (mode === 'skyit') {
        // SKY headers in order
        return [
          'remoteSiteDeviceName',
          'province',
          'contractId',
          'id',
          'type',
          'firmwareVersion',
          'systemType',
          'headendStatus',
          'drm',
          'groups',
          'connection'
        ]
      } else if (mode === 'tabcorp') {
        // Tabcorp headers in order
        return [
          'headendStatus',
          'venueId',
          'id',
          'alias',
          'state',
          'firmwareVersion',
          'groups',
          'connection'
        ]
      } else {
        // Default headers in order
        return [
          'headendStatus',
          'id',
          'alias',
          'type',
          'firmwareVersion',
          'groups',
          'connection'
        ]
      }
    }
  }
}

export default headends
