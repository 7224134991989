<template>
  <v-container fluid>
    <ConfirmDialog
      v-model="confirmDialog.show"
      :item-id="confirmDialog.itemId"
      :title="confirmDialog.title"
      :message="confirmDialog.message"
      @confirmed="dialogConfirmed"
    />
    <v-row v-if="headendData">
      <v-col
        cols="12"
        class="pt-0 pb-0"
      >
        <!-- ACTIONS -->
        <HeadendActions :headend="headendData" />
        <!-- ALERTS -->
        <transition name="scroll-x-transition">
          <HeadendAlert :headend="headendData" />
        </transition>
      </v-col>
      <!-- INFORMATION -->
      <v-col cols="12">
        <HeadendInfo :headend="headendData" />
      </v-col>
      <!-- COMMENT -->
      <v-col
        v-if="showing.comment"
        cols="6"
      >
        <HeadendComment :headend="headendData" />
      </v-col>
      <!-- DRM -->
      <v-col
        v-if="showing.drm"
        cols="6"
      >
        <HeadendDRM :headend="headendData" />
      </v-col>
      <!-- CA Info -->
      <v-col
        v-if="showing.caInfo"
        cols="6"
      >
        <HeadendCaInfo :headend="headendData" />
      </v-col>
      <!-- IPs -->
      <v-col
        v-if="showing.ips"
        cols="6"
      >
        <HeadendIps :headend="headendData" />
      </v-col>
      <!-- LINE UPS -->
      <v-col
        v-if="showing.lineUp"
        cols="6"
      >
        <HeadendLineUp :headend="headendData" />
      </v-col>
      <!-- ACTIVE ORDERS -->
      <v-col
        v-if="showing.ordersActive"
        cols="6"
      >
        <v-card>
          <v-card-title class="title">
            {{ $t('orderActive') }}
          </v-card-title>
          <v-card-text>
            <DisplayActiveOrderForId
              :headend-id="headendId"
              :active-orders="activeOrders"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <!-- HISTORIC ORDERS -->
      <v-col
        v-if="showing.ordersHistory"
        cols="6"
      >
        <v-card>
          <v-card-title>
            {{ $t('orderHistory') }}
          </v-card-title>
          <v-card-text>
            <DisplayOrderHistoryForId
              :headend-id="headendId"
              :history-orders="historyOrdersList"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <!-- BACKUP LISTS -->
      <v-col
        v-if="showing.backups"
        cols="12"
        md="6"
      >
        <BackupList
          :headend="headendData"
          :backups="backupsList"
          @apply="confirmApplyBackup"
          @create="confirmCreateBackup"
          @delete="confirmDeleteBackup"
        />
      </v-col>
      <!-- PICTURES -->
      <v-col
        v-if="showing.pictures"
        cols="12"
        md="6"
      >
        <PictureCarousel
          v-if="picturesList.length > 0"
          :headend="headendData"
          :pictures="picturesList"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { HEADENDS_GET_ALL } from '@/store/actions/headends'
import { ORDERS_SCHEDULES_GET_ALL } from '@/store/actions/ordersSchedules'
import PicturesService from '@/services/fleets/PicturesService'
import BackupsService from '@/services/fleets/BackupsService'

import HeadendActions from '@/views/headend/components/HeadendActions'
import HeadendAlert from '@/views/headend/components/HeadendAlert'
import HeadendInfo from '@/views/headend/components/HeadendInfo'
import HeadendComment from '@/views/headend/components/HeadendComment'
// import HeadendIps from '@/views/headend/components/HeadendIps'
import HeadendCaInfo from '@/views/headend/components/HeadendCaInfo'
import HeadendDRM from '@/views/headend/components/HeadendDRM'
import HeadendLineUp from '@/views/headend/components/HeadendLineUp'

import BackupList from '@/components/Headends/BackupList.vue'
import PictureCarousel from '@/components/Headends/PictureCarousel.vue'
import DisplayActiveOrderForId from '@/components/Orders/DisplayActiveOrderForId.vue'
import DisplayOrderHistoryForId from '@/components/Orders/DisplayOrderHistoryForId.vue'
import timeoutCancel from '@/mixins/timeoutCancel.js'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

export default {
  name: 'ViewHeadend',
  components: {
    HeadendActions,
    HeadendAlert,
    HeadendInfo,
    HeadendComment,
    // HeadendIps,
    HeadendCaInfo,
    HeadendDRM,
    HeadendLineUp,
    DisplayActiveOrderForId,
    DisplayOrderHistoryForId,
    BackupList,
    PictureCarousel,
    ConfirmDialog
  },
  mixins: [timeoutCancel],
  data () {
    return {
      confirmDialog: {
        show: false
      },
      headendId: '',
      activeOrdersList: [],
      historyOrdersList: [],
      backupsList: [],
      picturesList: [],
      orderTimeout: null,
      showing: {
        comment: true,
        ordersActive: false,
        ordersHistory: false,
        backups: true,
        pictures: false,
        ips: false,
        caInfo: false,
        lineUp: false,
        drm: false
      }
    }
  },
  computed: {
    ...mapGetters(['getHeadendById']),
    ...mapGetters('orders', ['getOrderById']),
    ...mapGetters(['hasRights']),
    headendData: function () {
      // console.log(this.getHeadendById(this.$route.params.headendId))
      return this.getHeadendById(this.$route.params.headendId)
    },
    activeOrders: function () {
      const orderslist = []
      this.activeOrdersList.forEach(activeOrder => {
        const order = this.getOrderById(activeOrder.orderID)
        const tmpDate = new Date(activeOrder.nextExecDate)
        const date =
          tmpDate.getFullYear() +
          '-' +
          ('0' + (tmpDate.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + tmpDate.getDate()).slice(-2)
        const time =
          ('0' + tmpDate.getHours()).slice(-2) +
          ':' +
          ('0' + tmpDate.getMinutes()).slice(-2)
        order.nextExecDate = date + ' ' + time
        orderslist.push(order)
      })
      return orderslist
    },
    historyOrders: function () {
      const orderslist = []
      this.historyOrdersList.forEach(historyOrder => {
        const tmpDate = new Date(historyOrder.status.createdTime)
        const date =
          tmpDate.getFullYear() +
          '-' +
          ('0' + (tmpDate.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + tmpDate.getDate()).slice(-2)
        const time =
          ('0' + tmpDate.getHours()).slice(-2) +
          ':' +
          ('0' + tmpDate.getMinutes()).slice(-2)
        historyOrder.status.createdTime = date + ' ' + time
        if (historyOrder.status.finishTime) {
          const tmpDate2 = new Date(historyOrder.status.finishTime)
          const date2 =
            tmpDate2.getFullYear() +
            '-' +
            ('0' + (tmpDate2.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + tmpDate2.getDate()).slice(-2)
          const time2 =
            ('0' + tmpDate2.getHours()).slice(-2) +
            ':' +
            ('0' + tmpDate2.getMinutes()).slice(-2)
          historyOrder.status.finishTime = date2 + ' ' + time2
        } else {
          historyOrder.status.finishTime = '-'
        }
        orderslist.push(historyOrder)
      })
      return orderslist
    }
  },
  created () {
    this.headendId = this.$route.params.headendId
  },
  mounted () {
    if (this.$isTabcorp) {
      if (this.hasRights('ordr:sc')) {
        this.showing.ordersActive = true
      }
      if (this.hasRights('ordr:ex')) {
        this.showing.ordersHistory = true
      }
      if (this.hasRights('flt:pc')) {
        this.showing.pictures = true
      }
    } else if (this.$isSky) {
      if (this.hasRights('flt:db')) {
        this.showing.backups = true
      }
      if (this.hasRights('sky:rci')) {
        this.showing.caInfo = true
      }
      if (this.hasRights('flt:dq')) {
        this.showing.drm = true
      }
      this.showing.ips = true
      this.showing.lineUp = true
    } else {
      if (this.hasRights('ordr:sc')) {
        this.showing.ordersActive = true
      }
      if (this.hasRights('ordr:ex')) {
        this.showing.ordersHistory = true
      }
      if (this.hasRights('flt:pc')) {
        this.showing.pictures = true
      }
    }
    this.loadData()
  },
  beforeDestroy () {
    clearTimeout(this.orderTimeout)
  },
  methods: {
    loadPictures: function () {
      const picturesPromise = PicturesService.getPictures(this.headendId)
      picturesPromise.then(response => {
        this.picturesList = response.data
      }).catch(() => {
        this.picturesList = []
      })
      return picturesPromise
    },
    loadBackups: function () {
      const backupsPromise = BackupsService.getBackups(this.headendId)
      backupsPromise.then(response => {
        this.backupsList = response.data
      }).catch(() => {
        this.backupsList = []
      })
      return backupsPromise
    },
    loadData: function () {
      this.$store.dispatch(LOADING_INCREASE)
      const promises = [this.$store.dispatch(HEADENDS_GET_ALL)]
      if (this.showing.ordersActive) {
        promises.push(this.$store.dispatch(ORDERS_SCHEDULES_GET_ALL))
      }
      if (this.showing.pictures) {
        promises.push(this.loadPictures())
      }
      if (this.showing.backups) {
        promises.push(this.loadBackups())
      }
      Promise.all(promises)
        .then(data => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('success.loadingData'))
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.status !== 401) {
            this.$store.dispatch(SNACKBAR_ERROR, this.$t('error.loadingData'))
          }
        })
        .finally(() => {
          this.orderTimeout = setTimeout(() => {
            this.loadStatus()
          }, 60000)
        })
    },
    loadStatus: function () {
      const promises = [this.$store.dispatch(HEADENDS_GET_ALL)]
      if (this.showing.ordersActive) {
        promises.push(this.$store.dispatch(ORDERS_SCHEDULES_GET_ALL))
      }
      if (this.showing.pictures) {
        promises.push(this.loadPictures())
      }
      if (this.showing.backups) {
        promises.push(this.loadBackups())
      }
      Promise.all(promises)
        .finally(() => {
          this.orderTimeout = setTimeout(() => {
            this.loadStatus(false)
          }, 60000)
        })
    },
    confirmApplyBackup: function (backup) {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('backups.confirmApplyBackupTitle')
      this.confirmDialog.message = this.$t(
        'backups.confirmApplyBackupMessage',
        backup
      )
      this.confirmDialog.type = 'applyBackup'
      this.confirmDialog.itemId = backup.url
    },
    confirmCreateBackup: function () {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('backups.confirmCreateBackupTitle')
      this.confirmDialog.message = this.$t(
        'backups.confirmCreateBackupMessage')
      this.confirmDialog.type = 'createBackup'
    },
    confirmDeleteBackup: function (backup) {
      this.confirmDialog.show = true
      this.confirmDialog.title = this.$t('backups.confirmDeleteBackupTitle')
      this.confirmDialog.message = this.$t(
        'backups.confirmDeleteBackupMessage',
        backup
      )
      this.confirmDialog.type = 'deleteBackup'
      this.confirmDialog.itemId = backup.url
    },
    dialogConfirmed: function (itemId) {
      if (this.confirmDialog.type === 'applyBackup') {
        this.applyBackup(itemId)
      }
      if (this.confirmDialog.type === 'createBackup') {
        this.createBackup(itemId)
      }
      if (this.confirmDialog.type === 'deleteBackup') {
        this.deleteBackup(itemId)
      }
    },
    applyBackup: function (url) {
      this.$store.dispatch(LOADING_INCREASE)
      BackupsService.setBackups(this.headendId, url)
        .then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('backups.backupApplied')
          )
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('backups.errorApplyingBackup')
          )
        })
    },
    createBackup: function () {
      this.$store.dispatch(LOADING_INCREASE)
      BackupsService.createBackup(this.headendId).then(() => {
        this.loadBackups()
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_SUCCESS,
          this.$t('backups.backupCreated')
        )
      })
        .catch((error) => {
          if (error.status === 400) {
            // Bad request
          }
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('backups.errorCreatingBackup')
          )
        })
    },
    deleteBackup: function (url) {
      this.$store.dispatch(LOADING_INCREASE)
      BackupsService.deleteBackup(this.headendId, undefined, url)
        .then(() => {
          this.loadBackups()
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('backups.backupDeleted')
          )
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('backups.errorDeletingBackup')
          )
        })
    }
  }
}
</script>
