<template>
  <v-data-table
    :headers="headers"
    :items="activeOrders"
    :footer-props="footerProps"
  >
    <template #[`item.nextExecDate`]="{item}">
      {{ item.nextExecDate | formatDate($locale, $timezone) }}
    </template>
    <template #[`item.options`]="{item}">
      <v-btn
        :to="'/ordersSchedules/edit/' + item.id"
        color="primary"
        icon
        :title="$t('orderActions.edit')"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: 'DisplayActiveOrdersForId',
  props: {
    headendId: {
      type: String,
      default: null
    },
    activeOrders: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      footerProps: {
        disableItemsPerPage: false,
        itemsPerPageOptions: [10, 20, 50, 100, -1],
        itemsPerPageText: this.$t('orders.perPageText')
      }
    }
  },
  computed: {
    headers: function () {
      return [
        {
          text: this.$t('alias'),
          align: 'left',
          value: 'config.alias'
        },
        {
          text: this.$t('dateScheduled'),
          align: 'left',
          value: 'nextExecDate'
        },
        {
          text: '',
          value: 'options'
        }
      ]
    }
  }
}
</script>
