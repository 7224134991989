import reportApi from '@/services/api/ReportsApi'

export default {
  getNotFinishedSchedules () {
    return reportApi()
      .get(
        '/schedule?filters[0][field]=status&filters[0][operator]=!=&filters[0][value]=FINISHED'
      )
      .then(response => {
        return response.data
      })
      .catch(error => {
        throw error
      })
  },
  create (reportTemplate) {
    return reportApi().post('/schedule', reportTemplate)
  },
  delete (id) {
    return reportApi().delete('/schedule/' + id)
  }
}
