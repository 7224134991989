<template>
  <!-- <v-alert
    v-if="areAlarms()"
    type="error"
    border="top"
    prominent
  >
    <div class="title">
      {{ $t('errorAlert') }}
    </div>
    <ul>
      <li
        v-for="[alarm,headends] in activeAlarms"
        :key="alarm"
      >
        {{ $t('alarmIn', { alarm: alarm }) }}
        <a
          v-if="headends.length === 1"
          style="color:inherit;"
          class="headend-link"
          @click="alarmTypeSelected(alarm)"
        >
          {{ $t('numHeadend', { value: headends.length }) }}
        </a>
        <a
          v-else
          style="color:inherit;"
          class="headend-link"
          @click="alarmTypeSelected(alarm)"
        >
          {{ $t('numHeadends', { value: headends.length }) }}
        </a>
      </li>
    </ul>
  </v-alert> -->
  <v-simple-table
    v-if="areAlarms()"
  >
    <template #default>
      <thead>
        <tr>
          <th>
            <v-icon
              medium
              color="red"
            >
              mdi-alert
            </v-icon>
          </th>
          <th
            v-for="item in getAlarmTypes()"
            :key="item.value"
          >
            {{ item.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <v-btn
              @click="cancelErrorAlarms()"
            >
              <v-icon>
                mdi-cancel
              </v-icon>
            </v-btn>
          </td>
          <td
            v-for="item in getAlarmTypes()"
            :key="item.value"
          >
            <v-btn
              v-if="activeAlarms.has(item.value)"
              @click="alarmTypeSelected(item.value)"
            >
              <v-icon
                color="red"
                small
              >
                mdi-alert
              </v-icon>
              {{ activeAlarms.get(item.value).length }}
            </v-btn>
            {{ activeAlarms.has(item.value) ? "" : "0" }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from 'vuex'
import referenceData from '@/mixins/referenceData.js'

export default {
  name: 'HeadendsAlerts',
  mixins: [referenceData],
  data () {
    return {
      applyErrorFilter: []
    }
  },
  computed: {
    ...mapGetters({
      activeAlarms: 'activeHeadendAlarmMap'
    })
  },
  methods: {
    alarmTypeSelected (alarmType) {
      this.$emit('alarmTypeSelected', alarmType)
    },
    areAlarms () {
      return this.activeAlarms.size > 0
    },
    cancelErrorAlarms () {
      this.$emit('cancelErrorAlarms')
    },
    getAlarmTypes () {
      if (this.$isTabcorp) {
        return this.tabcorpAlarmTypes
      } else {
        return this.alarmTypes
      }
    }
  }
}
</script>
