<template>
  <v-container fluid>
    <ConfirmDialog
      v-model="deleteDialog.show"
      :item-id="deleteDialog.itemId"
      :title="$t('deployChannelMap.deleteTitle')"
      :message="deleteDialog.message"
      @confirmed="deleteItem"
    />
    <v-card>
      <v-card-title>
        {{ $t('deployChannelMap.viewTitle') }}
      </v-card-title>
      <v-card-text>
        <ChannelMapDeployTable
          :items="items"
          :columns="['order', 'name', 'description', 'tags']"
          :actions="['edit', 'delete']"
          selection="none"
          @deleteItem="confirmDelete"
          @editItem="editItem"
        >
          <template #actions>
            <v-btn
              class="ma-1"
              color="primary"
              @click="createItem"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              {{ $t('deployChannelMap.new') }}
            </v-btn>
          </template>
        </ChannelMapDeployTable>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import ChannelMapDeployTable from '@/views/channelMapDeploy/components/ChannelMapDeployTable.vue'
import {
  DEVICE_CHANNEL_MAPS_GET_ALL,
  DEVICE_CHANNEL_MAPS_DELETE
} from '@/store/actions/deviceChannelMaps'
export default {
  name: 'ChannelMapDeploy',
  components: {
    ConfirmDialog,
    ChannelMapDeployTable
  },
  data () {
    return {
      deleteDialog: {
        show: false
      }
    }
  },
  computed: {
    items: function () {
      return this.$store.state.deviceChannelMaps.all
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData: function () {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(DEVICE_CHANNEL_MAPS_GET_ALL)
        .then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('deployChannelMap.loadedData'))
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.status !== 401) {
            this.$store.dispatch(SNACKBAR_ERROR, this.$t('deployChannelMap.errorLoadingData'))
          }
        })
    },
    createItem: function () {
      this.$router.push('/channelMapDeploys/new')
    },
    confirmDelete: function (item) {
      this.deleteDialog.show = true
      this.deleteDialog.itemId = item.id
      this.deleteDialog.message = this.$t('deployChannelMap.deleteText', item)
    },
    deleteItem: function (itemId) {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(DEVICE_CHANNEL_MAPS_DELETE, itemId)
        .then(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(SNACKBAR_SUCCESS, this.$t('deployChannelMap.deletedItem'))
        })
        .catch(error => {
          this.$store.dispatch(LOADING_DECREASE)
          if (error.response.status !== 401) {
            this.$store.dispatch(SNACKBAR_ERROR, this.$t('deployChannelMap.errorDeletingItem'))
          }
        })
    },
    editItem: function (item) {
      this.$router.push('/channelMapDeploys/edit/' + item.id)
    }
  }
}
</script>
