<template>
  <div>
    <ModuleAmp
      v-if="isTypeAmp"
      :key="module.slot"
      :module="module"
      :title="getModuleTitle"
    />
    <ModuleEmpty
      v-if="isTypeEmpty"
      :key="index"
      :index="index"
    />
    <ModulePsu
      v-if="isTypePsu"
      :key="module.slot"
      :module="module"
      :title="getModuleTitle"
    />
    <ModuleTransmod
      v-if="isTypeTransmod"
      :key="module.slot"
      :module="module"
      :title="getModuleTitle"
    />
  </div>
</template>
<script>
import ModuleAmp from './ModuleAmp.vue'
import ModuleEmpty from './ModuleEmpty.vue'
import ModulePsu from './ModulePsu.vue'
import ModuleTransmod from './ModuleTransmod.vue'

import { ReportConfigurationModuleType } from '../../../types/Report'

export default {
  name: 'Module',
  components: {
    ModuleAmp,
    ModuleEmpty,
    ModulePsu,
    ModuleTransmod
  },
  props: {
    module: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      required: true
    }
  },
  computed: {
    getModuleType: function () {
      return this.module?.type ?? 'empty'
    },
    getModuleTitle: function () {
      return ((this.module.master) ? this.$t('common.master') + '\n' : '') +
        this.module.model +
        ((this.module.name !== undefined && this.module.name !== null) ? '\n' + this.module.name : '') + '\n' +
        this.module.serial
    },
    isTypeAmp: function () {
      return this.getModuleType === ReportConfigurationModuleType.amp
    },
    isTypeEmpty: function () {
      return this.getModuleType !== ReportConfigurationModuleType.amp && this.getModuleType !== ReportConfigurationModuleType.psu && this.getModuleType !== ReportConfigurationModuleType.transmod
    },
    isTypePsu: function () {
      return this.getModuleType === ReportConfigurationModuleType.psu
    },
    isTypeTransmod: function () {
      return this.getModuleType === ReportConfigurationModuleType.transmod
    }
  }
}
</script>
<style scoped></style>
