<template>
  <v-container fluid>
    <ConfirmDialog
      v-model="deleteConfirmDialog.show"
      :item-id="deleteConfirmDialog.itemId"
      :title="$t('reportTemplates.deleteItemTitle')"
      :message="deleteConfirmDialog.message"
      @confirmed="deleteItem"
    />
    <v-card>
      <v-card-title>
        {{ $t('reportTemplates.viewTitle') }}
      </v-card-title>
      <v-card-text>
        <ReportTemplatesTable
          :items="reportsTemplates"
          :actions="['delete']"
          @deleteItem="confirmDeleteItem"
        >
          <template #default>
            <v-btn
              color="primary"
              @click="addTemplate"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              {{ $t('reportTemplates.newTemplate') }}
            </v-btn>
          </template>
        </ReportTemplatesTable>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapState } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'

import ReportTemplatesTable from '@/views/reportTemplates/components/ReportTemplatesTable'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

import {
  REPORTS_TEMPLATES_GET_ALL,
  REPORTS_TEMPLATES_DELETE
} from '@/store/actions/reportsTemplates'

export default {
  name: 'ReportTemplates',
  components: {
    ConfirmDialog,
    ReportTemplatesTable
  },
  data () {
    return {
      deleteConfirmDialog: { show: false }
    }
  },
  computed: {
    ...mapState({ reportsTemplates: state => state.reportsTemplates.all })
  },
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    Promise.all([this.$store.dispatch(REPORTS_TEMPLATES_GET_ALL)])
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_SUCCESS,
          this.$t('reportTemplates.loaded')
        )
      })
      .catch(error => {
        this.$store.dispatch(LOADING_DECREASE)
        if (error.response.status !== 401) {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('reportTemplates.errorLoadingRequiredData')
          )
        }
      })
  },
  methods: {
    addTemplate: function () {
      this.$router.push('/reportTemplates/new')
    },
    confirmDeleteItem: function (item) {
      this.deleteConfirmDialog.show = true
      this.deleteConfirmDialog.message = this.$t(
        'reportTemplates.deleteItemMessage',
        item
      )
      this.deleteConfirmDialog.itemId = item.id
    },
    deleteItem (itemId) {
      this.$store.dispatch(LOADING_INCREASE)
      this.$store
        .dispatch(REPORTS_TEMPLATES_DELETE, itemId)
        .then(value => {
          // console.log('[views.reportTemplates] Template with id: ' + itemId + ' deleted')
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('reportTemplates.templateDeleted')
          )
          this.$store.dispatch(LOADING_DECREASE)
        })
        .catch(() => {
          // console.error('[views.reportTemplates] Template with id: ' + itemId + ' could not be deleted')
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('reportTemplates.errorDeletingTheTemplate')
          )
        })
    }
  }
}
</script>
