<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{ $t('myAccount.viewTitle') }}
      </v-card-title>
      <v-card-text>
        <v-form
          ref="formInfo"
          v-model="validForm"
          value="false"
        >
          <!-- ID
          <v-text-field
            v-model="account.id"
            prepend-icon="mdi-identifier"
            readonly
            disabled
            :label="$t('myAccount.id')"
          />-->
          <!-- CODE -->
          <v-text-field
            v-if="!(account.code == undefined || account.code == null)"
            v-model="account.code"
            prepend-icon="mdi-card-account-details"
            readonly
            disabled
            :label="$t('myAccount.code')"
          />
          <!-- FIRST NAME -->
          <v-text-field
            v-model="account.firstName"
            prepend-icon="mdi-card-account-details"
            :rules="[rules.required]"
            :label="$t('myAccount.firstName')"
            @click="validateForm"
          />
          <!-- SECOND NAME -->
          <v-text-field
            v-model="account.secondName"
            prepend-icon="mdi-card-account-details"
            :rules="[rules.required]"
            :label="$t('myAccount.secondName')"
            @click="validateForm"
          />
          <!-- EMAIL -->
          <v-text-field
            v-model="account.email"
            prepend-icon="mdi-email"
            :label="$t('myAccount.email')"
            :rules="[rules.required, rules.email]"
            readonly
            disabled
            @click="validateForm"
            @keyup="validateForm"
          />
          <!-- TELEPHONE -->
          <v-text-field
            v-model="account.telephone"
            prepend-icon="mdi-phone"
            :label="$t('myAccount.telephone')"
            :rules="[]"
            @click="validateForm"
            @keyup="validateForm"
          />
          <!-- ROLE
          <v-text-field
            v-model="account.translatedRole"
            prepend-icon="mdi-shield-account"
            readonly
            disabled
            :label="$t('myAccount.role')"
          /> -->
          <!-- LANGUAGE -->
          <v-select
            v-model="account.language"
            prepend-icon="mdi-translate"
            :items="languages"
            :label="$t('myAccount.language')"
            :readonly="isLanguageReadOnly()"
          />
          <!-- TIME ZONES -->
          <v-select
            v-model="account.timeZone"
            prepend-icon="mdi-earth"
            :items="timeZones"
            :label="$t('myAccount.timeZone')"
            :readonly="isTimeZoneReadOnly()"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary darken-1"
          @click.stop="dialog = true"
        >
          {{ $t('myAccount.changePassword') }}
        </v-btn>
        <v-btn
          color="primary darken-1"
          :disabled="!validForm"
          @click="saveAccountInfo"
        >
          {{ $t('button.save') }}
        </v-btn>
      </v-card-actions>
      <v-dialog
        v-model="dialog"
        width="750"
      >
        <change-password
          @changePasswordCanceled="dialog = false"
          @changePasswordDone="dialog = false"
        />
      </v-dialog>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ChangePassword from '@/views/account/components/ChangePassword'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { ACCOUNT_GET, ACCOUNT_UPDATE } from '@/store/actions/account'
import { LANGUAGES_GET } from '@/store/actions/languages'
import { TIME_ZONES_GET } from '@/store/actions/timeZones'

export default {
  name: 'MyAccount',
  components: {
    ChangePassword
  },
  data () {
    return {
      validForm: false,
      dialog: false,
      rules: {
        required: value => !!value || this.$t('validation.requiredField'),
        email: value =>
          /.+@.+\..+/.test(value) || this.$t('validation.eMailMustBeValid')
      },
      account: {},
      languages: [],
      timeZones: []
    }
  },
  computed: {
    ...mapState('account', ['users'])
  },
  mounted () {
    this.loadData()
    this.$nextTick(() => {
      this.validateForm()
    })
  },
  methods: {
    loadData: function () {
      const accountDataPromise = this.$store
        .dispatch('account/' + ACCOUNT_GET)
        .then(account => {
          // console.log('[views.myaccount] Account data loaded')
          this.account = account
          this.account.translatedRole = this.$t('roles.' + account.role)
          if (this.languages.length === 0) {
            this.languages.push(this.account.language)
          }
          if (this.timeZones.length === 0) {
            this.timeZones.push(this.account.timeZone)
          }
        })
        .catch((/* error */) => {
          // console.error('[views.myaccount] Error loading account: ' + error)
        })
      const languagesPromise = this.$store
        .dispatch(LANGUAGES_GET)
        .then(languages => {
          // console.log('[views.myaccount] Languages data loaded')
          this.languages = languages
        })
        .catch((/* error */) => {
          // console.error('[views.myaccount] Error loading languages: ' + error)
        })
      const timeZonesPromise = this.$store
        .dispatch(TIME_ZONES_GET)
        .then(timeZones => {
          // console.log('[views.myaccount] Time zones data loaded')
          this.timeZones = timeZones
        })
        .catch((/* error */) => {
          // console.error('[views.myaccount] Error loading time zones: ' + error)
        })
      this.$store.dispatch(LOADING_INCREASE)
      Promise.all([accountDataPromise, languagesPromise, timeZonesPromise])
        .then(values => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('myAccount.dataLoaded')
          )
        })
        .catch(() => {
          this.$store.dispatch(LOADING_DECREASE)
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('myAccount.errorloadingAccount')
          )
        })
    },
    saveAccountInfo: function () {
      this.validateForm()
      if (this.validForm) {
        this.$store.dispatch(LOADING_INCREASE)
        this.$store
          .dispatch('account/' + ACCOUNT_UPDATE, this.account)
          .then(response => {
            // console.log('[views.myaccount] Account info saved correctly')
            this.$store.dispatch(LOADING_DECREASE)
            this.$store.dispatch(
              SNACKBAR_SUCCESS,
              this.$t('myAccount.dataSaved')
            )
          })
          .catch(() => {
            // console.error('[views.myaccount] Error saving the account info')
            this.$store.dispatch(LOADING_DECREASE)
            this.$store.dispatch(
              SNACKBAR_ERROR,
              this.$t('myAccount.errorSavingData')
            )
          })
      }
    },
    validateForm: function () {
      this.$refs.formInfo.validate()
    },
    isLanguageReadOnly: function () {
      return this.$store.getters['customization/isSky']
    },
    isTimeZoneReadOnly: function () {
      return this.$store.getters['customization/isSky']
    }
  }
}
</script>
