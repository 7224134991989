<template>
  <span>
    <ConfirmDialog
      v-model="dialogControl.showDeleteConfirmDialog"
      :item-id="accessedItem"
      :title="$t('reportsScheduled.deleteTitle')"
      :message="dialogControl.deleteConfirmDialogMessage"
      @confirmed="deleteItem"
    />
    <v-container fluid>
      <v-card>
        <v-card-title>
          {{ $t('reportsScheduled.viewTitle') }}
        </v-card-title>
        <v-card-text>
          <ReportsScheduledTable
            :items="reportsSchedules"
            selection="none"
            :actions="['delete']"
            @deleteItem="confirmDeleteItem"
          >
            <template #default>
              <v-btn
                color="primary"
                @click="addSchedule"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
                {{ $t('reportsScheduled.addSchedule') }}
              </v-btn>
            </template>
          </ReportsScheduledTable>
        </v-card-text>
      </v-card>
    </v-container>
  </span>
</template>
<script>
import { mapState } from 'vuex'
import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'

import ConfirmDialog from '@/components/common/ConfirmDialog.vue'
import ReportsScheduledTable from '@/views/reportsScheduled/components/ReportsScheduledTable'

import {
  REPORTS_SCHEDULES_GET_ALL,
  REPORTS_SCHEDULES_DELETE
} from '@/store/actions/reportsSchedules'

export default {
  name: 'ReportsScheduled',
  components: {
    ConfirmDialog,
    ReportsScheduledTable
  },
  data () {
    return {
      dialogControl: {
        showDeleteConfirmDialog: false,
        deleteConfirmDialogMessage: null
      },
      accessedItem: null
    }
  },
  computed: {
    ...mapState({ reportsSchedules: state => state.reportsSchedules.all })
  },
  watch: {},
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    this.$store
      .dispatch(REPORTS_SCHEDULES_GET_ALL)
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_SUCCESS,
          this.$t('reportsScheduled.loadedScheduledReports')
        )
      })
      .catch(error => {
        this.$store.dispatch(LOADING_DECREASE)
        if (error.status !== 401) {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('reportsScheduled.errorLoadingScheduledReports')
          )
        }
      })
  },
  methods: {
    addSchedule: function () {
      this.$router.push('/reportsScheduled/new')
    },
    confirmDeleteItem: function (item) {
      this.accessedItem = item.id
      this.dialogControl.showDeleteConfirmDialog = true
      this.dialogControl.deleteConfirmDialogMessage = this.$t(
        'reportsScheduled.deleteMessage',
        item
      )
    },
    deleteItem: function (itemId) {
      this.$store
        .dispatch(REPORTS_SCHEDULES_DELETE, itemId)
        .then(() => {
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('reportsScheduled.reportsScheduleDeleted')
          )
        })
        .catch(() => {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('reportsScheduled.errorRemovingReportSchedule')
          )
        })
    }
  }
}
</script>
