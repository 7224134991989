<template>
  <v-container fluid>
    <ConfirmDialog
      v-model="dialogControl.showDeleteConfirmDialog"
      :item-id="accessedItem"
      :title="$t('installerAssignments.deleteAssignmentTitle')"
      :message="dialogControl.deleteConfirmDialogMessage"
      @confirmed="deleteAssignment"
    />
    <ConfirmDialog
      v-model="dialogControl.showClearAllConfirmDialog"
      :title="$t('installerAssignments.clearAllTitle')"
      :message="$t('installerAssignments.clearAllMessage')"
      @confirmed="clearAllAssignments"
    />
    <v-dialog
      v-model="dialogControl.showAddAssignments"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <AddInstallerAssignments
        :installer="installer"
        :load="dialogControl.showAddAssignments"
        @close="dialogControl.showAddAssignments = false"
      />
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-toolbar>
          <v-btn
            icon
            color="primary"
            :title="$t('back')"
            @click="backToInstallers"
          >
            <v-icon>mdi-arrow-left-circle</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('installerAssignments.viewEditTitle') }}
            {{ userInfo }}
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <AssignmentsTable
        :items="showingData"
        :columns="headersColumns()"
        :actions="['delete']"
        selection="none"
        @deleteItem="confirmDeleteAssignment"
      >
        <template #default>
          <v-btn
            class="ma-2"
            color="primary"
            @click="addAssignments"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t('installerAssignments.newAssignments') }}
          </v-btn>
          <v-btn
            class="ma-2"
            color="primary"
            @click="confirmClearAllAssignments"
          >
            <v-icon left>
              mdi-table-large-remove
            </v-icon>
            {{ $t('installerAssignments.clearAll') }}
          </v-btn>
        </template>
      </AssignmentsTable>
    </v-row>
  </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import router from '@/router'

import { LOADING_INCREASE, LOADING_DECREASE } from '@/store/actions/loading'
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from '@/store/actions/snackbar'
import { USERS_GET_ALL } from '@/store/actions/users'
import {
  ASSIGNMENTS_GET_ALL,
  ASSIGNMENTS_DELETE,
  ASSIGNMENTS_DELETE_OF_USER
} from '@/store/actions/assignments'
import { HEADENDS_GET_ALL } from '@/store/actions/headends'

import AssignmentsTable from '@/views/installerAssignments/components/AssignmentsTable'
import AddInstallerAssignments from '@/views/installerAssignments/components/AddInstallerAssignments'
import ConfirmDialog from '@/components/common/ConfirmDialog.vue'

export default {
  name: 'InstallerAssignments',
  components: {
    AssignmentsTable,
    ConfirmDialog,
    AddInstallerAssignments
  },
  data () {
    return {
      userInfo: '',
      dialogControl: {
        showAddAssignments: false,
        showDeleteConfirmDialog: false,
        deleteConfirmDialogMessage: null,
        showClearAllConfirmDialog: false
      },
      accessedItem: null,
      showingData: []
    }
  },
  computed: {
    ...mapGetters({
      getHeadendByContractId: 'getHeadendByContractId',
      getHeadendById: 'getHeadendById'
    }),
    ...mapState({
      assignments: state => {
        return state.assignments.all
      }
    }),
    installer () {
      const installer = this.$store.getters.getUserById(
        this.$route.params.userId
      )
      if (installer) {
        installer.roleTranslation = this.$t('roles.' + installer.role)
        installer.assignments = this.$store.getters.getUserAssignments(
          installer.id
        )
      }
      return installer
    }
  },
  watch: {
    installer: function (item) {
      this.userInfo = item.code + ' - ' + item.firstName + ' ' + item.secondName
    },
    assignments: function (item) {
      this.matchAssginments()
    }
  },
  mounted () {
    this.$store.dispatch(LOADING_INCREASE)
    Promise.all([
      this.$store.dispatch(ASSIGNMENTS_GET_ALL),
      this.$store.dispatch(USERS_GET_ALL),
      this.$store.dispatch(HEADENDS_GET_ALL)
    ])
      .then(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_SUCCESS,
          this.$t('installerAssignments.installerDataLoaded')
        )
        this.matchAssginments()
      })
      .catch(() => {
        this.$store.dispatch(LOADING_DECREASE)
        this.$store.dispatch(
          SNACKBAR_ERROR,
          this.$t('installerAssignments.errorLoadingInstaller')
        )
      })
  },
  methods: {
    backToInstallers: function () {
      router.push('/installers')
    },
    headersColumns: function () {
      if (this.$isSky) {
        // SKY headers in order
        return ['contractId', 'id', 'remoteSiteDeviceName', 'systemType']
      } else if (this.$isTabcorp) {
        // Tabcorp headers in order
        return ['venueId', 'id', 'alias', 'remoteSiteDeviceName']
      } else {
        // Default headers in order
        return ['id', 'alias', 'remoteSiteDeviceName']
      }
    },
    addAssignments: function () {
      this.dialogControl.showAddAssignments = true
    },
    confirmClearAllAssignments: function () {
      this.dialogControl.showClearAllConfirmDialog = true
    },
    clearAllAssignments: function () {
      this.$store
        .dispatch(ASSIGNMENTS_DELETE_OF_USER, this.installer.id)
        .then(() => {
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('installerAssignments.assignmentsCleared')
          )
        })
        .catch(() => {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('installerAssignments.errorClearingAllAssignments')
          )
        })
    },
    confirmDeleteAssignment: function (item) {
      this.accessedItem = item.id
      this.dialogControl.showDeleteConfirmDialog = true
      this.dialogControl.deleteConfirmDialogMessage = this.$t(
        'installerAssignments.deleteMessage',
        item
      )
    },
    deleteAssignment: function (itemId) {
      this.$store
        .dispatch(ASSIGNMENTS_DELETE, itemId)
        .then(() => {
          this.$store.dispatch(
            SNACKBAR_SUCCESS,
            this.$t('installerAssignments.assignmentDeleted')
          )
        })
        .catch(() => {
          this.$store.dispatch(
            SNACKBAR_ERROR,
            this.$t('installerAssignments.errorDeletingAssignment')
          )
        })
    },
    matchAssginments: function () {
      this.showingData = []
      if (!this.installer) {
        return
      }
      this.installer.assignments.forEach(assignment => {
        if (assignment.headendId !== undefined) {
          assignment.headend = this.getHeadendById(assignment.headendId)
          this.showingData.push(assignment)
        } else {
          assignment.headend = this.getHeadendByContractId(
            assignment.clientHeadendId
          )
          this.showingData.push(assignment)
        }
      })
    }
  }
}
</script>
