<template>
  <v-list>
    <!-- AVATAR -->
    <v-list-item>
      <v-list-item-avatar>
        <v-avatar color="indigo">
          <span
            class="white--text headline"
          >{{ myAccount.firstName[0] }}{{ myAccount.secondName[0] }}</span>
        </v-avatar>
      </v-list-item-avatar>
      <v-spacer />
      <v-list-item-action>
        <v-btn
          v-ripple
          icon
          text
          rounded
          :title="$t('menuClose')"
          color="primary"
          @click="$emit('return')"
        >
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
      </v-list-item-action>
      <!-- ACCOUNT -->
    </v-list-item>
    <v-list-item
      to="/myAccount"
      link
    >
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ myAccount.firstName }} {{ myAccount.secondName }}
        </v-list-item-title>
        <v-list-item-subtitle>{{ myAccount.sub }}</v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-icon>mdi-account-circle</v-icon>
      </v-list-item-action>
    </v-list-item>
    <v-divider />
    <!-- HOME -->
    <v-list-item
      to="/"
      link
    >
      <v-list-item-icon>
        <v-icon>mdi-home</v-icon>
      </v-list-item-icon>

      <v-list-item-title>{{ $t('menuItem.homepage') }}</v-list-item-title>
    </v-list-item>
    <!-- MENU -->

    <v-list-group
      v-for="section in menu"
      :key="section.name"
      :value="section.active"
      :prepend-icon="section.icon"
      no-action
    >
      <template #activator>
        <v-list-item-title>{{ section.name }}</v-list-item-title>
      </template>
      <v-list-item
        v-for="view in section.views"
        :key="view.name"
        :to="view.path"
        link
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("menuItem."+view.name) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
    <v-divider />
    <!-- LOGOUT -->
    <v-list-item @click="logout">
      <v-list-item-content>
        {{ $t('logout') }}
      </v-list-item-content>
      <v-list-item-icon>
        <v-icon>mdi-toggle-switch-off</v-icon>
      </v-list-item-icon>
    </v-list-item>
    <slot />
  </v-list>
</template>

<script>
import { AUTH_LOGOUT } from '@/store/actions/authentication'
import { mapState, mapGetters } from 'vuex'
import views from '@/router/views.router'
import { MenuSection } from '@/models/menu-section.model'

export default {
  name: 'TheMenu',
  computed: {
    ...mapState({ myAccount: state => state.authentication.account }),
    ...mapGetters(['hasRights']),
    menu: function () {
      const menuSections = []

      const reports = new MenuSection(this.$t('menuItem.reports'), 'mdi-file-chart', ['skyit', 'tabcorp', 'ikusi'])
      reports.setViews(
        views.filter(viewRoute => viewRoute.menuSection === 'reports')
          .filter(viewRoute => viewRoute.clients.includes(this.$store.getters['customization/customizationType']))
          .filter(viewRoute => this.isSectionElements(viewRoute))
      )
      if (reports.views.length > 0) {
        menuSections.push(reports)
      }

      const orders = new MenuSection(this.$t('menuItem.orders'), 'mdi-calendar-check', ['skyit', 'tabcorp', 'ikusi'])
      orders.setViews(
        views.filter(viewRoute => viewRoute.menuSection === 'orders')
          .filter(viewRoute => viewRoute.clients.includes(this.$store.getters['customization/customizationType']))
          .filter(viewRoute => this.isSectionElements(viewRoute))
      )
      if (orders.views.length > 0) {
        menuSections.push(orders)
      }

      const headends = new MenuSection(this.$t('menuItem.headends'), 'mdi-server', ['skyit', 'tabcorp', 'ikusi'])
      headends.setViews(
        views.filter(viewRoute => viewRoute.menuSection === 'headends')
          .filter(viewRoute => viewRoute.clients.includes(this.$store.getters['customization/customizationType']))
          .filter(viewRoute => this.isSectionElements(viewRoute))
      )
      if (headends.views.length > 0) {
        menuSections.push(headends)
      }

      const channelMaps = new MenuSection(this.$t('menuItem.channelMaps'), ' mdi-television-guide', ['tabcorp'])
      channelMaps.setViews(
        views.filter(viewRoute => viewRoute.menuSection === 'channelMaps')
          .filter(viewRoute => viewRoute.clients.includes(this.$store.getters['customization/customizationType']))
          .filter(viewRoute => this.isSectionElements(viewRoute))
      )
      if (channelMaps.views.length > 0) {
        menuSections.push(channelMaps)
      }

      const users = new MenuSection(this.$t('menuItem.users'), 'mdi-account-group', ['skyit', 'tabcorp', 'ikusi'])
      users.setViews(views.filter(viewRoute => viewRoute.menuSection === 'users')
        .filter(viewRoute => viewRoute.clients.includes(this.$store.getters['customization/customizationType']))
        .filter(viewRoute => this.isSectionElements(viewRoute))
      )
      if (users.views.length > 0) {
        menuSections.push(users)
      }

      const userSections = menuSections.filter(menuSection => menuSection.clients.includes(this.$store.getters['customization/customizationType']))

      return userSections
    }
  },
  methods: {
    isSectionElements: function (viewRoute) {
      return this.validateRol(viewRoute.roles) && this.validateScopes(viewRoute.scopes)
    },
    validateRol: function (roles) {
      if (roles.length === 0) {
        return true
      }
      for (let i = 0; i < roles.length; i++) {
        if (roles[i] === this.myAccount.role) {
          return true
        }
      }
      return false
    },
    validateScopes: function (scopes) {
      if (scopes.length === 0) {
        return true
      }
      for (let i = 0; i < scopes.length; i++) {
        if (!this.hasRights(scopes[i])) {
          return false
        }
      }
      return true
    },
    logout: function () {
      this.$store.dispatch(AUTH_LOGOUT).then(() => {
        this.$emit('logout')
        this.$router.push('/login')
      })
    }
  }
}
</script>
